export const partyTypeOptions = [
    "Warehouse", "Client", "Vendor", "Technician", "M2M Account"
];

export const validSimStatuses = [
    "Available", "Active", "Deactivated", "Safe Custody", "Temp Disconnected"
];

export const SUBSCRIPTIONS = {
    "1y": "1 Year",
    "3y": "3 Years",
}

export const ROLES = {
    INVENTORY: "inventory", // done
    MANAGE_USERS: "manageUsers", // done
    ADD_DEVICES: "addDevices", // done
    WEB_LOGIN: "webLogin", // done
    APP_LOGIN: "appLogin", // to do in app only
    ACCESS_LOGS: "accessLogs", // done
}

export const ROLES_LABELS = {
    [ROLES.INVENTORY]: "Inventory Transfer",
    [ROLES.MANAGE_USERS]: "Manage Users & Roles",
    [ROLES.ADD_DEVICES]: "Add Devices",
    [ROLES.WEB_LOGIN]: "Web Login",
    [ROLES.APP_LOGIN]: "App Login",
    [ROLES.ACCESS_LOGS]: "Logs Access",
};

export const DEVICE_STATES = [
    "Blank", "Mapped", "Installed"
];

export const SYMBOLS = {
    RUPEE: "₹",
}