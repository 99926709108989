import React, { useEffect, useState } from "react";
import { Table, Button, Row, Col, Tabs, Input, DatePicker, Select } from "antd";
import { getTimeline } from "../../services/api";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { beautifyAmount, customDispatcher } from "../../utils";

const { TabPane } = Tabs;

const { RangePicker } = DatePicker;

const Inventory = () => {
  const state = useSelector((state) => state.oldState);
  const dispatch = customDispatcher(useDispatch());
  const navigate = useNavigate();
  const params = useParams();
  const { inventoryType } = params;
  // const [inventoryType, setinventoryType] = useState("debtor");
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filterDate, setFilterDate] = useState([]);
  const [filterText, setFilterText] = useState([]);


  const handleChange = (value) => {
    setFilterText(value);
  };


  const fetchRecords = async () => {
    setLoading(true);
    const response = await getTimeline();
    const resOffers = _.get(response, "data.data.statement", []);
    setRecords(resOffers);
    setLoading(false);
  };

  useEffect(() => {
    if (state.company && inventoryType === 'timeline') {
      fetchRecords();
    }
  }, [inventoryType, state]);

  useEffect(() => {
    setFilterDate([]);
    setFilterText([]);
    setSearchText("");
    if(inventoryType === 'timeline'){
        fetchRecords()
    }
  }, [inventoryType]);


  const applyFilters = (data) => {
    const [start, end] = filterDate || [];
    let filterByDate = true;
    let filterByText = true;
    if (start && end) {
      filterByDate = moment(data.date).isBetween(
        start.startOf("day"),
        end.endOf("day")
      );
    }
    if (filterText.length) {
      filterByText = filterText.includes(data.itemId);
    }
    return filterByDate && filterByText;
  };

  const timelineFilteredRecords = records.filter(applyFilters);

  const columns = [
    {
      title: "Item",
      weight: 1,
      include: ["timeline", "warehouse"],
      render: (obj) => (
        <b>
          {obj.name
            ? obj.name
            : state.items && state.items.map((i) => (i._id === obj.itemId ? i.name : ""))}
        </b>
      ),
    },
    {
      title: "Qty",
      weight: 1,
      include: ["timeline"],
      render: (obj) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <b>
            {obj.qty > 0 ? (
              <div style={{ color: "green", marginLeft: "3px" }}>
                <ArrowDownOutlined />
                {obj.qty}
              </div>
            ) : (
              <div style={{ color: "red", marginLeft: "3px" }}>
                <ArrowUpOutlined />
                {obj.qty < 0 ? obj.qty.toString().substring(1) : "0"}
              </div>
            )}
          </b>
        </div>
      ),
    },

    {
        title: "Current Stock",
        weight: 1,
        include: ["warehouse"],
        render: (obj) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <b>
              {obj.qty > 0 ? (
                <div style={{ color: "green"}}>
                  {'+' + obj.qty}
                </div>
              ) : (
                <div style={{ color: "red"}}>
                  {obj.qty || "0"}
                </div>
              )}
            </b>
          </div>
        ),
      },

    {
      title: "Record Type",
      weight: 1,
      include: ["timeline"],
      // dataIndex: 'state',
      render: (obj) => obj.recordType,
    },
    {
      title: "Remarks",
      weight: 1,
      include: ["timeline"],
      render: (obj) => obj.remarks,
    },
    {
      title: "Date",
      include: ["timeline"],
      render: (obj) => (
        <div style={{ color: "#686565" }}>
          {moment(obj.date).format("DD MMM YYYY")}
        </div>
      ),
      sorter: (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf(),
      defaultSortOrder: "descend",
    },
    {
      title: "Selling Price",
      weight: 1,
      include: ["warehouse"],
      render: (obj) => (
        <b style={{ color: "green" }}>
          ₹ {beautifyAmount(obj.defaultRate ? obj.defaultRate : 0)}
        </b>
      ),
    },
    {
      title: "Actions",
      weight: 1,
      include: ["warehouse"],
      render: (obj) => {
        return (
          <div style={{ fontSize: 20 }}>
            <Button
              className={"btnCustom4 hoverOrangeBorder"}
              style={{
                background: "#a2f998",
                color: "green",
                padding: 0,
                height: 28,
                fontWeight: "bold",
                fontSize: 13,
                paddingLeft: 8,
                paddingRight: 8,
              }}
              onClick={(e) => {
                e.stopPropagation();
                openCustomerDetailsDrawer(obj, true);
              }}
            >
              <ArrowDownOutlined style={{ color: "green" }} /> Stock in
            </Button>
            <Button
              className={"btnCustom4 hoverOrangeBorder"}
              style={{
                background: "#f5c4c8",
                color: "red",
                padding: 0,
                height: 28,
                fontWeight: "bold",
                fontSize: 13,
                paddingLeft: 8,
                paddingRight: 8,
                marginLeft: 10,
              }}
              onClick={(e) => {
                e.stopPropagation();
                openCustomerDetailsDrawer(obj, false);
              }}
            >
              <ArrowUpOutlined style={{ color: "red" }} /> Stock out
            </Button>
          </div>
        );
      },
    },
  ].filter((c) => c.include.includes(inventoryType));

  const openCustomerDetailsDrawer = (obj, stockIn) =>
    dispatch({
      popData: {
        visible: true,
        width: 550,
        type: "stockInOut",
        title: stockIn ? "Add Stock" : "Remove Stock",
        stockIn: stockIn,
        data: obj,
        bodyStyle: {
          padding: 16,
        },
      },
    });

  return (
    <div style={{ padding: 24, paddingTop: 8 }}>
      <Row>
        <Col span={12} style={{ fontSize: 24, fontWeight: "bold" }}>
          Inventory
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <Tabs
            className={"tabsLedgers"}
            activeKey={inventoryType}
            onChange={(newInventoryType) =>
              navigate(`/inventory/${newInventoryType}`)
            }
          >
            <TabPane
              className={"tabsLedgers"}
              tab={<div style={{ fontSize: 15 }}>Warehouse</div>}
              key="warehouse"
            >
              {/*Content of Tab Pane 1*/}
            </TabPane>
            <TabPane
              tab={<div style={{ fontSize: 15 }}>Timeline</div>}
              key="timeline"
            >
              {/*This is totally new content for 2nd Tab*/}
            </TabPane>
          </Tabs>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}></Col>
      </Row>
      <Row>
        {inventoryType === "warehouse" ? (
          <Input.Search
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={"Search with Item name"}
            style={{
              width: "auto",
              minWidth: 300,
              // height: 40
            }}
            allowClear
          />
        ) : (
          <>
            <>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "auto",
                  minWidth: 300,
                }}
                placeholder="Filter by items"
                onChange={handleChange}
                options={_.map(state.items || [], ({name, _id}) => ({
                    label: name,
                    value: _id
                }))}
                filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
              >
              </Select>
            </>
            &nbsp;
            <>
              <RangePicker
                style={{}}
                format={"DD MMM YYYY"}
                onChange={(val) => setFilterDate(val)}
              />
            </>
          </>
        )}
      </Row>
      &nbsp;&nbsp;
      <Table
        loading={loading}
        columns={columns}
        dataSource={(inventoryType === "timeline"
          ? timelineFilteredRecords
          : state.items
          ? state.items
          : []
        ).filter((r) =>
          (r.name || "").toLowerCase().includes(searchText.toLowerCase())
        )}
      />
    </div>
  );
};

export default Inventory;
