import React, {useEffect, useState} from "react";
import _ from "lodash";
import {useNavigate, useParams} from "react-router-dom";
import {PRIMARY_COLOR} from "../../models/constants";
import {Col, message, Row, Spin} from "antd";
import {cashfreeSandbox, cashfreeProd} from "cashfree-dropjs";
import {getPayOrder} from "../../services/api";
import {isProdEnv, isWhitelistedForPg} from "../../utils";
import payPageIcon from "../../res/img/payGif.gif";
import {RazorpayUI} from "./razorpay";

const Cashfree = isProdEnv() ? cashfreeProd : cashfreeSandbox;

const renderPaymentFrame = ({orderToken, transactionId, navigate}) => {
    const cashfree = new Cashfree.Cashfree();
    let parent = document.getElementById('paymentFrame');
    parent.innerHTML = '';
    cashfree.initialiseDropin(parent, {
        orderToken: orderToken, // "92IoGiZIcqOEwYzz48N4",
        onSuccess: (data) => {
            console.log("## Success ##", data);
            navigate(`/pay/${transactionId}/summary`);
        },
        onFailure: (err) => {
            console.log("## Err ##", err);
            message.error(`Something went wrong. Please try again.`);
        },
        components: [
            // "order-details",
            "card",
            "netbanking",
            "app",
            "upi"
        ],
        style: {
            theme: "light",
            color: PRIMARY_COLOR
        },
    });
}

const LoadingPaymentComponent = ({msg}) => {
    return <div style={{textAlign: "center", marginTop: 200}}>
        <img src={payPageIcon} width={168} style={{borderRadius: 200, opacity: 0.5}}/>
        <br/>
        <br/>
        {
            msg || <Spin size={"large"} spinning={true}/>
        }
    </div>
}

const CashfreeUI = ({payOrder, transactionId}) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (payOrder?.status === "unpaid") {
            renderPaymentFrame({
                orderToken: payOrder.pgOrderToken,
                transactionId,
                navigate,
            });
        }
    }, [payOrder])

    return <div>
        {/*<NavBar*/}
        {/*    style={{background: PRIMARY_COLOR, color: 'white'}}*/}
        {/*    onBack={() => navigate(`/pay/${transactionId}`)}*/}
        {/*>*/}
        {/*    KhataBuddy*/}
        {/*</NavBar>*/}
        <div style={{borderBottom: '1px solid lightgrey', background: PRIMARY_COLOR, color: 'white', padding: 16}}>
            <div style={{fontWeight: 'bold', fontSize: 18}}>
                <Row>
                    <Col span={12}>
                        Amount to Pay
                    </Col>
                    <Col span={12} style={{textAlign: 'right', fontSize: 22}}>
                        ₹ {payOrder.amount}
                    </Col>
                </Row>
            </div>
            <div style={{fontSize: 16}}>
                to {payOrder?.companyDetails?.name}
            </div>
        </div>
        <div id={"paymentFrame"}></div>
        <div style={{textAlign: 'right', paddingRight: 16}}>
            Powered by KhataBuddy
        </div>
    </div>;
}

const TransactionPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const {transactionId} = params;
    const [payOrder, setPayOrder] = useState({reset: true});

    if (_.get(payOrder, 'orderDetails.type') === 'license') {
        _.set(payOrder, 'companyDetails.name', "KhataBuddy");
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await getPayOrder(transactionId, "n");
            const payOrder = response.data.data?.payOrder;
            setPayOrder(payOrder);
            if (payOrder?.status === "paid") {
                navigate(`/pay/${transactionId}/summary`);
            }
        }
        fetchData();
    }, [transactionId, navigate]);


    return (
        <div style={{background: '#eeeeee', height: '100%', minHeight: '100vh'}}>
            <Row align={"middle"} justify={"center"} style={{border: '0px solid black', height: '100%'}}>
                <Col span={24} style={{maxWidth: '13cm', border: '0px solid red', background: '#fafafa', height: '100vh'}}>
                    {
                        payOrder?.reset && <LoadingPaymentComponent/>
                    }
                    {
                        !payOrder && <>
                            <LoadingPaymentComponent msg={"Invalid Payment Link"}/>
                        </>
                    }
                    {
                        payOrder?.pg === "cashfree" && <CashfreeUI payOrder={payOrder} transactionId={transactionId} />
                    }
                    {
                        payOrder?.pg === "razorpay" && <RazorpayUI payOrder={payOrder} transactionId={transactionId} />
                    }

                </Col>
            </Row>
        </div>
    )
};

const IFrameRedirectionPage = () => {
    const params = useParams();
    const {transactionId} = params;

    return <div style={{width: '100vh', height: '100vh'}}>
        <iframe
        src={`https://trakinfinity.infinitynk.com/pay/${transactionId}/gateway`}
        style={{width: '100%', height: '100%'}}
        />
    </div>
}

const PageWrapperWithSwitch = () => {
    return isWhitelistedForPg() ? <TransactionPage /> : <IFrameRedirectionPage/>
}

export default PageWrapperWithSwitch;
