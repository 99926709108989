import React, {useEffect} from "react";
import {Button, Divider, Form, Input, InputNumber, message, Select} from "antd";
import _ from "lodash";
import {taxes, units} from "../../models/constants";
import {loginRequest, saveItem} from "../../services/api";
import {customDispatcher} from "../../utils";
import {useDispatch} from "react-redux";
import {SwitchSelect} from "../../components/switch";
import {UploadImage} from "../../components/UploadImage";
import { ButtonV2 } from "../../components/ButtonV2";

const DrawerItem = ({selectedRecord, closeDrawer, onSave = () => {}}) => {
    const dispatch = customDispatcher(useDispatch());
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [selectedRecord, form]);

    return (
        <div>
            <Form
                form={form}
                labelCol={{
                    span: 10, style: {
                        textAlign: 'left',
                    }
                }}
                wrapperCol={{span: 13}}
                layout="horizontal"
                name="form_in_modal"
                initialValues={selectedRecord}
            >
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder={"Item Name"}/>
                </Form.Item>
                {/*<Row>*/}
                {/*    <Col span={12} style={{paddingRight: 8}}>*/}
                        <Form.Item name={"type"} label={"Type"}>
                            <Select
                                defaultValue={"Product"}
                                options={[{label: "Product", value: "Product"}, {label: "Service", value: "Service"}]}
                            />
                        </Form.Item>
                    {/*</Col>*/}
                    {/*<Col span={12} style={{paddingLeft: 8}}>*/}
                        <Form.Item name={"unit"} label={"Unit"} rules={[{required: true}]}>
                            <Select
                                defaultValue={"NOS"}
                                options={_.map(units, ({code, name}) => ({label: `${name} (${code})`, value: code}))}
                                filterOption={(input, option) => {
                                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                                showSearch
                            />
                        </Form.Item>
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row>*/}
                {/*    <Col span={12} style={{paddingRight: 8}}>*/}
                        <Form.Item name={"taxRate"} label={"Tax Rate"} rules={[{required: true}]}>
                            <Select
                                placeholder={"Select Tax Rate"}
                                options={_.map(taxes, ({name, rate}) => ({label: name, value: rate}))}
                            />
                        </Form.Item>
                    {/*</Col>*/}
                    {/*<Col span={12} style={{paddingLeft: 8}}>*/}
                        <Form.Item name="hsn" label={"HSN / SAC Code"}><Input placeholder={"HSN / SAC Code"} style={{width: '100%'}}/></Form.Item>
                {/*    </Col>*/}
                {/*</Row>*/}
                <Form.Item name="defaultRate" label={"Selling Price (per unit)"}><InputNumber placeholder={"Default Selling Price"} min={0} style={{width: '100%'}}/></Form.Item>
                <Form.Item name={"defaultDescription"} label={"Default Description"}>
                    <Input.TextArea placeholder={"Default Item Description"}/>
                </Form.Item>
                {/*<Form.Item name="phone" label={"Phone"} rules={[*/}
                {/*    {*/}
                {/*        required: true,*/}
                {/*    },*/}
                {/*]}><Input/></Form.Item>*/}

                <Divider/>
                <h3>Ecommerce Store Settings</h3>
                <Form.Item name={"showInEcommerceStore"} label={"Show in Ecommerce Store"}>
                    <SwitchSelect
                        defaultChecked={true}
                        checkedChildren={"Show"}
                        unCheckedChildren={"Don't Show"}
                        valuesMap={{
                            true: true,
                            false: false
                        }}
                        reverseMap={{
                            true: true,
                            false: false
                        }}
                    />
                </Form.Item>
                <Form.Item name={"img"} label={"Product Image"}>
                    <UploadImage/>
                </Form.Item>
            </Form>
            <br/>
            <ButtonV2
                type={"primary"}
                className={"customBorder"}
                onClick={async () => {
                    form
                        .validateFields()
                        .then(async (values) => {
                            if (!values.defaultRate) {
                                values.defaultRate = 0;
                            }
                            if (!values.defaultDescription) {
                                values.defaultDescription = "";
                            }
                            _.assign(selectedRecord, values);
                            try {
                                const resp = await saveItem(selectedRecord);
                                const newItemAdded = _.get(resp, 'data.data.item');
                                message.success("Item Saved Successfully");
                                closeDrawer();
                                await loginRequest("", "", dispatch);
                                onSave(newItemAdded);
                                // fetchRecords();
                            } catch (err) {
                                message.error('Failed to save item');
                                console.log("Failed to save user", err);
                            }
                        })
                        .catch((info) => {
                            message.error(info?.errorFields?.[0]?.errors?.[0] || "kindly fill all the mandatory field");
                            console.log("## Validate Failed ##", info);
                        });
                }}
            >
                Save Item
            </ButtonV2>
        </div>
    )
};

export default DrawerItem;
