import React, {useEffect, useState} from "react";
import {Button, Divider, message, Table, Tag, Tooltip, Popconfirm} from "antd";
import { fetchBankDetails, changeDefaultBank, loginRequest } from "../../services/api";
import {CheckCircleOutlined, DeleteOutlined} from "@ant-design/icons";
import {customDispatcher} from "../../utils";
import {useDispatch, useSelector} from "react-redux";
import _ from 'lodash'
import { saveLedger, deleteLedger } from "../../services/api";
import {
    EditOutlined,
} from "@ant-design/icons";
import { ButtonV2 } from "../../components/ButtonV2";

const getBankDetails = async ()=>{
    try{
        const response = await fetchBankDetails();
        const {bankDetails} = _.get(response,'data.data', []);
        return bankDetails||[];
    }catch(e){
        console.log(e, 'Error in Retriving bank details')
        return []
    }
}

async function bankDetails({defaultBankId, setRecords}){
    const bankRecords = [];
    const bankDetails = await getBankDetails();
    bankDetails.forEach(bank=>{
        bankRecords.push({
            name: bank.name,
            accountNumber: bank.bankDetails.accountNumber,
            ifscCode: bank.bankDetails.ifscCode,
            branchName: bank.bankDetails.branchName,
            extraInfo: bank.bankDetails.extraInfo,
            bankName: bank.bankDetails.bankName,
            default: defaultBankId === bank._id,
            _id: bank._id,
        })
    })
    setRecords(bankRecords)
}

const BankSettings = () => {
    let title = 'Bank Details';

    const [records,setRecords] = useState([]);
    const deleteRecord = deleteLedger;
    const dispatch = customDispatcher(useDispatch());
    const state = useSelector(state => state.oldState);
    const defaultBankId = state?.company?.invoiceConfig?.defaultBankId;
    

    useEffect(() => {

        if (state.company) {
            bankDetails({defaultBankId, setRecords});
        }
    }, [state, defaultBankId]);

    const columns=[
        {
            title:'Name',
            dataIndex:'name',
            key:'name',
        },
        {
            title:'Account Number',
            dataIndex:'accountNumber',
            key:'accountNumber',
        },
        {
            title:'IFSC code',
            dataIndex:'ifscCode',
            key:'ifscCode',
        },
        {
            title:'Branch Name',
            dataIndex:'branchName',
            key:'branchName',
        },
        {
            title:'Bank Name',
            dataIndex:'bankName',
            key:'bankName',
        },
        {
            title:'Actions',
            dataIndex:'default',
            key:'default',
            render: (val, record)=>{
                return (
                  <div>
                    {val === true ? (
                      <Tag icon={<CheckCircleOutlined />} color="success" style={{width:110}}>
                        Default
                      </Tag>
                    ) : (
                      <ButtonV2
                        type={"primary"}
                        onClick={async () => {
                          setBankAsDefault(record);
                        }}
                        size={"small"}
                      >
                        Set as Default
                      </ButtonV2>
                    )}
                  </div>
                );
            },
        },
        {
            title:'',
            dataIndex:'defaultAction',
            key:'defaultAction',
            render:(val, record)=>{
                return(
                    <div>
                        <Tooltip title={"Edit"}>
                            <Button className={"btnCustom4 hoverOrangeBorder"}
                                    style={{
                                        background: '#ffecb3',
                                        color: '#ff6f00',
                                        padding: 0,
                                        height: 28,
                                        fontWeight: 'bold',
                                        fontSize: 13,
                                        paddingLeft: 8,
                                        paddingRight: 8
                                    }}
                                    onClick={(e) => {
                                        record.type = 'cash';
                                        record.typeofLedger ='bank'
                                        record.title = "Bank Details"
                                        e.stopPropagation();
                                        console.log("## Btn Clicked ##", record);
                                        openCustomerDetailsDrawer(record);
                                    }}
                            ><EditOutlined style={{color: '#ff6f00'}} /> Edit
                            </Button>
                        </Tooltip>
                        <Popconfirm
                        title="Are you sure to delete this account ?"
                        onConfirm={async (e) => {
                            e.stopPropagation();
                            deleteRecord(record._id)
                                .then(async () => {
                                    message.success('Bank Account Deleted');
                                    await bankDetails({defaultBankId, setRecords});
                                })
                                .catch(() => message.error('Failed to delete Bank Accoun'))
                        }}
                        onCancel={(e) => {
                            e.stopPropagation();
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        {record?.default !== true && <Tooltip title={"Delete"}>
                            <Button className={"btnCustom4 hoverRedBorder"}
                                    style={{
                                        background: '#ffebee',
                                        color: '#b71c1c',
                                        padding: 0,
                                        height: 28,
                                        fontWeight: 'bold',
                                        fontSize: 13,
                                        paddingLeft: 8,
                                        paddingRight: 8
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                            ><DeleteOutlined style={{color: '#b71c1c'}} onClick={e => e.stopPropagation()}/>
                            </Button>
                        </Tooltip>}
                    </Popconfirm>
                    </div>
                )
            }
        }
    ]


    const setBankAsDefault = async (record)=>{
    
        try{
       const newDefault = record?._id;

       records.forEach((bank) => {
         
         if (bank?.default) {
           bank.default = false;
         }

         if (newDefault === bank._id) {
           bank.default = true;
         }
       });

       const body = {
           newDefault
       }
       const response = await changeDefaultBank(body);
       if(response?.data?.data !== 'success'){
           return message.error('Error in setting bank details')
       }
       message.success('Bank Details updated Successfully')
       setRecords([...records]);
       await loginRequest("", "", dispatch);
    }catch(e){
        console.log('Error in New Default',e)
        message.error('Error in setting bank details')
    }
        
    }

    const openCustomerDetailsDrawer = (selectedLedger) => dispatch({
        popData: {
            visible: true,
            width: 550,
            type: "ledger",
            title: selectedLedger ? `Edit Bank Details` : `Add New Bank Details`,
            data: {
                selectedRecord: selectedLedger ? selectedLedger : {
                    type: 'cash',
                    typeofLedger:'bank',
                    title,
                },
                onSave: async (values) => {
                    const isNewLedger = !selectedLedger;
                    const baseLedger = isNewLedger ? {} : selectedLedger;
                    _.assign(baseLedger, values);
                    try {
                        console.log("## BaseLedger ##", baseLedger, values);
                        await saveLedger(baseLedger);
                        await bankDetails({defaultBankId, setRecords});
                        message.success("Bank Details Saved Successfully");
                    } catch (err) {
                        message.error('Failed to save customer');
                        console.log("Failed to save customer", err);
                    }
                }
            },
            bodyStyle: {
                padding: 16,
            }
        }
    });

    return(
        <div >
           <h2 style={{float: 'left'}}>Bank Details</h2>
           <Divider/>
           <div>
               <h2>Create dynamic QR codes on Invoices at <span style={{color:'green'}}>ZERO Transaction</span> charge!</h2>
           </div>
           <div>
               <p>Link your UPI ID with the Bank Account now to generate dynamic QR codes on Invoices and Bills.<br/>
<strong>Your UPI ID is an address that identifies you on UPI (typically yourname@bankname).</strong><br/>
Payments will be settled to your bank account linked with UPI ID instantly.
</p>
           </div>
           <div>
               <Button type="primary" onClick={(e)=>{
                //    setNewPopUp(true);
                openCustomerDetailsDrawer();
               }}>
                   <strong>+</strong> Add New Bank Details
               </Button>
           </div>
           <div style={{marginTop:'5%'}}>
               <Table columns={columns} dataSource={records}/>
           </div>
        </div>
    )
};

export default BankSettings;
