const moment = require('moment');
export const getCurrentFinancialYear = () => {
    try {
        const todayDate = new Date();
        const financialYearStartMonth = 3; // April
        const financialYearEndMonth = 2; // March
        let currentYear = moment(todayDate).year();
        
        // If the given date is before the fiscal year start month (April), consider it as the previous fiscal year
        if (moment(todayDate).month() < financialYearStartMonth) {
            currentYear -= 1;
        }
        
        const financialYearStart = moment([currentYear, financialYearStartMonth]);
        const financialYearEnd = moment([currentYear + 1, financialYearEndMonth]).endOf('month');
  
        return [financialYearStart, financialYearEnd];
    } 
    catch(err) {
        return [];
    }
}

const getLastFinancialYear = () => {
    const currentYearDateRange = getCurrentFinancialYear();
    const lastFinancialYear = currentYearDateRange.map(dateString => dateString.subtract(1, 'years'));
    return lastFinancialYear;
  };
  
const presetRanges = {
    'Today': [moment(), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'This FY': getCurrentFinancialYear(),
    'Last FY': getLastFinancialYear(),
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Quarter': [moment().startOf('quarter'), moment().endOf('quarter')],
    'Last Quarter': [
      moment().subtract(1, 'quarter').startOf('quarter'),
      moment().subtract(1, 'quarter').endOf('quarter')
    ]
  };

export const getPresetDateRange = () => {
    return presetRanges
}
