import React from "react";

const ImportData = () => {



    return (
        <div>
            <h2>Import Data (Upcoming Feature)</h2>
        </div>
    )
};

export default ImportData;
