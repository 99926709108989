import React, {useEffect, useState} from "react";
import moment from "moment";
import {Button, Input, message, Popconfirm, Table, Tag, Tooltip} from "antd";
import {useSelector} from "react-redux";
import {deletePucSale, deletePucVehicle, getPucSales, getPucVehicles, notifyPucUser} from "../../services/api";
import {DeleteOutlined, EyeOutlined, MessageOutlined, WhatsAppOutlined} from "@ant-design/icons";
import {isValidPhone} from "../../utils";
import HistoryDrawer from "./historyDrawer";

const PucVehicles = () => {
    const state = useSelector(state => state.oldState);
    const [vehicles, setVehicles] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [showHistory, setShowHistory] = useState(false);
    const [historyVehicle, setHistoryVehicle] = useState();

    const actualTableData = vehicles.filter(record => {
        if (!searchKey) {
            return true;
        }
        const checkStr = (a) => a.toLowerCase().includes(searchKey.toLowerCase());
        return checkStr(record.vehicleNumber) || checkStr(record.customerName) || checkStr(record.customerContact);
    });

    useEffect(() => {
        if (state?.company) {
            getPucVehicles().then(resp => setVehicles(resp.data.data.vehicles));
        }
    }, [state]);


    const cols = [
        {
            title: "Vehicle",
            render: ({vehicleNumber}) => <>
                <span style={{
                    width: '100%',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    padding: 0,
                    margin: 0
                }}>{vehicleNumber}</span>
            </>,
            sorter: (a, b) => a.vehicleNumber.localeCompare(b.vehicleNumber)
        },
        {
            title: "Type",
            render: (vehicle) => <>
                <Tag color="gold">{vehicle.engineType}</Tag>
                <Tag color="cyan">{vehicle.registrationType}</Tag>
            </>
        },
        {
            title: "Customer",
            render: (vehicle) => <>
                <b>{vehicle.customerName}</b>
                <br/>
                {vehicle.customerContact}
            </>,
            sorter: (a, b) => a.customerName.localeCompare(b.customerName),
        },
        {
            title: "PUC Expiry",
            render: ({expiryDate}) => moment(expiryDate).format("DD MMM YYYY")
        },
        {
            title: "Status",
            render: ({expiryDate}) => {
                const colorMap = {
                    "Active": "green",
                    "Expiring Soon": "orange",
                    "Expired": "red"
                };
                let status = "Expired";
                const expiryMoment = moment(expiryDate);
                const daysLeft = expiryMoment.diff(moment(), 'days');
                if (daysLeft > 7) {
                    status = "Active";
                } else if (daysLeft >= 0) {
                    status = "Expiring Soon";
                }
                return <>
                    <Tag color={colorMap[status]}>{status}</Tag>
                    {status === "Expiring Soon" && <><br/><i>({daysLeft} days left)</i></>}
                </>
            },
            sorter: (a, b) => moment(a.expiryDate).diff(moment(b.expiryDate))
        },
        {
            title: "Actions",
            render: (vehicle) => {
                return <span style={{fontSize: 22}}>
                    <Tooltip title={"View Renewals History"}>
                        <EyeOutlined  style={{color: 'orange', fontWeight: 22, cursor: 'pointer'}} onClick={() => {
                            setShowHistory(true);
                            setHistoryVehicle(vehicle);
                        }}/>
                    </Tooltip>
                    &nbsp;&nbsp;
                    <Tooltip title={"Notify via SMS & Whatsapp"}>
                        {/*<Button type={"primary"} style={{padding: 0}}>*/}
                            <MessageOutlined style={{color: 'blue', fontWeight: 22, cursor: 'pointer'}} onClick={async () => {
                                if (!isValidPhone(vehicle.customerContact)) {
                                    message.error(`Customer contact is not a valid mobile number`);
                                    return;
                                }
                                const resp = await notifyPucUser(vehicle._id);
                                if (!resp.data.success) {
                                    message.error(resp.data.message);
                                } else {
                                    message.success("Notification SMS & Whatsapp sent successfully!");
                                }
                            }}/>
                        {/*</Button>*/}
                    </Tooltip>
                    &nbsp;&nbsp;
                    {
                        false && // Disabling temporarily, will switch to Wa business msg directly
                        <Tooltip title={"Notify via Whatsapp"}>
                        {/*<Button type={"primary"}>*/}
                        <WhatsAppOutlined style={{color: 'green', fontWeight: 22, cursor: 'pointer'}} onClick={() => {
                            if (!isValidPhone(vehicle.customerContact)) {
                                message.error(`Customer contact is not a valid mobile number`);
                                return;
                            }
                            const expiryMoment = moment(vehicle.expiryDate);
                            const daysLeft = expiryMoment.diff(moment(), 'days');
                            const subMsg = daysLeft >= 0 ? "is expiring on" : "has been expired on";
                            const msg = `Dear ${vehicle.customerName}, your Pollution Certificate for vehicle ${vehicle.vehicleNumber} ${subMsg} ${expiryMoment.format("DD MMM YYYY")}. Please renew it to avoid penalties. Contact your nearest PUC Centre at ${state?.company?.phone || ""} (${state?.company?.name || ""}). - Sent using KhataBuddy`;
                            const url = `https://api.whatsapp.com/send/?phone=91${vehicle.customerContact}&text=${encodeURI(msg)}`;
                            window.open(url, '_blank');
                        }}/>
                        {/*</Button>*/}
                    </Tooltip>}
                    &nbsp;&nbsp;
                    <Popconfirm
                        title="Are you sure to delete this vehicle ?"
                        onConfirm={async () => {
                            deletePucVehicle(vehicle._id)
                                .then((response) => {
                                    if (response.data.success) {
                                        message.success('Vehicle Deleted');
                                        getPucVehicles().then(resp => setVehicles(resp.data.data.vehicles));
                                    } else {
                                        message.error(response.data.message);
                                    }
                                })
                                .catch(() => message.error('Failed to delete vehicle'))
                        }}
                        onCancel={() => {
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                    <Button type={"danger"}><DeleteOutlined/></Button>
                </Popconfirm>
                </span>
            }
        }
    ]

    return (
        <div style={{padding: 24}}>
            <h2>Vehicles</h2>
            <Input.Search onSearch={(val) => setSearchKey(val)} placeholder={"Search"} />
            <br/>
            <br/>
            <Table
                columns={cols}
                dataSource={actualTableData}
            />
            <HistoryDrawer
                showHistory={showHistory}
                setShowHistory={setShowHistory}
                historyVehicle={historyVehicle}
            />
        </div>
    )
};

export default PucVehicles;
