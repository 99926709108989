import React, {useState} from "react";
import {Button, Col, Row} from "antd";
import {PRIMARY_COLOR, RUPEE} from "../../../models/constants";
import {Stepper} from "antd-mobile";
import {isMobileView} from "../../../utils";
import {getCart} from "../../../services/cookies";
import { StoreImage } from "./storeImage";

const ItemGrid = ({item, onChange}) => {
    const [isAdd, setAddtoCart] = useState(false);
    if(!item.showInEcommerceStore){
      return ''
    }
    const products = getCart()
    let alreadyAddedToCart = false;
    let qty = 0;

    for(let product of products){
      if(product.name ===item.name){
        alreadyAddedToCart = true;
        qty = product.qty;
      }
    }

    const isMobile = isMobileView();

    const RightSide = () => (
      <>
        <h3 style={{ marginTop: 8, fontWeight: "bold" }}>{item.name}</h3>
        <Row align={"middle"}>
          <Col span={10} style={{ fontWeight: "bold", fontSize: 16 }}>
            {RUPEE} {item.defaultRate}
          </Col>
          <Col
            span={14}
            style={{ textAlign: "right", border: "0px solid green" }}
          >
            {!isAdd && !alreadyAddedToCart ? (
              <Button
                type="primary"
                shape="round"
                onClick={() => {
                  item.qty = 1;
                  onChange(item);
                  setAddtoCart(true);
                }}
              >
                Add to Cart
              </Button>
            ) : (
              <Stepper
                style={{
                  border: "2px solid " + PRIMARY_COLOR,
                  borderRadius: 200,
                  float: "right",
                }}
                defaultValue={qty || 1}
                onChange={(count) => {
                  item.qty = count;
                  onChange(item);
                }}
                min={0}
              />
            )}
          </Col>
        </Row>
      </>
    );
    console.log('Inside..')

    return (
        isMobile ?
            <Col span={24} style={{padding: 16, border: '0px solid red'}}>
                <Row>
                    <Col span={8}>
                    <StoreImage image={`/api/internal/file/${item?.img}`} fallback="https://vyaparwebsiteimages.vypcdn.in/catalogue/product-placeholder.png"/>
                        {/* <Image style={{width: 80, height: 80}}
                               src={ `/api/internal/file/${item?.img}` || ""}/> */}

                    </Col>
                    <Col span={16}>
                        <RightSide/>
                    </Col>
                </Row>
            </Col>
            :
            <div style={{padding: 16, border: '0px solid red'}}>
                       <StoreImage image={`/api/internal/file/${item?.img}`} fallback="https://vyaparwebsiteimages.vypcdn.in/catalogue/product-placeholder.png"/>
                <RightSide/>
            </div>
    )
};

export default ItemGrid;
