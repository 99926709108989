import React, {useEffect} from "react";
import {Image, Spin} from "antd";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import {loginRequest} from "../services/api";
import {customDispatcher} from "../utils";
import {useDispatch} from "react-redux";
import logo from "../res/img/logoLandscapeV3.png";

const SplashScreen = () => {
    const navigate = useNavigate();
    const dispatch = customDispatcher(useDispatch());

    useEffect(() => {
        // return;
        console.log("## Init authenticating ##");
        const currentPass = Cookies.get('invPass');
        const currentUser = Cookies.get('invUser');
        if (!currentUser || !currentPass) {
            navigate("/login");
        } else {
            const tryLogin = async () => {
                await new Promise(resolve => setTimeout(resolve, 500));
                const user = await loginRequest(currentUser, currentPass, dispatch);
                if (user) { // logged in
                    navigate("/dashboard");
                } else { // auth failed
                    navigate("/login");
                }
            }
            tryLogin();
        }
        // authenticate(currentUser, currentPass);
    });

    return (
        <div className={"splash"}>
            <Image width={400} src={logo}/>
            <br/><br/>
            <Spin size="large"/>
        </div>
    )
};

export default SplashScreen;
