import React, {useEffect, useState} from "react";
import _ from "lodash";
import {Alert, Button, DatePicker, Form, Input, InputNumber, message, Select, Spin, Table} from "antd";
import {getParties, saveErpInventoryTransaction, transferDevices, verifyInvDevices} from "../services/api";
import BulkUpload from "../components/upload/BulkUpload";
import {isValidDeviceID} from "../utils";
import {SYMBOLS} from "../models/constant";
import {iAssert, requiredRule, sleep} from "../../utils";
import moment from "moment";
import {useSelector} from "react-redux";
import {INV_ACTIONS, pagesMap} from "../models/webConfigs";
import {ExternalError} from "../models/error";

const sampleGeneralFileLink = `https://staging.khatabuddy.com/api/internal/file/sample_list.xlsx`;
const samplePurchaseFileLink = `https://staging.khatabuddy.com/api/internal/file/sample_purchase.xlsx`;

const getTotal = (items) => {
    let total = 0;
    items.forEach(i => {
        total += ((i.qty || 0) * (i.rate || 0));
    });
    return total;
}

const listDefaultInfo = {
    type: "info",
    description: <div>File should have only 1 column with list of valid 15-digit Device IMEIs.
        Check <a href={sampleGeneralFileLink}>Sample File Format</a>
    </div>
};


const purchaseDefaultInfo = {
    type: "info",
    description:
        <div>File should have 2 columns (names should be exactly same):
            <br/>
            <b>Item Name</b>: Value must be a valid Item Name (as saved in database)
            <br/>
            <b>Device ID</b>: Value must be a valid 15 digit serial no. (Device ID) of item.
            <br/>
            <br/>
            <i>(All case-sensitive)</i>
            <br/>
            Check <a href={samplePurchaseFileLink}>Sample File Format</a>
        </div>
};


const parseExcel = async ({isPurchase, excelData, validItemsMap, transactionType}) => {
    const deviceIdsSet = new Set();
    const devices = [];
    const selectedItems = {};
    excelData.forEach((row, index) => {
        let deviceId, itemName;
        if (isPurchase) {
            ({"Item Name": itemName, "Device ID": deviceId} = row);
        } else {
            ([deviceId] = row);
        }

        const rowNum = isPurchase ? (index+2) : (index+1);
        const errPrefix = `Error on row #${rowNum}:`;

        if (!deviceId && !itemName) {
            return;
        }

        iAssert(deviceId, `${errPrefix} Blank Device ID`);
        deviceId = deviceId.toString().trim();
        if (isPurchase) {
            iAssert(itemName, `${errPrefix} Blank Item Name`);
            itemName = itemName.toString().trim();
            iAssert(validItemsMap[itemName], `${errPrefix} Invalid item name [${itemName}]`);
        }

        iAssert(isValidDeviceID(deviceId), `${errPrefix} Invalid 15 Device ID [${deviceId}]`);
        iAssert(!deviceIdsSet.has(deviceId), `${errPrefix} Duplicate Device ID [${deviceId}]`);


        deviceIdsSet.add(deviceId);
        if (isPurchase) {
            devices.push({
                deviceId,
                model: validItemsMap[itemName]._id
            })
            selectedItems[itemName] = (selectedItems[itemName] || 0) + 1;
        }
    });

    const deviceIds = Array.from(deviceIdsSet);
    // verify & fetch device Models now:
    const resp = await verifyInvDevices({deviceIds, action: transactionType});
    iAssert(resp.data.success, resp.data.message);
    const {currentInventoryHolder} = resp.data.data;
    if (!isPurchase) {
        (resp.data.data.devices || []).forEach(dev => {
            const itemName = dev.model?.name || "";
            if (itemName) {
                selectedItems[itemName] = (selectedItems[itemName] || 0) + 1;
            }

            devices.push({
                deviceId: dev.deviceId,
                model: validItemsMap[itemName]._id
            });
        });
    }

    return {
        devices,
        selectedItems,
        currentInventoryHolder,
    }
}

const ErpSaleDrawer = ({closeDrawer, onSave, data = {}}) => {
    const {recordType, subTab, action} = data;
    const pageConfig = pagesMap[recordType];
    const tabConfig = pagesMap[recordType][subTab];
    const actionConfig = pagesMap[recordType][subTab][action];
    const {transactionType} = actionConfig;
    const isPurchase = transactionType === INV_ACTIONS.PURCHASE;
    const defaultInfo = isPurchase ? purchaseDefaultInfo : listDefaultInfo;
    const state = useSelector(state => state.oldState);
    const [parties, setParties] = useState([]);
    const [excelData, setExcelData] = useState();
    const [voucherItems, setVoucherItems] = useState([]);
    const [devices, setDevices] = useState([]);
    const [info, setInfo] = useState(defaultInfo);
    const [verifying, setVerifying] = useState(false);
    const [saving, setSaving] = useState(false);
    const [selectedParty, setSelectedParty] = useState();
    const [form] = Form.useForm();

    const resetForm = () => {
        form.resetFields();
        form.setFieldsValue({date: moment()});
    }

    useEffect(() => {
        setInfo(defaultInfo);
    }, [isPurchase]);

    useEffect(() => {
        form.setFieldsValue({partyId: selectedParty});
    }, [selectedParty]);

    useEffect(() => {
        resetForm();
        setExcelData();
        setVoucherItems([]);
        setDevices([]);
        setInfo(defaultInfo);
        setVerifying(false);
        setSelectedParty();
    }, [recordType, subTab, action]);

    useEffect(() => {
        getParties().then(resp => setParties(resp.data.data.entities.filter(entity => entity.type === pageConfig.partySelectionType)));
    }, [pageConfig]);

    useEffect( async () => {
        if (!excelData) {
            setVoucherItems([]);
            setDevices([]);
            setInfo(defaultInfo);
            return;
        }
        setVerifying(true);
        try {
            const validItemsMap = _.keyBy(state?.items || [], "name");
            const {devices, selectedItems, currentInventoryHolder} = await parseExcel({
                isPurchase,
                excelData,
                validItemsMap,
                transactionType,
            });

            iAssert(devices.length <= 1000, `Max 1000 records allowed at once in single record.`);
            iAssert(devices.length, `No record found in uploaded file, or valid column headers are missing.`); // todo - message not generic

            await sleep(500); // buffer

            const voucherItems = _.map(selectedItems, (qty, name) => ({
                _id: validItemsMap[name]._id,
                name,
                qty,
                rate: 0,
            }));

            setSelectedParty(currentInventoryHolder);
            setDevices(devices);
            setVoucherItems(voucherItems);
            setInfo({
                type: "success",
                description: `Uploaded file having ${devices.length} records.`
            });
        } catch (err) {
            setInfo({
                type: "error",
                description: err.msg || "Something went wrong",
            });
            setDevices([]);
            setVoucherItems([]);
        } finally {
            setVerifying(false);
        }
    }, [excelData]);

    const cols = [
        {title: "S.No.", render: (a, b, index) => (index + 1).toString()},
        {title: "Item Name", dataIndex: 'name'},
        {title: "Qty", dataIndex: "qty"},
    ];

    if (subTab === "new") {
        cols.push({
                title: "Rate",
                render: (a, b, index) => (
                    <Form.Item name={`itemRate_${a._id}`} rules={requiredRule("can't be blank")} style={{margin: 0, padding: 0}}>
                        <InputNumber prefix={SYMBOLS.RUPEE} onChange={(val) => {
                            const clonedItems = _.cloneDeep(voucherItems);
                            clonedItems[index].rate = val;
                            setVoucherItems(clonedItems);
                        }}/>
                    </Form.Item>
                )
            },
            {
                title: "Total",
                render: (obj) => `${SYMBOLS.RUPEE} ${((obj.qty || 0) * (obj.rate || 0)).toFixed(2)}`
            });
    }

    return (
        <div>
            <Form
                form={form}
                layout={"vertical"}
                onFinish={async (vals) => {
                    console.log("## FormUpload ##", vals);
                    try {
                        setSaving(true);
                        if (info.type !== "success") {
                            message.error(`Please fix errors first`);
                            return;
                        }
                        /*
                            api fields:::

                            date: ok
                            partyId: ok
                            remarks: ok
                            type: prepare
                            items: prepare
                            serialNums: prepare
                            qty: prepare
                            amount: prepare
                            status: prepare (set only for resolution type of vouchers i.e. repair / sample)
                            resolution: 0 , // for repair/ samples
                            resolutionVouchers: [], // for repair/ samples
                         */

                        vals = _.pickBy(vals, (val, key) => !key.startsWith("itemRate_"));

                        vals.items = _.cloneDeep(voucherItems);
                        if (isPurchase) {
                            vals.devices = devices;
                        } else {
                            vals.serialNums = devices.map(d => d.deviceId);
                        }
                        vals.qty = devices.length;
                        vals.amount = getTotal(voucherItems);
                        vals.type = transactionType;

                        delete vals.inputDeviceIds;

                        if (subTab !== "new" && action === "add") {
                            vals.status = "Pending";
                            vals.pendingIds = vals.serialNums;
                            vals.resolutionVouchers = [];
                        }
                        console.log("## FormSubmitting ##", vals);
                        // return;
                        const resp = await saveErpInventoryTransaction({record: vals});
                        await sleep(500); // buffer
                        if (resp.data.success) {
                            message.success(`Sale record saved successfully`);
                            resetForm();
                            closeDrawer();
                            onSave();
                        } else {
                            message.error(resp.data.message);
                        }
                    } catch (err) {
                        message.error(`Failed to process bulk transfer: ${err.message}`);
                    } finally {
                        setSaving(false);
                    }
                }}
            >

                <Form.Item label={"Device IDs"} name={"inputDeviceIds"} style={{margin: 0, padding: 0}}
                           rules={[{required: true, message: "can't be blank"}]}
                >
                    <BulkUpload onChange={(data) => {
                        setExcelData(data);
                    }} headers={isPurchase} />
                </Form.Item>
                {
                    info && !verifying &&
                    <Alert
                        type={info.type}
                        showIcon={true}
                        description={info.description}
                    />
                }

                <br/>
                {
                    !voucherItems.length && verifying &&
                        <Spin />
                }
                {
                    !!voucherItems.length &&
                    <>
                        <Table
                            loading={verifying}
                            pagination={false}
                            dataSource={voucherItems}
                            columns={cols}
                        />
                        {
                            subTab === "new" &&
                            <div style={{
                                textAlign: 'right',
                                fontWeight: 'bold',
                                fontSize: 16,
                                color: '#333',
                                marginTop: 16,
                                paddingRight: 32
                            }}>Total: {SYMBOLS.RUPEE} {getTotal(voucherItems)}</div>
                        }
                    </>

                }


                <Form.Item label={"Date"} name={"date"} rules={requiredRule("can't be blank")}>
                    <DatePicker format={"DD MMM YYYY"}/>
                </Form.Item>
                <Form.Item label={pageConfig.partySelectionType} name={"partyId"}
                           rules={[{required: true, message: `Select ${pageConfig.partySelectionType}`}]}>
                    <Select
                        disabled={!!selectedParty}
                        placeholder={`Select ${pageConfig.partySelectionType}`}
                        options={
                            parties.map(model => ({
                                label: model.name,
                                value: model._id
                            }))
                        }
                    />

                </Form.Item>

                <Form.Item label={"Remarks"} name={"remarks"} rules={requiredRule("can't be blank")}>
                    <Input/>
                </Form.Item>
                <Button loading={saving} disabled={verifying} type={"primary"} htmlType={"submit"}>
                    Save Record
                </Button>
            </Form>
        </div>
    )
};

export default ErpSaleDrawer;
