
import axios, {baseUrl} from '../api';

//Ecommerce details api
export const fetchEcommerceSummary = ()=> axios.get(`${baseUrl}/ecommerce/summary`);

export const fetchOrders = ()=> axios.get(`${baseUrl}/ecommerce/orders`);

export const fetchOrderBasedOnOrderId = (orderId)=> axios.get(`${baseUrl}/ecommerce/internal/order/${orderId}`);

export const updateEcommerceSettings = (body) => axios.post(`${baseUrl}/ecommerce/updateEcommerceSettings`, body);

//Fetch Items
export const fetchItems = (companyId) => axios.get(`${baseUrl}/internal/items/${companyId}`);

export const createEcommerceOrder = (order)=> axios.post(`${baseUrl}/ecommerce/internal/order`, {ecommerceOrder:order});

export const increementStore = (body) => axios.post(`${baseUrl}/ecommerce/internal/incStoreVisit`, body);
