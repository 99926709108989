import React, {useEffect, useState} from "react";
import _ from "lodash";
import {Button, Form, Input, message, Select, Upload} from "antd";
import {getDeviceModels, saveDevices} from "../../../erp/services/api";
import {UploadOutlined} from "@ant-design/icons";
import BulkUpload from "../../../erp/components/upload/BulkUpload";
import {isValidDeviceID} from "../../../erp/utils";

const sampleFileLink = `https://staging.khatabuddy.com/api/internal/file/sample_list.xlsx`;

const DeviceWriteDrawer = ({selectedSegment, closeDrawer, onSave}) => {

    const [deviceModels, setDeviceModels] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        getDeviceModels().then(resp => setDeviceModels(resp.data.data.deviceModels));
    }, []);

    useEffect(() => {
        form.resetFields(["inputDeviceIds"]);
    }, [selectedSegment]);

    return (
        <div>
            <Form
                form={form}
                layout={"vertical"}
                onFinish={async (vals) => {
                    console.log("## FormUpload ##", vals);
                    try {
                        if (typeof vals.inputDeviceIds === "string") {
                            vals.inputDeviceIds = vals.inputDeviceIds.split("\n");
                        } else {
                            vals.inputDeviceIds = _.map(vals.inputDeviceIds || [], row => row && row[0]);
                        }

                        const deviceIds = [];
                        _.forEach(vals.inputDeviceIds || [], (deviceId = "", index) => {
                            const strId = deviceId.toString().trim();
                            if (!strId) {
                                return;
                            }
                            if (!isValidDeviceID(strId)) {
                                throw new Error(`Not a valid 15 digit numeric Device ID at line ${index + 1} :: ${deviceId}`);
                            }
                            deviceIds.push(strId);
                        });

                        if (deviceIds.length > 1000) {
                            throw new Error(`Max 1000 records can be added at once`);
                        }

                        vals.deviceIds = deviceIds;

                        const resp = await saveDevices(vals);
                        if (resp.data.success) {
                            message.success(`Devices saved successfully`);
                            closeDrawer();
                            onSave();
                        } else {
                            message.error(resp.data.message);
                        }
                    } catch (err) {
                        message.error(`Failed to process bulk upload: ${err.message}`);
                    }
                }}
            >

                <Form.Item label={"Device IDs"} name={"inputDeviceIds"}
                           rules={[{required: true, message: "can't be blank"}]}
                >
                    {
                        selectedSegment === "Manual" ?
                            <Input.TextArea rows={8}
                                            placeholder={"15-digit Device IMEIs\n(if multiple, enter in multiple lines)"}/>
                            :
                            <BulkUpload description={
                                <div>File should have only 1 column with list of valid 15-digit Device IMEIs.
                                    Check <a href={sampleFileLink}>Sample File Format</a>
                                </div>
                            }/>
                    }
                </Form.Item>


                <Form.Item label={"HW Model"} name={"model"} rules={[{required: true}]}>
                    <Select
                        placeholder={"H/W Model"}
                        options={
                            deviceModels.map(model => ({
                                label: model.name,
                                value: model._id
                            }))
                        }
                    />

                </Form.Item>

                <Button type={"primary"} htmlType={"submit"}>
                    Save Devices
                </Button>
            </Form>
        </div>
    )
};

export default DeviceWriteDrawer;
