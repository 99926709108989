import React, {useEffect} from "react";
import logoTi from "../../res/img/logo_ti.png";
import {useNavigate} from "react-router-dom";
import {appConfig} from "../../config";

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

async function displayRazorpay(navigate, transactionId, payOrder) {
    // console.log("## URL ##", window.location);
    // window.location.host = "infinitynk.com";
    // window.location.hostname = "infinitynk.com";
    // window.location.href = "http://infinitynk.com/pay/F84X8F/gateway";
    // window.location.origin = "http://infinitynk.com";

    const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
        alert("Razorpay SDK failed to load. Please check internet connection.");
        return;
    }

    // creating a new order
    // const result = await axios.post("http://localhost:5000/payment/orders");
    //
    // if (!result) {
    //     alert("Server error. Are you online?");
    //     return;
    // }
    //
    // // Getting the order details back
    // const { amount, id: order_id, currency } = result.data;

    const options = {
        key: appConfig.razorpay.keyId, // "rzp_live_0cLN0DjsuBl2G9", // Enter the Key ID generated from the Dashboard
        amount: (parseInt(payOrder?.amount || 0)*100).toString(),
        currency: "INR",
        name: "TrakInfinity",
        description: "GPS Subscription Renewal",
        // image: logoTi,
        order_id: payOrder?.pgOrderToken, // "order_NOVqCkREL5RONO",
        handler: async function (response) {
            console.log("## Success ##", response);
            navigate(`/pay/${transactionId}/summary`);
            // const data = {
            //     orderCreationId: "order_NOVqCkREL5RONO",
            //     razorpayPaymentId: response.razorpay_payment_id,
            //     razorpayOrderId: response.razorpay_order_id,
            //     razorpaySignature: response.razorpay_signature,
            // };
            //
            // console.log("## Handler Data ##", data);

            // alert(result.data.msg);
        },
        prefill: {
            name: payOrder?.orderDetails?.customerName, // "Naveen Kumar", // ToDo
            // email: "useremail@trakinfinity.com",
            contact: payOrder?.orderDetails?.customerMobile, // "9999999999", // ToDo
        },
        notes: {
            // key value pairs
        },
        theme: {
            // color: "#61dafb",
        },
        modal: {
            escape: false,
            confirm_close: true,
            ondismiss: (...args) => {
                console.log("## Dismissed ##", args);
                navigate(`/selfcare/${payOrder?.orderDetails?.userHash}`);
            }
        }
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
}

export const RazorpayUI = ({payOrder, transactionId}) => {
    const navigate = useNavigate();
    const isValid = payOrder?.pgOrderToken && payOrder?.pg === "razorpay"
    useEffect(() => {
        if (isValid) {
            console.log("## Opening Razorpay PG ##");
            displayRazorpay(navigate, transactionId, payOrder);
        }
    }, [payOrder, navigate, transactionId, isValid]);

    return <div>
        {!isValid && "Invalid Payment Link"}
        {/*RazorPay UI*/}
        {/*<br/><br/>*/}
        {/*<button onClick={() => displayRazorpay(navigate, transactionId, payOrder)}>Pay Now</button>*/}
    </div>
}
