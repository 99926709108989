import React, {useEffect, useState} from "react";
import _ from "lodash";
import {Alert, Button, DatePicker, Form, Input, InputNumber, message, Select, Table} from "antd";
import {getParties, saveErpInventoryTransaction, transferDevices} from "../services/api";
import BulkUpload from "../components/upload/BulkUpload";
import {isValidDeviceID} from "../utils";
import {SYMBOLS} from "../models/constant";
import {requiredRule} from "../../utils";
import moment from "moment";
import {useSelector} from "react-redux";

const sampleFileLink = `https://staging.khatabuddy.com/api/internal/file/sample_list.xlsx`;

const getTotal = (items) => {
    let total = 0;
    items.forEach(i => {
        total += ((i.qty || 0) * (i.rate || 0));
    });
    return total;
}

const defaultInfo = {
    type: "info",
    description: <div>File should have only 1 column with list of valid 15-digit Device IMEIs.
        Check <a href={sampleFileLink}>Sample File Format</a>
    </div>
};

const ErpPurchaseDrawer = ({closeDrawer, onSave}) => {
    const state = useSelector(state => state.oldState);
    const [parties, setParties] = useState([]);
    const [excelData, setExcelData] = useState();
    const [voucherItems, setVoucherItems] = useState([]);
    const [deviceIds, setDeviceIds] = useState([]);
    const [info, setInfo] = useState(defaultInfo);
    const [form] = Form.useForm();

    useEffect(() => {
        getParties().then(resp => setParties(resp.data.data.entities.filter(entity => entity.type === "Vendor")));
    }, []);

    useEffect( () => {
        if (!excelData) {
            setVoucherItems([]);
            setDeviceIds([]);
            setInfo(defaultInfo);
            return;
        }
        try {
            const deviceIds = new Set();
            const selectedItems = {};
            const validItemsMap = _.keyBy(state?.items || [], "name");
            console.log("## ValidMap ##", validItemsMap);
            excelData.forEach(({"Item Name": itemName, "Device ID": deviceId}, index) => {
                const errPrefix = `Error on row #${(index+2)}:`;
                if (!deviceId && !itemName) {
                    return;
                }
                if (!deviceId) {
                    throw new Error(`${errPrefix} Blank Device ID`);
                } else if (!itemName) {
                    throw new Error(`${errPrefix} Blank Item Name`);
                }

                itemName = itemName.toString().trim();
                deviceId = deviceId.toString().trim();

                if (!isValidDeviceID(deviceId)) {
                    throw new Error(`${errPrefix} Invalid 15 Device ID [${deviceId}]`);
                } else if (deviceIds.has(deviceId)) {
                    throw new Error(`${errPrefix} Duplicate Device ID [${deviceId}]`);
                } else if (!validItemsMap[itemName]) {
                    throw new Error(`${errPrefix} Invalid item name [${itemName}]`);
                }

                deviceIds.add(deviceId);
                selectedItems[itemName] = (selectedItems[itemName] || 0) + 1;

                if (deviceIds.size > 1000) {
                    throw new Error(`Max 1000 records allowed at once in single purchase.`);
                }

            });

            if (!deviceIds.size) {
                throw new Error(`No record found in uploaded file, or valid column headers are missing.`);
            }
            setDeviceIds(Array.from(deviceIds));
            setVoucherItems(_.map(selectedItems, (qty, name) => ({
                _id: validItemsMap[name]._id,
                name,
                qty,
                rate: 0,
            })));
            setInfo({
                type: "success",
                description: `Uploaded file having ${deviceIds.size} records.`
            });
        } catch (err) {
            setInfo({
                type: "error",
                description: err.message,
            });
            setDeviceIds([]);
            setVoucherItems([]);
        }
    }, [excelData]);

    return (
        <div>
            <Form
                form={form}
                initialValues={{
                    date: moment()
                }}
                layout={"vertical"}
                onFinish={async (vals) => {
                    console.log("## FormUpload ##", vals);
                    try {
                        /*
                            api fields:::

                            date: ok
                            partyId: ok
                            remarks: ok
                            type: prepare
                            items: prepare
                            serialNums: prepare
                            qty: prepare
                            status: prepare (no need to set)
                            amount: prepare

                         */

                        vals = _.pickBy(vals, (val, key) => !key.startsWith("itemRate_"));

                        vals.items = _.cloneDeep(voucherItems);
                        vals.serialNums = deviceIds;
                        vals.qty = deviceIds.length;
                        vals.amount = getTotal(voucherItems);
                        vals.type = "purchase";

                        delete vals.inputDeviceIds;

                        console.log("## FormSubmitting ##", vals);
                        const resp = await saveErpInventoryTransaction({record: vals});
                        if (resp.data.success) {
                            message.success(`Purchase record saved successfully`);
                            form.resetFields();
                            form.setFieldsValue({
                                date: moment()
                            });
                            closeDrawer();
                            onSave();
                        } else {
                            message.error(resp.data.message);
                        }
                    } catch (err) {
                        message.error(`Failed to process bulk transfer: ${err.message}`);
                    }
                }}
            >

                <Form.Item label={"Device IDs"} name={"inputDeviceIds"} style={{margin: 0, padding: 0}}
                           rules={[{required: true, message: "can't be blank"}]}
                >
                    <BulkUpload onChange={(data) => {
                        setExcelData(data);
                    }} headers={true} />
                </Form.Item>
                {
                    info &&
                    <Alert
                        type={info.type}
                        showIcon={true}
                        description={info.description}
                    />
                }

                <br/>
                {
                    !!voucherItems.length &&
                    <>
                        <Table
                            pagination={false}
                            dataSource={voucherItems}
                            columns={[
                                {title: "S.No.", render: (a, b, index) => (index + 1).toString()},
                                {title: "Item Name", dataIndex: 'name'},
                                {title: "Qty", dataIndex: "qty"},
                                {
                                    title: "Rate",
                                    render: (a, b, index) => (
                                        <Form.Item name={`itemRate_${a._id}`} rules={requiredRule("can't be blank")} style={{margin: 0, padding: 0}}>
                                            <InputNumber prefix={SYMBOLS.RUPEE} onChange={(val) => {
                                                const clonedItems = _.cloneDeep(voucherItems);
                                                clonedItems[index].rate = val;
                                                setVoucherItems(clonedItems);
                                            }}/>
                                        </Form.Item>
                                    )
                                },
                                {
                                    title: "Total",
                                    render: (obj) => `${SYMBOLS.RUPEE} ${((obj.qty || 0) * (obj.rate || 0)).toFixed(2)}`
                                },
                            ]}
                        />
                        <div style={{
                            textAlign: 'right',
                            fontWeight: 'bold',
                            fontSize: 16,
                            color: '#333',
                            marginTop: 16,
                            paddingRight: 32
                        }}>Total: {SYMBOLS.RUPEE} {getTotal(voucherItems)}</div>
                    </>

                }


                <Form.Item label={"Purchase Date"} name={"date"} rules={requiredRule("can't be blank")}>
                    <DatePicker format={"DD MMM YYYY"}/>
                </Form.Item>
                <Form.Item label={"Vendor"} name={"partyId"}
                           rules={[{required: true, message: "Select Vendor"}]}>
                    <Select
                        placeholder={"Select Vendor"}
                        options={
                            parties.map(model => ({
                                label: model.name,
                                value: model._id
                            }))
                        }
                    />

                </Form.Item>

                <Form.Item label={"Remarks"} name={"remarks"} rules={requiredRule("can't be blank")}>
                    <Input/>
                </Form.Item>
                <Button type={"primary"} htmlType={"submit"}>
                    Save Purchase
                </Button>
            </Form>
        </div>
    )
};

export default ErpPurchaseDrawer;
