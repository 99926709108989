import React, {useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import {Button} from "antd";

const PaymentPage = () => {

    const params = useParams();
    const {transactionId} = params;


    useEffect(() => {
    }, []);

    return (
        <div>
            Payment Page for {transactionId}
            <Link to={`/pay/${transactionId}/gateway`}>
            <Button>
                Pay Now
            </Button>
            </Link>
        </div>
    )
};

export default PaymentPage;
