import React, {useEffect, useState} from "react";
import {Button, Col, DatePicker, Input, Row, Select, Table, Tabs, Tag} from "antd";
import {
    CheckCircleOutlined, ClockCircleOutlined,
    DollarOutlined, GoldOutlined,
    PlusOutlined,
    RollbackOutlined,
    TransactionOutlined
} from "@ant-design/icons";
import {getDevices, getErpInventoryTransactions, getParties} from "../../services/api";
import {beautifyAmount, customDispatcher, sleep} from "../../../utils";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {verifyRole} from "../../utils";
import {DEVICE_STATES, ROLES, SYMBOLS} from "../../models/constant";
import {useNavigate, useParams} from "react-router-dom";
import {pagesMap, transactionTypesMap} from "../../models/webConfigs";
import {RUPEE} from "../../../models/constants";

const {TabPane} = Tabs;

const defaultPagination = {
    current: 1,
    total: 10,
    pageSize: 10,
};



const CommonDataPage = ({titleDel, buttonsDel}) => {
    const state = useSelector(state => state.oldState);
    const navigate = useNavigate();
    const params = useParams();
    const {recordType, subTab} = params || {};
    const pageConfig = pagesMap[recordType][subTab];
    console.log("## CustomParams ##", params);
    const dispatch = customDispatcher(useDispatch());
    const [data, setData] = useState({});
    const [pagination, setPagination] = useState(defaultPagination);
    const [loading, setLoading] = useState(false);
    const [filteredRecords, setFilteredRecords] = useState([]);

    const {records = [], entities = [], deviceModels = []} = data || {};

    // Filters::
    const [filterTypes, setFilterTypes] = useState(["Blank", "Mapped"]);
    const [filterModel, setFilterModel] = useState();
    const [filterInventory, setFilterInventory] = useState();
    const [filterStatus, setFilterStatus] = useState();
    const [filterSearch, setFilterSearch] = useState("");
    const [filterDate, setFilterDate] = useState(null);

    const applyFilter = (record) => {
        // type (recordType, subTab), date, party, status
        // type:
        const txnType = transactionTypesMap[record.type];
        const byType = txnType.recordType === recordType && txnType.subTab === subTab;

        let byDate = true;
        let byParty = true;
        let byStatus = true;

        // date
        const [start, end] = filterDate || [];
        if (start && end) {
            byDate = moment(record.date).isBetween(start.startOf('day'), end.endOf('day'));
        }

        // party
        if (filterInventory) {
            byParty = record.partyId === filterInventory;
        }

        // status
        if (filterStatus) {
            byStatus = record.status === filterStatus;
        }

        return byType && byDate && byParty && byStatus;
    }

    useEffect( () => {
        fetchRecords(true);
    }, []);

    useEffect(async () => {
        setLoading(true);
        await sleep(500); // buffer for future API usage
        setFilteredRecords(records.filter(applyFilter));
        setLoading(false);
    }, [records, recordType, subTab, filterDate, filterInventory, filterStatus]);


    const openSaleDrawer = (action) => {
        dispatch({
            popData: {
                visible: true,
                type: "erpSale",
                width: 500,
                title: pageConfig[action].buttonTitle,
                // segments: ["Manual", "Bulk"],
                onSave: fetchRecords,
                data: {
                    recordType,
                    subTab,
                    action,
                }
            }
        });
    }


    const title = pagesMap[recordType]?.title;
    const buttons = [
        {
            label: <><PlusOutlined />&nbsp;{pageConfig.add.buttonTitle}</>,
            onClick: () => {
                openSaleDrawer("add");
            },
        },
        {
            label: <><RollbackOutlined/>&nbsp;{pageConfig.return.buttonTitle}</>,
            onClick: () => {
                openSaleDrawer("return");
            },
        },
    ];

    const fetchRecords = async (isPaginationTrigger = false) => {
        // const paginationParams = isPaginationTrigger ? pagination : defaultPagination;
        // setLoading(true);
        // const params = {};
        // if (filterTypes) {
        //     params.types = filterTypes.join(",");
        // }
        // if (filterModel) {
        //     params.model = filterModel;
        // }
        // if (filterInventory) {
        //     params.inventoryHolder = filterInventory;
        // }
        // if (filterSearch) {
        //     params.search = filterSearch;
        // }
        setLoading(true);
        await getErpInventoryTransactions({
            // ...paginationParams,
            // ...params,
        }).then(resp => setData(resp.data.data));
        setLoading(false);
        // if (!isPaginationTrigger) {
        //     setPagination(defaultPagination);
        // }
    }



    const columns = [
        {
            title: "Date",
            render: obj => moment(obj.date).format("DD MMM YYYY"),
        },
        {
            title: pagesMap[recordType].partySelectionType,
            render: obj => obj.party?.name || "---",
        },
        {
            title: "Type",
            render: obj => <Tag color={transactionTypesMap[obj.type]?.color}>{transactionTypesMap[obj.type]?.label || "---"}</Tag>,
        },
        {
            title: "Qty",
            render: (obj) => <div style={{fontWeight: 'bold', color: transactionTypesMap[obj.type]?.color}}>
                {obj.qty}
            </div>
        },
        {
            title: "Amount",
            render: (obj) => <div style={{fontWeight: 'bold', color: transactionTypesMap[obj.type]?.color}}>
                {SYMBOLS.RUPEE}&nbsp;{beautifyAmount(obj.amount)}
            </div>
        },
        {
            title: "Profit",
            render: (obj) => <div style={{fontWeight: 'bold', color: (transactionTypesMap[obj.type]?.factor * (obj.profit || 0))>=0 ? "green" : "red"}}>
                {SYMBOLS.RUPEE}&nbsp;{beautifyAmount(transactionTypesMap[obj.type]?.factor*(obj.profit || 0))}
            </div>
        },
        {
            title: "Resolution",
            render: obj => obj.status ? <div style={{color: obj.pendingIds?.length ? "red" : "green"}}>
                {obj.serialNums.length - (obj.pendingIds?.length || 0)} / {obj.serialNums?.length || 0}
            </div> : "---",
        },
        {
            title: "Status",
            render: obj => obj.status ? <Tag color={obj.pendingIds?.length ? "red" : "green"}>{obj.status}</Tag> : "---",
        },
        {
            title: "Actions",
            render: (obj) => (
                <a onClick={() => {
                    dispatch({
                        popData: {
                            visible: true,
                            type: "erpInvRecordDetails",
                            title: "Details",
                            data: obj
                        }
                    })
                }}>
                    Details
                </a>
            )
        }
    ];

    let colsToUse;
    if (subTab === "new") {
        if (recordType === "sale") {
            colsToUse = [0, 1, 2, 3, 4, 5, 8];
        } else {
            colsToUse = [0, 1, 2, 3, 4, 8];
        }
    } else {
        colsToUse = [0, 1, 2, 6, 7, 8];
    }


    const filterComponents = [
        <DatePicker.RangePicker
            onChange={setFilterDate}
        />,
        <Select
            placeholder={`${pagesMap[recordType].partySelectionType}-wise`}
            style={{width: 200}}
            options={
                entities.filter(e => e.type === pagesMap[recordType].partySelectionType).map(p => ({
                    label: p.name,
                    value: p._id,
                }))
            }
            allowClear={true}
            showSearch={true}
            onChange={setFilterInventory}
        />,
    ];

    if (["repair", "sample"].includes(subTab)) {
        filterComponents.push(
            <Select
                placeholder={"Status-wise"}
                style={{width: 150}}
                options={[
                    {label: "Pending", value: "Pending"},
                    {label: "Resolved", value: "Resolved"},
                ]}
                allowClear={true}
                onChange={setFilterStatus}
            />
        );
    }

    let netAmount = 0;
    let netQty = 0;
    let netProfit = 0;
    let netPendingDevices = 0;

    filteredRecords.forEach(r => {
        const factor = transactionTypesMap[r.type]?.factor || 1;
        netAmount += (r.amount || 0) * factor;
        netQty += (r.qty || 0) * factor;
        netProfit += (r.profit || 0) * factor;
        netPendingDevices += (r.pendingIds?.length || 0);
    });

    const selectComponentClass = (props) => {
        return (
            <div style={{ width: '100%', textAlign: 'right', marginLeft: 16 }}>
                {
                    subTab === "new" ?
                        <>
                            <Tag style={{fontSize: 14, padding: 6}} color={"#f50"} icon={<DollarOutlined/>}>
                                Net {pagesMap[recordType].title}: <b>{RUPEE} {beautifyAmount(netAmount.toFixed())}</b>
                            </Tag>

                            <Tag style={{fontSize: 14, padding: 6}} color={"#108ee9"} icon={<GoldOutlined/>}>
                                Net Qty: <b>{netQty.toFixed()}</b>
                            </Tag>
                            {
                                recordType === "sale" &&
                                <Tag style={{fontSize: 14, padding: 6}} color={"#5c9444"} icon={<CheckCircleOutlined/>}>
                                    Net Profit: <b>{RUPEE} {beautifyAmount(netProfit.toFixed())}</b>
                                </Tag>
                            }
                        </>
                        :
                        <Tag style={{fontSize: 14, padding: 6}} color={"#5c9444"} icon={<ClockCircleOutlined/>}>
                            Total Pending Devices: <b>{netPendingDevices.toFixed()}</b>
                        </Tag>
                }
            </div>
        );
    };


    return (
        <div style={{padding: 24, paddingTop: 8}}>
            <Row justify={"center"} align={"middle"}>
                <Col span={12} style={{fontSize: 24, fontWeight: 'bold'}}>
                    {title}
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <>
                        {
                            buttons.map(btn =>
                                <Button type={"primary"} onClick={btn.onClick} style={{
                                    // background: 'darkslateblue',
                                    // color: 'white',
                                    borderRadius: 6,
                                    fontWeight: 'bold',
                                }}>
                                    {btn.label}
                                </Button>
                            ).joinComponents()
                        }
                    </>

                </Col>
            </Row>

            <Tabs className={"tabsLedgers"} activeKey={subTab} onChange={(newTab) => navigate(`/erp_inv/${recordType}/${newTab}`)}>
                {
                    (pagesMap[recordType]?.tabs || []).map(({label, key}) => (
                        <TabPane tab={<div style={{fontSize: 15}}>{label}</div>} key={key}>
                        </TabPane>
                    ))
                }
            </Tabs>


            <Row>
                {
                    filterComponents.joinComponents()
                }
            </Row>

            <br/>

            <Table
                loading={loading}
                columns={colsToUse.map(i => columns[i])}
                pagination={{
                    ...pagination,
                    total: data.count || records.length || 0,
                    selectComponentClass,
                    showSizeChanger: true,
                    position: ["bottomLeft"]
                }}
                onChange={setPagination}
                dataSource={filteredRecords}
            />

        </div>
    );
};

export default CommonDataPage;
