import React, {useEffect, useMemo, useState} from 'react';
import {Button, message, Form, Input, Select, Row, Col, Alert, Tag, Result, Spin, Image, Switch, Popover} from 'antd';
import {getInternalCompany, saveCompany} from '../../../services/api';
import _ from "lodash";
import {states} from "../../../models/constants";
import {useSelector} from "react-redux";
import {
    fetchChatMessages,
    sendChatMessage,
    sendPrivateNote,
    toggleChatbot,
    toggleTicket
} from "../../../services/api/chatbot";
import {Link, useNavigate, useParams} from "react-router-dom";
import moment from "moment";
import {
    CheckCircleFilled,
    CheckCircleOutlined,
    ClockCircleFilled,
    CloseCircleFilled,
    SmileOutlined
} from "@ant-design/icons";
import { ButtonV2 } from '../../../components/ButtonV2';

import ImgWatPostActivation from "../../../res/img/waTemplateOutputs/trakinfinity_creds_post_activation.png";
import ImgWatKycBlock from "../../../res/img/waTemplateOutputs/trakinfinity_kyc_block.png";
import ImgWatKycReminder from "../../../res/img/waTemplateOutputs/trakinfinity_kyc_reminder_v2.png";
import ImgWatRenewReminder from "../../../res/img/waTemplateOutputs/trakinfinity_renewal_reminder.png";
import ImgWatResetPassword from "../../../res/img/waTemplateOutputs/trakinfinity_reset_password.png";
import ImgWatWelcomeKyc2 from "../../../res/img/waTemplateOutputs/trakinfinity_welcome_kyc_2.png";
import ImgWatInsuranceReminder from "../../../res/img/waTemplateOutputs/trakinfinity_insurance_reminder.png";


const TicketToggle = ({userMobile, currentValueInDb}) => {
    const [isTicketOpen, setIsTicketOpen] = useState(false);
    useEffect(() => {
        setIsTicketOpen(currentValueInDb);
    }, [currentValueInDb]);
    return (
        <Switch
            onChange={async (val) => {
                console.log("## setIsTicketOpen ##", val);
                setIsTicketOpen(val);
                if (val) {
                    message.success(`Chat marked as Pending`);
                } else {
                    message.success(`Chat marked as Closed`);
                }
                await toggleTicket({
                    userMobile,
                    isOpen: val,
                });
            }}
            checked={isTicketOpen}
            checkedChildren={"Pending"} unCheckedChildren={"Resolved"} />
    )
}

/*
     trakinfinity_creds_post_activation, trakinfinity_kyc_block, trakinfinity_kyc_reminder_v2,
     trakinfinity_renewal_reminder, trakinfinity_reset_password, trakinfinity_welcome_kyc_2,
     trakinfinity_insurance_reminder
 */
const templateOutputs = {
    trakinfinity_creds_post_activation: (components) => {
        const parameters = components[0].parameters;
        return <div>
            <i><u>Template: Post-Activation (with Login Credentials)</u></i>
            &nbsp;&nbsp;<Image src={ImgWatPostActivation} style={{width: 30}}/>
            {
                ["Device ID", "Username", "Password"].map((label, i) => <>
                    <br/>
                    {label}: {parameters[i]?.text}
                </>)
            }
        </div>
    },
    trakinfinity_kyc_block: (components) => {
        const parameters = components[0].parameters;
        return <div>
            <i><u>Template: Block Device due to KYC</u></i>
            &nbsp;&nbsp;<Image src={ImgWatKycBlock} style={{width: 30}}/>
            {
                ["Device ID", "Portal Link Suffix"].map((label, i) => <>
                    <br/>
                    {label}: {parameters[i]?.text}
                </>)
            }
        </div>
    },
    trakinfinity_kyc_reminder_v2: (components) => {
        const parameters = components[0].parameters;
        return <div>
            <i><u>Template: KYC Reminder</u></i>
            &nbsp;&nbsp;<Image src={ImgWatKycReminder} style={{width: 30}}/>
            {
                ["Link", "Due Date"].map((label, i) => <>
                    <br/>
                    {label}: {parameters[i]?.text}
                </>)
            }
        </div>
    },
    trakinfinity_renewal_reminder: (components) => {
        const parameters = components[0].parameters;
        return <div>
            <i><u>Template: Renewal Reminder</u></i>
            &nbsp;&nbsp;<Image src={ImgWatRenewReminder} style={{width: 30}}/>
            {
                ["Device ID", "Customer Name", "Expiry Date", "Amount", "PhonePe no", "UPI ID", "Helpline"].map((label, i) => <>
                    <br/>
                    {label}: {parameters[i]?.text}
                </>)
            }
        </div>
    },
    trakinfinity_reset_password: (components) => {
        const parameters = components[0].parameters;
        return <div>
            <i><u>Template: Reset Password</u></i>
            &nbsp;&nbsp;<Image src={ImgWatResetPassword} style={{width: 30}}/>
            {
                ["Username", "Password"].map((label, i) => <>
                    <br/>
                    {label}: {parameters[i]?.text}
                </>)
            }
        </div>
    },
    trakinfinity_welcome_kyc_2: (components) => {
        const parameters = components[0].parameters;
        return <div>
            <i><u>Template: Pre-Activation Welcome (with KYC Link)</u></i>
            &nbsp;&nbsp;<Image src={ImgWatWelcomeKyc2} style={{width: 30}}/>
            {
                ["Device ID", "Portal Link Suffix"].map((label, i) => <>
                    <br/>
                    {label}: {parameters[i]?.text}
                </>)
            }
        </div>
    },
    trakinfinity_insurance_notif: (components) => {
        const parameters = components[0].parameters;
        return <div>
            <i><u>Template: Insurance Policy Reminder</u></i>
            &nbsp;&nbsp;<Image src={ImgWatInsuranceReminder} style={{width: 30}}/>
            {
                ["Vehicle Num"].map((label, i) => <>
                    <br/>
                    {label}: {parameters[i]?.text}
                </>)
            }
        </div>
    },

}

/*
    To handle:
    - outgoing sms text
    - outgoing whatsapp template
    - outgoing whatsapp text
    - incoming whatsapp text
    - incoming whatsapp interactive
    - incoming whatsapp image

 */
const MessageBody = ({msg}) => {
    const invalidStr = `[Unsupported Packet - temporary view]: ${JSON.stringify(msg.content)}`;
    if (msg.channel === "sms") {
        return (msg.content.value || "--");
    } else if (msg.channel === "whatsapp" && msg.direction === "incoming") {
        const {type, value} = msg.content;
        if (type === "text") {
            return (value?.body || "--");
        } else if (type === "image" || type === "sticker") {
            return <div>
                {type === "image" ? (value.caption || "") : "Sticker"}
                <Image src={`/api/internal/file/wa_918010666611_${value.id}`} alt="image"/>
            </div>;
        } else if (type === "document") {
            return <div>
                Document: {value.filename || ""}
                <br/>
                <a target={"_blank"} href={`/api/internal/file/wa_918010666611_${value.id}`}>View Document</a>
                {(moment(msg.messageTimestamp).valueOf() < 1717710055000) ? <><br/>(May not be available - Media too old)</> : ""}
            </div>;
        } else if (type === "audio") {
            return <div>
                Audio
                <br/>
                <a target={"_blank"} href={`/api/internal/file/wa_918010666611_${value.id}`}>Listen Audio</a>
                {(moment(msg.messageTimestamp).valueOf() < 1717710055000) ? <><br/>(May not be available - Media too old)</> : ""}
            </div>;
        } else if (type === "video") {
            return <div>
                Video
                <br/>
                <a target={"_blank"} href={`/api/internal/file/wa_918010666611_${value.id}`}>Watch Video</a>
                {(moment(msg.messageTimestamp).valueOf() < 1717710055000) ? <><br/>(May not be available - Media too old)</> : ""}
            </div>;
        } else if (type === "location") {
            return <div>
                Location
                <br/>
                <a target={"_blank"} href={`https://www.google.com/maps?q=${value.latitude},${value.longitude}`}>View Location</a>
            </div>;
        } else if (type === "interactive") {
            if (value.type === "button_reply") {
                return `Clicked Reply Button: ${value.button_reply?.title}`
            } else if (value.type === "list_reply") {
                return `Selected from List: ${value.list_reply?.title}`
            }
        } else if (type === "button") {
            return `Clicked Button: ${value?.text || "--"}`;
        }
    } else if (msg.channel === "whatsapp" && msg.direction === "outgoing") {
        const {payload = {}} = msg?.content || {};
        const {type = "text"} = payload;
        const value = payload[type] || {};
        if (type === "text") {
            return value?.body || "--";
        } else if (type === "interactive") {
            if (value.type === "button") {
                return <div>
                    {value.body?.text}
                    <br/>
                    {
                        (value?.action?.buttons || []).map(btn => <><button disabled={true}>{btn?.reply?.title}</button>&nbsp;</>)
                    }
                </div>
            } else if (value.type === "list") {
                return <div>
                    {value.body?.text || ""}
                    <br/>
                    {
                        (value.action?.sections || []).map(section =>
                            section.rows.map(row => <div>
                                --> {row.title}
                            </div>)
                        )
                    }
                </div>
            }
        } else if (type === "template") {
            const templateHandler = templateOutputs[value.name];
            if (templateHandler) {
                return templateHandler(value.components);
            }
        }
    } else if (msg.channel === "voice") {
        const {type, duration} = msg.content?.payload || {};
        let label = "Unrecognized call";
        if (type === 1) {
            label = <b style={{color: 'blue'}}>Incoming Call</b>;
        } else if (type ===2) {
            label = <b style={{color: 'green'}}>Outgoing Call</b>;
        } else if (type ===3) {
            label = <b style={{color: 'red'}}>Missed Call</b>;
        } else if (type ===5) {
            label = <b style={{color: 'brown'}}>Rejected Call</b>;
        }

        let durationTxt;
        const durationMoment = moment.duration(duration || 0, 'seconds');
        durationTxt = moment.utc(durationMoment.asMilliseconds()).format('mm:ss');
        durationTxt = `[${durationTxt}]`;
        return <div>
            {label}&nbsp;&nbsp;
            {([1, 2].includes(type) || duration) ? durationTxt : <></>}
        </div>;
    } else if (msg.channel === "privateNote") {
        return <div style={{fontStyle: 'italic'}}><b>Internal Note:</b> {msg.content?.payload?.msgContent || "--"}</div>
    } else if (msg.channel === "privateRecording") {
        const {folderPath, fileKey} = msg.content?.payload || {};
        return <div>
            <b>Internal Recording</b>
            <br/>
            <a target={"_blank"} href={`https://app.khatabuddy.com/api/internal/file/generic?key=${folderPath}${fileKey}`}>Listen Recording</a>
        </div>;
    } else {
        return "-- Unsupported Channel -- (Please contact admin) --"
    }
    return invalidStr;
}

const triggersMap = {
    UNKNOWN: "Uncategorized",
    MANUAL: "Manual",
    CHATBOT: "ChatBot",
    PORTAL_JOB: "Portal Automated",
    PORTAL_ACTION: "Portal Action",
};

const titleMap = {
    SEEN: "Read",
    DELIVERED: "Delivered",
    SENT: "Sent",
};

const timeHover = (msg) => Object.entries(msg.statusTimeline || {})
    .map(([key, val]) => <>{moment(val).format("DD MMM HH:mm:ss")} ({titleMap[key]})<br/></>);

const waStatusHandler = {
    ACCEPTED: {
        icon: <ClockCircleFilled style={{color: 'grey', fontSize: 14}}/>,
        text: (timing) => `Waiting`,
    },
    SENT: {
        icon: <CheckCircleFilled style={{color: 'grey', fontSize: 14}}/>,
        text: timeHover,
    },
    DELIVERED: {
        icon: <CheckCircleFilled style={{color: '#32a852', fontSize: 14}}/>,
        text: timeHover,
    },
    SEEN: {
        icon: <CheckCircleFilled style={{color: '#4592ff', fontSize: 14}}/>,
        text: timeHover,
    },
    DEFAULT: {
        icon: <CheckCircleFilled style={{color: 'grey', fontSize: 14}}/>,
        text: (timing) => `Sent`,
    },
}


const WhatsappTick = ({msg}) => {
    const handler = waStatusHandler[msg.status] || waStatusHandler.DEFAULT;
    return <Popover trigger={"hover"}
                    content={<>
                        {handler.text(msg)}
                    </>}
        >
        {handler.icon}
    </Popover>
}

const ChatMessagesPage = () => {
    const navigate = useNavigate();
    const state = useSelector(state => state.oldState);
    const params = useParams();
    const {selectedContact, chatbotAccount} = params;
    const [allData, setAllData] = useState({});
    const {messages = [], users: recentContacts = [], nextMsgOffset, nextUserOffset} = allData;
    const [inputMsg, setInputMsg] = useState("");
    const [inputNote, setInputNote] = useState("");

    const [syncing, setSyncing] = useState(false);
    const [sending, setSending] = useState(false);
    const [intervalRef, setIntervalRef] = useState();
    const [searchTerm, setSearchTerm] = useState("");
    const [userOffset, setUserOffset] = useState(0);
    const [msgOffset, setMsgOffset] = useState(0);
    const [chatbotEnabled, setChatbotEnabled] = useState(false);

    const isDisabled = useMemo(() => {
        for (const contact of recentContacts) {
            if (contact?.userPhone === selectedContact) {
                if (moment().diff(moment(contact.lastMessageTimestamp), 'minutes') > 60*24) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }, [selectedContact, recentContacts, chatbotAccount]);

    const syncChatData = async () => {
        setSyncing(true);

        const params = {
            searchTerm,
            userOffset,
            msgOffset,
            chatbotAccount,
        };
        if (selectedContact) {
            params.selectedContact = selectedContact;
        }

        const response = await fetchChatMessages(params);
        setAllData(response.data.data);
        setChatbotEnabled(!!response.data.data?.chatbotEnabled);
        setSyncing(false);
    }

    useEffect(() => {
        if (state.company) {
            syncChatData();
            if (intervalRef) {
                clearInterval(intervalRef);
            }
            const _intRef = setInterval(syncChatData, 10000);
            setIntervalRef(_intRef);
        }
    }, [state, selectedContact, searchTerm, userOffset, msgOffset, chatbotAccount]);


    return (
        <div>
            <h2>Message Center</h2>

            <Row>
                <Col span={6} style={{paddingRight: 16}}>
                    <Input.Search
                        onSearch={(_searchTerm) => {
                            console.log("## Searching ##", _searchTerm);
                            setSearchTerm(_searchTerm);
                        }}
                    />
                    <br/>
                    <br/>
                    <Spin spinning={syncing}>
                        {
                            recentContacts.map(contact => {
                                return <div style={{
                                    background: contact.userPhone === selectedContact ? '#a8e6b7' : '#dbffe5',
                                    border: '1px solid grey',
                                    borderRadius: 8,
                                    cursor: 'pointer',
                                    padding: 8,
                                    marginBottom: 8,
                                }} onClick={() => {
                                    navigate(`/portal/chatbot/${chatbotAccount}/messages/${contact.userPhone}`);
                                }}>
                                    <div style={{fontSize: 16, fontWeight: 'bold'}}>
                                        <Row>
                                            <Col span={14}>
                                                {(contact.userPhone || "")}
                                            </Col>
                                            <Col span={10} style={{color: 'grey', fontSize: 12, fontWeight: 'normal', textAlign: 'right'}}>
                                                ({moment(contact.lastMessageTimestamp).format("DD MMM HH:mm")})
                                            </Col>
                                        </Row>
                                    </div>
                                    <div style={{color: 'grey', fontSize: 13}}>
                                        <Row>
                                            <Col span={14}>
                                                {contact.name || "--"}
                                            </Col>
                                            <Col span={10} style={{color: 'grey', fontSize: 12, fontWeight: 'normal', textAlign: 'right'}}>
                                                <TicketToggle
                                                    userMobile={contact.userPhone}
                                                    currentValueInDb={!!contact.isTicketOpen}
                                                />
                                            </Col>
                                        </Row>
                                    </div>


                                </div>
                            })
                        }
                        <br/>
                        {
                            nextUserOffset &&
                                <Button
                                    onClick={() => {
                                        setUserOffset(nextUserOffset);
                                    }}
                                >Load More</Button>
                        }
                    </Spin>
                </Col>
                <Col span={18} style={{
                    background: '#fdffe6',
                    height: 600,
                    padding: 8,
                    fontWeight: 500,
                }}>
                    {
                        selectedContact ?
                            <>
                            <div style={{}}>
                                {isDisabled && <Alert
                                    style={{marginBottom: 12}}
                                    showIcon={true}
                                    type={"error"}
                                    message={"Message can't be sent after 24 hours of user's message"}
                                />}
                                    <Row>
                                        <Col span={20}>
                                            <Input.TextArea value={inputNote} onChange={e => setInputNote(e.target.value)} rows={2} placeholder={"Enter internal note"}/>
                                        </Col>
                                        <Col span={4} style={{paddingLeft: 8}}>
                                            <ButtonV2
                                                type={"danger"}
                                                loading={sending} style={{width: '100%'}} onClick={async () => {
                                                if (!inputNote || !inputNote.trim()) {
                                                    message.error(`Invalid note`);
                                                    return;
                                                }
                                                setSending(true);
                                                const response = await sendPrivateNote({
                                                    msgType: "text",
                                                    msgContent: inputNote,
                                                    to: selectedContact,
                                                    from: chatbotAccount,
                                                });
                                                if (!response?.data?.success) {
                                                    message.error(response?.data?.message || "Unknown failure");
                                                    setSending(false);
                                                    return;
                                                }
                                                setInputNote("");
                                                message.success("Note Saved");
                                                setSending(false);
                                                syncChatData();
                                            }}>
                                                Save Note
                                            </ButtonV2>
                                        </Col>
                                    </Row>
                                <Row style={{marginTop: 20}}>
                                    <Col span={20}>
                                        <Input.TextArea value={inputMsg} onChange={e => setInputMsg(e.target.value)} rows={3} placeholder={"Enter message to reply"}/>
                                    </Col>
                                    <Col span={4} style={{paddingLeft: 8}}>
                                        <ButtonV2
                                            disabled={isDisabled}
                                            loading={sending} type={"primary"} style={{width: '100%'}} onClick={async () => {
                                            if (!inputMsg || !inputMsg.trim()) {
                                                message.error(`Invalid message`);
                                                return;
                                            }
                                            setSending(true);
                                            const response = await sendChatMessage({
                                                msgType: "text",
                                                msgContent: inputMsg,
                                                to: selectedContact,
                                                from: chatbotAccount,
                                            });
                                            if (!response?.data?.success) {
                                                message.error(response?.data?.message || "Unknown failure");
                                                setSending(false);
                                                return;
                                            }
                                            setInputMsg("");
                                            message.success("Message sent");
                                            setSending(false);
                                            syncChatData();
                                        }}>
                                            Send
                                        </ButtonV2>
                                        <br/><br/>
                                        ChatBot: <Switch
                                        onChange={async (val) => {
                                            console.log("## Checked ##", val);
                                            setChatbotEnabled(val);
                                            if (val) {
                                                message.success(`Chatbot Enabled now`);
                                            } else {
                                                message.success(`Chatbot Disabled for 8 hours`);
                                            }
                                            await toggleChatbot({
                                                userMobile: selectedContact.slice(-10),
                                                isEnabled: val,
                                            });
                                        }}
                                        checked={chatbotEnabled} checkedChildren={"On"}
                                        unCheckedChildren={"Off"} />
                                    </Col>
                                </Row>
                                </div>
                                <Spin spinning={syncing}>
                                    <div style={{height: 500, overflow: 'scroll', marginTop: 40}}>
                                        {
                                            messages.map(msg => {
                                                const isOutgoing = msg.direction === "outgoing";
                                                let format = isOutgoing ? "right" : "left";
                                                if (["privateNote", "privateRecording"].includes(msg.channel)) {
                                                    format = "center";
                                                }
                                                const formattingConfigMap = {
                                                    left: {
                                                        paddingLeft: '',
                                                        paddingRight: '40%',
                                                        color: "pink",
                                                    },
                                                    right: {
                                                        paddingLeft: '40%',
                                                        paddingRight: '',
                                                        color: "green",
                                                    },
                                                    center: {
                                                        paddingLeft: '20%',
                                                        paddingRight: '20%',
                                                        color: "blue",
                                                    },
                                                };
                                                const formatConfig = formattingConfigMap[format];
                                                const trigger = triggersMap[msg.trigger] || "---";
                                                return <div style={{
                                                    textAlign: format,
                                                    borderBottom: '1px solid lightgrey',
                                                    marginBottom: 16,
                                                    paddingLeft: formatConfig.paddingLeft,
                                                    paddingRight: formatConfig.paddingRight,
                                                    paddingBottom: 8,
                                                }}>
                                                    <Col style={{textAlign: format}}>
                                                        <Tag color={"orange"} style={{marginLeft: 8}}>
                                                            {trigger}
                                                        </Tag>
                                                        {
                                                            msg.channel === "sms" ?
                                                            <Tag color={"blue"} style={{marginLeft: 8}}>
                                                                SMS
                                                            </Tag> :
                                                                // <Tag color={"blue"} style={{marginLeft: 8}}>
                                                                <>
                                                                {msg.direction === "outgoing" ?
                                                                    <WhatsappTick msg={msg}/> : "" }
                                                                </>
                                                                // </Tag>
                                                        }
                                                        <Tag color={formatConfig.color} style={{marginLeft: isOutgoing ? 8 : 0}}>
                                                            {moment(msg.messageTimestamp).format("DD MMM HH:mm")}
                                                        </Tag>
                                                    </Col>
                                                    <div style={{paddingLeft: 8, paddingRight: 8}}>
                                                        <MessageBody msg={msg} />
                                                    </div>
                                                </div>
                                            })
                                        }
                                        {
                                            nextMsgOffset &&
                                            <Button
                                                onClick={() => {
                                                    setMsgOffset(nextMsgOffset);
                                                }}
                                            >Load More</Button>
                                        }
                                    </div>

                                </Spin>

                            </>
                            :
                            <div>
                                <Result
                                    icon={<SmileOutlined />}
                                    title="Great, select a number from list on left side to start messaging!"
                                    // extra={<Button type="primary">Next</Button>}
                                />
                            </div>
                    }
                </Col>
            </Row>

        </div>
    );
}

export default ChatMessagesPage;
