import React, {useEffect, useState} from "react";
import _ from "lodash";
import {Alert, Button, Col, Divider, Dropdown, Input, Menu, message, Popconfirm, Popover, Row, Table} from "antd";
import moment from "moment";
import {RUPEE, states, voucherTypes} from "../../models/constants";
import {
    beautifyAmount,
    copyToClipboard,
    customDispatcher,
    formulateItems,
    getPublicShareLink,
    isValidPhone, PublicShareLinks
} from "../../utils";
import {useDispatch, useSelector} from "react-redux";
import {deleteVoucher, getVouchers, sendSMS} from "../../services/api";
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    LinkOutlined,
    MailFilled,
    MessageFilled,
    ShareAltOutlined,
    WhatsAppOutlined
} from "@ant-design/icons";
import {transactionTypesMap} from "../models/webConfigs";

const ErpInvRecordDetails = ({data: document}) => {
    const state = useSelector(state => state.oldState);
    const {company} = state;
    const dispatch = customDispatcher(useDispatch());
    const [invoicesMap, setInvoicesMap] = useState({});
    const [loadingIMap, setLoadingIMap] = useState(false);
    const voucher = _.cloneDeep(document);
    const voucherType = voucher.type;
    const transactionType = transactionTypesMap[voucherType];
    const docType = voucherTypes[voucherType];
    // const invoiceNum = ["purchase", "debitNote"].includes(voucherType) ? voucher.externalDocumentNumber :
    //     (docType.idType !== "mixed" ? voucher.documentNumber : `${voucher.documentNumber.prefix}${voucher.documentNumber.num}`);
    const isIgst = voucher.party?.state !== state?.company?.state;
    const isAbroad = voucher.party?.country && voucher.party?.country !== "IN";

    useEffect(() => {
        const executor = async () => {
            if (document.type === "receipt" && document.entryType === "Invoice-Wise") {
                setLoadingIMap(true);
                const voucherIds = (document.distribution || []).map(d => d.voucherId);
                const iMap = {};
                for (const vid of voucherIds) {
                    if (vid !== "Advance") {
                        const resp = await getVouchers("invoice", vid);
                        iMap[vid] = resp.data.data.vouchers[0];
                    }
                }
                setInvoicesMap(iMap);
                setLoadingIMap(false);
            }
        }
        executor();
    }, [document]);

    const dbItemsMap = {};

    (state?.items || []).forEach(item => {
        dbItemsMap[item._id] = item;
    });

    const isRoundOffEnabled = _.get(voucher, 'customConfig.enableRoundOff', true);
    const {
        tableItems,
        summary: {extraDiscount}
    } = formulateItems({
        items: voucher.items, dbItemsMap, isIgst, extraDiscount: {
            extraDiscountType: voucher.extraDiscountType,
            extraDiscountValue: voucher.extraDiscountValue,
        }, isAbroad, isRoundOffEnabled,
    });

    const cols = [
        {
            title: "S.No.",
            render: (item, rec, index) => (index + 1).toString()
        },
        {
            title: "Item",
            // dataIndex: "name",
            render: (item) => <>
                <b>{item.name}</b>
                <br/>
                <span style={{fontSize: 12}}>{item.description}</span>
            </>
        },
        {
            title: "Qty",
            dataIndex: "qty"
        },
    ];

    if (transactionType.subTab === "new") {
        cols.push(
            {
                title: "Rate",
                dataIndex: "rate",
            },
            {
                title: "Amount",
                className: "genericBold",
                render: (obj) => ((obj.qty || 0)*(obj.rate)).toFixed(2)
            },
        );
    }

    const receiptCols = [
        // {
        //     title: "S.No.",
        //     render: (line, rec, index) => (index + 1).toString()
        // },
        // {
        //     title: "Invoice Reference",
        //     render: (line) => {
        //         const inv = invoicesMap[line.voucherId];
        //         const invoiceNum = inv ? `${inv?.documentNumber?.prefix}${inv?.documentNumber?.num}` : "Advance";
        //         return invoiceNum;
        //     }
        // },
        // {
        //     title: "Amount",
        //     render: line => line.amount,
        // }
    ];

    if (voucher.type === "receipt") {
        const crParty = voucher.crParty;
        voucher.crParty = voucher.party;
        voucher.party = crParty;
    }

    const obj = voucher;
    const publicLink = getPublicShareLink(PublicShareLinks.VOUCHER, voucher?.hashId);
    const msgContent = <>Hi {voucher?.party?.name}
        <br/>Thank you for making payment of {RUPEE} {beautifyAmount(voucher.amount)} at {company?.name || ""}.
        <br/>Here is your Payment Receipt: {publicLink}
        <br/>- Sent using KhataBuddy</>;



    return (
        <div>
            {/*Invoice Component*/}

            <Row>
                <Col span={16} style={{paddingRight: 20}}>
                    Party: <strong>{voucher.party?.name}</strong>
                </Col>
                <Col span={8}>
                    {/*{voucher.type === "invoice" ? "Invoice" : "Doc."} # <b>{invoiceNum}</b>*/}
                    {/*<br/>*/}
                    Date: <b>{moment(voucher.date).format("DD MMM YYYY")}</b>
                    {
                        (voucher.type === "receipt" || voucher.type === "payment")  &&
                        <>
                            <br/>
                            Payment Method: <b>{voucher.paymentMode || "Cash"}</b>
                            <br/>
                            {voucher.type === "receipt" ? "Receiving Account:" : "Paying Account"} <b>{voucher.crParty?.name || ""}</b>
                        </>
                    }
                </Col>
            </Row>

            {
                (voucher.type !== "receipt" || voucher.entryType === "Invoice-Wise") && document.type !== "payment" &&
                <Table
                    columns={voucher.type === "receipt" ? receiptCols : cols}
                    dataSource={voucher.type === "receipt" ? (loadingIMap ? [] : (voucher.distribution || [])) : voucher.items}
                    pagination={false}
                    loading={voucher.type === "receipt" && loadingIMap}
                    style={{marginTop: 24}}
                />
            }

            {
                !(voucher.type !== "receipt" || voucher.entryType === "Invoice-Wise") &&
                <Divider/>
            }

            {
                voucher.type === "invoice" && extraDiscount && <div style={{textAlign: 'right', marginTop: 16,

                    fontSize: 16,
                    paddingRight: 24,
                }}>
                    {extraDiscount.title}:
                    &nbsp;{RUPEE}&nbsp;-{Number(extraDiscount.amount).toFixed(0)}
                </div>
            }
            {
                transactionType.subTab === "new" &&
                <div style={{
                    marginTop: 16,
                    color: 'green',
                    fontSize: 18,
                    textAlign: 'right',
                    fontWeight: 'bold',
                    paddingRight: 24
                }}>
                    Total Amount{(voucher.type === "receipt" && " Received") || (voucher.type === "payment" && " Paid")}: ₹{beautifyAmount(voucher.amount)}
                </div>

            }


            {
                true &&
                    <>
                        <br/>
                        <h3>IMEIs</h3>
                <Input.TextArea
                    disabled={true}
                    value={voucher.serialNums.join("\n")}
                    rows={10}
                />
                    </>
            }

            {
                <div style={{position: 'fixed', bottom: 32}}>
                    <b>Remarks</b>:&nbsp;
                    <i>
                        {voucher.remarks}
                    </i>
                </div>
            }

        </div>
    )
};

export default ErpInvRecordDetails;
