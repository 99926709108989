import React, {useEffect, useState} from "react";
import {getCustomers, saveCustomer} from "../../services/api";
import {useSelector} from "react-redux";
import {Button, Form, Input, message, Modal, Select, Table} from "antd";
import {convertArrayToMap} from "../../utils";
import {Link} from "react-router-dom";
import _ from "lodash";
import {states} from "../../models/constants";
import {EditOutlined} from "@ant-design/icons";
import { ButtonV2 } from "../../components/ButtonV2";

const findStateByGstin = (gstin = "") => {
    const inputStateCode = gstin.slice(0, 2);
    const vals = _.values(states["IN"]);
    for (const state of vals) {
        if (state.gstCode === inputStateCode) {
            return state.alphaCode
        }
    }
}

const Customers = () => {
    const state = useSelector(state => state.oldState);
    const [customers, setCustomers] = useState([]);
    const [editId, setEditId] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [gstState, setGstState] = useState();
    const [form] = Form.useForm();
    if (gstState) {
        form.setFieldsValue({state: gstState});
    }

    const fetchCustomers = async () => {
        console.log("## Customers Updating ##");
        await getCustomers().then(res => setCustomers(res.data.data.customers));
    }

    useEffect(() => {
        const customersMap = convertArrayToMap(customers, "_id");
        form.resetFields();
        if (editId) {
            form.setFieldsValue(customersMap[editId]);
        }
    }, [editId, form, customers])

    useEffect(() => {
        if (state?.company) {
            fetchCustomers();
        }
    }, [state]);

    const columns = [
        {
            title: "S.No.",
            render: (curr, obj, index) => (index + 1).toString()
        },
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Contact",
            dataIndex: "phone",
        },
        {
            title: "Address",
            dataIndex: "address"
        },
        {
            title: "Subscriptions",
            render: (curr) => (curr?.subscriptions?.length || 0) + ' linked subscriptions'
        },
        {
            title: "Actiosn",
            render: (curr) => (
                <>
                    <Link to={`/subscription/users/view/${curr._id}`}>
                        <Button>
                            View
                        </Button>
                    </Link>
                    &nbsp;
                    <Button type={"primary"} onClick={() => {
                        setEditId(curr._id);
                        setShowModal(true);
                    }}>
                        <EditOutlined/>
                    </Button>
                </>
            )
        }
    ]

    return (
        <div>
            <h2 style={{float: 'left'}}>Customers</h2>
            <ButtonV2 onClick={async () => {
                setEditId("");
                setShowModal(true);
            }} style={{float: 'right'}} type={"primary"}>
                Add New Customer</ButtonV2>
            <Table
                columns={columns}
                dataSource={customers}
            />

            <Modal
                visible={showModal}
                title={"Add New Customer"}
                onCancel={() => setShowModal(false)}
                onOk={async () => {
                    const vals = await form.validateFields();
                    if (editId) {
                        vals._id = editId;
                    }
                    console.log("## vals ##", vals);
                    await saveCustomer({customer: vals});
                    message.success("Customer saved successfully");
                    fetchCustomers();
                    setShowModal(false);
                }}
            >
                <Form
                    layout={"vertical"}
                    form={form}
                >
                    <Form.Item name={"name"} label={"Name"}
                               rules={[{required: true, message: "Please select"}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={"phone"} label={"Phone"}
                               // rules={[{required: true, message: "Please select"}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item name={"address"} label={"Address"}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={"gstin"} label={"GSTIN (Optional)"}>
                        <Input maxLength={15} onChange={(e) => setGstState(findStateByGstin(e.target.value))}/>
                    </Form.Item>
                    <Form.Item
                        name="state"
                        label="State"
                        rules={[
                            {required: true}
                        ]}
                    >
                        <Select
                            disabled={!!gstState}
                            options={_.map(states, state => ({label: state.name, value: state.alphaCode}))}
                            allowClear={true}
                            filterOption={(input, option) => {
                                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                            showSearch
                        />
                    </Form.Item>
                </Form>
            </Modal>


        </div>
    )
};

export default Customers;
