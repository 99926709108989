import React, {useEffect, useState} from "react";
import {Layout, Menu} from "antd";
import {
    DollarOutlined,
    FileOutlined,
    IdcardOutlined,
    UsergroupAddOutlined,
    UserOutlined,
    BankOutlined, FileDoneOutlined, ShoppingCartOutlined, LineChartOutlined, BarcodeOutlined, CodeSandboxOutlined
} from "@ant-design/icons";
import {Link, Outlet, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const {Sider, Content} = Layout;

const ErpInventory = () => {
    const state = useSelector(state => state.oldState);
    const params = useParams();
    const [selectedKey, setSelectedKey] = useState();
    useEffect(() => {
        setSelectedKey(params["*"]?.split("/")[1]);
    }, [params]);

    return (
        <div>
            <Layout>
                <Sider width={160} className="site-layout-background">
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={[selectedKey]}
                        style={{height: '100%', borderRight: 0}}
                        selectedKeys={[selectedKey]}
                    >
                        <Menu.Item key={"sale"} icon={<FileDoneOutlined/>}>
                            <Link to={"/erp_inv/sale/new"}>Sale</Link>
                        </Menu.Item>
                        <Menu.Item key={"purchase"} icon={<ShoppingCartOutlined/>}>
                            <Link to={"/erp_inv/purchase/new"}>Purchase</Link></Menu.Item>
                        <Menu.Item key={"summary"} icon={<LineChartOutlined/>}>
                            <Link to={"/erp_inv/summary"}>Summary</Link></Menu.Item>
                        <Menu.Item key={"advanced"} icon={<BarcodeOutlined/>}>
                            <Link to={"/erp_inv/advanced"}>Advanced</Link></Menu.Item>
                        <Menu.Item key={"items"} icon={<CodeSandboxOutlined/>}>
                            <Link to={"/erp_inv/items"}>Items</Link></Menu.Item>

                        {/*<Menu.Item key={"reports"} icon={<BankOutlined/>}>*/}
                        {/*    <Link to={"/erp_inv/reports"}>Reports</Link></Menu.Item>*/}
                    </Menu>
                </Sider>
                <Layout style={{padding: 0}}>
                    <Content
                        className="site-layout-background"
                        style={{
                            // padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}
                    >
                        <Outlet/>
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
};

export default ErpInventory;
