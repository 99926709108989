import React from "react";
import {Col, Image, Row} from "antd";
import logo from "../../../res/img/logoLandscapeV3.png";
import {isMobileView} from "../../../utils";

const BrandingHeader = (props) => {
    const isMobile = isMobileView();
    let sidePadding = props.headerChange ? 20 : "11%";
    
    
    return (
        <div style={{borderBottom: '1px solid lightgrey', fontSize: 14, padding: 7, paddingLeft: isMobile ? 12 : sidePadding, paddingRight: isMobile ? 12 :sidePadding}}>
 
            <Row>
                {
                    !isMobile &&
                    
                    <Col span={12}>

                        {props.headerChange ?   <div>
                        <strong>Ledger Statement from </strong>
                        &nbsp;&nbsp;
                        {props.company?.name}
                        </div> : 
                        <div>
                        <strong>Do you own a business ?</strong>
                        &nbsp;&nbsp;
                        Make your business online with KhataBuddy
                        </div>
                        }
                        
                        

                    </Col>
                }
                <Col span={isMobile ? 24 : 12} style={{textAlign: 'right', fontSize: isMobile ? 10 : 12}}>
                    Powered By&nbsp;&nbsp;
                    <Image style={{height: isMobile ? 16 : 24}} preview={false} src={logo}/>
                </Col>
            </Row>
        </div>
    )
};

export default BrandingHeader;
