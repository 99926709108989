import React from "react";

const SubSettings = () => {

    return (
        <div>
            Settings Component
        </div>
    )
};

export default SubSettings;
