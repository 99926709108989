import React, {useEffect, useRef, useState} from "react";
import {getProducts, saveProduct} from "../../services/api";
import {
    Button,
    Col,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Popconfirm,
    Row,
    Select,
    Table,
    Tag,
    Tooltip
} from "antd";
import {useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import _ from "lodash";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import { ButtonV2 } from "../../components/ButtonV2";

const ProductPage = () => {
    const state = useSelector(state => state.oldState);
    const params = useParams();
    const {productId} = params;
    const [product, setProduct] = useState({});
    const [showPlanModal, setShowPlanModal] = useState(false);
    const [editId, setEditId] = useState("");
    const [cFields, setCFields] = useState([]);
    const [tagState, setTagState] = useState({
        tags: ['Unremovable', 'Tag 2', 'Tag 3'],
        inputVisible: false,
        inputValue: '',
        editInputIndex: -1,
        editInputValue: '',
    });
    const inputRef = useRef(0);
    const [editInputRef, setEditInputRef] = useState();
    const [formPlan] = Form.useForm();
    const [formName] = Form.useForm();
    console.log("## Product ##", product);

    useEffect(() => {
        const fetchProducts = async () => {
            await getProducts().then(resp => setProduct(resp.data.data.products.filter(({_id}) => _id === productId)[0]));
            console.log("## Fetched Products ##");
        }

        if (state?.company) {
            fetchProducts();
        }
    }, [state, productId]);


    useEffect(() => {
        setCFields(product.customFields || []);
    }, [product]);

    useEffect(() => {
        const plan = product?.plans?.[editId];
        formPlan.resetFields();
        if (plan) {
            formPlan.setFieldsValue(plan);
        }
    }, [editId, formPlan, product?.plans]);

    const columns = [
        {
            title: "S.No.",
            render: (curr, obj, index) => (index + 1).toString()
        },
        {
            title: "Plan Name",
            dataIndex: "name",
        },
        {
            title: "Duration",
            dataIndex: "duration",
        },
        {
            title: "Price",
            dataIndex: "price"
        },
        {
            title: "Actions",
            render: (curr, obj, index) => (
                <>
                    <Button type={"primary"}
                            onClick={() => {
                                setEditId(index);
                                setShowPlanModal(true);
                            }}
                    ><EditOutlined/></Button>
                    <Popconfirm title={"Are you sure to delete this plan ?"} onConfirm={async () => {
                        const clonedProduct = _.cloneDeep(product);
                        clonedProduct.plans.splice(index, 1);
                        setProduct(clonedProduct);
                        // return;
                        await saveProduct({product: clonedProduct});
                        message.success("Plan deleted successfully");
                    }}>
                        <Button type={"danger"}><DeleteOutlined/></Button>
                    </Popconfirm>
                </>
            )
        }
    ]

    const onEnter = () => {
        const { inputValue } = tagState;
        let { tags } = tagState;
        if (inputValue && tags.indexOf(inputValue) === -1) {
            tags = [...tags, inputValue];
        }
        console.log(tags);
        setTagState({
            ...tagState,
            tags,
            inputVisible: false,
            inputValue: '',
        });
    };

    const { tags, inputVisible, inputValue, editInputIndex, editInputValue } = tagState;

    return (
        <div>
            <h2 style={{float: 'left'}}>Product: {product?.name}
                <Link to={"#"} type={"primary"}
                        onClick={() => {
                            const modal = Modal.info();
                            modal.update({
                                icon: false,
                                maskClosable: true,
                                title: 'Update product name',
                                content: (
                                    <div>
                                        <Form form={formName}>
                                            <Form.Item name={"name"} label={"New Name"} rules={[{required: true, message: "can't be blank"}]}>
                                                <Input />
                                            </Form.Item>
                                        </Form>
                                    </div>
                                ),
                                onOk: async () => {
                                    const formVals = await formName.validateFields();
                                    const clonedProduct = _.cloneDeep(product);
                                    clonedProduct.name = formVals.name;
                                    // await new Promise(resolve => setTimeout(resolve, 500));
                                    await saveProduct({product: clonedProduct});
                                    message.success("Product name updated successfully");
                                    setProduct(clonedProduct);
                                }
                            });
                        }}
                ><EditOutlined/></Link>
            </h2>
            <ButtonV2 onClick={async () => {
                setEditId("");
                setShowPlanModal(true);
            }} style={{float: 'right'}} type={"primary"}>
                Add New Plan</ButtonV2>
            <br/>
            <br/>
            <h3>Custom Fields: </h3>

            <Select
                style={{width: '100%'}}
                mode={"tags"}
                value={cFields}
                onChange={async (customFields) => {
                    setCFields(customFields);
                    const clonedProduct = _.cloneDeep(product);
                    clonedProduct.customFields = customFields;
                    setProduct(clonedProduct);
                    await saveProduct({product: clonedProduct});
                }}
                tokenSeparators={[',']}
                />
            {
                false &&
                <>
                {tags.map((tag, index) => {
                    if (editInputIndex === index) {
                        return (
                            <Input
                                ref={(iRef) => setEditInputRef(iRef)}
                                key={tag}
                                size="small"
                                className="tag-input"
                                value={editInputValue}
                                onChange={(e) => setTagState({...tagState, editInputValue: e.target.value})}
                                onBlur={onEnter}
                                onPressEnter={onEnter}
                            />
                        );
                    }

                    const isLongTag = tag.length > 20;

                    const tagElem = (
                        <Tag
                            className="edit-tag"
                            key={tag}
                            closable={index !== 0}
                            onClose={() => {
                                const cTags = tagState.tags.filter(t => t !== tag);
                                console.log(cTags);
                                setTagState({...tagState, tags: cTags});
                            }}
                        >
              <span
                  onDoubleClick={e => {
                      if (index !== 0) {
                          setTagState({...tagState, editInputIndex: index, editInputValue: tag}, () => {
                              editInputRef.focus();
                          });
                          e.preventDefault();
                      }
                  }}
              >
                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
              </span>
                        </Tag>
                    );
                    return isLongTag ? (
                        <Tooltip title={tag} key={tag}>
                            {tagElem}
                        </Tooltip>
                    ) : (
                        tagElem
                    );
                })}
                {inputVisible && (
                    <Input
                        ref={inputRef}
                        type="text"
                        size="small"
                        className="tag-input"
                        value={inputValue}
                        onChange={(e) => setTagState({...tagState, inputValue: e.target.value})}
                        onBlur={onEnter}
                        onPressEnter={onEnter}
                    />
                )}
                {!inputVisible && (
                    <Tag className="site-tag-plus" onClick={() => {
                        setTagState({...tagState, inputVisible: true});
                        console.log("## InputRef ##", inputRef);
                        inputRef.current?.focus?.();
                    }}>
                        <PlusOutlined/> New Tag
                    </Tag>
                )}
            </>
            }

            <br/>
            <br/>
            <Table
                columns={columns}
                dataSource={product?.plans}
            />


            <Modal
                visible={showPlanModal}
                title={"Add New Plan"}
                okText={"Add"}
                cancelText="Cancel"
                onCancel={() => {
                    // formPlan.resetFields();
                    setShowPlanModal(false);
                }}
                onOk={async () => {
                    const values = await formPlan.validateFields();
                    console.log("## Plan Vals ##", {editId, values});
                    const clonedProduct = _.cloneDeep(product);
                    if (editId !== "") { // edit
                        clonedProduct.plans[editId] = values;
                    } else { // new
                        clonedProduct.plans.push(values);
                    }
                    setProduct(clonedProduct);
                    setShowPlanModal(false);
                    // return;
                    await saveProduct({product: clonedProduct});
                    message.success("Plan saved successfully");
                    //         const email = values.email;
                    //         if (_.filter(users || [], user => user.email === email).length) {
                    //             message.error("User already added !");
                    //             return;
                    //         }
                    //         const resp = await addSubuser({
                    //             email
                    //         });
                    //         if (_.get(resp, 'data.success')) {
                    //             message.success("Invite sent successfully");
                    //             form.resetFields();
                    //             onCancel();
                    //         } else {
                    //             message.error(resp.data.message);
                    //         }
                    //     })
                }}
            >
                <Form
                    form={formPlan}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{}}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{required: true, message: "can't be blank"}]}
                    >
                        <Input/>
                    </Form.Item>
                    Plan Validity:
                    <Row>
                        <Col span={12}>
                            <Form.Item name={"duration"} label={""}
                                       rules={[{required: true, message: "can't be blank"}]}>
                                <InputNumber style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={"durationUnit"} label={""}
                                       rules={[{required: true, message: "can't be blank"}]}>
                                <Select
                                    options={[
                                        {label: "Days", value: "d"},
                                        {label: "Months", value: "M"},
                                        {label: "Years", value: "y"}
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name={"price"} label={"Price"} rules={[{required: true, message: "can't be blank"}]}>
                        <InputNumber style={{width: '100%'}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
};

export default ProductPage;
