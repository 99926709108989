import { createStore } from 'redux';
import _ from "lodash";

const oldProps = ['user', 'company', 'items'];

function reducer(state = {}, {type, props}) {
    if (!state.oldState) {
        state.oldState = {};
    }
    if (type === "original") {
        // _.assign(state, props);
        const selectiveOldProps = _.pick(props, oldProps);
        if (Object.keys(selectiveOldProps).length) {
            _.assign(state.oldState, _.pick(props, oldProps));
            state.oldState = _.cloneDeep(state.oldState);
        }
    }

    // return _.cloneDeep(state);

    _.assign(state, _.omit(props, oldProps));
    return {
        ...state,
    }
}

const store = createStore(reducer, {})

export default store;