import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { InputNumber, Button, message } from "antd";
import { redirectToPay } from "../../services/api";
import { RUPEE } from "../../models/constants";
import { beautifyAmount } from "../../utils";

const PayNowDrawer = ({
  payData,
  updateContent,
  onRedirect = () => {},
  closeDrawer = () => {},
}) => {
  const [paymentAmount, setPaymentAmount] = useState(0);

  const data = {
    amount: paymentAmount,
    ledgerId: payData.ledgerId,
    voucherId: payData.voucherId,
  };

  const RedirectToPay = async () => {
    if(paymentAmount > 10 && paymentAmount <= 10000){const Data = await redirectToPay(data);
    onRedirect(Data);
    closeDrawer();}
    else { message.error( paymentAmount < 11 ? 'Please add amount more than ₹10' : 'KYC required for amount more than ₹10,000')}
  };

  useEffect(() => {
    setPaymentAmount(payData.payAmount);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateContent]);

  return (
    <div>
      <OuterContainer>
        <Heading>
          Initiate payment by paying the full or partial amount.
        </Heading>
        <BottomContainer>
          <InputNumber
            prefix={RUPEE}
            min={1}
            value={paymentAmount}
            onChange={(val) => setPaymentAmount(val)}
            style={{
              width: 120,
            }}
            placeholder={"Input Amount"}
          />
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              RedirectToPay();
            }}
          >
            Pay ({RUPEE}{' '}{beautifyAmount(paymentAmount)})
          </Button>
        </BottomContainer>
      </OuterContainer>
    </div>
  );
};

export default PayNowDrawer;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 20px;
  font-weight: bold;
  width: 45%;
  margin-top: 20px;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Heading = styled.div`
  font-size: 18px;
`;
