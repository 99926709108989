import React from 'react';
import {Modal, Form, Input, message, Alert} from 'antd';
import _ from "lodash";
import {addSubuser} from "../services/api";

const SubuserModal = ({visible, isNew, users, onCancel}) => {
    const [form] = Form.useForm();

    return (
        <Modal
            visible={visible}
            title={isNew ? "Invite new user to company" : "Modify Permissions"}
            okText={"Send Invite"}
            cancelText="Cancel"
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then(async (values) => {
                        const email = values.email;
                        if (_.filter(users || [], user => user.email === email).length) {
                            message.error("User already added !");
                            return;
                        }
                        const resp = await addSubuser({
                            email
                        });
                        if (_.get(resp, 'data.success')) {
                            message.success("Invite sent successfully");
                            form.resetFields();
                            onCancel();
                        } else {
                            message.error(resp.data.message);
                        }
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{}}
            >
                <Form.Item
                    name="email"
                    label="Username (Email or Mobile)"
                    rules={[
                        {
                            // type: "email",
                            required: true,
                            message: "Invalid Email"
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                {
                    isNew && <Alert message={"If email is used, user will receive an invite on email with a link to accept it (Link will expire after 24 hours, if not accepted)"}/>
                }

            </Form>
        </Modal>
    );
};


export default SubuserModal;
