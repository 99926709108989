import React, {useEffect} from "react";
import {Button, Form, Input, message} from "antd";
import {saveDeviceModel} from "../../../erp/services/api";

const DeviceModelWriteDrawer = ({closeDrawer, onSave, record}) => {

    const isNewRecord = !record;
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(record);
    }, [record]);

    return (
        <div>
            <Form
                form={form}
                layout={"vertical"}
                onFinish={async (vals) => {
                    console.log("## FormVals ##", vals);
                    if (!isNewRecord) {
                        vals._id = record._id;
                    }
                    await saveDeviceModel({deviceModel: vals});
                    message.success(`Device HW model saved successfully`);
                    closeDrawer();
                    onSave();
                }}
            >
                <Form.Item label={"Name"} name={"name"} rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label={"SMS Commands (separated in multiple lines)"} name={"smsCommands"}>
                    <Input.TextArea/>
                </Form.Item>
                <Button type={"primary"} htmlType={"submit"}>
                    Save Item
                </Button>
            </Form>
        </div>
)
};

export default DeviceModelWriteDrawer;
