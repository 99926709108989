import React, {useEffect, useState} from "react";
import {Table, Tag} from "antd";
import moment from "moment";
import {getErpInventoryTransactions} from "../services/api";
import {transactionTypesMap} from "../models/webConfigs";

const InvHistoryDrawer = ({data = {}}) => {
    const {deviceId} = data;
    const [historyData, setHistoryData] = useState({});

    useEffect(() => {
        getErpInventoryTransactions({deviceId}).then(resp => setHistoryData(resp.data.data));
    }, [deviceId]);

    const columns = [
        {
            title: "Date",
            render: obj => moment(obj.date).format("DD MMM YYYY"),
        },
        {
            title: "Type",
            render: obj => <div color={transactionTypesMap[obj.type]?.color}>{transactionTypesMap[obj.type]?.label || "---"}</div>,
        },
        {
            title: "Movement",
            dataIndex: "movement",
        },
    ];

    return (
        <div>
            <Table
                columns={columns}
                dataSource={historyData.records || []}
                />
        </div>
    )
};

export default InvHistoryDrawer;
