import React, {useEffect, useState} from "react";
import {Layout, Menu} from "antd";
import {
    DollarOutlined,
    FileOutlined,
    IdcardOutlined,
    UsergroupAddOutlined,
    UserOutlined,
    BankOutlined
} from "@ant-design/icons";
import {Link, Outlet, useParams} from "react-router-dom";
import {isErpWeb, isPuc} from "../../../utils";
import {verifyRole} from "../../../erp/utils";
import {ROLES} from "../../../erp/models/constant";
import {useSelector} from "react-redux";

const {Sider, Content} = Layout;

const ChatbotPage = () => {
    const state = useSelector(state => state.oldState);
    const params = useParams();
    const [selectedKey, setSelectedKey] = useState();
    useEffect(() => {
        setSelectedKey(params["*"]?.split("/")[1]);
    }, [params]);

    return (
        <div>
            <Layout>
                <Sider style={{display: 'none'}} width={160} className="site-layout-background">
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={[selectedKey]}
                        style={{height: '100%', borderRight: 0}}
                        selectedKeys={[selectedKey]}
                    >
                        {/*{*/}
                        {/*    isPuc() &&*/}
                        {/*    <Menu.Item key={"config"} icon={<AppstoreOutlined/>}>*/}
                        {/*        <Link to={"/settings/config"}>Config</Link>*/}
                        {/*    </Menu.Item>*/}
                        {/*}*/}


                        <Menu.Item key={"messages"} icon={<UserOutlined/>}>
                            <Link to={"/portal/chatbot/messages"}>Messages</Link>
                        </Menu.Item>
                        {
                            !isPuc() && !isErpWeb() && <>
                                <Menu.Item key={"company"} icon={<IdcardOutlined/>}>
                                    <Link to={"/settings/company"}>Company</Link></Menu.Item>
                                <Menu.Item key={"bank"} icon={<BankOutlined/>}>
                                    <Link to={"/settings/bank"}>Bank</Link></Menu.Item>
                                <Menu.Item key={"invoice"} icon={<FileOutlined/>}>
                                    <Link to={"/settings/invoice"}>Invoice</Link>
                                </Menu.Item>
                            </>
                        }
                        {
                            !isErpWeb() &&
                            <Menu.Item key={"subscription"} icon={<DollarOutlined/>}>
                                <Link to={"/settings/subscription"}>Subscription</Link>
                            </Menu.Item>
                        }
                        {
                            !isPuc() && (!isErpWeb() || verifyRole(ROLES.MANAGE_USERS, state.user)) &&
                            <Menu.Item key={"users"} icon={<UsergroupAddOutlined/>}>
                                <Link to={"/settings/users"}>Users & Roles</Link>
                            </Menu.Item>
                        }
                        {
                            !isPuc() && !isErpWeb() &&
                            <Menu.Item key={"wallet"} icon={<DollarOutlined/>}>
                                <Link to={"/settings/wallet"}>Wallet</Link>
                            </Menu.Item>
                        }

                    </Menu>
                </Sider>
                <Layout style={{padding: '0 24px 24px'}}>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}
                    >
                        <Outlet/>
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
};

export default ChatbotPage;
