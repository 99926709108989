import React, {useEffect, useState} from "react";
import {Button, Form, message, Modal, Select, Table} from "antd";
import {getCustomers, getProducts, getRenewals, getSubscriptions, saveRenewal} from "../../services/api";
import {useSelector} from "react-redux";
import {convertArrayToMap} from "../../utils";
import _ from "lodash";
import {Link} from "react-router-dom";
import { ButtonV2 } from "../../components/ButtonV2";


const Renewals = () => {
    const state = useSelector(state => state.oldState);
    const [formRenew] = Form.useForm();
    const [showModal, setShowModal] = useState(false);
    const [renewals, setRenewals] = useState([]);
    const [products, setProducts] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [customers, setCustomers] = useState([]);

    const [formCustomerId, setFormCustomerId] = useState();
    const [formSubscriptionId, setFormSubscriptionId] = useState();

    const productsMap = convertArrayToMap(products, "_id");
    const subscriptionsMap = convertArrayToMap(subscriptions, "_id");
    const customersMap = convertArrayToMap(customers, "_id");

    const fetchRenewals = async () => {
        await getRenewals().then(resp => setRenewals(resp.data.data.renewals));
        console.log("## Fetched Renewals ##");
    }

    const fetchSubscriptions = async () => {
        getSubscriptions().then(resp => setSubscriptions(resp.data.data.subscriptions));
        console.log("## Fetched Subscriptions ##");
    }

    const fetchCustomers = async () => {
        await getCustomers().then(resp => setCustomers(resp.data.data.customers));
        console.log("## Fetched Customers ##");
    }

    const fetchProducts = async () => {
        await getProducts().then(resp => setProducts(resp.data.data.products));
        console.log("## Fetched Products ##");
    }


    useEffect(() => {
        if (state?.company) {
            fetchRenewals();
            fetchSubscriptions();
            fetchCustomers();
            fetchProducts();
        }
    }, [state]);

    const columns = [
        {
            title: "S.No.",
            render: (curr, obj, index) => (index + 1).toString()
        },
        {
            title: "Customer",
            render: (curr) => customersMap[subscriptionsMap[curr.subscriptionId]?.customerId]?.name
        },
        {
            title: "Product",
            render: (curr) => productsMap[subscriptionsMap[curr.subscriptionId]?.productId]?.name
        },
        {
            title: "Product Info",
            render: (curr) => (
                <>
                    {_.map(subscriptionsMap[curr.subscriptionId]?.customFieldValues, (val, key) => (
                        <div>{key}: {val}</div>
                    ))}
                </>
            )
        },
        {
            title: "Plan",
            render: (curr) => (
                <>
                    {curr?.plan?.name}
                    <br/>
                    ({curr?.plan?.duration} {curr?.plan?.durationUnit})
                </>
            )
        },
        {
            title: "Price",
            render: (curr) => curr?.plan?.price || "---"
        },
        {
            title: "Actions",
            render: (curr) => (<>
                <Link to={`/subscription/sales/view/${curr._id}`}>
                    <Button type={"primary"}>
                        View Invoice
                    </Button>
                </Link>
            </>)
        }
    ];

    return (
        <div>
            <h2 style={{float: 'left'}}>Renewals</h2>
            <ButtonV2 onClick={async () => {
                // setEditId("");
                setShowModal(true);
            }} style={{float: 'right'}} type={"primary"}>
                Add Renewal</ButtonV2>
            <Table
                columns={columns}
                dataSource={renewals}
            />

            <Modal
                visible={showModal}
                title={"Add Manual Renewal"}
                onCancel={() => setShowModal(false)}
                onOk={async () => {
                    const vals = await formRenew.validateFields();
                    const plan = _.cloneDeep(
                        productsMap[subscriptionsMap[formSubscriptionId]?.productId]?.plans
                            .filter(p => p.name === vals.plan)[0]
                    );
                    vals.plan = plan;
                    console.log("## vals ##", vals);
                    await saveRenewal({renewal: vals});
                    message.success("Renewal saved successfully");
                    fetchRenewals();
                    setShowModal(false);
                }}
            >
                <Form
                    form={formRenew}
                >
                    <Form.Item name={"customerId"} label={"Customer"}
                               rules={[{required: true, message: "Please select"}]}>
                        <Select
                            onChange={(val) => setFormCustomerId(val)}
                            options={customers.map(c => ({label: c.name, value: c._id}))}
                        />
                    </Form.Item>
                    <Form.Item name={"subscriptionId"} label={"Subscription"}
                               rules={[{required: true, message: "Please select"}]}>
                        <Select
                            onChange={(val) => setFormSubscriptionId(val)}
                            options={subscriptions.filter((s) => s.customerId === formCustomerId)
                                .map(p => ({label: p._id, value: p._id}))}
                        />
                    </Form.Item>
                    <Form.Item name={"plan"} label={"Plan"} rules={[{required: true, message: "Please select"}]}>
                        <Select
                            // onChange={(val) => setFormCustomerId(val)}
                            options={
                                productsMap[subscriptionsMap[formSubscriptionId]?.productId]?.plans
                                    .map(p => ({label: p.name, value: p.name}))
                            }
                        />
                    </Form.Item>
                </Form>
            </Modal>

        </div>
    )
};

export default Renewals;
