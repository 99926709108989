import React, {useState} from "react";
import {Alert, Button, Form, Input, Select} from "antd";
import {submitHelpTicket} from "../../services/api";
import { ButtonV2 } from "../../components/ButtonV2";

const Help = () => {

    const [form] = Form.useForm();
    const [ticketNum, setTicketNum] = useState();

    return (
        <div style={{padding: 30}}>
            <h2>Contact Support</h2>
            <Form form={form} layout={"vertical"} style={{width: '50%', maxWidth: 500}}>
                <Form.Item name={"name"} label={"Name"} rules={[{required: true, message: "can't be blank"}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name={"email"} label={"Email"}
                           rules={[{type: "email", required: true, message: "Invalid Email"}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name={"subject"} label={"Subject"}
                           rules={[{required: true, message: "Please select subject"}]}>
                    <Select
                        placeholder={"Select a subject"}
                        options={
                            [
                                "Facing an Issue",
                                "Feedback",
                                "Customized Plan Requirement",
                                "Request a New Feature"
                            ]
                                .map(item => ({label: item, value: item}))
                        }
                    />
                </Form.Item>
                <Form.Item name={"message"} label={"Message"} rules={[{required: true, message: "can't be blank"}]}>
                    <Input.TextArea rows={4}/>
                </Form.Item>
            </Form>
            <ButtonV2 type={"primary"} onClick={async () => {
                const formData = await form.validateFields();
                const resp = await submitHelpTicket(formData);
                const {ticketNum} = resp.data.data;
                setTicketNum(ticketNum);
                form.resetFields();
            }}>
                Submit
            </ButtonV2>
            <br/><br/>
            {
                ticketNum &&
                <Alert
                    message={`Ticket #${ticketNum} has been created for your query. Our team will reply you within 48 working hours through email.`}
                    type="info" showIcon={true}/>
            }

        </div>
    )
};

export default Help;
