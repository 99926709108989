import React, {useEffect, useState} from 'react';
import {
    Table,
    Button,
    Popconfirm,
    message,
    Row,
    Col,
    Input,
    Divider, DatePicker, Segmented, Dropdown, Menu, Popover, Alert, Tooltip, Empty
} from 'antd';
import {getVouchers, deleteVoucher} from '../../services/api';
import {useDispatch, useSelector} from "react-redux";
import {
    DeleteOutlined,
    EditOutlined,
    ShareAltOutlined,
    PlusOutlined,
    LinkOutlined,
    MailFilled,
    WhatsAppOutlined,
    MessageFilled,
    ArrowUpOutlined, ArrowDownOutlined
} from "@ant-design/icons";
import moment from "moment";
import { sendSMS } from "../../services/api";

import {
    beautifyAmount,
    customDispatcher,
    copyToClipboard,
    isValidPhone,
    getPublicShareLink,
    PublicShareLinks
} from "../../utils";
import {RUPEE, voucherTypes} from "../../models/constants";
import { ButtonV2 } from '../../components/ButtonV2';
import { getDateRange, saveDateRange } from '../../services/cookies';
import { getPresetDateRange } from '../../services/functions';

const {RangePicker} = DatePicker;


const PaymentModule = () => {
    
    const dateRange = getDateRange()
    const dispatch = customDispatcher(useDispatch());
    const docType = voucherTypes['receipt'];
    const state = useSelector(state => state.oldState);
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterClient, setFilterClient] = useState("");
    const [filterDate, setFilterDate] = useState(dateRange);
    const [selectedSegment, setSelectedSegment] = useState("all");
    const [onSave, setOnSave] = useState(true);


    const updateTable = () => {
        setOnSave(!onSave);
    }


    const applyFiltersOnVoucher = (record) => {
        const filterByInvoice = record.displayDocNum?.toLowerCase().includes(filterClient.toLowerCase());
        const filterByClient = record.partyName?.toLowerCase().includes(filterClient.toLowerCase());
        const filterByVendor = record.crPartyName?.toLowerCase().includes(filterClient.toLowerCase());
        let filterByDate = true;
        const [start, end] = filterDate || [];
        if (start && end) {
            filterByDate = moment(record.date).isBetween(start.startOf('day'), end.endOf('day'));
        }
        let filterbySegment = true;
        if (selectedSegment === "all") {
            filterbySegment = true;
        } else if (selectedSegment === "payin" && record.type === "receipt") {
            filterbySegment = true;
        } else if (selectedSegment === "payout" && record.type === "payment") {
            filterbySegment = true;
        } else {
            filterbySegment = false;
        }
        return (filterByInvoice || filterByClient || filterByVendor) && filterByDate && filterbySegment;
    }

    const filteredRecords = records.filter(applyFiltersOnVoucher)

    const columns = [
        {
            title: "Date",
            render: (obj) => moment(obj.date).format('DD MMM YYYY'),     
            sorter: (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf(),
            defaultSortOrder: "descend"
        },
        {
            title: 'Document #',
            dataIndex: 'displayDocNum',
            sorter: (a, b) => {
                const aPrefix = a.displayDocNum.prefix || "";
                const aNum = a.displayDocNum.num;
                const bPrefix = b.displayDocNum.prefix || "";
                const bNum = b.displayDocNum.num;
                if (aPrefix === bPrefix) {
                    return aNum - bNum;
                }
                return aPrefix.localeCompare(bPrefix);
            },
        },
        {
            title: 'Debit Ledger',
            render : obj => <b>{obj.partyName}</b>,
            sorter: (a, b) => a.partyName?.localeCompare(b.partyName)
        },
        {
            title: 'Credit Ledger',          
            render : obj => <b>{obj.crPartyName}</b>,
            sorter: (a, b) => {a.crPartyName?.localeCompare(b.crPartyName)}
        },
        {
            title: 'Amount',
            render: (obj) => {
                return (
                   <div>  {obj.type === 'payment' ? <b style={{color: 'red'}}><ArrowUpOutlined /> ₹ {beautifyAmount(obj.amount)}</b>:
                   <b style={{color: 'green'}}><ArrowDownOutlined /> ₹ {beautifyAmount(obj.amount)}</b>}
                    </div> 
                )
            },
            sorter: (a, b) => a.amount - b.amount
        },
        {
            title: 'Actions',
            render: (obj) => {
                const publicLink = getPublicShareLink(PublicShareLinks.VOUCHER, obj.hashId);
                const msgContent = <>Hi {obj?.crPartyName}
                    <br/>Thank you for making payment of {RUPEE} {beautifyAmount(obj.amount)} at {obj.company?.name || ""}.
                    <br/>Here is your Payment Receipt: {publicLink}
                    <br/>- Sent using KhataBuddy</>;
                return (
                    <div>
                           <Tooltip title={"Edit"}>
                            <Button className={"btnCustom4 hoverOrangeBorder"}
                                    style={{
                                        background: '#ffecb3',
                                        color: '#ff6f00',
                                        padding: 0,
                                        height: 28,
                                        fontWeight: 'bold',
                                        fontSize: 13,
                                        paddingLeft: 8,
                                        paddingRight: 8
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                            dispatch({ 
                                                popData: {
                                                    visible: true,
                                                    type: obj.type === 'receipt' ? "receiptWrite" : "paymentWrite",
                                                    title: `Payment Details`,
                                                    data: obj,
                                                    onSave : updateTable,
                                                }
                                            });
                                        } 
                                    }
                                    
                            ><EditOutlined style={{color: '#ff6f00'}} />
                            </Button>
                    </Tooltip>
                    &nbsp;
                        <Popconfirm
                            title="Are you sure to delete this document ?"
                            onConfirm={async () => {
                                deleteVoucher(obj._id)
                                    .then(() => {
                                        message.success('Document Deleted');
                                        fetchRecords();
                                    })
                                    .catch(() => message.error('Failed to delete document'))
                            }}
                            onCancel={() => {
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Tooltip title={"Delete"}>
                            <Button className={"btnCustom4 hoverRedBorder"}
                                    style={{
                                        background: '#ffebee',
                                        color: '#b71c1c',
                                        padding: 0,
                                        height: 28,
                                        fontWeight: 'bold',
                                        fontSize: 13,
                                        paddingLeft: 8,
                                        paddingRight: 8
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                            ><DeleteOutlined style={{color: '#b71c1c'}} onClick={e => e.stopPropagation()}/>
                            </Button>
                        </Tooltip>
                        </Popconfirm>
                        &nbsp;
                        {obj.type === 'receipt' && <Dropdown overlay={
                                    <Menu className={"menuWrapper"} onClick={(e) => {
                                        e.domEvent.stopPropagation();
                                    }}>
                                        <Menu.Item className={"customHover"} onClick={() => {
                                            // e.stopPropagation();
                                            dispatch({
                                                popData: {
                                                    visible: true,
                                                    type: "email",
                                                    title: `Send ${docType.name} ${obj.displayDocNum} on Email`,
                                                    data: obj,
                                                }
                                            })
                                        }}>
                                            <div style={{fontWeight: 'bold', width: '100%'}} onClick={e => {
                                            }}>
                                                <MailFilled style={{fontSize: 18, color: 'red'}}/>&nbsp;&nbsp;Email
                                            </div>
                                        </Menu.Item>
                                    <Popover style={{width: '100%'}}
                                            content={
                                                <div onClick={e => e.stopPropagation()}>
                                                    <Alert type={"info"} style={{background: 'white', border: 0, width: 500}}
                                                           showIcon={false} message={
                                                        <>
                                                           <div>
                                                                {msgContent}
                                                            </div>
                                                            <br/>
                                                            <ButtonV2 type={"primary"} style={{fontSize: 12, padding: 4, height: 26}}
                                                                    onClick={async () => {
                                                                        if (!isValidPhone(obj?.[obj.type ==="receipt" ? "crParty" : "party"]?.phone)) {
                                                                            message.error(`Customer contact is not a valid mobile number`);
                                                                            return;
                                                                        }
                                                                        let resp;
                                                                        if (obj.type === "receipt") {
                                                                            console.log("obj -------->>>>",obj)
                                                                            resp = await sendSMS({
                                                                                origin: "EXTERNAL_KB_APP",
                                                                                phone: obj?.crParty?.phone,
                                                                                smsTemplate: "KB_WHATSAPP_SEND_VOUCHER",
                                                                                // need to check orderid text
                                                                                params: {
                                                                                    voucherLink:"https://app.khatabuddy.com/api/internal/pdf/"+obj?._id,
                                                                                    documentType:"Document",
                                                                                    customerName: obj?.crParty?.name || "customer",
                                                                                    action: `making payment of ${RUPEE} ${beautifyAmount(obj.amount)}`,
                                                                                    amount: `${RUPEE} ${beautifyAmount(obj.amount)}`,
                                                                                    merchant: obj.company?.name || "",
                                                                                    url: obj?.hashId,
                                                                                    voucherFileName:"receipt.pdf"
                                                                            
                                                                                }
                                                                            },"whatsapp");
                                                                        } else {
                                                                            message.error("“Sharing not supported for this voucher type yet”");
                                                                            return;
                                                                        }

                                                                        if (!resp.data.success) {
                                                                            message.error(resp.data.message);
                                                                        } else {
                                                                            message.success(`${docType.name} sent on SMS successfully!`);
                                                                        }
                                                                    }}>
                                                                Send on WhatsApp
                                                            </ButtonV2>
                                                        </>
                                                    }/>
                                                </div>
                                            }
                                            title="" trigger="click">
                                        <Menu.Item className={"customHover"} style={{fontWeight: 'bold'}} onClick={(menuInfo) => {
                                            // e.stopPropagation();
                                            const party = obj.type === 'receipt' ? obj.crParty : obj.party;
                                            // const waMsg = `Hi ${party?.name},\nThank you for your business.\nHere is your ${docType.name}: ${publicLink}\nYou can view or download PDF using this link.`;
                                            // const phoneString = isValidPhone(party?.phone) ? `91${party?.phone}` : "";
                                            // const waLink = `https://api.whatsapp.com/send/?phone=${phoneString}&text=${encodeURI(waMsg || "")}`;
                                            // window.open(waLink, '_blank');
                                        }}>
                                            <div style={{width: '100%'}}>
                                                <WhatsAppOutlined style={{color: 'green', fontSize: 18, cursor: 'pointer'}}/>
                                                &nbsp;&nbsp;Whatsapp
                                            </div>
                                        </Menu.Item>
                                        
                                    </Popover>
                                        <Popover
                                            style={{width: '100%'}}
                                            content={
                                                <div onClick={e => e.stopPropagation()}>
                                                    <Alert type={"info"} style={{background: 'white', border: 0, width: 500}}
                                                           showIcon={false} message={
                                                        <>
                                                           <div>
                                                                {msgContent}
                                                            </div>
                                                            <br/>
                                                            <ButtonV2 type={"primary"} style={{fontSize: 12, padding: 4, height: 26}}
                                                                    onClick={async () => {
                                                                        if (!isValidPhone(obj?.[obj.type ==="receipt" ? "crParty" : "party"]?.phone)) {
                                                                            message.error(`Customer contact is not a valid mobile number`);
                                                                            return;
                                                                        }
                                                                        let resp;
                                                                        if (obj.type === "receipt") {
                                                                            resp = await sendSMS({
                                                                                origin: "EXTERNAL_KB_APP",
                                                                                phone: obj?.crParty?.phone,
                                                                                smsTemplate: "KB_SEND_DOCUMENT",
                                                                                params: {
                                                                                    customerName: obj?.crParty?.name || "customer",
                                                                                    action: `making payment of Rs. ${beautifyAmount(obj.amount)}`,
                                                                                    merchant: obj.company?.name || "",
                                                                                    docWithLink: `${publicLink}`,
                                                                                }
                                                                            },"sms");
                                                                        } else {
                                                                            message.error("Failed to send SMS, please contact our Customer Service.");
                                                                            return;
                                                                        }

                                                                        if (!resp.data.success) {
                                                                            message.error(resp.data.message);
                                                                        } else {
                                                                            message.success(`${docType.name} sent on SMS successfully!`);
                                                                        }
                                                                    }}>
                                                                Send on SMS
                                                            </ButtonV2>
                                                        </>
                                                    }/>
                                                </div>
                                            }
                                            title="" trigger="click">

                                            <Menu.Item className={"customHover"} style={{fontWeight: 'bold'}}>
                                                <div style={{width: '100%'}}>
                                                    <MessageFilled style={{color: 'blue', fontSize: 18, cursor: 'pointer'}}
                                                                   onClick={async (e) => {
                                                                       e.stopPropagation();
                                                                   }}/>
                                                    &nbsp;&nbsp;SMS
                                                </div>
                                            </Menu.Item>
                                        </Popover>

                                        <Menu.Item className={"customHover"} style={{fontWeight: 'bold'}} onClick={() => {
                                            copyToClipboard(publicLink);
                                            message.success("Link Copied");
                                        }}>
                                            <div style={{width: '100%'}}>
                                                <LinkOutlined style={{color: '#e040fb', fontSize: 18, cursor: 'pointer'}}
                                                              onClick={(e) => {
                                                              }}/>
                                                &nbsp;&nbsp;Copy Link
                                            </div>
                                        </Menu.Item>
                                    </Menu>
                                }>
                                    <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                        <Button className={"btnCustom4"}
                                                style={{
                                                    background: '#e8f5e9',
                                                    color: '#1b5e20',
                                                    padding: 0,
                                                    height: 28,
                                                    fontWeight: 'bold',
                                                    fontSize: 13,
                                                    paddingLeft: 8,
                                                    paddingRight: 8
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                        ><ShareAltOutlined/> Send
                                        </Button>
                                    </span>
                                </Dropdown>}
                    </div>
                )
            }
        }
    ];

    const fetchRecords = async () => {
        const {data: {data: {vouchers: data}}} = await getVouchers("receipt,payment");
        setRecords(data);
        setLoading(false);
    }

    useEffect(() => {
        if (state.company) {
            setLoading(true);
            fetchRecords();
        }
    }, [state, setRecords, onSave]);

    console.log({onSave})
    return (
        <div style={{padding: 24, paddingTop: 8}}>
            <Row justify={"center"} align={"middle"}>
                <Col span={12} style={{fontSize: 24, fontWeight: 'bold'}}>
                   Payments
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    
                            <>
                                <Button type={"primary"} onClick={() => {
                                    dispatch({
                                        popData: {
                                            visible: true,
                                            type: "paymentWrite",
                                            title: "Record Payment Out",
                                            onSave : updateTable,
                                        }
                                    });
                                }} style={{
                                    borderRadius: 6,
                                    fontWeight: 'bold',
                                }}>
                                    <PlusOutlined style={{}}/> New Payment Pay Out
                                </Button>
                                &nbsp;&nbsp;
                            </> 
                                <Button className={"btnCustom2"} onClick={() => {
                                dispatch({
                                    popData: {
                                        visible: true,
                                        type: "receiptWrite",
                                        title: "Record Payment",
                                        onSave : updateTable,
                                    }
                                });
                            }}
                            >
                                <PlusOutlined style={{}}/> New Payment Pay In
                            </Button>
                </Col>
            </Row>
            <Divider style={{margin: 4, marginBottom: 12}}/>
            <div style={{padding: 0}}>
                <Row style={{border: '0px solid red'}}>
                    <Col span={24}>
                        <Segmented
                            // options2={['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly']}
                            onChange={setSelectedSegment}
                            options={[
                                {
                                    label: <div>
                                        All
                                    </div>, value: "all"
                                },
                                {label: "Pay In", value: "payin"},
                                {label: "Pay Out", value: "payout"},
                                // {label: "Cancelled", value: "cancelled"}
                            ]}
                        />
                        &emsp;
                        <Input onChange={(e) => setFilterClient(e.target.value)}
                               placeholder={"Search with Customer Name, Vendor Name, Document No."}
                               style={{width: 'auto', minWidth: 500}}
                               allowClear/>
                        &emsp;
                        <RangePicker style={{}} format={"DD MMM YYYY"}
                                    defaultValue={dateRange}
                                    
                                    ranges={getPresetDateRange()}
                                     onChange={(val) => {     
                                        setFilterDate(val);
                                        val ? saveDateRange(val) : saveDateRange([moment()]);
                                     }
                                     
                                     }/>

                    </Col>
                </Row>
            </div>

            <br/>

                    <Table
                    loading={loading}
                    rowClassName={"tableSalesRow"}
                    onRow={(record) => {
                        return {
                            onClick: event => {
                                dispatch({
                                    popData: {
                                        visible: true,
                                        type: "invoice",
                                        title: `Payment Receipt`,
                                        data: record
                                    }
                                })
                            },
                        };
                    }}
                    columns={columns}
                    dataSource={filteredRecords}
                    locale={{ emptyText:
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={"No Data available between selected Date Range, kindly change the date range"}
                        />
                    }}
                />

        </div>
    );
}

export default PaymentModule;
