import React, {useEffect, useState} from "react";
import _ from "lodash";
import {useNavigate, useParams} from "react-router-dom";
import {Modal} from "antd-mobile";
import {CheckCircleFill, CloseCircleFill} from 'antd-mobile-icons'
import {getPayOrder} from "../../services/api";
import {saveCart} from "../../services/cookies";
import {Spin} from "antd";


const TransactionSummary = () => {

    const params = useParams();
    const navigate = useNavigate();
    const {transactionId} = params;
    const [payOrder, setPayOrder] = useState();
    const isDone = payOrder?.status === "paid";

    useEffect(() => {
        const doIt = async () => {
            const response = await getPayOrder(transactionId, "y");
            const payOrder = response.data.data.payOrder;
            if (payOrder.status === "paid") {
                saveCart([]);
            }
            setPayOrder(payOrder);
        }
        doIt();
    }, [transactionId]);


    return (
        <div style={{background: '#eeeeee', height: '100%', minHeight: '100vh'}}>
            {
                payOrder ?
                <Modal
                    visible={true}
                    header={
                        isDone ?
                            <CheckCircleFill style={{
                                fontSize: 64,
                                color: 'var(--adm-color-success)',
                            }}/> :
                            <CloseCircleFill style={{
                                fontSize: 64,
                                color: 'var(--adm-color-danger)',
                            }}/>

                    }
                    title={isDone ? "Payment Successful" : "Payment Failed"}
                    content={
                        // isDone ?
                        //     <>
                        //     </> :
                        //     `Either payment is not yet tried, or failed.`
                        `Transaction ID: ${transactionId}`
                    }
                    actions={[
                        {key: 'done', text: isDone ? 'Go to Home' : 'Retry', primary: true}
                    ]}
                    onAction={() => {
                        if (isDone) {
                            if (_.get(payOrder, "type") === "pgCollection") {
                                // redirect to public share page:
                                const {ledgerId, voucherId} = payOrder.orderDetails;
                                navigate(`/share/${voucherId ? "voucher" : "ledger"}/${voucherId || ledgerId}`);
                            } else if (_.get(payOrder, "type") === "walletRecharge") {
                                navigate(`/settings/wallet`);
                            } else if (_.get(payOrder, 'orderDetails.type') === "subscription") {
                                navigate(`/selfcare/621dacf70096d6aac889ac25`);
                            } else {
                                navigate(`/settings/subscription`);
                            }

                        } else {
                            navigate(`/pay/${transactionId}/gateway`);
                        }
                    }}
                /> :
                    <div style={{textAlign: 'center', width: '100%', paddingTop: '30vh'}}>
                        <Spin
                            size={"large"}
                        />
                        <br/>
                        <h3>Verifying Payment Status...</h3>
                    </div>
            }
        </div>
    )
};

export default TransactionSummary;
