import React, {useEffect, useState} from "react";
import {Button, Col, Input, message, Row, Table, Tabs} from "antd";
import {EditOutlined, LockOutlined, PlusOutlined} from "@ant-design/icons";
import {customDispatcher} from "../../../utils";
import {useDispatch, useSelector} from "react-redux";
import {deleteDeviceModel, getDeviceModels, getErpUsers, saveErpUser} from "../../../erp/services/api";
import EditButton from "../../../erp/components/buttons/EditButton";
import DeleteButton from "../../../erp/components/buttons/DeleteButton";
import {ROLES_LABELS} from "../../models/constant";
import { ButtonV2 } from "../../../components/ButtonV2";

const ErpUsersPage = () => {
    const state = useSelector(state => state.oldState);
    const dispatch = customDispatcher(useDispatch());
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");

    const fetchRecords = async () => {
        const response = await getErpUsers();
        setRecords(response.data.data.users);
    }

    useEffect(async () => {
        fetchRecords();
    }, []);

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Username",
            dataIndex: "email",
        },
        {
            title: "Permissions",
            render: obj => (obj.permissions || []).map(role => ROLES_LABELS[role]).join(", ")
        },
        {
            title: "Actions",
            render: (record) => <>
                <EditButton disabled={record._id === state?.user?._id} label={"Edit"} onClick={() => {
                    openDrawer(record);
                }}/>
                &nbsp;
                <ButtonV2 disabled={record._id === state?.user?._id} className={"btnCustom3"}
                        style={{
                            padding: 0,
                            height: 28,
                            fontSize: 13,
                            fontWeight: 'bold',
                            paddingLeft: 8,
                            paddingRight: 8,
                            background: '#e8eaf6',
                            color: '#283593'
                        }}
                        onClick={async () => {
                            await saveErpUser({
                                user: {
                                    _id: record._id,
                                    password: "123456",
                                    email: record.email,
                                }
                            });
                            message.success("Password reset successfully, New Password: 123456 (Please change after login)");
                        }}
                ><LockOutlined/>Reset Password
                </ButtonV2>
            </>
        }
    ];

    const openDrawer = (record) => {
        const isNew = !record;
        dispatch({
            popData: {
                visible: true,
                type: "erpUser",
                // width: 600,
                size: "medium",
                title: isNew ? `Add Item` : `Edit Item`,
                onSave: fetchRecords,
                record,
                // segments: ["Manual", "Bulk"]
                // data: obj,
            }
        })
    }

    return (
        <div style={{
            // padding: 24, paddingTop: 8
        }}>
            <Row justify={"center"} align={"middle"}>
                <Col span={12} style={{fontSize: 24, fontWeight: 'bold'}}>
                    Users & Roles
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <>
                        <Button type={"primary"} onClick={() => {
                            openDrawer();
                        }} style={{
                            // background: 'darkslateblue',
                            // color: 'white',
                            borderRadius: 6,
                            fontWeight: 'bold',
                        }}>
                            <PlusOutlined style={{}}/> New User
                        </Button>
                    </>

                </Col>
            </Row>

            {/*<Row>*/}
            {/*    <Input.Search onChange={(e) => setSearchText(e.target.value)}*/}
            {/*                  placeholder={"Search with Name"}*/}
            {/*                  style={{width: 'auto', minWidth: 300,*/}
            {/*                      // height: 40*/}
            {/*                  }}*/}
            {/*                  allowClear/>*/}

            {/*</Row>*/}

            {/*<div style={{padding: 16, paddingLeft: 0, paddingRight: 0}}>*/}
            {/*    <h2 style={{float: 'left'}}>{title}</h2>*/}
            {/*    <Button onClick={() => {*/}
            {/*        setSelectedRecord({*/}
            {/*            type*/}
            {/*        });*/}
            {/*        setShowModal(true);*/}
            {/*    }} style={{float: 'right'}} type={"primary"}>*/}
            {/*        Create New Ledger</Button>*/}
            {/*</div>*/}

            <br/>
            <Table
                loading={loading}
                columns={columns}
                dataSource={records.filter(r => true || (r.name || "").toLowerCase().includes("searchText".toLowerCase()))}
            />

        </div>
    );
};

export default ErpUsersPage;
