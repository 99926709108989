import React from "react";
import {Image} from 'antd'
import {isMobileView} from "../../../utils";

export const StoreImage = ({image, fallback})=>{
    const isMobile = isMobileView();
    return (
        <div>
            <Image style={{height: isMobile ? 70 : 140}} src={image} fallback={fallback}/>
        </div>
    )
}