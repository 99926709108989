import React from "react";
import DeviceWriteDrawer from "../../views/rightDrawer/erp/deviceWrite";
import InventoryTransferDrawer from "../../views/rightDrawer/erp/inventoryTransfer";
import BulkSimsDrawer from "../../views/rightDrawer/erp/bulkSims";
import DeviceModelWriteDrawer from "../../views/rightDrawer/erp/deviceModelWrite";
import PartyWriteDrawer from "../../views/rightDrawer/erp/party";
import InstallationRightDrawer from "../../views/rightDrawer/erp/installation";
import ErpUserDrawer from "../../views/rightDrawer/erp/erpUser";
import ErpPurchaseDrawer from "./purchase";
import ErpSaleDrawer from "./sale";
import ErpInvRecordDetails from "./recordDetails";
import InvHistoryDrawer from "./invHistory";

const ErpDrawer = ({popData, closeDrawer, selectedSegment}) => {

    if (!popData.onSave) {
        popData.onSave = () => {}
    }
    return (
        <>
            {popData.type === "deviceWrite" && <DeviceWriteDrawer selectedSegment={selectedSegment} onSave={popData.onSave} closeDrawer={closeDrawer}/>}
            {popData.type === "inventoryTransfer" && <InventoryTransferDrawer selectedSegment={selectedSegment} onSave={popData.onSave} closeDrawer={closeDrawer}/>}
            {popData.type === "bulkSims" && <BulkSimsDrawer onSave={popData.onSave} closeDrawer={closeDrawer}/>}
            {popData.type === "deviceModelWrite" && <DeviceModelWriteDrawer closeDrawer={closeDrawer} onSave={popData.onSave} record={popData.record}/>}
            {popData.type === "partyWrite" && <PartyWriteDrawer closeDrawer={closeDrawer} onSave={popData.onSave} record={popData.record}/>}
            {popData.type === "installation" && <InstallationRightDrawer closeDrawer={closeDrawer} onSave={popData.onSave} record={popData.record}/>}
            {popData.type === "erpUser" && <ErpUserDrawer closeDrawer={closeDrawer} onSave={popData.onSave} record={popData.record}/>}

            {popData.type === "erpPurchase" && <ErpPurchaseDrawer onSave={popData.onSave} closeDrawer={closeDrawer}/>}
            {popData.type === "erpSale" && <ErpSaleDrawer onSave={popData.onSave} closeDrawer={closeDrawer} data={popData.data}/>}
            {popData.type === "erpInvRecordDetails" && <ErpInvRecordDetails onSave={popData.onSave} closeDrawer={closeDrawer} data={popData.data}/>}
            {popData.type === "erpInvHistory" && <InvHistoryDrawer onSave={popData.onSave} closeDrawer={closeDrawer} data={popData.data}/>}
        </>
    )
};

export default ErpDrawer;
