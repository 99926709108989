import React, {useEffect} from "react";
import _ from "lodash";
import {Button, Form, Input, message, Select} from "antd";
import {saveDeviceModel, saveErpUser, saveParty} from "../../../erp/services/api";
import {requiredRule} from "../../../utils";
import {ROLES_LABELS} from "../../../erp/models/constant";

const ErpUserDrawer = ({closeDrawer, onSave, record = {type: "Client"}}) => {

    const isNewRecord = !record._id;
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(record);
    }, [record]);

    return (
        <div>
            <Form
                form={form}
                layout={"vertical"}
                onFinish={async (vals) => {
                    if (!isNewRecord) {
                        vals._id = record._id;
                    } else {
                        vals.password = "123456";
                        vals.isErpUser = true;
                    }
                    await saveErpUser({user: vals});
                    if (isNewRecord) {
                        message.success(`User created successfully, default Password: 123456 (please change after login)`);
                    } else {
                        message.success(`User modified successfully`);
                    }
                    closeDrawer();
                    onSave();
                }}
            >
                <Form.Item label={"Name"} name={"name"} rules={requiredRule("can't be blank")}>
                    <Input/>
                </Form.Item>
                <Form.Item label={"Username"} name={"email"} rules={[
                    {required: true, message: "can't be blank"},
                    {pattern: /(^[A-Za-z]+$)|(^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$)/, message: "username should be only alphabetical or email only"}
                ]}>
                    <Input/>
                </Form.Item>
                <Form.Item label={"Roles / Permissions"} name={"permissions"} required={requiredRule("Pleas select at least 1 role.")}>
                    <Select
                        mode={"multiple"}
                        showSearch={true}
                        options={
                            _.map(ROLES_LABELS, (label, value) => ({
                                label,
                                value
                            }))
                        }
                        />
                </Form.Item>
                <Button type={"primary"} htmlType={"submit"}>
                    Save User
                </Button>
            </Form>
        </div>
    )
};

export default ErpUserDrawer;
