import {IdcardOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {Menu} from "antd";
import React from "react";

export const erpSettinsMenu = [
    <Menu.Item key={"deviceModels"} icon={<QuestionCircleOutlined/>}>
        <Link to={"/settings/deviceModels"}>HW Models</Link>
    </Menu.Item>,
];
