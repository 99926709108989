import React from "react";
import styled from "styled-components";
import { Table } from "antd";
import { RUPEE } from "../../models/constants";
import { beautifyAmount } from "../../utils";

const PayoutDrawer = ({ payoutData, columns }) => {
  const tableData = payoutData.payOrders;

  return (
    <div>
      <Table
        columns={columns.filter((c) => c.include.includes("transactionDrawer"))}
        dataSource={tableData}
        pagination={false}
        style={{ marginTop: 11 }}
      />
      <OuterContainer>
        <BottomContainer>
          <Heading><div style={{ marginRight: '44px' }}>Total Amount</div>
          <div>:</div>
          </Heading>
          <div>{RUPEE} {beautifyAmount(payoutData.totalAmount)}</div>
        </BottomContainer>
        <BottomContainer>
          <Heading>
            <div style={{ marginRight: '24px' }}>Transaction Fee</div>
          <div>:</div>
          </Heading>
          <div>- {RUPEE} {beautifyAmount(payoutData.transactionFee)}</div>
        </BottomContainer>
        <BottomContainer>
          <Heading>
          <div style={{ marginRight: '26px' }}>Payout Amount</div>
          <div>:</div>
          </Heading>
          <div>{RUPEE} {beautifyAmount(payoutData.paidAmount)}</div>
        </BottomContainer>
      </OuterContainer>
    </div>
  );
};

export default PayoutDrawer;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 25%;
  justify-content: space-between;
  font-size: 18px;
  margin-right: 8%;
  font-weight: bold;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 5%;
`;

const Heading = styled.div`
  display: flex;
`;
