import React, {useState} from "react";
import {
    BrowserRouter,
    Routes,
    Route, Outlet
} from "react-router-dom";
import SplashScreen from "./splash";
import LoginPage from "./login";
import {Provider, useStore} from "react-redux";
import MainPage from "./index";
import Dashboard from "./dashboard";
import Ledgers from "./ledgers";
import Items from "./items";
import CompanySettings from "./settings/CompanySettings";
import Vouchers from "./invoices";
import Settings from "./settings";
import InvoiceSettings from "./settings/InvoiceSettings";
import SubUsers from "./subusers";
import ProfileSettings from "./profile";
import VerifyLink from "./verifylink";
import PaymentModule from "./PaymentModule";
import ImportExport from "./data";
import ImportData from "./data/import";
import ExportData from "./data/export";
import LedgerShare from "./publicShareLink/ledgerShare";
import SubscriptionManagement from "./subscription";
import Help from "./help";
import MobileView from "./mobileView";
import EcommerceBase from "./ecommerce";
import EcommerceHome from "./ecommerce/home";
import EcommerceOrders from './ecommerce/orders';
import EcommerceSettings from './ecommerce/settings';
import SubscriptionProduct from "./sub";
import Renewals from "./sub/renewals";
import Products from "./sub/products";
import Customers from "./sub/customers";
import Subscriptions from "./sub/subscriptions";
import SubHome from "./sub/home";
import SubSettings from "./sub/settings";
import ProductPage from "./sub/productPage";
import CustomerPage from "./sub/customerPage";
import ScSubscriptionPage from "./selfcare/subscription";
import SelfcareHome from "./selfcare";
import PaymentPage from "./payment";
import TransactionPage from "./payment/transactionPage";
import TransactionSummary from "./payment/summary";
import PlanError from "./PlanError";
import PlanCheckout from "./subscription/checkout";
import {isErpWeb, isPuc} from "../utils";
import PucRenewals from "./puc/renewals";
import PucVehicles from "./puc/vehicles";
import PucConfig from "./puc/config";
import PucRecord from "./puc/pucRecord";
import RightDrawer from "./rightDrawer";
import VoucherWrite from "./newVoucher";
import LedgerStatement from "./ledgers/ledgerStatement";
import BankSettings from "./settings/bankSettings";
import StoreHome from "./EcommerceUser/StoreHome";
import ItemPage from "./EcommerceUser/ItemPage";
import CartPage from "./EcommerceUser/CartPage";
import Inventory from "./inventory";
import TrackPage from "./EcommerceUser/TrackOrder";
import ShareVoucher from "./publicShareLink/voucherShare";
import PaymentGateway from "./PaymentGateway";
import WalletSettings from "./settings/WalletSettings";
import {erpRoutes} from "../erp/routes";
import ErpUsersPage from "../erp/views/settings/users";
import InstallationsPage from "../erp/views/installations";
import ChatbotPage from "./portal/chatbot";
import ChatMessagesPage from "./portal/chatbot/chatMessages";
import BankingPage from "./portal/banking";
import LoginPageV2 from "./loginV2";
import TiSelfcareHome from "./ti_selfcare";
import {TiPortal} from "./portal/tiPortal";
import {ErrorsReportingPage} from "./portal/errors";
import {TiKycPage} from "./ti_selfcare/kycPage";
import {PortalKycAdminPage} from "./portal/kyc";
import {PortalMappingPage} from "./portal/PortalMapping";

const EntryComponent = () => {
    const [siderCollapsed, setSiderCollapsed] = useState(false);

    const store = useStore();
    const isPucPlatform = isPuc();
    return (
        <Provider store={store}>
            <BrowserRouter style={{fontFamily: 'sans-serif'}}>
                <Routes>
                    <Route path="/" element={<SplashScreen/>}/>
                    <Route path="/m" element={<MobileView/>}/>
                    <Route path="/login" element={(!isPuc() && !isErpWeb()) ? <LoginPageV2 /> : <LoginPage/>}/>
                    <Route path="/signup" element={<LoginPage />}/>
                    <Route path="/link/:key" element={<VerifyLink/>}/>
                    <Route path={"/pay/:transactionId"} element={<PaymentPage/>}/>
                    <Route path={"/pay/:transactionId/gateway"} element={<TransactionPage/>}/>
                    <Route path={"/pay/:transactionId/summary"} element={<TransactionSummary/>}/>
                    <Route path={"/share/voucher/:voucherId"} element={<ShareVoucher/>}/>
                    <Route path={"/share/ledger/:ledgerId"} element={<LedgerShare/>}/>
                    <Route path={"/store"} element={<Outlet/>}>
                        <Route path={":companyId/cart"} element={<CartPage/>} />
                        <Route path={":companyId/:itemId"} element={<ItemPage/>} />
                        <Route path={":companyId"} element={<StoreHome/>} />
                        <Route path={""} element={"Invalid Store Page"}/>
                        <Route path={"track/:orderId"} element={<TrackPage/>}/>
                    </Route>
                    <Route path={"/old-selfcare"} element={<Outlet/>}>
                        <Route path={"subscription/:subscriptionId"} element={<ScSubscriptionPage/>}/>
                        <Route path={":customerId"} element={<SelfcareHome/>}/>
                        <Route path={""} element={"Invalid Page"}/>
                    </Route>
                    <Route path={"/selfcare"} element={<Outlet/>}>
                        <Route path={"kyc/:customerId/:deviceId"} element={<TiKycPage/>}/>
                        <Route path={":customerId"} element={<TiSelfcareHome/>}/>
                        <Route path={""} element={"Invalid Page"}/>
                    </Route>
                    <Route path={"/selfcare/s"} element={<Outlet/>}>
                        <Route path={"kyc/:customerId/:deviceId"} element={<TiKycPage/>}/>
                        <Route path={":customerId"} element={<TiSelfcareHome/>}/>
                        <Route path={""} element={"Invalid Page"}/>
                    </Route>
                    <Route path="*" element={<MainPage setSiderCollapsed={setSiderCollapsed}/>}>
                        {/*Temporary showing PlanError on not-developed features*/}
                        {/*<Route path={"vouchers/credit"} element={<PlanError/>}/>*/}
                        {/*<Route path={"vouchers/delivery"} element={<PlanError/>}/>*/}
                        {erpRoutes}

                        <Route path={"payments"} element={<PaymentModule/>}/>

                        <Route path={"inventory/:inventoryType"} element={<Inventory />}/>

                        <Route path={"ledgers/statement/:ledgerId"} element={<LedgerStatement/>}/>
                        <Route path={"ledgers/:ledgerType"} element={<Ledgers/>}/>
                        <Route path={"ecommerce"} element={<EcommerceBase/>}/>
                        <Route path={"collect"} element={<PlanError/>}/>

                        <Route path="dashboard" element={isPucPlatform ? <PucRenewals/> : (isErpWeb() ? <InstallationsPage/> : <Dashboard/>)}/>
                        <Route path="puc" element={<PucRecord/>}/>
                        <Route path="puc/:vehicleId" element={<PucRecord/>}/>
                        <Route path={"vehicles"} element={<PucVehicles/>}/>
                        <Route path="vouchers/:voucherType" element={<Outlet/>}>
                            <Route path="" element={<Vouchers/>}/>
                            <Route path="create" element={<VoucherWrite siderCollapsed={siderCollapsed}/>}/>
                            <Route path="edit/:voucherId" element={<VoucherWrite siderCollapsed={siderCollapsed}/>}/>
                        </Route>
                        <Route path="pg/:paymentType" element={<PaymentGateway />}/>
                        {/*<Route path="clients" element={<Ledgers/>}/>*/}
                        {/*<Route path="items" element={<Items/>}/>*/}
                        <Route path="reports" element={<ImportExport/>}>
                            <Route path={"import"} element={<ImportData/>}/>
                            <Route path={"export"} element={<ExportData/>}/>
                        </Route>
                        <Route path={"subscription"} element={<SubscriptionProduct/>}>
                            <Route path={"home"} element={<SubHome/>}/>
                            <Route path={"data"} element={<Subscriptions/>}/>
                            <Route path={"users"} element={<Outlet/>}>
                                <Route path={""} element={<Customers/>}/>
                                <Route path={"view/:customerId"} element={<CustomerPage/>}/>
                            </Route>
                            <Route path={"products"} element={<Outlet/>}>
                                <Route path={""} element={<Products/>}/>
                                <Route path={"view/:productId"} element={<ProductPage/>}/>
                            </Route>
                            <Route path={"sales"} element={<Outlet/>}>
                                <Route path={""} element={<Renewals/>}/>
                                <Route path={"view/:renewalId"} element={"Invoice Page - ToDo (removed old page)"}/>
                            </Route>
                            <Route path={"settings"} element={<SubSettings/>}/>
                        </Route>
                        <Route path={"ecommerce"} element={<EcommerceBase/>}>
                            <Route path={"home"} element={<EcommerceHome/>}/>
                            <Route path={"orders"} element={<EcommerceOrders/>}/>
                            <Route path={"products"} element={<Items/>}/>
                            <Route path={"settings"} element={<EcommerceSettings/>}/>
                        </Route>
                        <Route path={"portal"} element={<TiPortal/>}>
                            <Route path={"banking"} element={<BankingPage/>}/>
                            <Route path={"chatbot/:chatbotAccount"} element={<ChatbotPage/>}>
                                <Route path={"messages"} element={<ChatMessagesPage/>}/>
                                <Route path={"messages/:selectedContact"} element={<ChatMessagesPage/>}/>
                            </Route>
                            <Route path={"errors"} element={<ErrorsReportingPage />}/>
                            <Route path={"kyc"} element={<PortalKycAdminPage/>}/>
                            <Route path={"mapping"} element={<PortalMappingPage/>}/>
                        </Route>
                        <Route path={"collect"} element={"Collect Payments - To be launched soon"}>
                            <Route path={"home"} element={"Home"}/>
                        </Route>
                        <Route path={"help"} element={<Help/>}/>
                        <Route path="settings" element={<Settings/>}>
                            <Route path="profile" element={<ProfileSettings/>}/>
                            <Route path="company" element={<CompanySettings/>}/>
                            <Route path="invoice" element={<InvoiceSettings/>}/>
                            <Route path="bank" element={<BankSettings/>}/>
                            <Route path={"subscription/:selectedPlan"} element={<PlanCheckout/>}/>
                            <Route path="subscription" element={<SubscriptionManagement/>}/>
                            <Route path="users" element={isErpWeb() ? <ErpUsersPage/> : <SubUsers/>}/>
                            <Route path="config" element={<PucConfig/>}/>
                            <Route path="wallet" element={<WalletSettings/>}/>
                        </Route>
                        <Route path="items" element={<Items/>}/>
                        <Route
                            path="*"
                            element={
                                <main style={{padding: "2rem"}}>
                                    <p>Something is wrong (404)</p>
                                    <p>
                                        <a href="/dashboard">Go To Home</a>
                                    </p>
                                </main>
                            }
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
            <RightDrawer/>
        </Provider>
    )
};

export default EntryComponent;
