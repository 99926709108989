import React, {useEffect, useState} from "react";
import {getInternalCustomer, savePayOrder} from "../../services/api";
import {useNavigate, useParams} from "react-router-dom";
import _ from "lodash";
import {Avatar, Button, Col, Divider, Image, Row, Table} from "antd";
import {Popup, Loading} from "antd-mobile";
import {PhoneOutlined, ShoppingCartOutlined, WhatsAppOutlined} from "@ant-design/icons";
import {PRIMARY_COLOR} from "../../models/constants";
import moment from "moment";
import {convertArrayToMap} from "../../utils";
import ModalRenew from "./renewModal";
import {getCart, saveCart} from "../../services/cookies";
import { ButtonV2 } from "../../components/ButtonV2";

const SelfcareHome = () => {

    const params = useParams();
    const navigate = useNavigate();
    const {customerId} = params;
    const [customer, setCustomer] = useState({});
    const [cart, setCart] = useState([]);
    const [showCart, setShowCart] = useState(false);
    const [showRenewModal, setShowRenewModal] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState();
    const [creatingOrder, setCreatingOrder] = useState(false);

    let cartTotal = 0;
    cart.forEach((cartItem) => {
        cartTotal += cartItem.plan.price
    });

    const checkIfExistsInCart = (subscriptionId) => {
        for (const cartItem of cart) {
            if (cartItem.subscription === subscriptionId) {
                return true;
            }
        }
        return false;
    }

    const addItemToCart = (plan) => {
        const clonedCart = _.cloneDeep(cart);
        clonedCart.push({
            subscription: selectedSubscription._id,
            plan
        });
        setCart(clonedCart);
        saveCart(clonedCart);
    }

    const removeItemFromCart = (subscriptionId) => {
        const clonedCart = _.cloneDeep(cart).filter(cartItem => cartItem.subscription !== subscriptionId);
        setCart(clonedCart);
        saveCart(clonedCart);
    }

    // const [products, setProducts] = useState([]);

    // const fetchProducts = async () => {
    //     await getInternalProducts().then(resp => setProducts(resp.data.data.products));
    //     console.log("## Fetched Products ##");
    // }




    useEffect(() => {
        const fetchCustomer = async () => {
            const resp = await getInternalCustomer(customerId);
            setCustomer(resp.data.data.customers[0]);
        }

        fetchCustomer();
        const _cart = getCart();
        console.log("## Fetching Cart ##", _cart);
        setCart(_cart);
    }, [customerId]);

    const {companyDetails: company = {}} = customer;
    const {products = []} = customer;
    const productsMap = convertArrayToMap(products, "_id");

    const getSubscriptionDetails = (subscriptionId, plan) => {
        const {subscriptions = []} = customer;
        const subscriptionsMap = convertArrayToMap(subscriptions, "_id");
        const subscription = subscriptionsMap[subscriptionId];
        const product = productsMap[subscription?.productId];
        const customFields = (product?.customFields || []).slice(0, 2);
        const title = <div>
            {product?.name}
            {
                customFields.map(field =>
                    <>
                        <br/>
                        {field}: {subscription?.customFieldValues?.[field]}
                    </>
                )
            }
        </div>;
        const newExpiry = moment(subscription?.expiryDate).add(plan.duration, plan.durationUnit).format("DD MMM YYYY");
        return {
            title,
            currentExpiry: moment(subscription?.expiryDate).format("DD MMM YYYY"),
            newExpiry,
        }
    }

    const tdStyles = {border: '1px solid black', paddingLeft: 6};
    const cTdStyles = {border: '1px solid black', padding: 6};

    const renewalColumns = [
        {
            title: "S.No.",
            render: (curr, obj, index) => (index + 1).toString()
        },
        {
            title: "Plan Name",
            dataIndex: "name",
        },
        {
            title: "Duration",
            dataIndex: "duration",
        },
    ];

    return (
        <div style={{background: '#eeeeee', height: '100%', minHeight: '100vh'}}>
            {/*<div>*/}
            {/*    {JSON.stringify(customer)}*/}
            {/*</div>*/}
            <div style={{padding: 12}}>
                <Row align={"middle"} justify={"center"} style={{border: '0px solid black'}}>
                    <Col span={24} style={{maxWidth: '18cm', border: '0px solid red'}}>
                        <Row>
                            <Col span={16} style={{paddingRight: 8}}>
                                <h2 style={{fontWeight: 'bold'}}>
                                    {company.name}
                                </h2>
                                &nbsp;&nbsp;
                                <Avatar size={40} style={{backgroundColor: PRIMARY_COLOR}} icon={<PhoneOutlined/>}/>
                                &nbsp;&nbsp;
                                <Avatar size={40} style={{backgroundColor: '#87d068'}} icon={<WhatsAppOutlined/>}/>
                            </Col>
                            <Col span={8}>
                                <Image preview={false}
                                       src={`/api/internal/file/${company.imgLogo}`}
                                       style={{maxWidth: '100%', width: 168, padding: 8}}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24} style={{maxWidth: '26cm', border: '0px solid red'}}>
                        <Divider/>
                        <table style={{
                            width: '100%',
                            // maxWidth: '18cm',
                            borderCollapse: 'collapse',
                            border: '1px solid black',
                            fontSize: 16
                        }}>
                            <tr>
                                <td style={{...tdStyles, textAlign: 'center', fontWeight: 'bold'}} colSpan={2}>
                                    Account Details
                                </td>
                            </tr>
                            <tr>
                                <td style={tdStyles}>Name</td>
                                <td style={tdStyles}>{customer.name || "---"}</td>
                            </tr>
                            <tr>
                                <td style={tdStyles}>Contact</td>
                                <td style={tdStyles}>{customer.phone || "---"}</td>
                            </tr>
                            <tr>
                                <td style={tdStyles}>Email</td>
                                <td style={tdStyles}>{customer.email || "---"}</td>
                            </tr>
                        </table>
                    </Col>
                    <Col span={24}>
                        <Divider/>
                        <h2>Subscriptions</h2>
                        <Row>
                            {
                                (customer.subscriptions || []).map(subscription => {
                                        let type;
                                        const diff = moment().diff(moment(subscription.expiryDate));
                                        if (diff >= 0) {
                                            type = "expired";
                                        } else if (diff > 100) {
                                            type = "expiringSoon";
                                        } else {
                                            type = "active"
                                        }

                                        const buttons = {
                                            expired: {
                                                color: "red",
                                                label: "Expired"
                                            },
                                            expiringSoon: {
                                                color: "orange",
                                                label: "Expiring Soon"
                                            },
                                            active: {
                                                color: "green",
                                                label: "Active"
                                            }
                                        };
                                        const button = buttons[type || "expired"];
                                        return <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                                            <div style={{
                                                border: '2px solid lightgrey',
                                                background: 'white',
                                                boxSizing: 'border-box',
                                                padding: 8
                                            }}>
                                                <Row>
                                                    <div style={{width: '100%'}}>
                                                        <div style={{float: 'left', fontWeight: 'bold', fontSize: 18}}>
                                                            {productsMap[subscription.productId]?.name || "---"}
                                                        </div>
                                                        <div style={{float: 'right', marginRight: 16}}>
                                                        <span style={{
                                                            background: button.color, color: 'white',
                                                            fontWeight: 'bold', paddingLeft: 8, paddingRight: 8
                                                        }}>
                                                            {button.label}
                                                        </span>
                                                        </div>

                                                    </div>
                                                </Row>
                                                <div>
                                                    <table>
                                                        {
                                                            (productsMap[subscription.productId]?.customFields || []).map(cField => (
                                                                <tr>
                                                                    <td>{cField}</td>
                                                                    <td style={{paddingLeft: 16}}>{subscription.customFieldValues?.[cField] || "---"}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                        <tr>
                                                            <td>Activation Date:</td>
                                                            <td style={{paddingLeft: 16}}>
                                                                {moment(subscription.activationDate).format("DD MMM YYYY")}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Expiry Date:</td>
                                                            <td style={{paddingLeft: 16}}>
                                                                {moment(subscription.expiryDate).format("DD MMM YYYY")}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div style={{padding: 0}}>
                                                    {
                                                        checkIfExistsInCart(subscription._id) ?
                                                            <Button type={"danger"}
                                                                    style={{
                                                                        width: '100%',
                                                                        borderRadius: 0,
                                                                        fontWeight: 'bold'
                                                                    }}
                                                                    onClick={() => {
                                                                        removeItemFromCart(subscription._id);
                                                                    }}
                                                            >
                                                                Remove from Cart
                                                            </Button> :
                                                            <Button type={"primary"}
                                                                    style={{
                                                                        width: '100%',
                                                                        borderRadius: 0,
                                                                        fontWeight: 'bold'
                                                                    }}
                                                                    onClick={() => {
                                                                        setSelectedSubscription(subscription);
                                                                        setShowRenewModal(true);
                                                                    }}
                                                            >
                                                                Renew Subscription
                                                            </Button>
                                                    }

                                                </div>
                                            </div>
                                            {/*<Card*/}
                                            {/*    title={"DL5CQ6692"}*/}
                                            {/*    actions={[*/}
                                            {/*        <Button type={"primary"}>Renew</Button>*/}
                                            {/*    ]}*/}
                                            {/*/>*/}
                                        </Col>
                                    }
                                )
                            }
                        </Row>
                        <Divider/>
                        <h2>Renewals</h2>
                        <Table
                            dataSource={customer?.renewals || []}
                            columns={renewalColumns}
                        />
                    </Col>
                </Row>
            </div>
            {
                !!cart.length &&
                <div style={{position: 'fixed', bottom: 12, width: '100%', textAlign: 'center'}}>
                    <center>
                        <div onClick={() => setShowCart(true)} style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            maxWidth: '10cm',
                            width: '100%',
                            background: PRIMARY_COLOR,
                            color: 'white',
                            padding: 8,
                            borderRadius: 200
                        }}>
                            {/*<Badge size="default" count={5}>*/}
                            {/*    <Avatar icon={<ShoppingCartOutlined/>} size="large" style={{backgroundColor: PRIMARY_COLOR}} />*/}
                            {/*</Badge>*/}
                            <Row>
                                <Col span={12} style={{borderRight: '1px solid white'}}>
                                    {cart.length} items &nbsp;&nbsp;&#9679;&nbsp;&nbsp; ₹ {cartTotal}
                                </Col>
                                <Col span={12}>
                                    <ShoppingCartOutlined/>&nbsp;&nbsp;
                                    View Cart
                                </Col>
                            </Row>
                            {/*<ShoppingCartOutlined/> &nbsp;&nbsp;&nbsp;*/}
                            {/*View Cart (₹ 700)*/}
                        </div>
                    </center>
                </div>
            }
            <ModalRenew
                visible={showRenewModal}
                productsMap={productsMap}
                subscription={selectedSubscription}
                setShowRenewModal={setShowRenewModal}
                addItemToCart={addItemToCart}
            />

            <Popup
                visible={showCart}
                onMaskClick={() => setShowCart(false)}
            >
                <h1 style={{padding: 16, fontWeight: 'bold'}}>Cart</h1>
                <div style={{textAlign: 'center', width: '100%', border: '0px solid blue'}}>
                    <table style={{width: '90%', marginLeft: '5%', marginRight: '5%'}}>
                        {
                            <>
                                <tr>
                                    <th style={cTdStyles}>S.No.</th>
                                    <th style={cTdStyles}>Subscription</th>
                                    <th style={cTdStyles}>Current Expiry</th>
                                    <th style={cTdStyles}>Renewal Plan</th>
                                    <th style={cTdStyles}>New Expiry</th>
                                    <th style={cTdStyles}>Amount</th>
                                </tr>
                                {
                                    cart.map((item, index) => {
                                        const details = getSubscriptionDetails(item.subscription, item.plan);
                                        return <tr>
                                            <td style={cTdStyles}>{index + 1}</td>
                                            <td style={cTdStyles}>{details.title}</td>
                                            <td style={cTdStyles}>{details.currentExpiry}</td>
                                            <td style={cTdStyles}>{item.plan.name}</td>
                                            <td style={cTdStyles}>{details.newExpiry}</td>
                                            <td style={cTdStyles}>{item.plan.price}</td>
                                        </tr>
                                    })
                                }
                                <tr>
                                    <td style={{
                                        ...cTdStyles,
                                        textAlign: 'right',
                                        color: 'green',
                                        fontWeight: 'bold',
                                        border: 'none',
                                        fontSize: 16
                                    }} colSpan={6}>
                                        Total Amount: ₹{cartTotal}
                                    </td>
                                </tr>
                            </>
                        }
                    </table>
                    {/*<div style={{width: '100%', textAlign: 'right', color: 'green', fontWeight: 'bold', fontSize: 18}}>*/}
                    {/*    Total Amount: ₹{cartTotal}*/}
                    {/*</div>*/}
                    <ButtonV2 disabled={creatingOrder} type={"primary"}
                            style={{marginBottom: 32, fontWeight: 'bold', fontSize: 16}} onClick={async () => {
                        try {
                            setCreatingOrder(true);
                            const response = await savePayOrder({
                                payOrder: {
                                    type: "subscription",
                                    company: customer?.company,
                                    orderDetails: {
                                        type: "subscription",
                                        cart,
                                        customerId: customer._id,
                                    }
                                }
                            });

                            setCreatingOrder(false);
                            const orderId = response.data.data.payOrder.orderId;
                            console.log("## OrderID ##", orderId);
                            navigate(`/pay/${orderId}/gateway`);
                        } catch (err) {
                            console.log("## Failed to create Order ##", err);
                        } finally {
                            setCreatingOrder(false);
                        }
                    }}>
                        {
                            creatingOrder ? <Loading/> : "Proceed to Pay"
                        }
                    </ButtonV2>
                </div>
            </Popup>

        </div>
    )
};

export default SelfcareHome;
