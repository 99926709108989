import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Table,
  Button,
  Row,
  Col,
  Tabs,
  Input,
  DatePicker,
  Form,
  Select,
  message,
} from "antd";
import { getTransactions, getPayouts } from "../../services/api";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";

import { SwitchSelect } from "../../components/switch";
import cashfree from "../../res/img/cashFree.png";
import { RUPEE } from "../../models/constants";
import { beautifyAmount, customDispatcher } from "../../utils";
import { getLedgers, saveCompany, loginRequest } from "../../services/api";

const { TabPane } = Tabs;

const { RangePicker } = DatePicker;

const PaymentGateway = () => {
  const [form] = Form.useForm();
  const state = useSelector((state) => state.oldState);
  const dispatch = customDispatcher(useDispatch());
  const navigate = useNavigate();
  const params = useParams();
  const { paymentType } = params;
  const [loading, setLoading] = useState(false);
  const [transactionRecords, setTransactionRecords] = useState([]);
  const [payoutRecords, setPayoutRecords] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filterDate, setFilterDate] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [saveUpdateLoading, setSaveUpdateLoading] = useState(false);

  const fetchRecords = async () => {
    const _bankMap = [];
    setLoading(true);
    const response = await getTransactions();
    const payoutResponse = await getPayouts();
    const banks = (await getLedgers("cash")).data.data.ledgers;
    const resTransaction = _.get(response, "data.data.transactions", []);
    const resPayout = _.get(payoutResponse, "data.data.payouts", []);
    banks.forEach((ledger) => {
      if (!_.isEmpty(ledger.bankDetails)) {
        _bankMap.push({
          label: ledger.name,
          value: ledger._id,
        });
      }
    });
    if (_.get(state, "company.pgConfig", false)) {
      const defaultCheck = state.company.pgConfig.enabled;
      form.setFieldsValue({
        enabled: defaultCheck,
      });
    }
    if (_.get(state, "company.pgConfig.bankForSettlement", false)) {
      const defaultBank = state.company.pgConfig?.bankForSettlement;
      form.setFieldsValue({
        bankForSettlement: defaultBank,
      });
    } else {
      form.setFieldsValue({
        bankForSettlement: _bankMap[0].value,
      });
    }
    setTransactionRecords(resTransaction);
    setPayoutRecords(resPayout);
    setBankAccounts(_bankMap);
    setLoading(false);
  };

  useEffect(() => {
    fetchRecords();
  }, []);

  useEffect(() => {
    setFilterDate([]);
    setSearchText("");
  }, [paymentType]);

  const applyFilters = (data) => {
    const [start, end] = filterDate || [];
    let filterByDate = true;
    let filterByText = true;
    filterByText = data.customer.name
      ?.toLowerCase()
      .includes(searchText.toLowerCase());
    if (start && end) {
      filterByDate = moment(data.date).isBetween(
        start.startOf("day"),
        end.endOf("day")
      );
    }
    return filterByDate && filterByText;
  };

  const transactionFilteredRecords = transactionRecords.filter(applyFilters);

  const columns = [
    {
      title: "Date",
      include: ["payouts", "transactions", "transactionDrawer"],
      render: (obj) =>
        moment(obj.creationDate || obj.date).format("DD MMM YYYY"),
      sorter: (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf(),
      defaultSortOrder: "descend",
    },
    {
      title: "Amount",
      weight: 1,
      include: ["transactions", "transactionDrawer"],
      render: (obj) => (
        <b>
          <div style={{ color: "green" }}>
            {RUPEE} {beautifyAmount(obj.amount)}
          </div>
        </b>
      ),
    },
    {
      title: "Customer",
      weight: 1,
      include: ["transactions", "transactionDrawer"],
      render: (obj) => (
        <>
          <b>{obj.customer.name}</b>
          <br />
          <div style={{ fontSize: 11, color: "grey" }}>
            {obj.customer.phone}
          </div>
        </>
      ),
    },
    {
      title: "Bill #",
      weight: 1,
      include: ["transactions", "transactionDrawer"],
      render: (obj) => <b>{obj.invoiceNumber || "--"}</b>,
    },
    {
      title: "Payout Status",
      weight: 1,
      include: ["transactions"],
      render: (obj) => (
        <b>
          <div
            style={{
              background: obj.displayPayoutId ? "#a2f998" : "#f5c4c8",
              color: obj.displayPayoutId ? "green" : "red",
              padding: "5px",
              borderRadius: "8px",
              width: obj.displayPayoutId ? "160px" : "70px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {obj.displayPayoutId
              ? `Paid Out (#${obj.displayPayoutId})`
              : "Pending"}
          </div>
        </b>
      ),
    },
    {
      title: "Remarks",
      weight: 1,
      include: ["transactions", "transactionDrawer"],
      render: (obj) => obj.orderDetails.remarks,
    },
    {
      title: "Payout Id",
      weight: 1,
      include: ["payouts"],
      render: (obj) => obj.displayPayoutId,
    },
    {
      title: "Total Amount",
      weight: 1,
      include: ["payouts"],
      render: (obj) => (
        <b>
          <div style={{ color: "green" }}>
            {RUPEE} {beautifyAmount(obj.totalAmount)}
          </div>
        </b>
      ),
    },
    {
      title: "Transaction Fee",
      weight: 1,
      include: ["payouts"],
      render: (obj) => (
        <b>
          <div style={{ color: "red" }}>
            {RUPEE} {beautifyAmount(obj.transactionFee)}
          </div>
        </b>
      ),
    },
    {
      title: "Payout Amount",
      weight: 1,
      include: ["payouts"],
      render: (obj) => (
        <b>
          <div style={{ color: "green" }}>
            {RUPEE} {beautifyAmount(obj.paidAmount)}
          </div>
        </b>
      ),
    },
    {
      title: "Actions",
      weight: 1,
      include: ["payouts"],
      render: (obj) => {
        return (
          <div style={{ fontSize: 20 }}>
            <Button
              className={"btnCustom3"}
              style={{
                padding: 0,
                height: 28,
                fontSize: 13,
                fontWeight: "bold",
                paddingLeft: 8,
                paddingRight: 8,
                background: "#e8eaf6",
                color: "#283593",
              }}
              onClick={(e) => {
                e.stopPropagation();

                dispatch({
                  popData: {
                    visible: true,
                    placement: "bottom",
                    type: "payout",
                    title: "Payout #" + obj.displayPayoutId + " Details",
                    data: obj,
                    columns: columns,
                  },
                });
              }}
            >
              <EyeOutlined /> View Details
            </Button>
          </div>
        );
      },
    },
  ];

  const settings = () => (
    <>
      <div>
        <Heading>Accept Online Payments in seconds!</Heading>
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 6,
            style: {
              textAlign: "left",
            },
          }}
          wrapperCol={{ span: 16 }}
          onFinish={async (data) => {
            setSaveUpdateLoading(true);
            const companyData = { ...state.company, pgConfig: data };
            if (Object.keys(data).length > 1) {
              await saveCompany(companyData);
            } else {
              message.error("Please choose from both feilds");
            }
            await loginRequest("", "", dispatch);
            setSaveUpdateLoading(false);
          }}
          onFinishFailed={(err) => {
            console.log("## Finish Failed ##", err);
          }}
          autoComplete="off"
        >
          <Row>
            <Col span={12}>
              <Form.Item
                name={"enabled"}
                style={{ marginTop: 16 }}
                label={"Payment Gateway:"}
                labelCol={{
                  span: 12,
                  style: {
                    textAlign: "left",
                  },
                }}
              >
                <SwitchSelect
                  checkedChildren={"On"}
                  unCheckedChildren={"Off"}
                  valuesMap={{
                    true: true,
                    false: false,
                  }}
                  reverseMap={{
                    true: true,
                    fakse: false,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                className={"zeroMargin"}
                name={"bankForSettlement"}
                label={"Select Bank Account"}
                labelCol={{
                  span: 12,
                  style: {
                    textAlign: "left",
                  },
                }}
              >
                <Select options={bankAccounts} />
              </Form.Item>
            </Col>
          </Row>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: 16, marginBottom: 16 }}
            loading={saveUpdateLoading}
          >
            Save {"&"} Update
          </Button>
          {/*</Form.Item>*/}
        </Form>
        <b>Payment Gateway Charges: 2% + GST</b>
      </div>
      &nbsp;&nbsp;
      <div>
        <SubHeading>Settlement Time</SubHeading>
        <>Payments will be settled to your bank account in 24-48 Hrs.</>
      </div>
      &nbsp;&nbsp;
      <div>
        <SubHeading>KYC required for activation</SubHeading>
        <ul>
          <li>
            <b>Valid incorporation</b> details. Please check this link.
          </li>
          <li>
            <b>PAN card</b> of the company/business.
          </li>
          <li>
            <b>Cancelled cheque / Bank statement</b> of the company current
            account. <b>Current Account</b> is mandatory for settlements.
            Personal accounts will not be accepted.
          </li>
          <li>
            Please email all your documents to <b>team@khataBuddy.com</b>. KYC
            Verification might take 24-48 hours.
          </li>
          <li>
            Please provide your Individual <b>Aadhaar</b> and <b>PAN card</b> if
            your business type is not "Private Limited Company".
          </li>
          <li>
            International payments is <b>NOT</b> supported.
          </li>
        </ul>
      </div>
      &nbsp;&nbsp;
      <PoweredBy>Payments Powered by</PoweredBy>
      <img
        alt={"CashFree"}
        src={cashfree}
        width={140}
        style={{ marginTop: "10px" }}
      />
    </>
  );

  return (
    <div style={{ padding: 24, paddingTop: 8 }}>
      <Row>
        <Col span={12} style={{ fontSize: 24, fontWeight: "bold" }}>
          Payments
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <Tabs
            className={"tabsLedgers"}
            activeKey={paymentType}
            onChange={(newpaymentType) => navigate(`/pg/${newpaymentType}`)}
          >
            <TabPane
              className={"tabsLedgers"}
              tab={<div style={{ fontSize: 15 }}>Transactions</div>}
              key="transactions"
            >
              {/*Content of Tab Pane 1*/}
            </TabPane>
            <TabPane
              tab={<div style={{ fontSize: 15 }}>Payouts</div>}
              key="payouts"
            >
              {/*This is totally new content for 3rd Tab*/}
            </TabPane>
            <TabPane
              tab={<div style={{ fontSize: 15 }}>Settings</div>}
              key="settings"
            ></TabPane>
          </Tabs>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}></Col>
      </Row>
      <Row>
        {paymentType === "transactions" && (
          <>
            <Input.Search
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={"Search with Customer name"}
              style={{
                width: "auto",
                minWidth: 300,
                // height: 40
              }}
              allowClear
            />
            &nbsp;
            <>
              <RangePicker
                style={{}}
                format={"DD MMM YYYY"}
                onChange={(val) => setFilterDate(val)}
              />
            </>
          </>
        )}
      </Row>
      &nbsp;&nbsp;
      {paymentType !== "settings" ? (
        <Table
          loading={loading}
          columns={columns.filter((c) => c.include.includes(paymentType))}
          dataSource={
            paymentType === "transactions"
              ? transactionFilteredRecords
              : payoutRecords
          }
        />
      ) : (
        settings()
      )}
    </div>
  );
};

export default PaymentGateway;

const Heading = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

const SubHeading = styled.div`
  font-size: 18px;
  height: 36px;
  border-bottom: 2px solid lightgrey;
  font-weight: bold;
  margin-bottom: 10px;
`;

const PoweredBy = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: grey;
`;
