import React from "react";
import {Route} from "react-router-dom";
import InstallationsPage from "../views/installations";
import DevicesPage from "../views/devices";
import PartiesPage from "../views/parties";
import SimsPage from "../views/sims";
import MappingPage from "../views/mappings";
import ErpLogsPage from "../views/logs";
import ErpInventory from "../views/inventory";
import CommonDataPage from "../components/outlet/CommonDataPage";
import ErpInvSummaryPage from "../views/inventory/summary";
import ErpInvAdvanced from "../views/inventory/advanced";
import ErpInvItemsPage from "../../views/settings/erp/DeviceModelsPage";

export const erpRoutes = [
    <Route path={"installations"} element={<InstallationsPage/>}/>,
    <Route path={"devices"} element={<DevicesPage/>}/>,
    <Route path={"parties"} element={<PartiesPage/>}/>,
    <Route path={"sims"} element={<SimsPage/>}/>,
    <Route path={"mappings"} element={<MappingPage/>}/>,
    <Route path={"logs"} element={<ErpLogsPage/>}/>,
    <Route path={"erp_inv"} element={<ErpInventory/>}>
        <Route path={":recordType/:subTab"} element={<CommonDataPage/>}/>
        <Route path={"advanced"} element={<ErpInvAdvanced/>}/>
        <Route path={"summary"} element={<ErpInvSummaryPage/>}/>
        <Route path={"reports"} element={<div>Reports</div>}/>
        <Route path={"items"} element={<ErpInvItemsPage/>}/>
    </Route>,
];
