import React from "react";
import _ from "lodash";
import {beautifyAmount} from "../../utils";
import {Button, Form, InputNumber, Select, Table} from "antd";
import {DeleteFilled, PlusOutlined} from "@ant-design/icons";
import {RUPEE} from "../../models/constants";
import { ButtonV2 } from "../../components/ButtonV2";

const {Option} = Select;

const ReceiptDistribution = ({value = [], onChange, invoices = [], selectedLedgerId}) => {

    const [form] = Form.useForm();
    const valuesMap = _.keyBy(value, 'voucherId');
    const invoicesMap = _.keyBy(invoices, "_id");

    let total = 0;
    value.forEach(row => {
        total += row.amount;
    });

    return (
        <div>
            <Table
                pagination={false}
                style={{border: '1px solid black', borderBottom: 0}}
                // emptyText={"No invoice adjusted for this receipt yet"}
                locale={{
                    emptyText: <span>No invoice adjusted for this receipt yet</span>
                }}
                columns={[
                    {
                        title: "Invoice no. / Advance", render: ({voucherId}) => {
                            if (voucherId === "Advance") {
                                return "Advance";
                            }
                            const inv = invoicesMap[voucherId];
                            const invoiceNum = `${inv?.documentNumber?.prefix}${inv?.documentNumber?.num}`;
                            return <>
                                {invoiceNum}
                                <div style={{fontSize: 10, fontStyle: 'italic', color: 'red'}}>
                                    Balance: ₹ {(inv?.amount || 0) - (inv?.paid || 0)}
                                </div>
                            </>;
                        }
                    },
                    {title: "Amount", render: (obj) => `${RUPEE} ${beautifyAmount(obj.amount || 0)}`},
                    {
                        title: " ", render: (obj, curr, index) =>
                            <DeleteFilled onClick={() => {
                                const clonedValue = _.cloneDeep(value);
                                clonedValue.splice(index, 1);
                                onChange(clonedValue);
                            }}/>
                    }
                ]}
                dataSource={value}
            />

            <Form
                style={{border: '1px solid black', borderTop: 0, padding: 8}}
                layout={"inline"}
                form={form}
            >
                <Form.Item name={"voucherId"} label={""} rules={[{required: true}]} style={{width: '50%'}}>
                    <Select
                        style={{width: '100%'}}
                        placeholder={"Select Invoice"}
                    >
                        {
                            [
                                {label: "Advance", value: "Advance"},
                                ...invoices.filter(inv => inv.ledgerDr === selectedLedgerId).map(inv => ({
                                    label: `${inv.documentNumber.prefix}${inv.documentNumber.num}`,
                                    value: inv._id,
                                    balance: inv.amount - (inv.paid || 0)
                                }))
                            ].filter(opt => !valuesMap[opt.value]).map(opt => (
                                <Option value={opt.value}>
                                    <div>
                                        <b>{opt.label}</b>
                                    </div>
                                    {
                                        opt.label !== "Advance" &&
                                        <div style={{fontSize: 10, fontStyle: 'italic', color: 'red'}}>
                                            Balance: ₹ {opt.balance || 0}
                                        </div>
                                    }
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item name={"amount"} label={""} rules={[{required: true}]} style={{width: '30%'}}>
                    <InputNumber style={{width: '100%'}} prefix={"₹"} placeholder={"Amount"} min={1}/>
                </Form.Item>
                <ButtonV2 type={"primary"} onClick={async () => {
                    const rowData = await form.validateFields();
                    console.log("## Row Data ##", rowData);
                    const clonedValue = _.cloneDeep(value);
                    clonedValue.push(rowData);
                    onChange(clonedValue);
                    form.resetFields();
                }}>
                    <PlusOutlined/>
                </ButtonV2>
            </Form>

            <div style={{fontSize: 16, marginTop: 8}}>
                Total Amount Received: <b>₹ {beautifyAmount(total)}</b>
            </div>
        </div>
    )
};

export default ReceiptDistribution;
