import React, {useEffect, useState} from "react";
import moment from "moment";
import {Button, Input, message, Popconfirm, Table, Tag, Tooltip} from "antd";
import {deletePucSale, getPucSales, updatePucRenewal} from "../../services/api";
import {useSelector} from "react-redux";
import {pucRenewalPlansMap} from "../../utils";
import {CheckOutlined, DeleteOutlined} from "@ant-design/icons";
import { ButtonV2 } from "../../components/ButtonV2";

const PucRenewals = () => {
    const state = useSelector(state => state.oldState);
    const [sales, setSales] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const actualTableData = sales.filter(record => {
        if (!searchKey) {
            return true;
        }
        const checkStr = (a) => a.toLowerCase().includes(searchKey.toLowerCase());
        return checkStr(record.vehicle.vehicleNumber) || checkStr(record.vehicle.customerName) || checkStr(record.vehicle.customerContact);
    });
    useEffect(() => {
        if (state?.company) {
            // console.log("## Using Effect PucRenewals ##", (state?.company));
            getPucSales().then(resp => setSales(resp.data.data.sales));
        }
    }, [state]);

    const cols = [
        {
            title: "Issue Date",
            render: ({renewalDate}) => moment(renewalDate).format("DD MMM YYYY"),
            sorter: (a, b) => moment(a.renewalDate).diff(moment(b.renewalDate))
        },
        {
            title: "Expiry",
            render: ({expiryDate}) => moment(expiryDate).format("DD MMM YYYY"),
            sorter: (a, b) => moment(a.expiryDate).diff(moment(b.expiryDate))
        },
        {
            title: "Vehicle",
            render: ({vehicle}) => <>
                <span style={{
                    width: '100%',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    padding: 0,
                    margin: 0
                }}>{vehicle.vehicleNumber}</span>
                {/*<br/>*/}
                {/*<Tag color="magenta">{vehicle.engineType}</Tag>*/}
                {/*<Tag color="red">{vehicle.registrationType}</Tag>*/}
            </>,
            sorter: (a, b) => a.vehicle.vehicleNumber.localeCompare(b.vehicle.vehicleNumber)
        },
        {
            title: "Type",
            render: ({vehicle}) => <>
                {/*<span style={{width: '100%', textAlign: 'center', fontWeight: 'bold', padding: 0, margin: 0}}>{vehicle.vehicleNumber}</span>*/}
                {/*<br/>*/}
                <Tag color="gold">{vehicle.engineType}</Tag>
                <Tag color="cyan">{vehicle.registrationType}</Tag>
            </>
        },
        {
            title: "Customer",
            render: ({vehicle}) => <>
                <b>{vehicle.customerName}</b>
                <br/>
                {vehicle.customerContact}
            </>,
            sorter: (a, b) => a.vehicle.customerName.localeCompare(b.vehicle.customerName),
        },
        {
            title: "Plan",
            render: ({plan}) => pucRenewalPlansMap[plan]?.label,
            sorter: (a, b) => a.plan.localeCompare(b.plan)
        },
        {
            title: "Payment Status",
            render: ({isPaid}) => <Tag color={isPaid ? "green" : "red"}>{isPaid ? "Paid" : "Pending"}</Tag>,
            sorter: (a, b) => a.isPaid - b.isPaid
        },
        {
            title: "Actions",
            render: (record) =>
                <>
                <Popconfirm
                    title="Are you sure to delete this record ?"
                    onConfirm={async () => {
                        deletePucSale(record._id)
                            .then(() => {
                                message.success('Record Deleted');
                                getPucSales().then(resp => setSales(resp.data.data.sales));
                            })
                            .catch(() => message.error('Failed to delete record'))
                    }}
                    onCancel={() => {
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type={"danger"}><DeleteOutlined/></Button>
                </Popconfirm>&emsp;
                    {
                        !record.isPaid &&
                        <Tooltip title="Mark as Paid">
                        <ButtonV2 type={"primary"} onClick={async () => {
                            await updatePucRenewal({
                                _id: record._id,
                                isPaid: true
                            });
                            message.success("Renewal marked as Paid");
                            getPucSales().then(resp => setSales(resp.data.data.sales));
                        }}>
                            <CheckOutlined/>
                        </ButtonV2>
                        </Tooltip>
                    }
                </>
        }
    ];

    return (
        <div style={{padding: 24}}>
            <h2>PUC Renewals</h2>
            <Input.Search onSearch={(val) => setSearchKey(val)} placeholder={"Search"} />
            <br/>
            <br/>
            <Table
                columns={cols}
                dataSource={actualTableData}
            />
        </div>
    )
};

export default PucRenewals;
