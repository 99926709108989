import React from 'react';
import {Modal, Form, Input, message} from 'antd';
import {useSelector} from "react-redux";
import {saveUser} from "../services/api";

const ModalChangePassword = ({visible, onCancel}) => {
    const state = useSelector(state => state.oldState);
    const [form] = Form.useForm();

    return (
        <Modal
            visible={visible}
            title={"Change Password"}
            okText={"Save"}
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then(async (values) => {
                        console.log("## Vals ##", values);
                        if (values.newPass !== values.confirmPass) {
                            message.error("Passwords don't match. Please ensure same password is typed in 'Password' and 'Confirm Password' fields");
                            return;
                        }
                        if (values.currentPass !== state?.user.password) {
                            message.error("Incorrect current password !");
                            return;
                        }
                        form.resetFields();
                        await saveUser({
                            user: {
                                _id: state?.user?._id,
                                password: values.newPass
                            }
                        });
                        message.success("Password Changed Successfully");
                        onCancel();
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
            >
                <Form.Item
                    name="currentPass"
                    label="Current Password"
                    rules={[
                        {
                            required: true,
                            message: "can't be blank"
                        },
                    ]}
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item
                    name="newPass"
                    label="New Password"
                    rules={[
                        {
                            required: true,
                            message: "can't be blank"
                        },
                    ]}
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item
                    name="confirmPass"
                    label="Confirm New Password"
                    rules={[
                        {
                            required: true,
                            message: "can't be blank"
                        },
                    ]}
                >
                    <Input.Password/>
                </Form.Item>
            </Form>
        </Modal>
    );
};


export default ModalChangePassword;
