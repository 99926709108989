import React, {useEffect, useState} from 'react';
import {
    Button,
    message,
    Row,
    Col,
    Card,
    Statistic,
    Form, Input, Modal, Result
} from 'antd';
import {getSummary, requestDemo} from '../../services/api';
import _ from "lodash";
import {CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, BarChart} from "recharts";
import {beautifyAmount} from "../../utils";
import {RUPEE} from "../../models/constants";
import {useNavigate} from "react-router-dom";
import { ButtonV2 } from '../../components/ButtonV2';


const Dashboard = () => {
    const [summary, setSummary] = useState({});
    const [showNudge, setShowNudge] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const fetchRecords = async () => {
        const {data: {data: _summary}} = await getSummary();
        setSummary(_summary);
    }

    useEffect(() => {
        fetchRecords();
    }, []);

    useEffect(() => {
        if (window.showNewAccountNudge) {
            window.showNewAccountNudge = false;
            console.log("## nudge ##");
            setShowNudge(true);
        }
    }, [window.showNewAccountNudge]);

    const {sales = [], monthlySales} = summary;
    sales.forEach(sale => {
        sale["Sale"] = sale.amount;
        sale["Payment Received"] = sale.receipt;
    })
    const monthlyData = _.map(monthlySales, ({sales, receipt}, monthString) => ({
        name: monthString,
        "Sale": sales,
        "Payment Received": receipt
    }));


    return (
        <div>
            <Row>
                <Col span={12}>
                    <h2 style={{marginTop: 32, marginLeft: 32}}>Monthly Sales & Payments Received</h2>
                    <BarChart
                        width={500}
                        height={300}
                        data={monthlyData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Sale" fill="#4472C4" />
                        <Bar dataKey="Payment Received" fill="green" />
                    </BarChart>

                </Col>
                <Col span={12}>
                    <div className="site-statistic-demo-card" style={{padding: 30}}>
                        <h2>Last 3 Months Summary</h2>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Card>
                                    <Statistic
                                        title="Total Sales"
                                        value={beautifyAmount(summary?.newNumbers?.sales)}
                                        precision={0}
                                        valueStyle={{ color: '#3f8600' }}
                                        prefix={RUPEE}
                                    />
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <Statistic
                                        title="Total Payments Received"
                                        value={beautifyAmount(summary?.newNumbers?.receipt)}
                                        precision={0}
                                        valueStyle={{ color: '#3f8600' }}
                                        prefix={RUPEE}
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{marginTop: 16}}>
                            <Col span={12}>
                                <Card>
                                    <Statistic
                                        title="Total Payments Made"
                                        value={beautifyAmount(summary?.newNumbers?.paid)}
                                        precision={0}
                                        valueStyle={{ color: '#cf1322' }}
                                        prefix={RUPEE}
                                    />
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <Statistic
                                        title="Net Cash Flow"
                                        value={beautifyAmount((summary?.newNumbers?.receipt || 0) - (summary?.newNumbers?.paid || 0))}
                                        precision={0}
                                        valueStyle={{ color: (summary?.newNumbers?.receipt || 0) - (summary?.newNumbers?.paid || 0)>0 ? '#3f8600' : '#cf1322' }}
                                        prefix={RUPEE}
                                    />
                                </Card>
                            </Col>

                        </Row>
                    </div>
                </Col>
            </Row>

            <br/>


            <Row>
                <Col span={12}>
                    <div className="site-statistic-demo-card" style={{padding: 30}}>
                        <h2>Request a Demo</h2>
                        <Form
                            form={form}
                            style={{
                                background: '#fafafa', padding: 16, paddingLeft: 40, paddingRight: 40, maxWidth: 400,
                                borderRadius: 16,
                                border: '1px solid lightgrey'
                            }}>
                            <Form.Item name={"name"}
                                       // label={"Full Name"}
                                       rules={[{required: true, message: "can't be blank"}]}>
                                <Input placeholder={"Full Name"}/>
                            </Form.Item>
                            <Form.Item name={"phone"}
                                       // label={"Phone No"}
                            >
                                <Input placeholder={"Phone No."}/>
                            </Form.Item>
                            <Form.Item name={"email"}
                                       // label={"Email"}
                            >
                                <Input placeholder={"Email"}/>
                            </Form.Item>
                            <Form.Item name={"message"}
                                       // label={"Message"}
                            >
                                <Input.TextArea placeholder={"Message"} />
                            </Form.Item>
                            <center><ButtonV2 type={"primary"}
                                            onClick={async () => {
                                                const formData = await form.validateFields();
                                                console.log("## FormData ##", formData);
                                                const response = await requestDemo(formData);
                                                message[
                                                    response.data.success ? "success" : "error"
                                                    ](response.data.message);
                                                if (response.data.success) {
                                                    await form.resetFields();
                                                }
                                            }}
                            >Request Demo</ButtonV2></center>
                        </Form>
                    </div>
                </Col>
                <Col span={12}
                     // style={{padding: 0, paddingRight: 16}}
                    // style={{height: 400}}
                >
                    <div className="site-statistic-demo-card" style={{padding: 30, height: '100%'}}>
                    <h2>How to make your first invoice ?</h2>
                    {/*<div style={{background: 'white', width: '100%', height: '100%', padding: 16}}>*/}
                        <iframe
                            style={{width: '100%',
                                height: '100%',
                                minHeight: 220,
                                maxHeight: 320,
                            }}
                            // width="560" height="315"
                            src="https://www.youtube.com/embed/X7xstTSmrPQ"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                    {/*</div>*/}
                    </div>
                </Col>
            </Row>


            <Modal title="Complete Your Profile" maskClosable={false} visible={showNudge} open={showNudge} footer={null} onCancel={() => setShowNudge(false)}>
                <Result
                    status="500"
                    title="Complete Your Profile"
                    subTitle="For better experience, please update your business details for printing on invoices."
                    extra={<div>
                        <Button type="primary" onClick={() => {navigate('/settings/company')}}>Continue to update details</Button>
                        &nbsp;&nbsp;
                        <Button type="warning" onClick={() => setShowNudge(false)}>Skip, I will update later</Button>
                    </div>}
                />
            </Modal>

        </div>
    );
}

export default Dashboard;
