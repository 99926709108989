import axios from "axios";
import {baseUrl} from "../../services/api";

// export const getInstallations = async () => axios.get(`${baseUrl}/erpNew/installations`); // - deprecated

export const saveInstallation = async (data) => axios.post(`${baseUrl}/erpNew/installations`, data);

export const deleteInstallation = async (id) => axios.delete(`${baseUrl}/erpNew/installations?id=${id}`);

export const getDevices = async (params = {}) => axios.get(`${baseUrl}/erpNew/devices?` + new URLSearchParams(params));

export const getErpInvSummary = (params = {}) => axios.get(`${baseUrl}/erpNew/inventory/summary?` + new URLSearchParams(params));

export const saveDevices = async (data) => axios.post(`${baseUrl}/erpNew/devices/bulk`, data);

export const deleteDevice = async (id) => axios.delete(`${baseUrl}/erpNew/devices`);

export const transferDevices = async (data) => axios.post(`${baseUrl}/erpNew/devices/inventorytransfer`, data);

export const getDeviceModels = async () => axios.get(`${baseUrl}/erpNew/devicemodels`);

export const saveDeviceModel = async (data) => axios.post(`${baseUrl}/erpNew/devicemodels`, data);

export const deleteDeviceModel = async (id) => axios.delete(`${baseUrl}/erpNew/devicemodels?id=${id}`);

export const getParties = async () => axios.get(`${baseUrl}/erpNew/entity`);

export const saveParty = async (data) => axios.post(`${baseUrl}/erpNew/entity`, data);

export const deleteParty = async (id) => axios.delete(`${baseUrl}/erpNew/entity?id=${id}`);

export const getSims = async (params) => axios.get(`${baseUrl}/erpNew/sims?` + new URLSearchParams(params));

export const bulkUploadSims = async (data) => axios.post(`${baseUrl}/erpNew/sims/bulkUpload`, data);

export const mapDevice = async (data) => axios.post(`${baseUrl}/erpNew/mappings/map`, data);

export const remapDevice = async (data) => axios.post(`${baseUrl}/erpNew/mappings/remap`, data);

export const getErpUsers = async () => axios.get(`${baseUrl}/erpNew/users`);

export const saveErpUser = async (data) => axios.post(`${baseUrl}/erpNew/users`, data);

export const getLogs = async (params = {}) =>
    axios.get(`${baseUrl}/erpNew/logs?` + new URLSearchParams(params));

export const saveErpInventoryTransaction = data => axios.post(`${baseUrl}/erpNew/inventory`, data);

export const verifyInvDevices = data => axios.post(`${baseUrl}/erpNew/inventory/verify`, data);

export const getErpInventoryTransactions = (params = {}) => axios.get(`${baseUrl}/erpNew/inventory?` + new URLSearchParams(params));