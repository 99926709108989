import axios, {baseUrl} from '../api';

export const validateBankStatement = async (data) => axios.post(`${baseUrl}/portal/accounting/bankRecon/validateStatement`, data);

export const getLedgersList = async () => axios.get(`${baseUrl}/portal/accounting/common/internal/ledgers`);

export const postLedgerRecordUpdate = async (data) => axios.post(`${baseUrl}/portal/accounting/bankRecon/postRecord`, data);

export const getPortalErrorsData = async () => axios.get(`${baseUrl}/portal/errors`);

