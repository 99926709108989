import React, {useEffect, useState} from "react";
import {Button, Col, Input, message, Row, Table, Tabs} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {deleteParty, getParties} from "../../services/api";
import EditButton from "../../components/buttons/EditButton";
import DeleteButton from "../../components/buttons/DeleteButton";
import {customDispatcher} from "../../../utils";
import {useDispatch} from "react-redux";

const PartiesPage = () => {
    const dispatch = customDispatcher(useDispatch());
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");


    const fetchRecords = async () => {
        getParties().then(resp => setRecords(resp.data.data.entities));
    }

    useEffect(() => {
        fetchRecords();
    }, []);

    const openDrawer = (record) => {
        const isNew = !record;
        dispatch({
            popData: {
                visible: true,
                type: "partyWrite",
                // width: 600,
                size: "medium",
                title: isNew ? `Add New Party` : `Edit Party`,
                onSave: fetchRecords,
                record,
                // segments: ["Manual", "Bulk"]
                // data: obj,
            }
        })
    }


    const columns = [
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Type",
            dataIndex: "type",
        },
        {
            title: "Actions",
            render: (record) => record.name === "Stock" ? "---" : <>
                <EditButton onClick={() => {
                    openDrawer(record);
                }}
                />
                &nbsp;
                <DeleteButton
                    onClick={async () => {
                    await deleteParty(record._id);
                    message.success(`Party deleted successfully`);
                    fetchRecords();
                }}/>
            </>
        }
    ];


    return (
        <div style={{padding: 24, paddingTop: 8}}>
            <Row justify={"center"} align={"middle"}>
                <Col span={12} style={{fontSize: 24, fontWeight: 'bold'}}>
                    Parties
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <>
                        <Button type={"primary"} onClick={() => {
                            openDrawer();
                        }} style={{
                            // background: 'darkslateblue',
                            // color: 'white',
                            borderRadius: 6,
                            fontWeight: 'bold',
                        }}>
                            <PlusOutlined style={{}}/> New Party
                        </Button>
                    </>

                </Col>
            </Row>

            {/*<Row>*/}
            {/*    <Input.Search onChange={(e) => setSearchText(e.target.value)}*/}
            {/*                  placeholder={"Search with Name"}*/}
            {/*                  style={{width: 'auto', minWidth: 300,*/}
            {/*                      // height: 40*/}
            {/*                  }}*/}
            {/*                  allowClear/>*/}

            {/*</Row>*/}

            {/*<div style={{padding: 16, paddingLeft: 0, paddingRight: 0}}>*/}
            {/*    <h2 style={{float: 'left'}}>{title}</h2>*/}
            {/*    <Button onClick={() => {*/}
            {/*        setSelectedRecord({*/}
            {/*            type*/}
            {/*        });*/}
            {/*        setShowModal(true);*/}
            {/*    }} style={{float: 'right'}} type={"primary"}>*/}
            {/*        Create New Ledger</Button>*/}
            {/*</div>*/}

            <br/>
            <Table
                loading={loading}
                columns={columns}
                dataSource={records.filter(r => true || (r.name || "").toLowerCase().includes("searchText".toLowerCase()))}
            />

        </div>
    );
};

export default PartiesPage;
