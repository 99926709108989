import React, {useRef, useState} from "react";
import {Button, Col, Input, message, Row, Descriptions, Alert} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {getDevices, mapDevice, remapDevice} from "../../services/api";
import {isValidDeviceID, isValidSimNumber} from "../../utils";
import { ButtonV2 } from "../../../components/ButtonV2";

const MappingPage = () => {
    const [txtDeviceId, setTxtDeviceId] = useState("");
    const [device, setDevice] = useState();
    const [msg, setMsg] = useState();
    const isSecondStep = !!device;
    const isAlreadyMapped = device && device.sim;


    return (
        <div style={{padding: 24, paddingTop: 8}}>
            <Row justify={"center"} align={"middle"}>
                <Col span={12} style={{fontSize: 24, fontWeight: 'bold'}}>
                    Device - Sim Mappings
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <>

                    </>

                </Col>
            </Row>

            <br/><br/>
            <div style={{textAlign: 'center'}}>
                <Input.Search
                    value={txtDeviceId}
                    onChange={e => setTxtDeviceId(e.target.value)}
                    disabled={isSecondStep}
                    style={{width: '70%'}}
                    placeholder={"Search with Device ID (15 digit, or last 6 digits)"}
                    size={"large"}
                    enterButton={true}
                    maxLength={15}
                    onSearch={async (val) => {
                        try {
                            if (!isValidDeviceID(val, true)) {
                                throw new Error(`Invalid Device ID, Please enter 15 digit or last 6 digit of Device ID`);
                            }
                            const resp = await getDevices({search: val});
                            const {devices} = resp.data.data || {};
                            if (devices && devices.length > 1) {
                                throw new Error(`Multiple devices found with this short ID, please enter full 15-digit ID`);
                            } else if (!devices || !devices.length) {
                                throw new Error(`Device not found with this ID`);
                            }

                            setDevice(devices[0]);
                            setMsg();
                        } catch(err) {
                            setMsg({
                                type: "error",
                                content: err.message,
                            });
                        }
                    }}
                />
                {
                    isSecondStep &&
                        <span style={{fontSize: 18}}>
                            &nbsp;&nbsp;
                            <Button type={"primary"} style={{background: 'brown'}} size={"large"}
                            onClick={() => {
                                setDevice();
                                setTxtDeviceId("");
                                setMsg();
                            }}
                            >
                                Reset
                            </Button>
                        </span>
                }
                <br/><br/>
                {
                    (isSecondStep) &&
                    <Descriptions style={{border: '1px solid darkgrey', padding: 8}} title="Current Mapping Details"
                                  bordered={false}>
                        <Descriptions.Item label="Device ID">Airtel</Descriptions.Item>
                        <Descriptions.Item label="H/W Model">325423523</Descriptions.Item>
                        <Descriptions.Item label="Inventory">57532432</Descriptions.Item>

                        <Descriptions.Item label="Sim Provider">{device?.sim?.simProvider?.name || "(Not Mapped)"}</Descriptions.Item>
                        <Descriptions.Item label="Sim Number">{device?.sim?.simNumber || "(Not Mapped)"}</Descriptions.Item>
                        <Descriptions.Item label="Mobile Number">{device?.sim?.mobileNumber || "(Not Mapped)"}</Descriptions.Item>
                    </Descriptions>
                }
                <br/><br/>
                {
                    isSecondStep && isAlreadyMapped &&
                    <ButtonV2 size={"large"} type={"danger"}
                            onClick={async () => {
                                await remapDevice({
                                    deviceId: device.deviceId
                                });
                                setMsg({
                                    type: "success",
                                    content: `Device ${device.deviceId} remapped successfully.`
                                });
                                setDevice();
                                setTxtDeviceId("");
                            }}
                    >Remap Device</ButtonV2>
                }
                {
                    isSecondStep && !isAlreadyMapped &&
                    <Input.Search
                        style={{width: '70%'}}
                        placeholder={"Sim Number (19 digit or last 8 digits)"}
                        size={"large"}
                        enterButton={"Map Device"}
                        maxLength={19}
                        onSearch={async (val) => {
                            try {
                                if (!isValidSimNumber(val, true)) {
                                    throw new Error(`Invalid Sim Number, Please enter 19 digit or last 8 digit of Sim Number`)
                                }

                                const resp = await mapDevice({
                                    deviceId: device.deviceId,
                                    simNumber: val
                                });
                                if (!resp.data.success) {
                                    throw new Error(resp.data.message);
                                }
                                setMsg({
                                    type: "success",
                                    content: `Device ${device.deviceId} successfully mapped to sim ${val}`
                                });
                                setDevice();
                                setTxtDeviceId("");
                            } catch(err) {
                                setMsg({
                                    type: "error",
                                    content: err.message,
                                });
                            }

                        }}
                    />
                }
            </div>

            <br/>
            {
                msg &&
                <Alert
                    message={msg.type === "error" ? "Error" : "Success"}
                    description={msg.content}
                    type={msg.type}
                    showIcon
                />
            }
        </div>
    )
};

export default MappingPage;
