import React, {useEffect, useState} from "react";
import {Layout, Menu} from "antd";
import {
    AppstoreOutlined,
    HomeOutlined,
    UnorderedListOutlined, SettingOutlined,
} from "@ant-design/icons";
import {Link, Outlet, useParams} from "react-router-dom";

const {Sider, Content} = Layout;

const EcommerceBase = () => {
    const params = useParams();
    const [selectedKey, setSelectedKey] = useState();
    useEffect(() => {
        setSelectedKey(params["*"]?.split("/")[1]);
    }, [params]);

    return (
        <div>
            <Layout>
                <Sider width={160} className="site-layout-background">
                    <Menu
                        mode="inline"
                        selectedKeys={[selectedKey]}
                        style={{height: '100%', borderRight: 0}}
                    >
                        <Menu.Item key={"home"} icon={<HomeOutlined/>}>
                            <Link to={"/ecommerce/home"}>Home</Link>
                        </Menu.Item>
                        <Menu.Item key={"orders"} icon={<UnorderedListOutlined/>}>
                            <Link to={"/ecommerce/orders"}>Orders</Link>
                        </Menu.Item>
                        <Menu.Item key={"products"} icon={<AppstoreOutlined/>}>
                            <Link to={"/ecommerce/products"}>Products</Link>
                        </Menu.Item>
                        <Menu.Item key={"settings"} icon={<SettingOutlined/>}>
                            <Link to={"/ecommerce/settings"}>Settings</Link>
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout style={{padding: '0 24px 24px'}}>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}
                    >
                        <Outlet/>
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
};

export default EcommerceBase;
