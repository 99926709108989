import React, {useEffect, useState} from "react";
import {Button, DatePicker, Form, Input, message, Select} from "antd";
import {getDevices, getParties, saveInstallation} from "../../../erp/services/api";
import {isValidDeviceID} from "../../../erp/utils";
import _ from "lodash";
import {SUBSCRIPTIONS} from "../../../erp/models/constant";
import {requiredRule} from "../../../utils";
import moment from "moment";

const InstallationRightDrawer = ({closeDrawer, onSave, record}) => {
    const [validatedId, setValidatedId] = useState();
    const [txtDeviceId, setTxtDeviceId] = useState("");
    const [isNewRecord, setIsNewRecord] = useState(true);
    const [parties, setParties] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        setTxtDeviceId("");
        setValidatedId();
        form.resetFields();
        if (record._id) {
            record.installationDate = moment(record.installationDate);
            form.setFieldsValue(record);
            setValidatedId(record._id);
            setTxtDeviceId(record.deviceId);
            setIsNewRecord(false);
        } else {
            form.setFieldsValue({
                installationDate: moment()
            })
        }
    }, [record]);

    useEffect(() => {
        getParties().then(resp => setParties(resp.data.data.entities.filter(entity => entity.type === "Technician")));
    }, []);

    return (
        <div>
            <Form
                form={form}
                layout={"horizontal"}
                labelCol={{
                    span: 7, style: {
                        textAlign: 'left',
                    }
                }}
                wrapperCol={{span: 16}}
                onFinish={async (vals) => {
                    if (!validatedId) {
                        return;
                    }

                    await saveInstallation({
                        device: {
                            ...vals,
                            _id: validatedId,
                            isNewRecord,
                        }
                    });

                    message.success(`Installation saved successfully`);
                    closeDrawer();
                    onSave();
                }}
            >
                <Form.Item label={"Device ID"} name={"deviceId"}>
                    <Input.Search
                        placeholder={"15 digit or last 6 digits of Device ID"}
                        disabled={!!validatedId} value={txtDeviceId}
                        onChange={e => setTxtDeviceId(e.target.value)}
                        enterButton={true}
                        maxLength={15} onSearch={async (searchId) => {
                        try {
                            if (!isValidDeviceID(searchId, true)) {
                                throw new Error(`Invalid Device ID, Please enter 15 digit or last 6 digit of Device ID`);
                            }
                            const resp = await getDevices({search: searchId});
                            const {devices} = resp.data.data || {};
                            if (devices && devices.length > 1) {
                                throw new Error(`Multiple devices found with this short ID, please enter full 15-digit ID`);
                            } else if (!devices || !devices.length) {
                                throw new Error(`Device not found with this ID`);
                            }

                            const [device] = devices;
                            if (!device.simId) {
                                throw new Error(`Device is not mapped, please first Map sim with device`);
                            } else if (device.installedBy) {
                                throw new Error(`Device has already been installed, please check device ID`);
                            }
                            setValidatedId(device._id);
                            setTxtDeviceId(device.deviceId);
                        } catch (err) {
                            message.error(err.message);
                        }
                    }}/>
                </Form.Item>

                {
                    validatedId &&
                    <>
                        <Form.Item label={"Installation Date"} name={"installationDate"} rules={requiredRule("Please select installation date")}>
                            <DatePicker format={"DD MMM YYYY"} allowClear={false}/>
                        </Form.Item>
                        <Form.Item label={"Subscription Plan"} name={"subscriptionPlan"} rules={requiredRule("Please select Subscription Plan")}>
                            <Select
                                placeholder={"Select Subscription"}
                                options={_.map(SUBSCRIPTIONS, (subLabel, subKey) => ({
                                    label: subLabel, value: subKey
                                }))}
                            />
                        </Form.Item>
                        <Form.Item label={"Installed By"} name={"installedBy"} rules={requiredRule("Please select technician")}>
                            <Select
                                placeholder={"Select Technician"}
                                options={parties.map(p => ({
                                    label: p.name,
                                    value: p._id,
                                }))}
                            />
                        </Form.Item>
                        <Form.Item label={"Customer Name"} name={"customerName"}>
                            <Input placeholder={"Customer Name"}/>
                        </Form.Item>
                        <Form.Item label={"Customer Mobile"} name={"customerPhone"} rules={[
                            {required: true, message: "can't be blank"},
                            {pattern: /^[0-9]{10}$/, message: "should be valid 10 digit mobile"}
                        ]}>
                            <Input placeholder={"Customer Mobile"} maxLength={10}/>
                        </Form.Item>
                        <Form.Item label={"Vehicle Reg. Number"} name={"vehicleNumber"} rules={requiredRule("can't be blank")}>
                            <Input placeholder={"Vehicle Reg. Number"}/>
                        </Form.Item>
                        <Form.Item label={"Vehicle Model"} name={"vehicleModel"}>
                            <Input placeholder={"Vehicle Model"}/>
                        </Form.Item>
                        <Button type={"primary"} htmlType={"submit"}>
                            Save Installation
                        </Button>

                    </>
                }

            </Form>

        </div>
    )
};

export default InstallationRightDrawer;
