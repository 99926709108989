import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Alert, Button, Card, Col, Divider, Empty, Form, Image, Input, message, Row, Select, Tag} from "antd";
import LicenseIcon from "../../res/img/license_icon.png";
import _ from "lodash";
import {states} from "../../models/constants";
import Search from "antd/es/input/Search";
import {customDispatcher, isPuc, sleep} from "../../utils";
import {applyCoupon, completePurchase, getCoupons, getPlans, loginRequest} from "../../services/api";
import {useDispatch, useSelector} from "react-redux";
import { ButtonV2 } from "../../components/ButtonV2";

const findStateByGstin = (gstin = "") => {
    const inputStateCode = gstin.slice(0, 2);
    const vals = _.values(states["IN"]);
    for (const state of vals) {
        if (state.gstCode === inputStateCode) {
            return state.alphaCode
        }
    }
}

// const coupons = [
//     {
//         code: "WELCOME",
//         description: "Flat 20% Off on all products",
//         discount: {
//             type: "percent",
//             value: 20
//         }
//     },
//     {
//         code: "PREMIUM",
//         description: "Instant 50% discount on purchase of Premium subscription",
//         discount: {
//             type: "fixed",
//             value: 300
//         }
//     }
// ];

const planNameMap = {
    "lite": "Lite",
    "premium": "Premium"
}

const pucPlanNamesMap = {
    "lite": "Premium",
    "premium": "Elite"
};

const planDurationsMap = {
    "1yr": "1 Year",
    "3yr": "3 Years"
};


const PlanCheckout = () => {
    const state = useSelector(state => state.oldState);
    const dispatch = customDispatcher(useDispatch());
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [gstState, setGstState] = useState();
    const [applyingCode, setApplyingCode] = useState(false);
    const [codeApplied, setCodeApplied] = useState("");
    const [coupons, setCoupons] = useState([]);
    const [plans, setPlans] = useState({
        "lite": {
            "1yr": "--",
            "3yr": "--"
        },
        "premium": {
            "1yr": "--",
            "3yr": "--"
        }
    });

    const {selectedPlan = ""} = useParams();
    const [plan, duration] = selectedPlan.split("_");
    const cost = parseInt(plans[plan][duration]) || 0;
    const netAmount = (cost - (codeApplied?.discount || 0)).toFixed(0);
    console.log("## Cost ##", cost);
    if (gstState) {
        form.setFieldsValue({state: gstState});
    }

    useEffect(() => {
        if (state && state.company) {
            getCoupons().then(resp => setCoupons(resp.data.data.coupons));
            getPlans().then(resp => setPlans(resp.data.data.plans));
        }
    }, [state])

    const onApplyCode = async (code = "") => {
        setApplyingCode(true);
        await sleep(500);
        const resp = await applyCoupon({code, plan, duration});
        const {success, message: msg, data} = resp.data;
        if (success) {
            message.success("Code applied successfully");
            setCodeApplied({
                code,
                discount: data.discount
            });
        } else {
            message.error("Failed to apply code: " + msg);
        }
        setApplyingCode(false);
    }

    return (
        <div>
            {/*<h2><b>Checkout</b></h2>*/}
            {/*<Card title="Cart" style={{ width: '100%' }}>*/}
            {/*    <Image src={LicenseIcon} preview={false}/>*/}
            {/*    <Divider/>*/}
            {/*    ABCD*/}
            {/*</Card>*/}
            <Row>
                <Col span={16}>
                    <Row align={"middle"} justify={"center"}>
                        <Col span={18}>
                            <div style={{float: 'left'}}>
                                <Image src={LicenseIcon} preview={false} style={{width: 72, float: 'left'}}/>
                            </div>
                            <div style={{verticalAlign: 'middle', lineHeight: 2, fontSize: 18, marginLeft: 90}}>
                                <b>KhataBuddy {isPuc() && "PUC "}Software License</b>
                                <br/>
                                Subscription Plan: {isPuc() ? pucPlanNamesMap[plan] : planNameMap[plan]}, Validity: {planDurationsMap[duration]}
                                <br/>
                                <Link to={"/settings/subscription"} style={{fontSize: 14}}>
                                    Check our other plans
                                </Link>
                            </div>
                        </Col>
                        <Col span={6} style={{
                            textAlign: 'right', paddingRight: 64, fontSize: 22, fontWeight: 'bold',
                            // color: 'green'
                        }}>
                            ₹ {cost}
                        </Col>
                    </Row>


                    {/*<i>*/}
                    {/*<h3 style={{marginTop: 32}}>*/}
                    {/*    Billing Details:*/}
                    {/*</h3>*/}
                    {/*</i>*/}
                    <Form
                        style={{width: '100%', paddingRight: 64, marginTop: 64}}
                        form={form}
                        labelCol={{span: 6}}
                        wrapperCol={{span: 18}}
                        onFinish={() => {
                        }}
                        onFinishFailed={() => {
                        }}
                    >

                        <Form.Item name={"name"} label={"Full Name"} rules={[{required: true, message: "can't be blank"}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name={"companyName"} label={"Company Name"}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name={"address"} label={"Address"}
                                   rules={[{required: true, message: "can't be blank"}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label={<><span style={{color: 'red'}}>*</span>&nbsp;State</>}>
                            <Row>
                                <Col span={12} style={{paddingRight: 16}}>
                                    <Form.Item name={"state"} label={"State"} rules={[{required: true, message: "can't be blank"}]} noStyle>
                                        <Select
                                            disabled={!!gstState}
                                            options={_.map(states["IN"], state => ({
                                                label: state.name,
                                                value: state.alphaCode
                                            }))}
                                            allowClear={true}
                                            filterOption={(input, option) => {
                                                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }}
                                            showSearch
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={"gstin"} label={"GSTIN"}>
                                        <Input onChange={(e) => setGstState(findStateByGstin(e.target.value))}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item label={<><span style={{color: 'red'}}>*</span>&nbsp;Email</>}>
                            {/*<Row>*/}
                            {/*<Col>*/}
                            <Form.Item name={"email"} label={"Email"} rules={[{type: 'email', required: true, message: "can't be blank"}]} noStyle>
                                <Input style={{width: 256}}/>
                            </Form.Item>
                            {/*</Col>*/}
                            {/*<Col>*/}
                            <span style={{fontStyle: 'italic', marginLeft: 16}}>
                            (Invoice will be sent to this email)
                        </span>
                            {/*    </Col>*/}
                            {/*</Row>*/}
                        </Form.Item>

                    </Form>
                </Col>
                <Col span={8} style={{borderLeft: '1px solid lightgrey', paddingLeft: 16}}>

                    <div>
                        <div style={{fontWeight: 'bold', fontSize: 18}}>Promotional Code</div>
                        <br/>
                        {
                            codeApplied ?
                                <Alert
                                    message={`Code Applied: ${codeApplied.code}`}
                                    type={"success"}
                                    closable={true}
                                    closeText={"Remove"}
                                    onClose={() => {
                                        setCodeApplied("");
                                    }}
                                />
                                :
                                <Search
                                    placeholder="Enter Coupon Code"
                                    maxLength={20}
                                    // allowClear
                                    enterButton="Apply"
                                    // size="large"
                                    onSearch={onApplyCode}
                                    loading={applyingCode}
                                />

                        }
                        {
                            coupons.length ?
                                coupons.map(c => (
                                    <Card size="small" title={
                                        <div style={{fontWeight: 'bold'}}>
                                            <Tag color="green" style={{
                                                border: '1px dashed green'
                                            }}>{c.code}</Tag>
                                            {/*{c.code}*/}
                                        </div>
                                    }
                                          extra={<Link to={"#"} onClick={() => {
                                              onApplyCode(c.code);
                                          }}>Apply</Link>}
                                          style={{width: '100%', marginTop: 16}}>
                                        <p>{c.description}</p>
                                    </Card>
                                )) :
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                       description={"No offers running currently"}/>
                        }

                        <div>
                            {
                                codeApplied &&
                                <Row style={{fontSize: 14}}>
                                    <Divider/>
                                    <Col span={16}>
                                        Items Total:
                                    </Col>
                                    <Col span={6} style={{textAlign: 'right'}}>
                                        ₹{cost}
                                    </Col>
                                    <Col span={16}>
                                        Promotion Applied:
                                    </Col>
                                    <Col span={6} style={{textAlign: 'right'}}>
                                        -₹{codeApplied.discount}
                                    </Col>
                                </Row>
                            }
                            <Divider style={{padding: 0, marginTop: 8, marginBottom: 8}}/>
                            <Row style={{fontSize: 20, color: 'green', fontWeight: 'bold'}}>
                                <Col span={16}>
                                    Order Total:
                                </Col>
                                <Col span={6} style={{textAlign: 'right'}}>
                                    ₹{netAmount}
                                </Col>
                            </Row>
                            <Divider style={{padding: 0, marginTop: 8, marginBottom: 8}}/>
                        </div>
                        <div style={{textAlign: 'center', width: '100%'}}>
                            <ButtonV2 type={"primary"} style={{
                                marginTop: 32,
                                // borderRadius: 100,
                                fontSize: 20,
                                height: 'auto',
                                paddingLeft: 24,
                                paddingRight: 24,
                                // fontWeight: 'bold',
                            }}
                                    onClick={async () => {
                                        console.log("## USer ##", state?.user);
                                        const formData = await form.validateFields();
                                        console.log("## FormData ##", formData);
                                        const resp = await completePurchase({
                                            formData,
                                            code: (codeApplied && codeApplied.code) || "",
                                            plan,
                                            duration,
                                            username: state?.user?.email || state?.user?.phone,
                                        });
                                        const {data: {data: {needToPay, payOrder}}} = resp;
                                        console.log("## payOrder ##", payOrder);
                                        if (needToPay) {
                                            const {orderId} = payOrder;
                                            navigate(`/pay/${orderId}/gateway`);
                                        } else { // Free purchase
                                            message.success("Congratulations, your plan has been upgraded!");
                                            navigate(`/settings/subscription`);
                                            await loginRequest("", "", dispatch);
                                        }
                                    }}
                            >
                                Complete Purchase
                            </ButtonV2>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export default PlanCheckout;
