import React, {useEffect, useState} from "react";
import _ from "lodash";
import {Alert, Button, Col, Divider, Dropdown, Menu, message, Popconfirm, Popover, Row, Table, Tooltip} from "antd";
import moment from "moment";
import {RUPEE, states, voucherTypes} from "../../models/constants";
import {
    beautifyAmount,
    copyToClipboard,
    customDispatcher,
    formulateItems,
    getPublicShareLink,
    isValidPhone, PublicShareLinks
} from "../../utils";
import {useDispatch, useSelector} from "react-redux";
import {deleteVoucher, getVouchers, saveVoucher, sendSMS} from "../../services/api";
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined, InfoCircleOutlined,
    LinkOutlined,
    MailFilled,
    MessageFilled,
    ShareAltOutlined,
    WhatsAppOutlined
} from "@ant-design/icons";
import VoucherAttachments from "../../components/VoucherAttachments";
import { ButtonV2 } from "../../components/ButtonV2";

const cachedUpdatedAttachments = {}; // {[voucherId: key]: string[]}


const DrawerInvoice = ({document}) => {
    const state = useSelector(state => state.oldState);
    const {company} = state;
    const dispatch = customDispatcher(useDispatch());
    const [invoicesMap, setInvoicesMap] = useState({});
    const [loadingIMap, setLoadingIMap] = useState(false);
    const voucher = _.cloneDeep(document);
    const voucherType = voucher.type;
    const docType = voucherTypes[voucherType];
    const invoiceNum = ["purchase", "debitNote"].includes(voucherType) ? voucher.externalDocumentNumber :
        (docType.idType !== "mixed" ? voucher.documentNumber : `${voucher.documentNumber.prefix}${voucher.documentNumber.num}`);
    const isIgst = voucher.party?.state !== state?.company?.state;
    const isAbroad = voucher.party?.country && voucher.party?.country !== "IN";

    useEffect(() => {
        const executor = async () => {
            if (document.type === "receipt" && document.entryType === "Invoice-Wise") {
                setLoadingIMap(true);
                const voucherIds = (document.distribution || []).map(d => d.voucherId);
                const iMap = {};
                for (const vid of voucherIds) {
                    if (vid !== "Advance") {
                        const resp = await getVouchers("invoice", vid);
                        iMap[vid] = resp.data.data.vouchers[0];
                    }
                }
                setInvoicesMap(iMap);
                setLoadingIMap(false);
            }
        }
        executor();
    }, [document]);

    const dbItemsMap = {};

    (state?.items || []).forEach(item => {
        dbItemsMap[item._id] = item;
    });

    const isRoundOffEnabled = _.get(voucher, 'customConfig.enableRoundOff', true);
    const {
        tableItems,
        summary: {extraDiscount}
    } = formulateItems({
        items: voucher.items, dbItemsMap, isIgst, extraDiscount: {
            extraDiscountType: voucher.extraDiscountType,
            extraDiscountValue: voucher.extraDiscountValue,
        }, isAbroad, isRoundOffEnabled,
    });

    const cols = [
        {
            title: "S.No.",
            render: (item, rec, index) => (index + 1).toString()
        },
        {
            title: "Item",
            // dataIndex: "name",
            render: (item) => <>
                <b>{item.name}</b>
                <br/>
                <span style={{fontSize: 12}}>{item.description}</span>
            </>
        },
        {
            title: "Rate",
            dataIndex: "price",
        },
        {
            title: "Qty",
            dataIndex: "qty"
        },
        {
            title: "Discount",
            dataIndex: "discount"
        },
        {
            title: "Taxable Value",
            dataIndex: "taxable"
        },
        {
            title: "Tax",
            dataIndex: "totalTax",
        },
        {
            title: "Amount",
            dataIndex: "total",
            className: "genericBold"
        }
    ];

    const receiptCols = [
        {
            title: "S.No.",
            render: (line, rec, index) => (index + 1).toString()
        },
        {
            title: "Invoice Reference",
            render: (line) => {
                const inv = invoicesMap[line.voucherId];
                const invoiceNum = inv ? `${inv?.documentNumber?.prefix}${inv?.documentNumber?.num}` : "Advance";
                return invoiceNum;
            }
        },
        {
            title: "Amount",
            render: line => line.amount,
        }
    ];

    if (voucher.type === "receipt") {
        const crParty = voucher.crParty;
        voucher.crParty = voucher.party;
        voucher.party = crParty;
    }

    const obj = voucher;
    const publicLink = getPublicShareLink(PublicShareLinks.VOUCHER, voucher?.hashId);
    const msgContent = <>Hi {voucher?.party?.name}
        <br/>Thank you for making payment of {RUPEE} {beautifyAmount(voucher.amount)} at {company?.name || ""}.
        <br/>Here is your Payment Receipt: {publicLink}
        <br/>- Sent using KhataBuddy</>;



    return (
        <div>
            {/*Invoice Component*/}

            <Row>
                <Col span={16} style={{paddingRight: 20}}>
                    <i>{document.party.type === "creditor" ? "Vendor Details:" : document.party.type === "expense" ? "Expense Details:" : "Customer Details:"}</i>
                    <br/>
                    <strong>{voucher.party?.name}</strong>
                    <br/>
                    {
                        document.party.type !== "expense" && voucher.party?.address &&
                        <div>
                            Address: {voucher.party?.address}
                            <br/>
                        </div>
                    }
                    {document.party.type !== "expense" && <>State: {states[voucher.party?.country || "IN"][voucher.party?.state]?.name}<br/></>}
                    {
                        voucher.party?.gstin &&
                        <div>
                            GSTIN: {voucher.party?.gstin}
                            <br/>
                        </div>
                    }
                    {
                        voucher.party?.phone &&
                        <div>
                            Phone: {voucher.party?.phone}
                            <br/>
                        </div>
                    }
                    {
                        voucher.party?.email &&
                        <div>
                            Email: {voucher.party?.email}
                            <br/>
                        </div>
                    }
                    {
                        voucher.party?.pan &&
                        <div>
                            PAN: {voucher.party?.pan}
                            <br/>
                        </div>
                    }
                </Col>
                <Col span={8}>
                    {voucher.type === "invoice" ? "Invoice" : "Doc."} # <b>{invoiceNum}</b>
                    <br/>
                    Date: <b>{moment(voucher.date).format("DD MMM YYYY")}</b>
                    {
                        (voucher.type === "receipt" || voucher.type === "payment")  &&
                            <>
                                <br/>
                                Payment Method: <b>{voucher.paymentMode || "Cash"}</b>
                                <br/>
                                {voucher.type === "receipt" ? "Receiving Account:" : "Paying Account"} <b>{voucher.crParty?.name || ""}</b>
                            </>
                    }
                </Col>
            </Row>

            {
                (voucher.type !== "receipt" || voucher.entryType === "Invoice-Wise") && document.type !== "payment" &&
                <Table
                    columns={voucher.type === "receipt" ? receiptCols : cols}
                    dataSource={voucher.type === "receipt" ? (loadingIMap ? [] : (voucher.distribution || [])) : tableItems}
                    pagination={false}
                    loading={voucher.type === "receipt" && loadingIMap}
                    style={{marginTop: 24}}
                />
            }

            {
                !(voucher.type !== "receipt" || voucher.entryType === "Invoice-Wise") &&
                <Divider/>
            }

            {
                voucher.type === "invoice" && extraDiscount && <div style={{textAlign: 'right', marginTop: 16,

                    fontSize: 16,
                    paddingRight: 24,
                }}>
                    {extraDiscount.title}:
                    &nbsp;{RUPEE}&nbsp;-{Number(extraDiscount.amount).toFixed(0)}
                </div>
            }
            <div style={{
                marginTop: 16,
                color: 'green',
                fontSize: 18,
                textAlign: 'right',
                fontWeight: 'bold',
                paddingRight: 24
            }}>
                Total Amount{(voucher.type === "receipt" && " Received") || (voucher.type === "payment" && " Paid")}: ₹{beautifyAmount(voucher.amount)}
            </div>

            {
                voucher.type === "receipt" &&
                <>
                    <Divider/>

                    <div style={{fontSize: 20}}>
                        {
                            ["invoice", "proforma", "receipt"].includes(voucherType) &&
                            <>
                                <Button className={"btnCustom3"}
                                        style={{
                                            padding: 0,
                                            height: 28,
                                            fontSize: 13,
                                            fontWeight: 'bold',
                                            paddingLeft: 8,
                                            paddingRight: 8,
                                            background: '#e8eaf6',
                                            color: '#283593'
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();

                                            const titleMap = {
                                                "invoice": "Preview Invoice",
                                                "proforma": "Preview Proforma",
                                                "receipt": "Preview Receipt",
                                            }

                                            dispatch({
                                                popData: {
                                                    visible: true,
                                                    type: "previewInvoice",
                                                    title: titleMap[voucherType] || "Preview",
                                                    data: {
                                                        voucherId: voucher?._id,
                                                        type: voucherType,
                                                    },
                                                }
                                            })
                                        }}
                                ><EyeOutlined/> View Receipt
                                </Button>
                                &nbsp;
                                <Dropdown overlay={
                                    <Menu className={"menuWrapper"} onClick={(e) => {
                                        e.domEvent.stopPropagation();
                                    }}>
                                        <Menu.Item className={"customHover"} onClick={() => {
                                            // e.stopPropagation();
                                            dispatch({
                                                popData: {
                                                    visible: true,
                                                    type: "email",
                                                    title: `Send Payment Receipt #${invoiceNum} on Email`,
                                                    data: document,
                                                }
                                            })
                                        }}>
                                            <div style={{fontWeight: 'bold', width: '100%'}} onClick={e => {
                                            }}>
                                                <MailFilled style={{fontSize: 18, color: 'red'}}/>&nbsp;&nbsp;Email
                                            </div>
                                        </Menu.Item>
                                        <Menu.Item className={"customHover"} style={{fontWeight: 'bold'}} onClick={(menuInfo) => {
                                            // e.stopPropagation();
                                            const publicLink = getPublicShareLink(PublicShareLinks.VOUCHER, voucher?.hashId);
                                            const msgContent = `Hello ${voucher?.party?.name},\nThanks for your business!\nPlease find your payment receipt PAYIN-${voucher.documentNumber} here, ${publicLink}\nThanks *${state.company?.name}* *${state.company?.phone || ""}* \n- Sent using *KhataBuddy*`;
                                            const phoneString = isValidPhone(voucher?.party?.phone) ? `91${voucher?.party?.phone}` : "";
                                            const waLink = `https://api.whatsapp.com/send/?phone=${phoneString}&text=${encodeURI(msgContent || "")}`;
                                            window.open(waLink, '_blank');
                                        }}>
                                            <div style={{width: '100%'}}>
                                                <WhatsAppOutlined style={{color: 'green', fontSize: 18, cursor: 'pointer'}}/>
                                                &nbsp;&nbsp;Whatsapp
                                            </div>
                                        </Menu.Item>

                                        <Popover
                                            style={{width: '100%'}}
                                            content={
                                                <div onClick={e => e.stopPropagation()}>
                                                    <Alert type={"info"} style={{background: 'white', border: 0, width: 500}}
                                                           showIcon={false} message={
                                                        <>
                                                            <div>
                                                                {msgContent}
                                                            </div>
                                                            <br/>
                                                            <ButtonV2 type={"primary"} style={{fontSize: 12, padding: 4, height: 26}}
                                                                    onClick={async () => {
                                                                        if (!isValidPhone(voucher?.party?.phone)) {
                                                                            message.error(`Customer contact is not a valid mobile number`);
                                                                            return;
                                                                        }
                                                                        const resp = await sendSMS({
                                                                            origin: "EXTERNAL_KB_APP",
                                                                            phone: voucher?.party?.phone,
                                                                            smsTemplate: "KB_SEND_DOCUMENT",
                                                                            params: {
                                                                                customerName: voucher?.party?.name || "customer",
                                                                                action: `making payment of Rs. ${beautifyAmount(voucher.amount)}`,
                                                                                merchant: company?.name || "",
                                                                                docWithLink: `${publicLink}`,
                                                                            }
                                                                        },"sms");
                                                                        if (!resp.data.success) {
                                                                            message.error(resp.data.message);
                                                                        } else {
                                                                            message.success("Invoice sent on SMS successfully!");
                                                                        }
                                                                    }}>
                                                                Send on SMS
                                                            </ButtonV2>
                                                        </>
                                                    }/>
                                                </div>
                                            }
                                            title="" trigger="click">

                                            <Menu.Item className={"customHover"} style={{fontWeight: 'bold'}}>
                                                <div style={{width: '100%'}}>
                                                    <MessageFilled style={{color: 'blue', fontSize: 18, cursor: 'pointer'}}
                                                                   onClick={async (e) => {
                                                                       e.stopPropagation();
                                                                   }}/>
                                                    &nbsp;&nbsp;SMS
                                                </div>
                                            </Menu.Item>
                                        </Popover>

                                        <Menu.Item className={"customHover"} style={{fontWeight: 'bold'}} onClick={() => {
                                            // e.stopPropagation();
                                            // ref.select();
                                            // document.execCommand('copy');
                                            // // This is just personal preference.
                                            // // I prefer to not show the whole text area selected.
                                            // e.target.focus();
                                            copyToClipboard(publicLink);
                                            message.success("Link Copied");
                                        }}>
                                            <div style={{width: '100%'}}>
                                                <LinkOutlined style={{color: '#e040fb', fontSize: 18, cursor: 'pointer'}}
                                                              onClick={(e) => {
                                                              }}/>
                                                &nbsp;&nbsp;Copy Link
                                            </div>
                                        </Menu.Item>
                                    </Menu>
                                }>
                                    <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                        <Button className={"btnCustom4"}
                                                style={{
                                                    background: '#e8f5e9',
                                                    color: '#1b5e20',
                                                    padding: 0,
                                                    height: 28,
                                                    fontWeight: 'bold',
                                                    fontSize: 13,
                                                    paddingLeft: 8,
                                                    paddingRight: 8
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                        ><ShareAltOutlined/> Send
                                        </Button>
                                    </span>
                                </Dropdown>
                                &nbsp;

                            </>
                        }
                            <Button className={"btnCustom4 hoverOrangeBorder"}
                                    style={{
                                        background: '#ffecb3',
                                        color: '#ff6f00',
                                        padding: 0,
                                        height: 28,
                                        fontWeight: 'bold',
                                        fontSize: 13,
                                        paddingLeft: 8,
                                        paddingRight: 8
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        console.log("## Btn Clicked ##");
                                        if (voucherType === "receipt") {
                                            console.log("## Dispatching ##");
                                            dispatch({
                                                popData: {
                                                    visible: true,
                                                    type: "receiptWrite",
                                                    title: `Payment Details`,
                                                    data: voucher,
                                                }
                                            });
                                        }
                                    }}
                            ><EditOutlined style={{color: '#ff6f00'}} /> Edit
                            </Button>

                        {/*</Button>*/}
                        &nbsp;
                        <Popconfirm
                            title="Are you sure to delete this document ?"
                            onConfirm={async (e) => {
                                e.stopPropagation();
                                deleteVoucher(obj._id)
                                    .then(() => {
                                        message.success('Document Deleted');
                                    })
                                    .catch(() => message.error('Failed to delete document'))
                            }}
                            onCancel={(e) => {
                                e.stopPropagation();
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                                <Button className={"btnCustom4 hoverRedBorder"}
                                        style={{
                                            background: '#ffebee',
                                            color: '#b71c1c',
                                            padding: 0,
                                            height: 28,
                                            fontWeight: 'bold',
                                            fontSize: 13,
                                            paddingLeft: 8,
                                            paddingRight: 8
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                ><DeleteOutlined style={{color: '#b71c1c'}} onClick={e => e.stopPropagation()}/> Delete
                                </Button>
                        </Popconfirm>
                    </div>
                </>
            }


            {
                // true &&
                <div style={{marginTop: 16}}>
                    <h3>Attachments <Tooltip title={"Attachments can be used to store customer-signed receivings, purchase bills, or other related document."}><InfoCircleOutlined/></Tooltip></h3>
                    <VoucherAttachments value={cachedUpdatedAttachments[voucher?._id] || voucher?.attachments || []}
                                        onChange={async (attachments) => {
                                            if (!voucher?._id) {
                                                return;
                                            }
                                            cachedUpdatedAttachments[voucher._id] = attachments;
                                            await saveVoucher({
                                                voucher: {
                                                    _id: voucher._id,
                                                    attachments,
                                                }
                                            })
                                        }}
                    />
                </div>
            }

            {
                voucher.privateNote &&
                <div style={{position: 'fixed', bottom: 32}}>
                    <b>Private Note</b>:&nbsp;
                    <i>
                        {voucher.privateNote}
                    </i>
                </div>
            }

        </div>
    )
};

export default DrawerInvoice;
