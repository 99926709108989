import React from "react";
import {PRIMARY_COLOR} from "../../../models/constants";
import {Col, Row} from "antd";
import {ShoppingCartOutlined} from "@ant-design/icons";
import {beautifyAmount, isMobileView} from "../../../utils";
import { getCart } from "../../../services/cookies";
import { useNavigate } from "react-router-dom";
import { getTotalCost } from '../cartTotal';


const CartFooter = ({id}) => {
    const isMobile = isMobileView();
    const cart = getCart();
    const navigate = useNavigate();


    let cartTotal= getTotalCost();
    


    return (
        <div style={{position: 'fixed', bottom: isMobile ? 0 : 12, width: '100%', textAlign: 'center'}}>
            <center>
                <div onClick={() => {
                    navigate(`/store/${id}/cart`);
                }} style={{
                    cursor: 'pointer',
                    fontSize:  isMobile ? 13 : 16,
                    fontWeight: 'bold',
                    maxWidth: '10cm',
                    width: '100%',
                    background: PRIMARY_COLOR,
                    color: 'white',
                    padding: 8,
                    paddingTop: 12,
                    paddingBottom: 12,
                    borderRadius: isMobile ? 0 : 200
                }}>
                    {/*<Badge size="default" count={5}>*/}
                    {/*    <Avatar icon={<ShoppingCartOutlined/>} size="large" style={{backgroundColor: PRIMARY_COLOR}} />*/}
                    {/*</Badge>*/}
                    <Row>
                        <Col span={12} style={{borderRight: '1px solid white'}}>
                            {cart.length} items &nbsp;&nbsp;&#9679;&nbsp;&nbsp; ₹ {beautifyAmount(cartTotal)}
                        </Col>
                        <Col span={12}>
                            <ShoppingCartOutlined/>&nbsp;&nbsp;
                            View Cart
                        </Col>
                    </Row>
                    {/*<ShoppingCartOutlined/> &nbsp;&nbsp;&nbsp;*/}
                    {/*View Cart (₹ 700)*/}
                </div>
            </center>
        </div>
    )
};

export default CartFooter;
