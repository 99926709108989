import React, {useEffect, useState} from "react";
import {Layout, Menu} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {Link, Outlet, useParams} from "react-router-dom";

const {Sider, Content} = Layout;


const SubscriptionProduct = () => {

    const params = useParams();
    const [selectedKey, setSelectedKey] = useState();
    useEffect(() => {
        setSelectedKey(params["*"]?.split("/")[1]);
    }, [params]);

    return (
        <div>
            <Layout>
                <Sider width={160} className="site-layout-background">
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={[selectedKey]}
                        style={{height: '100%', borderRight: 0}}
                        selectedKeys={[selectedKey]}
                    >
                        <Menu.Item key={"home"} icon={<UserOutlined/>}>
                            <Link to={"/subscription/home"}>Home</Link>
                        </Menu.Item>
                        <Menu.Item key={"data"} icon={<UserOutlined/>}>
                            <Link to={"/subscription/data"}>Subscriptions</Link>
                        </Menu.Item>
                        <Menu.Item key={"users"} icon={<UserOutlined/>}>
                            <Link to={"/subscription/users"}>Customers</Link>
                        </Menu.Item>
                        <Menu.Item key={"products"} icon={<UserOutlined/>}>
                            <Link to={"/subscription/products"}>Products</Link>
                        </Menu.Item>
                        <Menu.Item key={"sales"} icon={<UserOutlined/>}>
                            <Link to={"/subscription/sales"}>Renewals</Link>
                        </Menu.Item>
                        <Menu.Item key={"settings"} icon={<UserOutlined/>}>
                            <Link to={"/subscription/settings"}>Settings</Link>
                        </Menu.Item>

                    </Menu>
                </Sider>
                <Layout style={{padding: '0 24px 24px'}}>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}
                    >
                        <Outlet/>
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
};

export default SubscriptionProduct;
