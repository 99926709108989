import React, {useEffect, useState} from 'react';
import {Outlet, Link, useNavigate, useParams} from "react-router-dom";
import {Layout, Dropdown, Menu, Button, Row, Col, Avatar, message} from 'antd';
import {
    DollarOutlined,
    ShopOutlined,
    UserOutlined,
    DashboardOutlined,
    ContainerOutlined,
    SettingOutlined,
    FileTextOutlined,
    LogoutOutlined,
    DownOutlined,
    FileDoneOutlined,
    ShoppingCartOutlined,
    MoneyCollectOutlined,
    CustomerServiceFilled,
    PlusOutlined,
    ThunderboltFilled,
    RocketOutlined,
    CarOutlined,
    DollarCircleFilled,
    UserSwitchOutlined,
    GoldOutlined,
    LineChartOutlined,
    AccountBookOutlined,
    FileSyncOutlined,
    FileOutlined,
    QuestionCircleOutlined,
    RadiusUprightOutlined,
    UsergroupAddOutlined,
    HistoryOutlined, RetweetOutlined, RobotOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import _ from "lodash";
import logo from "../res/img/logoLandscapeV3.png";
import erpLogo from "../res/img/logoipsum.svg";
import {useDispatch, useSelector} from "react-redux";
import Cookies from "js-cookie";
import {loginRequest} from "../services/api";
import {customDispatcher, getPlan, isErpWeb, isPuc, planNamesMap, SUBSCRIPTIONS} from "../utils";
import PlanError from "./PlanError";
import MenuDivider from "antd/es/menu/MenuDivider";
import {verifyRole} from "../erp/utils";
import {ROLES} from "../erp/models/constant";

const {Sider, Header} = Layout;

const MainPage = ({setSiderCollapsed}) => {
    const puc = isPuc();
    const isErp = isErpWeb();
    const params = useParams();
    const [selectedKey, setSelectedKey] = useState();

    const navigate = useNavigate();
    const dispatch = customDispatcher(useDispatch());
    const state = useSelector(state => state.oldState);

    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        const urlPart = params["*"]?.split("/");
        const _selectedKey = urlPart[0] !== "vouchers" ? urlPart[0] : urlPart[1];
        setSelectedKey(_selectedKey);
    }, [params]);

    useEffect(() => {
        setSiderCollapsed(collapsed);
    }, [collapsed, setSiderCollapsed]);

    const [apiCalled, setApiCalled] = useState(false);

    const logoutAction = () => {
        Cookies.set("invUser", "");
        Cookies.set("invPass", "");
        navigate("/login");
    }

    useEffect(() => {
        if (state?.user) {
            if (isErpWeb() && !verifyRole(ROLES.WEB_LOGIN, state?.user)) {
                message.error(`You don't have Web Login permission. Please contact admin.`);
                logoutAction();
            }
        }
    }, [state]);

    useEffect(() => {
        if (!apiCalled) {
            setApiCalled(true);
            const currentPass = Cookies.get('invPass');
            const currentUser = Cookies.get('invUser');
            if (!currentUser || !currentPass) {
                navigate("/login");
            } else {
                const tryLogin = async () => {
                    const user = await loginRequest(currentUser, currentPass, dispatch);
                    if (user) { // logged in
                    } else { // auth failed
                        navigate("/login");
                    }
                }
                tryLogin();
            }
        }
    }, [dispatch, navigate, apiCalled]);

    const isValidPlan = [SUBSCRIPTIONS.FREE, SUBSCRIPTIONS.PREMIUM].includes(getPlan(state?.company));

    const quickLinks = [
        {title: "Sale Invoice", icon: <FileDoneOutlined style={{fontSize: 18, color: 'red'}}/>, link: `/vouchers/invoice/create`},
        {title: "Proforma Invoice", icon: <FileTextOutlined style={{fontSize: 18, color: 'blue'}}/>, link: "/vouchers/proforma/create"},
        {title: "Delivery Challan", icon: <CarOutlined style={{fontSize: 18, color: 'purple'}}/>, link: "/vouchers/delivery/create"},
        {title: "Expense", icon: <DollarCircleFilled style={{fontSize: 18, color: 'red'}}/>, onClick: () => {
                dispatch({
                    popData: {
                        visible: true,
                        type: "paymentWrite",
                        title: "Record Payment Out",
                    }
                });
            }},
        {title: "Payment Receipt", icon: <DollarCircleFilled style={{fontSize: 18, color: 'green'}}/>, onClick: () => {
                dispatch({
                    popData: {
                        visible: true,
                        type: "receiptWrite",
                        title: "Record Payment",
                    }
                });
            }},
    ];

    return (
        <Layout style={{minHeight: '100vh'}}>
            <Header className="" style={{
                position: 'fixed',
                zIndex: 100,
                width: '100%',
                padding: 0,
                background: "#fff" ,//"#09203E", // "#fff",
                height: '40px',
                lineHeight: '40px',
                borderBottom: '2px solid grey'
            }}>
                <>
                    <Row>
                        <Col span={12}>
                            <div style={{float: 'left', minWidth: 200}}>
                                <div style={{
                                    height: 40,
                                    verticalAlign: 'middle',
                                    padding: 4,
                                    paddingTop: 0,
                                    paddingLeft: 32
                                }} className="logo">
                                    <img style={{padding: 4}} alt={"logo"} className="imgLogo" src={!isErp ? logo : erpLogo}/>
                                </div>
                            </div>
                            {
                                !isErp &&
                                <>
                                <Button className={"hoverGreenBackground"} style={{
                                    marginLeft: 24,
                                    borderRadius: 4,
                                    // color: 'white',
                                    border: 'none', padding: 0,
                                    height: 22, fontWeight: 'bold', fontSize: 14, paddingLeft: 8, paddingRight: 8
                                }}
                                        onClick={() => {
                                            const msg = puc ? "Hello, I need some help on PUC Software." :
                                                "Hello, I need some help on KhataBuddy platform.";
                                            const url = `https://api.whatsapp.com/send/?phone=91${"8700260932"}&text=${encodeURI(msg)}`;
                                            window.open(url, '_blank');
                                        }}
                                ><CustomerServiceFilled/> Help/Support</Button>
                                &emsp;
                                </>
                            }
                            {

                                puc ? <>
                                        <Button style={{
                                            background: 'darkslateblue',
                                            borderRadius: 4,
                                            color: 'white',
                                            border: 'none',
                                            padding: 0,
                                            height: 22,
                                            fontWeight: 'bold',
                                            fontSize: 14,
                                            paddingLeft: 8,
                                            paddingRight: 8
                                        }}
                                                onClick={() => {
                                                    navigate("/puc");
                                                }}
                                        ><PlusOutlined/> Add New Renewal
                                        </Button>
                                    </> :

                                    (
                                        !isErp &&
                                            <>
                                                <Dropdown overlay={
                                                    <Menu className={"menuWrapper"}>
                                                        {
                                                            quickLinks.map(q => (
                                                                <Menu.Item className={"customHover"} style={{fontWeight: 'bold'}} onClick={(menuInfo) => {
                                                                    if (q.link) {
                                                                        navigate(q.link);
                                                                    } else if (q.onClick) {
                                                                        q.onClick();
                                                                    }
                                                                }}>
                                                                    {q.icon}
                                                                    &nbsp;&nbsp;
                                                                    {q.title}
                                                                </Menu.Item>
                                                            ))
                                                        }
                                                    </Menu>
                                                }>
                                        <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                            <Button className={"btnCustom2"}
                                                    style={{
                                                        // background: 'darkslateblue',
                                                        // borderRadius: 4,
                                                        // color: 'white',
                                                        border: 'none',
                                                        padding: 0,
                                                        height: 22,
                                                        // fontWeight: 'bold',
                                                        fontSize: 14,
                                                        paddingLeft: 8,
                                                        paddingRight: 8
                                                    }}
                                                    onClick={() => {
                                                        // const msg = "Hello, I need some help on KhataBuddy platform.";
                                                        // const url = `https://api.whatsapp.com/send/?phone=91${"8700260932"}&text=${encodeURI(msg)}`;
                                                        // window.open(url, '_blank');
                                                    }}
                                            ><ThunderboltFilled/> Create
                                                <DownOutlined style={{fontSize: 12}}/>
                                            </Button>
                                        </span>
                                                </Dropdown>
                                            </>
                                    )

                            }
                        </Col>
                        <Col span={12}>
                            <div style={{textAlign: 'right', verticalAlign: 'middle', paddingRight: 24}}>
                                {
                                    !isErp &&
                                    <span style={{color: "#777"}}>{planNamesMap[getPlan(state?.company)]}
                                    {
                                        getPlan(state?.company) !== SUBSCRIPTIONS.PREMIUM &&
                                        <>
                                            {/*&emsp;*/}
                                            &nbsp;
                                            <Link to={"/settings/subscription"}>
                                                <Button style={{
                                                    background: 'lavender',
                                                    // color: 'white',
                                                    borderRadius: 4,
                                                    border: 'none',
                                                    padding: 0,
                                                    height: 22,
                                                    fontWeight: 'bold',
                                                    fontSize: 14,
                                                    paddingLeft: 8,
                                                    paddingRight: 8
                                                }}
                                                        onClick={() => {
                                                            // const msg = "Hello, I need some help on KhataBuddy platform.";
                                                            // const url = `https://api.whatsapp.com/send/?phone=91${"8700260932"}&text=${encodeURI(msg)}`;
                                                            // window.open(url, '_blank');
                                                        }}
                                                >
                                                    <RocketOutlined /> Upgrade Now
                                                </Button>
                                            </Link>
                                        </>
                                    }

                                    &emsp;|&emsp;
                                </span>
                                }
                                {/*<Button style={{background: '#25D366', borderRadius: 4, color: 'white', border: 'none', padding: 0,*/}
                                {/*    height: 22, fontWeight: 'bold', fontSize: 12, paddingLeft: 8, paddingRight: 8*/}
                                {/*}}*/}
                                {/*onClick={() => {*/}
                                {/*    const msg = "Hello, I need some help on KhataBuddy platform.";*/}
                                {/*    const url = `https://api.whatsapp.com/send/?phone=91${"8700260932"}&text=${encodeURI(msg)}`;*/}
                                {/*    window.open(url, '_blank');*/}
                                {/*}}*/}
                                {/*><CustomerServiceFilled /> Help/Support</Button>*/}
                                {/*&emsp;|*/}
                                <Dropdown overlay={
                                    <Menu className={"menuWrapper"}>
                                        {
                                            !puc && !isErp &&
                                            <Link to={"/settings/profile"}>
                                            <Menu.Item className={"customHover"}>
                                                <UserSwitchOutlined />&nbsp;&nbsp;Switch Company
                                            </Menu.Item>
                                            </Link>
                                        }
                                        <Link to={(puc || isErp) ? "/settings/profile" : "/settings/company"}>
                                        <Menu.Item className={"customHover"}>
                                            <SettingOutlined/>
                                            &nbsp;&nbsp;
                                            {!puc && !isErp && "Company"}
                                                &nbsp;Settings
                                        {/*</a>*/}
                                        </Menu.Item>
                                    </Link>
                                        <Menu.Item className={"customHover"} onClick={() => {
                                            logoutAction();
                                        }} danger><LogoutOutlined />&nbsp;&nbsp;Logout</Menu.Item>
                                    </Menu>
                                }>
                                    <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                        <strong>{(puc || isErp) ? state?.user?.businessName : state?.company?.name}</strong>&nbsp;
                                        <Avatar style={{backgroundColor: '#7265e6', verticalAlign: 'middle'}}
                                                size="small">
                                            {state?.user?.name?.falseAlways ? state.user.name.substr(0, 1) :
                                                <UserOutlined/>}
                                            {/*falseAlways - just added to make condition always false*/}
                                        </Avatar>
                                        {/*<DownOutlined />*/}
                                    </span>
                                </Dropdown>
                                {/*&emsp;*/}
                                {/*<Link to={"/settings/profile"}><Avatar style={{ backgroundColor: '#7265e6', verticalAlign: 'middle' }} size="small" gap={0}>N</Avatar><SettingFilled style={{fontSize: 20, color: "#999999"}}/></Link>*/}
                            </div>
                        </Col>
                    </Row>

                </>
            </Header>
            <Layout style={{marginTop: 40}}>
            <Sider theme={"light"} style={{border: "0px solid blue",
                // background: 'black',
                overflow: 'scroll',
                // height: '100vh',
                height: '100%',
                position: 'fixed', left: 0}} collapsible collapsed={collapsed} onCollapse={setCollapsed}>
                {/*<div className="logo"><img className="imgLogo" src={logo}/></div>*/}

                {/*<div style={{textAlign: 'center', marginBottom: 50, color: 'white'}}>*/}
                    {/*{`Hi ${state?.user?.name || "---"}`}*/}
                {/*</div>*/}

                <Menu className={"sideMenu"} theme="light" defaultSelectedKeys={[selectedKey]} selectedKeys={[selectedKey]} mode="inline"
                      style={{
                          // background: 'black',
                          // paddingBottom: 120,
                          paddingLeft: 2,
                          paddingRight: 2,
                          paddingBottom: 88,
                          // border: '2px solid green',
                          fontWeight: 'normal', fontSize: 16}}>


                    {
                        isErp &&
                            <>
                                <Menu.Item className={"sideMenuItem"} key="installations"
                                           icon={<FileDoneOutlined style={{fontSize: 16}}/>}>
                                    <Link to="/installations">Installations</Link>
                                </Menu.Item>
                                {/*<Menu.Item className={"sideMenuItem"} key="devices"*/}
                                {/*           icon={<GoldOutlined style={{fontSize: 16}}/>}>*/}
                                {/*    <Link to="/devices">Devices</Link>*/}
                                {/*</Menu.Item>*/}
                                <Menu.Item className={"sideMenuItem"} key="erp_inv"
                                           icon={<GoldOutlined style={{fontSize: 16}}/>}>
                                    <Link to="/erp_inv/sale/new">Inventory</Link>
                                </Menu.Item>
                                <Menu.Item className={"sideMenuItem"} key="sims"
                                           icon={<RadiusUprightOutlined style={{fontSize: 16}}/>}>
                                    <Link to="/sims">Sims</Link>
                                </Menu.Item>
                                <Menu.Item className={"sideMenuItem"} key="mappings"
                                           icon={<RetweetOutlined style={{fontSize: 16}}/>}>
                                    <Link to="/mappings">Mappings</Link>
                                </Menu.Item>
                                <Menu.Item className={"sideMenuItem"} key="parties"
                                           icon={<UsergroupAddOutlined style={{fontSize: 16}}/>}>
                                    <Link to="/parties">Parties</Link>
                                </Menu.Item>
                                {
                                    verifyRole(ROLES.ACCESS_LOGS, state.user) &&
                                    <Menu.Item className={"sideMenuItem"} key="logs"
                                               icon={<HistoryOutlined style={{fontSize: 16}}/>}>
                                        <Link to="/logs">Logs</Link>
                                    </Menu.Item>
                                }
                            </>
                    }

                    {
                        !isErp &&
                        <Menu.Item className={"sideMenuItem"} key="dashboard"
                                   icon={<DashboardOutlined style={{fontSize: 16}}/>}>
                            <Link to="/dashboard">{puc ? "Renewals" : "Dashboard"}</Link>
                            {/*Dashboard*/}
                        </Menu.Item>
                    }
                    {
                        !puc && !isErp && <>

                            {
                                state?.company?.enableSubAlwaysFalse &&
                                <Menu.Item key="subscription" icon={<DollarOutlined style={{fontSize: 16}}/>}>
                                    <Link to="/subscription/home">Subscriptions</Link>
                                </Menu.Item>
                            }
                            {/*<Menu.Item key="dues" icon={<BarChartOutlined style={{fontSize: 16}}/>}>*/}
                            {/*    <Link to="/dues">Outstandings</Link>*/}
                            {/*</Menu.Item>*/}
                            <Menu.Item className={"sideMenuItem"} key="ledgers" icon={<AccountBookOutlined style={{fontSize: 16}}/>}>
                                <Link to="/ledgers/debtor">Ledgers</Link>
                            </Menu.Item>
                            <MenuDivider/>
                            <Menu.Item className={"sideMenuItem"} key="invoice" icon={<FileDoneOutlined style={{fontSize: 16}}/>}>
                                <Link to="/vouchers/invoice">Sale Invoices</Link>
                            </Menu.Item>
                            <Menu.Item className={"sideMenuItem"} key="payments"
                                       icon={<DollarOutlined style={{fontSize: 16}}/>}
                            >
                                <Link to="/payments">Payments</Link>
                                {/*ABCD*/}
                            </Menu.Item>
                            {/*<Menu.SubMenu title="Other Vouchers"  icon={<FileDoneOutlined style={{fontSize: 16}}/>}>*/}
                                <Menu.Item className={"sideMenuItem"} key="proforma" icon={<FileTextOutlined style={{fontSize: 16}}/>}>
                                    <Link to="/vouchers/proforma">Proforma Invoices</Link>
                                </Menu.Item>
                                <Menu.Item key="purchase" icon={<ShoppingCartOutlined style={{fontSize: 16}}/>}>
                                    <Link to="/vouchers/purchase">Purchases</Link>
                                </Menu.Item>
                                <Menu.Item className={"sideMenuItem"} key="creditNote" icon={<FileSyncOutlined style={{fontSize: 16}}/>}>
                                    <Link to="/vouchers/creditNote">Credit Notes</Link>
                                </Menu.Item>
                                <Menu.Item className={"sideMenuItem"} key="debitNote" icon={<FileOutlined style={{fontSize: 16}}/>}>
                                    <Link to="/vouchers/debitNote">Debit Notes</Link>
                                </Menu.Item>
                                <Menu.Item className={"sideMenuItem"} key="delivery" icon={<CarOutlined style={{fontSize: 16}}/>}>
                                    <Link to="/vouchers/delivery">Delivery Challans</Link>
                                </Menu.Item>
                            {/*</Menu.SubMenu>*/}
                            <MenuDivider/>
                            <Menu.Item className={"sideMenuItem"} key="items" icon={<GoldOutlined style={{fontSize: 16}}/>}>
                                <Link to="/items">Items</Link>
                            </Menu.Item>
                            <Menu.Item className={"sideMenuItem"} key="inventory" icon={<ContainerOutlined style={{fontSize: 16}}/>}>
                                <Link to="/inventory/warehouse">Inventory</Link>
                            </Menu.Item>
                            {
                                _.get(state, 'company.chatbotOptions.enabled') &&
                                    <>
                                        <Menu.Item className={"sideMenuItem"} key="portal" icon={<RobotOutlined style={{fontSize: 16}}/>}>
                                            <Link to="/portal/chatbot/8010666611/messages">Portal</Link>
                                        </Menu.Item>
                                    </>
                            }
                            <Menu.Item className={"sideMenuItem"} key="ecommerce" icon={<ShopOutlined style={{fontSize: 16}}/>}>
                                <Link to="/ecommerce/home">Ecommerce Store</Link>
                            </Menu.Item>
                            <Menu.Item className={"sideMenuItem"} key="pg" icon={<MoneyCollectOutlined style={{fontSize: 16}}/>}>
                                <Link to="/pg/transactions">Payment Gateway</Link>
                            </Menu.Item>
                            {/*<Menu.Item key="receipt" icon={<DollarOutlined style={{fontSize: 16}}/>}>*/}
                            {/*    <Link to="/vouchers/receipt">Payment Receipts</Link>*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item key="payment" icon={<DollarOutlined style={{fontSize: 16}}/>}>*/}
                            {/*    <Link to="/vouchers/payment">Payments Made</Link>*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item key="expense" icon={<DollarOutlined style={{fontSize: 16}}/>}>*/}
                            {/*    <Link to="/vouchers/expense">Expenses</Link>*/}
                            {/*</Menu.Item>*/}

                            {/*<Menu.Item key="clients" icon={<TeamOutlined/>}>*/}
                            {/*    <Link to="/clients">Clients</Link>*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item key="masters" icon={<TeamOutlined style={{fontSize: 16}}/>}>*/}
                            {/*    <Link to="/masters/items">Masters</Link>*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item key="items" icon={<CodeSandboxOutlined/>}>*/}
                            {/*    <Link to="/items">Items</Link>*/}
                            {/*</Menu.Item>*/}
                            <Menu.Item className={"sideMenuItem"} key="reports" icon={<LineChartOutlined style={{fontSize: 16}}/>}>
                                <Link to="/reports/export">Reports</Link>
                            </Menu.Item>
                            {/*<Menu.Item key="ecommerce" icon={<ShopOutlined/>}>*/}
                            {/*    <Link to="/ecommerce/home">Ecommerce Store</Link>*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item key="collect" icon={<MoneyCollectOutlined/>}>*/}
                            {/*    <Link to="/collect/home">Collect Payments</Link>*/}
                            {/*</Menu.Item>*/}
                        </>
                    }
                    {
                        puc && <>
                            <Menu.Item className={"sideMenuItem"} key="vehicles" icon={<CarOutlined style={{fontSize: 16}}/>}>
                                <Link to="/vehicles">Vehicles</Link>
                            </Menu.Item>
                        </>
                    }
                    <Menu.Item className={"sideMenuItem"} key="settings" icon={<SettingOutlined style={{fontSize: 16}}/>}>
                        <Link to={(puc || isErp) ? "/settings/profile" : "/settings/company"}>Settings</Link>
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout className="site-layout" style={{
                // background: 'white',
                marginLeft: collapsed ? 80 : 200, borderLeft: '1px solid lightgrey'}}>
                {
                    (isValidPlan || (params["*"].includes("settings") || params["*"].includes("help"))) ? <Outlet/> :
                        <PlanError/>
                }
            </Layout>
            </Layout>
        </Layout>
    );
}

export default MainPage;
