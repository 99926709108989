import React, {useEffect, useMemo, useState} from "react";
import {Button, Card, Col, Form, message, Row, Select, Statistic, Table, Tag, Upload} from "antd";
import BulkUpload from "../../../erp/components/upload/BulkUpload";
import {getLedgersList, postLedgerRecordUpdate, validateBankStatement} from "../../../services/api/portal";
import moment from "moment";
import {beautifyAmount} from "../../../utils";
import {RUPEE} from "../../../models/constants";
import {SYMBOLS} from "../../../erp/models/constant";
import {portalContants} from "../../../models/constants/portalContants";
import {CheckCircleFilled, CloseCircleFilled, EyeOutlined, ReloadOutlined, UploadOutlined} from "@ant-design/icons";

const ledgerOptions = [
    {label: "Kotak Bank - CSV", value: "kotakCsv"},
    {label: "Paytm Bank - CSV", value: "paytmBankCsv"},
    {label: "Paytm Wallet - CSV", value: "paytmWalletCsv"},
];


const labelsMap = {
    missing: {
        title: "Missing",
        color: "red",
    },
    suspense: {
        title: "Suspense",
        color: "orange",
    },
    valid: {
        title: "Valid",
        color: "green",
    },
    extra: {
        title: "Extra",
        color: "magenta",
    },
}

const actionsMap = {
    "valid": {
        btnLabel: "",
        action: "",
    },
    "missing": {
        btnLabel: "Add",
        action: "add",
    },
    "suspense": {
        btnLabel: "Save",
        action: "save",
    },
    "extra": {
        btnLabel: "Delete",
        action: "delete",
    }
}

const ActionsComponent = ({validator, ledgersList, ledgerKey, record}) => {
    const [form] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = useState();

    const action = actionsMap[record.label];
    const selectedOtherPartyId = (record.type === "DR" ? record.ledgerCr : record.ledgerDr) || portalContants.SUSPENSE_LEDGER_ID;

    useEffect(() => {
        form.setFieldsValue({otherPartyLedgerId: selectedOtherPartyId})
    }, [record]);

    return <div id={`actions_my_row_${record.constraintId || record.tid}`}>
        <Form
            form={form}
            layout={"inline"}
            style={{
                width: 350
            }}
            initialValues={{
                otherPartyLedgerId: selectedOtherPartyId,
            }}
            onFinish={async (values) => {
                try {
                    setIsSubmitting(true);
                    if (!values.otherPartyLedgerId) {
                        message.error(`Please select Other Party Ledger`);
                        return;
                    }
                    console.log("## Updating entry ##", values, ledgerKey);
                    const response = await postLedgerRecordUpdate({
                        action: action.action,
                        record,
                        otherPartyLedgerId: values.otherPartyLedgerId,
                        ledgerKey,
                    });
                    if (!response.data.success) {
                        message.error(response.data.message);
                        return;
                    }
                    message.success(response.data.message);
                    await validator();
                } catch(err) {
                } finally {
                    setIsSubmitting(false);
                }
            }}
        >
            <Form.Item style={{padding: 0, margin: 0}} name={"otherPartyLedgerId"}>
            <Select
                id={`select_my_row_${record.constraintId || record.tid}`}
                disabled={["valid", "extra"].includes(record.label)}
                options={ledgersList.map(entity => ({
                    value: entity.TID,
                    label: entity.Name,
                }))}
                style={{
                    width: 270
                }}
                filterOption={(input, option) => {
                    return (
                        option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                        option.value.toString()
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    );
                }}
                showSearch={true}
            />
            </Form.Item>
            &nbsp;
            {
                action.btnLabel ?
                <Button loading={isSubmitting} htmlType={"submit"} type={"primary"}>{action.btnLabel}</Button>
                    : "--"
            }
        </Form>
    </div>
}


const allowedFileTypes = [
    "application/pdf",
    // "image/jpeg",
    // "image/png",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",

];



const BankingPage = () => {
    const [form] = Form.useForm();
    const [processing, setProcessing] = useState(false);
    const [response, setResponse] = useState();
    const [ledgersList, setLedgersList] = useState();
    const [availableFiles, setAvailableFiles] = useState([]);
    const [ledgerKey, setLedgerKey] = useState();
    const [ledgerName, setLedgerName] = useState("");
    const [formValues, setFormValues] = useState({});
    const [loadingFiles, setLoadingFiles] = useState(false);

    const loadFiles = async () => {
        setLoadingFiles(true);
        await getLedgersList().then(response => {
            setLedgersList(response.data.data?.ledgers);
            setAvailableFiles(response.data.data?.availableFiles);
        });
        setLoadingFiles(false);
    }

    useEffect(() => {
        loadFiles();
    }, []);

    const columns = useMemo(() => {
        return [
            {title: "S.No.", render: (obj, a, index) => (index+1).toString()},
            {title: "Label", render: (obj) => <Tag color={labelsMap[obj.label].color}>{labelsMap[obj.label].title}</Tag> },
            {title: "Date", render: (obj) => moment(obj.date).format("DD MMM")},
            {title: "Amount", render: (obj) => <div style={{fontWeight: 'bold', color: obj.type === "DR" ? "green" : "red"}}>
                    {SYMBOLS.RUPEE}&nbsp;{obj.type==="DR" ? "": "-"}{beautifyAmount(obj.amount)}
                </div>},
            {title: "Remarks", dataIndex: "remarks"},
            // {title: "Other Party", dataIndex: "otherParty"},
            {title: "Actions", render: (obj) => <>
                    <ActionsComponent validator={async () => {
                        const response = await validateBankStatement(formValues);
                        if (!response.data.success) {
                            message.error(`Failed to process File, Invalid Data`);
                            return;
                        }
                        setResponse(response.data?.data || {});
                    }} ledgerKey={ledgerKey} record={obj} ledgersList={ledgersList} />
                </>},
        ];
    }, [ledgersList, ledgerKey, formValues, setResponse, response])

    return (
        <div style={{padding: 30}}>
            <h2>TrakInfinity Portal - Banking Reconcilation</h2>
            <Row>
                <Col span={20}>
                    <Form
                        layout={"inline"}
                        form={form}
                        onFinish={async (values) => {
                            try {
                                setProcessing(true);
                                // setLedgerKey(values.ledgerKey);
                                setFormValues(values);

                                const response = await validateBankStatement(values);
                                if (!response.data.success) {
                                    message.error(`Failed to process File, Invalid Data`);
                                    return;
                                }
                                setLedgerKey(response.data?.data?.ledgerKey);
                                setLedgerName(response.data?.data?.ledgerName);
                                setResponse(response.data?.data || {});
                            } catch (err) {
                            } finally {
                                setProcessing(false);
                            }
                        }}
                    >
                        <Button
                            loading={loadingFiles}
                            onClick={() => {
                            loadFiles();
                        }}>
                            <ReloadOutlined/>
                        </Button>
                        &nbsp;
                        {/*<Form.Item name={"fileContent"} rules={[{required: true}]}>*/}
                        {/*<Upload*/}
                        {/*    accept={".csv"}*/}
                        {/*    maxCount={1}*/}
                        {/*    beforeUpload={(file) => {*/}
                        {/*        // processExcel(file);*/}
                        {/*        return false;*/}
                        {/*    }}*/}
                        {/*    onRemove={() => {*/}
                        {/*        // onChange(null);*/}
                        {/*    }}*/}
                        {/*    style={{width: 300}}*/}
                        {/*>*/}
                        {/*    <Button icon={<UploadOutlined/>}>Click to upload</Button>*/}
                        {/*</Upload>*/}

                        {/*<BulkUpload raw={true} v2Read={true}/>*/}


                        {/*</Form.Item>*/}
                        {/*<Form.Item name={"ledgerKey"} rules={[{required: true}]}>*/}
                        {/*    <Select*/}
                        {/*        options={ledgerOptions}*/}
                        {/*        options={availableFiles.map(x => ({label: x.fileName, value: x.fileName}))}*/}
                        {/*        style={{width: 300}}*/}
                        {/*        placeholder={"Select File"}*/}
                        {/*        onChange={(selectedLedgerKey) => {*/}
                        {/*            // console.log("## Selected Ledger Key ##", selectedLedgerKey);*/}
                        {/*            // setLedgerKey(selectedLedgerKey);*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</Form.Item>*/}
                        <Form.Item name={"fileToProcess"} rules={[{required: true}]}>
                            <Select
                                loading={loadingFiles}
                                // options={ledgerOptions}
                                options={availableFiles.map(x => ({label: x.fileName, value: x.fileName}))}
                                style={{width: 500}}
                                showSearch={true}
                                placeholder={"Select File"}
                                onChange={(selectedLedgerKey) => {
                                    // console.log("## Selected Ledger Key ##", selectedLedgerKey);
                                    // setLedgerKey(selectedLedgerKey);
                                }}
                            />
                        </Form.Item>
                        <Button
                            type={"primary"}
                            htmlType={"submit"}
                            loading={processing}
                        >
                            Validate Statement</Button>
                    </Form>
                </Col>
                <Col span={4} style={{border: '1px solid black', padding: 8}}>
                    <>
                        <Upload
                            accept={".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
                            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            action={`/api/internal/uploadCustom?type=banking`}
                            maxCount={1}
                            // onRemove={onFileRemove}
                            showUploadList={{
                                showRemoveIcon: true,
                            }}
                            beforeUpload={(file) => {
                                if (!allowedFileTypes.includes(file.type)) {
                                    message.error('You can only upload PDF or Excel file!');
                                    console.log("## Uploaded file type ##", file.type);
                                    file.status = "error";
                                    return false;
                                }
                                const isLt2M = file.size / 1024 / 1024 < 10;
                                if (!isLt2M) {
                                    message.error('File size must be smaller than 10 MB!');
                                    file.status = "error";
                                    return false;
                                }
                                return true;
                            }}
                            // listType="picture-card"
                            // fileList={fileList}
                            // onPreview={handlePreview}
                            // onChange={handleChange}
                        >
                            <Button icon={<UploadOutlined/>}>Click to upload</Button>
                        </Upload>

                    </>
                </Col>
            </Row>

            <br/>

            {
                response &&
                    <>
                        <Row style={{}}>
                            <Col span={6}>
                                <Card>
                                    <Statistic
                                        title="File Data"
                                        value={`${response?.duration || "--"}\n(${ledgerName})`}
                                        // precision={0}
                                        valueStyle={{ color: '#3f8600', fontSize: 18}}
                                    />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card>
                                    <Statistic
                                        title="Missing # Suspense # Extra # Valid"
                                        value={
                                                `${response?.counters?.missing || 0} # ${response?.counters?.suspense || 0} # ${response?.counters?.extra || 0} # ${response?.counters?.valid || 0}`
                                        }
                                        valueStyle={{ color: '#cf1322', fontSize: 18 }}
                                        suffix={(response?.counters?.missing || response?.counters?.suspense || response?.counters?.extra) ? <CloseCircleFilled color={"red"} size={20}/> : <CheckCircleFilled style={{color: 'green'}} color={"green"}/>}
                                    />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card>
                                    <Statistic
                                        title="Total Credit"
                                        value={response?.counters?.totalCr || 0}
                                        valueStyle={{ color: 'green', fontSize: 18 }} // #3f8600
                                        prefix={RUPEE}
                                    />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card>
                                    <Statistic
                                        title="Total Debit"
                                        value={response?.counters?.totalDr || 0}
                                        // precision={2}
                                        valueStyle={{ color:  'red', fontSize: 18 }}
                                        prefix={RUPEE}
                                    />
                                </Card>
                            </Col>
                        </Row>

                        <Table
                            columns={columns}
                            dataSource={(response?.records || []).sort((a, b) => {
                                return moment(a.date).diff(moment(b.date), 'minutes');
                            })}
                            pagination={false}
                        />
                    </>

            }
        </div>
    )
};

export default BankingPage;
