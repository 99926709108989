import React, {useEffect, useState} from "react";
import _ from "lodash";
import moment from "moment";
import {Button, Col, DatePicker, Form, Input, message, Radio, Row, Segmented, Select, Switch} from "antd";
import {convertArrayToMap, pucRenewalPlans} from "../../utils";
import {getPucVehicles, savePucSale} from "../../services/api";
import {useSelector} from "react-redux";
import { ButtonV2 } from "../../components/ButtonV2";

const PucRecord = () => {
    const state = useSelector(state => state.oldState);
    const [vehicles, setVehicles] = useState([]);
    const [isExisting, setIsExisting] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState(undefined);
    const [form] = Form.useForm();

    useEffect(() => {
        console.log("## Using Effect PucRecord ##");
        if (state?.company) {
            getPucVehicles().then(resp => setVehicles(resp.data.data.vehicles));
        }
        form.setFieldsValue({
            renewalDate: moment(),
            plan: "6_months",
            isPaid: true,
            engineType: "Petrol",
            registrationType: "Private",
        })
    }, [state, form]);

    useEffect(() => {
        form.resetFields(['vehicleNumber', 'customerName', 'customerContact']);
        setSelectedVehicle(undefined);
    }, [isExisting, form]);

    useEffect(() => {
        const vehiclesMap = convertArrayToMap(vehicles, "_id");
        if (selectedVehicle) {
            const vehicle = vehiclesMap[selectedVehicle];
            form.setFieldsValue({
                customerName: vehicle.customerName,
                customerContact: vehicle.customerContact,
                engineType: vehicle.engineType,
                registrationType: vehicle.registrationType
            })
        }
    }, [selectedVehicle, form, vehicles]);


    return (
        <div style={{padding: 24}}>
            <h2>Add New Renewal</h2>
            <Segmented onChange={(val) => setIsExisting(val === "Existing Vehicle")}
                       options={['New Vehicle', 'Existing Vehicle']}/>
            <Form
                form={form}
                layout={"vertical"}
                style={{maxWidth: 900, marginTop: 32}}
            >
                <Row>
                    <Col span={12} style={{paddingRight: 16}}>
                        <Form.Item name={"vehicleNumber"} label={"Registration No."}
                                   rules={[{required: true, message: "can't be blank"}]}>
                            {
                                isExisting ?
                                    <Select
                                        showSearch={true}
                                        filterOption={(input, option) => {
                                            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }}
                                        placeholder={"Select Vehicle"}
                                        options={vehicles.map(v => ({
                                            label: v.vehicleNumber,
                                            value: v._id
                                        }))}
                                        onChange={(val) => setSelectedVehicle(val)}
                                    /> :
                                    <Input/>
                            }
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={"registrationType"} label={"Registration Type"}>
                            <Radio.Group defaultValue="Private" buttonStyle="solid" disabled={isExisting}>
                                <Radio.Button value="Private">Private</Radio.Button>
                                <Radio.Button value="Commercial">Commercial</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={"engineType"} label={"Engine Type"}>
                            <Radio.Group defaultValue="Petrol" buttonStyle="solid" disabled={isExisting}>
                                <Radio.Button value="Petrol">Petrol</Radio.Button>
                                <Radio.Button value="Diesel">Diesel</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12} style={{paddingRight: 16}}>
                        <Form.Item name={"customerName"} label={"Customer Name"} rules={[{required: true, message: "can't be blank"}]}>
                            <Input disabled={isExisting}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={"customerContact"} label={"Customer Contact"} rules={[{required: true, message: "can't be blank"}]}>
                            <Input disabled={isExisting} maxLength={10}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{marginTop: 16}}>
                    <Col span={6} style={{paddingRight: 16}}>
                        <Form.Item name={"renewalDate"} label={"Renewal Date"}
                                   rules={[{required: true, message: "can't be blank"}]}>
                            <DatePicker format={"DD MMM YYYY"} allowClear={false} style={{width: '100%'}} defaultValue={moment()}/>
                        </Form.Item>
                    </Col>
                    <Col span={6} style={{paddingRight: 16}}>
                        <Form.Item name={"plan"} label={"Plan"} rules={[{required: true, message: "can't be blank"}]}>
                            <Select
                                options={pucRenewalPlans}
                                placeholder={"Select Plan"}
                                defaultValue={"6_months"}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={"isPaid"} label={"Payment Status"} rules={[{required: true, message: "can't be blank"}]}>
                            <Switch checkedChildren={"Paid"} unCheckedChildren={"Pending"} defaultChecked={true} />
                        </Form.Item>

                    </Col>
                </Row>
            </Form>
            <ButtonV2 type={"primary"}
                    onClick={async () => {
                        const formData = await form.validateFields();
                        console.log("## formData ##", formData);

                        const apiData = {
                            isExisting,
                            pucVehicle: _.pick(formData,
                                ["vehicleNumber", "engineType", "registrationType", "customerName", "customerContact"]),
                            pucRenewal: _.pick(formData, ["remarks", "isPaid", "renewalDate", "plan"])
                        };
                        if (isExisting) {
                            apiData.pucRenewal.vehicleId = formData.vehicleNumber;
                        }
                        console.log("## apiData ##", apiData);
                        // return;
                        const resp = await savePucSale(apiData);
                        if (resp.data.success) {
                            message.success("Renewal saved successfully");
                            form.resetFields(["vehicleNumber", "customerName", "customerContact"]);
                        } else {
                            message.error(resp.data.message);
                        }
                    }}
            >
                Save
            </ButtonV2>
        </div>
    )
};

export default PucRecord;
