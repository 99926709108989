import React from "react";

Array.prototype.joinComponents = function (separator = <>&emsp;</>) {
    const ret = [this[0]];
    for (let i = 1; i< this.length; i++) {
        ret.push(separator);
        ret.push(this[i]);
    }

    return ret;
};