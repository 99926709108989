import React from "react";
import {Link} from "react-router-dom";
import {Button, Result} from "antd";

const PlanError = ({msg}) => {
    if (!msg) {
        msg = "This feature is not available in your current subscription plan."
        // msg = "Your current subscription is valid only for Mobile App.";
    }

    return (
        <div>
            <Result
                status="warning"
                // title="There are some problems with your operation."
                title={msg}
                extra={
                    <Link to={"/settings/subscription"}>
                        <Button type="primary" key="console">
                            Upgrade Subscription Plan
                        </Button>
                    </Link>
                }
            />
        </div>
    )
};

export default PlanError;
