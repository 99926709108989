import React from "react";

const ScSubscriptionPage = () => {

    return (
        <div>
            ScSubscriptionPage Component
        </div>
    )
};

export default ScSubscriptionPage;
