import React from 'react';
import ReactDOM from 'react-dom';
import { Provider} from 'react-redux'
import store from './services/store'
import reportWebVitals from './reportWebVitals';
import EntryComponent from "./views/entry";
import './views/preview_styles.css';
import './index.css';
import './erp/models/prototypeOverrides';

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <EntryComponent/>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
