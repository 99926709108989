import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
} from "antd";

import {useDispatch} from "react-redux";
import { loginRequest, stockInOut } from "../../services/api";
import {
  customDispatcher,
} from "../../utils";

const DrawerStockInOut = ({ closeDrawer, stock, stockIn, onSave = () => {} }) => {
  console.log({ stock });
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const dispatch = customDispatcher(useDispatch());

  useEffect(() => {
    form.resetFields();
    if (stock) {
      form.setFieldsValue({
        name: stock.name,
        date: moment(stock.date),
      });
    } else {
      form.setFieldsValue({
        date: moment(),
      });
    }
  }, [stock, form]);

  return (
    <div>
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 6,
          style: {
            textAlign: "left",
          },
        }}
        wrapperCol={{ span: 16 }}
        onFinish={async (data) => {
          setSaving(true);
          const response = await stockInOut({
            itemId: stock._id,
            qty: stockIn ? data.qty : -data.qty,
            remarks: data.remarks,
            date: data.date,
          });
          await loginRequest("", "", dispatch);
          if (!response.data.success) {
            message.error(response.data.message);
            setSaving(false);
            return;
          }

          message.success("Inventory Record Saved");
          setSaving(false);
          closeDrawer();
          form.resetFields();
          form.setFieldsValue({
            date: moment(),
          });
        }}
        onFinishFailed={(err) => {
          console.log("## Finish Failed ##", err);
        }}
        autoComplete="off"
      >
        <Row>
          <Form.Item
            labelCol={{
              span: 12,
              style: {
                textAlign: "left",
              },
            }}
            name={"name"}
            label={"Name"}
          >
            <Input style={{marginLeft: '-47px', width: '347px'}} disabled={true} value={stock.name}/>
          </Form.Item>
        </Row>

        <Form.Item
          name="qty"
          label="Qty"
          rules={[
            {
              required: true,
              message: "Quantity cannot be less than 1",
            },
          ]}
        >
          <InputNumber
            type="number"
            min={1}
            max={100000000}
            style={{ fontWeight: "bold", width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          name={"remarks"}
          label={"Remarks"}
          rules={[
            {
              required: true,
              message: "Maximum 100 words",
              max: 100,
            },
          ]}
        >
          <Input.TextArea rows={2} placeholder={"Write remark"} />
        </Form.Item>

        <Row>
          <Col span={12}>
            <Form.Item
              labelCol={{
                span: 12,
                style: {
                  textAlign: "left",
                },
              }}
              wrapperCol={{ span: 12 }}
              name="date"
              label="Record Date"
              rules={[
                {
                  required: true,
                  message: "Please select Date",
                },
              ]}
            >
              <DatePicker format={"DD MMM YYYY"} allowClear={false} />
            </Form.Item>
          </Col>
        </Row>

        {/*<Form.Item wrapperCol={{ offset: 8, span: 16 }}>*/}
        <Button type="primary" htmlType="submit" loading={saving}>
          {stockIn ? "Add Stock" : "Remove Stock"}
        </Button>
        {/*</Form.Item>*/}
      </Form>
    </div>
  );
};

export default DrawerStockInOut;
