import React  from "react";

const PucConfig = () => {

    return (
        <div>
            PucConfig Component
        </div>
    )
};

export default PucConfig;
