import React from "react";

const ItemPage = () => {

    return (
        <div>
            ItemPage Component
        </div>
    )
};

export default ItemPage;
