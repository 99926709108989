import React, {useEffect, useState} from 'react';
import {
    Table,
    Button,
    Popconfirm,
    message,
    Row,
    Col,
    Tabs,
    Tooltip, Input
} from 'antd';
import {getLedgers, saveLedger, deleteLedger} from '../../services/api';
import _ from "lodash";
import {states} from "../../models/constants";
import {useDispatch, useSelector} from "react-redux";
import {
    DeleteOutlined,
    EditOutlined,
    HistoryOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import {useNavigate, useParams} from "react-router-dom";
import {beautifyAmount, customDispatcher} from "../../utils";

const { TabPane } = Tabs;

const fetchRecords = async ({ledgerType, setLoading, setRecords}) => {
    setLoading(true);
    const response = await getLedgers(ledgerType);
    const resOffers = _.get(response, 'data.data.ledgers', []);
    setRecords(resOffers);
    setLoading(false);
}

const Ledgers = () => {
    const state = useSelector(state => state.oldState);
    const dispatch = customDispatcher(useDispatch());
    const navigate = useNavigate();
    const params = useParams();
    const {ledgerType} = params;
    const [loading, setLoading] = useState(true);
    const [records, setRecords] = useState([]);
    const [searchText, setSearchText] = useState("");

    let title;
    switch (ledgerType) {
        case "debtor":
            title = "Customer";
            break;
        case "creditor":
            title = "Vendor";
            break;
        case "cash":
            title = "Cash-in-hand Ledger";
            break;
        case "expense":
            title = "Expense Ledger";
            break;
        default:
            break;
    }

    const columns = [
        {
            title: 'Name',
            weight: 1,
            include: ["debtor", "creditor", "expense", "cash"],
            render: obj => <b>{obj.name}</b>
        },
        {
            title: "Contact Info",
            weight: 1,
            include: ["debtor", "creditor"],
            render: (obj) => <>
                {obj.phone ? <>{obj.phone}<br/></> : <></>}
                <div style={{fontSize: 12, color: '#333'}}>
                    {obj.email}
                </div>
            </>
        },

        {
            title: 'State',
            weight: 1,
            include: ["debtor", "creditor"],
            // dataIndex: 'state',
            render: (obj) => states[obj.country || "IN"][obj.state]?.name || "---"
        },
        {
            title: 'GSTIN',
            weight: 1,
            include: ["debtor", "creditor"],
            dataIndex: 'gstin',
            // render: (obj) => <Image width={75} src={obj.img || "error"} fallback="https://static.thenounproject.com/png/340719-200.png"/>
        },
        {
            title: "Closing Balance",
            weight: 1,
            include: ["debtor", "creditor", "expense", "cash"],
            render: ({balance=0}) => <b style={{color: 'green'}}>₹ {beautifyAmount(balance)}</b>,
        },
        {
            title: 'Actions',
            weight: 1,
            include: ["debtor", "creditor", "expense", "cash"],
            render: (obj) => {
                return (

                    <div style={{fontSize: 20}}>


                                <Button className={"btnCustom3"}
                                        style={{
                                            padding: 0,
                                            height: 28,
                                            fontSize: 13,
                                            fontWeight: 'bold',
                                            paddingLeft: 8,
                                            paddingRight: 8,
                                            background: '#e8eaf6',
                                            color: '#283593'
                                        }}
                                        onClick={(e) => {
                                            navigate(`/ledgers/statement/${obj._id}`);
                                        }}
                                ><HistoryOutlined/> Statement
                                </Button>
                                &nbsp;


                        <Tooltip title={"Edit"}>
                            <Button className={"btnCustom4 hoverOrangeBorder"}
                                    style={{
                                        background: '#ffecb3',
                                        color: '#ff6f00',
                                        padding: 0,
                                        height: 28,
                                        fontWeight: 'bold',
                                        fontSize: 13,
                                        paddingLeft: 8,
                                        paddingRight: 8
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        console.log("## Btn Clicked ##");
                                        openCustomerDetailsDrawer(obj);
                                    }}
                            ><EditOutlined style={{color: '#ff6f00'}} /> Edit
                            </Button>
                        </Tooltip>

                        {/*</Button>*/}
                        &nbsp;
                        <Popconfirm
                            title="Are you sure to delete this ledger ?"
                            onConfirm={async (e) => {
                                const resp = await deleteLedger(obj._id);
                                if (resp.data.success) {
                                    message.success(`${title} Deleted`);
                                    fetchRecords({ledgerType, setRecords, setLoading});
                                } else {
                                    message.error(resp.data.message);
                                }
                            }}
                            onCancel={(e) => {
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Tooltip title={"Delete"}>
                                <Button className={"btnCustom4 hoverRedBorder"}
                                        style={{
                                            background: '#ffebee',
                                            color: '#b71c1c',
                                            padding: 0,
                                            height: 28,
                                            fontWeight: 'bold',
                                            fontSize: 13,
                                            paddingLeft: 8,
                                            paddingRight: 8
                                        }}
                                        onClick={(e) => {
                                            // e.stopPropagation();
                                            // message.info("Feature under maintenance");
                                        }}
                                ><DeleteOutlined style={{color: '#b71c1c'}} onClick={() => {}}/>
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </div>
                )
            }
        },
    ].filter(c => c.include.includes(ledgerType));


    useEffect(() => {
        if (state.company) {
            fetchRecords({ledgerType, setRecords, setLoading});
        }
    }, [state, ledgerType,]);

    const openCustomerDetailsDrawer = (selectedLedger) => dispatch({
        popData: {
            visible: true,
            width: 550,
            type: "ledger",
            title: selectedLedger ? `Edit ${title}` : `Add New ${title}`,
            data: {
                selectedRecord: selectedLedger ? selectedLedger : {
                    type: ledgerType,
                    title,
                },
                onSave: async (values) => {
                    const isNewLedger = !selectedLedger;
                    const baseLedger = isNewLedger ? {} : selectedLedger;
                    _.assign(baseLedger, values);
                    try {
                        await saveLedger(baseLedger);
                        await fetchRecords({ledgerType, setRecords, setLoading});
                        message.success("Customer Saved Successfully");
                    } catch (err) {
                        message.error('Failed to save customer');
                        console.log("Failed to save customer", err);
                    }
                }
            },
            bodyStyle: {
                padding: 16,
            }
        }
    });


    return (
        <div style={{padding: 24, paddingTop: 8}}>
            <Row justify={"center"} align={"middle"}>
                <Col span={12} style={{fontSize: 24, fontWeight: 'bold'}}>
                    Ledgers
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                        <>
                            <Input.Search onChange={(e) => setSearchText(e.target.value)}
                                   placeholder={"Search with Name"}
                                   style={{width: 'auto', minWidth: 300,
                                       // height: 40
                                   }}
                                   allowClear/>
                            &nbsp;&nbsp;&nbsp;
                            <Button type={"primary"} onClick={() => {
                                // message.info("Feature under maintenance");
                                openCustomerDetailsDrawer();
                                // navigate(`/vouchers/${voucherType}/create`)
                            }} style={{
                                // background: 'darkslateblue',
                                // color: 'white',
                                borderRadius: 6,
                                fontWeight: 'bold',
                            }}>
                                <PlusOutlined style={{}}/> New {title}
                            </Button>
                        </>

                </Col>
            </Row>

            <Row>
                <Col span={16}>
            <Tabs className={"tabsLedgers"} activeKey={ledgerType} onChange={(newLedgerType) => navigate(`/ledgers/${newLedgerType}`)}>
                <TabPane className={"tabsLedgers"} tab={<div style={{fontSize: 15}}>Customers</div>} key="debtor">
                    {/*Content of Tab Pane 1*/}
                </TabPane>
                <TabPane tab={<div style={{fontSize: 15}}>Vendors</div>} key="creditor">
                    {/*This is totally new content for 2nd Tab*/}
                </TabPane>
                <TabPane tab={<div style={{fontSize: 15}}>Expenses</div>} key="expense">
                    {/*Content of Tab Pane 3*/}
                </TabPane>
                <TabPane tab={<div style={{fontSize: 15}}>Cash-in-hand</div>} key="cash">
                    {/*Content of Tab Pane 3*/}
                </TabPane>
            </Tabs>
                </Col>
                <Col span={8} style={{textAlign: 'right'}}>

                </Col>
            </Row>

            {/*<div style={{padding: 16, paddingLeft: 0, paddingRight: 0}}>*/}
            {/*    <h2 style={{float: 'left'}}>{title}</h2>*/}
            {/*    <Button onClick={() => {*/}
            {/*        setSelectedRecord({*/}
            {/*            type*/}
            {/*        });*/}
            {/*        setShowModal(true);*/}
            {/*    }} style={{float: 'right'}} type={"primary"}>*/}
            {/*        Create New Ledger</Button>*/}
            {/*</div>*/}

            <Table
                loading={loading}
                columns={columns}
                dataSource={records.filter(r => (r.name || "").toLowerCase().includes(searchText.toLowerCase()))}
            />

        </div>
    );
}

export default Ledgers;
