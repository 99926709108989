import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Divider,
  Form,
  Input,
  Switch,
  Button, message
} from "antd";
import { useSelector,useDispatch } from "react-redux";
import {loginRequest } from "../../services/api";
import { updateEcommerceSettings } from "../../services/api/ecommerce";
import {customDispatcher} from "../../utils";

const Settings = () => {
  const {TextArea} = Input
  const [form] = Form.useForm();
  const state = useSelector(state=> state.oldState);
  const dispatch = customDispatcher(useDispatch());
  const [acceptOnlineOrder, setAcceptOnlineOrders] = useState();
  const [businessDescription, setBusinessDescription]= useState();

  const updateSettings = async (settings)=>{
    try{
      console.log(settings, 'Settigns..')
      let obj = {
        enabled: settings.acceptOnlineOrders,
        businessDescription: settings.description,
        minOrderValue: settings.minimumOrderValue
      }
      await updateEcommerceSettings({ecommerceOptions:obj});
      await loginRequest("", "", dispatch);
      message.success('Settings updated successfully');
      form.setFieldsValue(settings);
    }catch(e){
      console.log(e, 'error')
    }

  }


  useEffect(()=>{

    if(state.company){
     setAcceptOnlineOrders(state.company.ecommerceOptions?.enabled || false);
     setBusinessDescription(state.company.ecommerceOptions?.businessDescription|| '');


     form.setFieldsValue({
       "acceptOnlineOrders": state.company.ecommerceOptions?.enabled || false,
       "description": state.company.ecommerceOptions?.businessDescription|| '',
       "minimumOrderValue":state.company.ecommerceOptions?.minOrderValue || 0
     })
    }
  },[state]);

  return (
    <div>
      <div style={{ paddingTop: 8 }}>
        <Row justify={"left"} align={"left"}>
          <Col span={12} style={{ fontSize: 24, fontWeight: "bold" }}>
            Ecommerce Store
          </Col>
        </Row>
      </div>
      <Divider style={{ marginBottom: 18 }} />
      <div>
        <Form
          name="basic"
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="Accept Online Orders"
            name="acceptOnlineOrders"
            rules={[
              {
                required: true,
                message: "Accept Online Orders",
              },
            ]}
          >
            <Switch
              defaultChecked
              onChange={(val) => {
                setAcceptOnlineOrders(val);
              }}
            />
          </Form.Item>
          {/* <Form.Item
            label="Minimum Order Value"
            name="minimumOrderValue"
            rules={[
              {
                required: true,
                message: "Minimum Order Value",
              },
            ]}
          >
            <InputNumber min={1} defaultValue={minOnlineOrder} onChange={(e)=>{
              setMinOnlineOrder(e);
            }} />
          </Form.Item> */}
          {/* <Form.Item
            label="Delivery Charges"
            name="deliveryCharges"
            rules={[
              {
                required: true,
                message: "Delivery Charges",
              },
            ]}
          >
             <InputNumber min={1} defaultValue={delivery} onChange={(e)=>{
              setDeliveryCharges(e);
            }} />
          </Form.Item> */}
          <Form.Item
            label="Business Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Please add your business description",
              },
            ]}
          >
            <TextArea
              value={businessDescription}
              onChange={(e) => setBusinessDescription(e.target.value)}
              placeholder="Business Description"
              autoSize={{
                minRows: 3,
                maxRows: 5,
              }}
            />
          </Form.Item>
          <Button
            type="primary"
            onClick={() => {
              form
                .validateFields()
                .then((record) => {
                  updateSettings(record);
                })
                .catch(() => {});
            }}
          >Submit</Button>
        </Form>
      </div>
    </div>
  );
};

export default Settings;
