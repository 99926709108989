import React, {useEffect, useState} from "react";
import {Spin} from "antd";

const DrawerPreview = ({documentId, options = {type: "voucher"}, selectedTags, disableTags}) => {
    const [isLoading, setIsLoading] = useState(false);

    const urlSeparater = documentId && documentId.includes("?") ? "&" : "?";

    const srcUrl = `/api/internal/pdf/${documentId}${urlSeparater}copies=${disableTags ? "original" : selectedTags.join(",")}&type=${options.type}&from=${options.from}&to=${options.to}`;

    useEffect(() => {
        setIsLoading(true);
    }, [srcUrl, selectedTags])

    return (
        <div style={{height: "100%"}}>
            <Spin spinning={isLoading} tip={"Loading Invoice Preview"} style={{height: 500, border: '0px solid red', padding: 0}} wrapperClassName={"fullHeight"}>
                <iframe title={"Preview"} style={{height: '85vh'}} onLoad={() => setIsLoading(false)}
                        src={srcUrl} type="application/pdf" width="100%">
                </iframe>
            </Spin>
        </div>
    )
};

export default DrawerPreview;
