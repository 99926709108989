import React, {useEffect, useState} from 'react';
import {Table, Button, Popconfirm, message, Row, Col} from 'antd';
import {deleteItem, loginRequest} from '../../services/api';
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {beautifyAmount, customDispatcher} from "../../utils";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {RUPEE} from "../../models/constants";


const Items = () => {
    const state = useSelector(state => state.oldState);
    const dispatch = customDispatcher(useDispatch());
    const [records, setRecords] = useState([]);

    const openItemDrawer = (selectedRecord) => dispatch({
        popData: {
            visible: true,
            width: 550,
            type: "item",
            title: selectedRecord ? "Edit Item" : "Add New Item",
            bodyStyle: {
                padding: 16,
            },
            data: {
                selectedRecord: selectedRecord || {
                    type: "Product",
                    unit: "NOS",
                    
                },
            }
        }
    });


    const columns = [
        {
            title: 'Name',
            render: obj => <strong>{obj.name}</strong>
            // to add HSN
        },
        {
            title: "Type",
            render: obj =>  obj.type || "Product",
        },
        {
            title: "HSN",
            render: obj =>  obj.hsn || "",
        },
        {
            title: "GST Rate",
            render: obj => `${obj.taxRate}%`
        },
        {
            title: "Selling Price",
            render: obj => (
                <>
                    <div style={{fontWeight: 'bold', color: 'green'}}>
                        {RUPEE} {beautifyAmount(obj.defaultRate || 0)}
                    </div>
                    <div>

                    </div>
                </>
            )
        },
        {
            title: 'Actions',
            render: (obj) => {
                return (
                    <div>
                        <Button className={"btnCustom4 hoverOrangeBorder"}
                                style={{
                                    background: '#ffecb3',
                                    color: '#ff6f00',
                                    padding: 0,
                                    height: 28,
                                    fontWeight: 'bold',
                                    fontSize: 13,
                                    paddingLeft: 8,
                                    paddingRight: 8
                                }}
                                onClick={(e) => {
                                    openItemDrawer(obj);
                                }}
                        ><EditOutlined style={{color: '#ff6f00'}} /> Edit</Button>
                        <Popconfirm
                            title="Are you sure to delete this item ?"
                            onConfirm={async () => {
                                deleteItem(obj._id)
                                    .then(async (resp) => {
                                        if (_.get(resp, 'data.success')) {
                                            message.success('Item Deleted');
                                            await loginRequest("", "", dispatch);
                                            setRecords(_.cloneDeep(state.items))
                                        } else {
                                            message.error(_.get(resp, 'data.message'));
                                        }
                                    })
                                    .catch(() => message.error('Failed to delete item'))
                            }}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                        >
                            &nbsp;
                            <Button className={"btnCustom4 hoverRedBorder"}
                                    style={{
                                        background: '#ffebee',
                                        color: '#b71c1c',
                                        padding: 0,
                                        height: 28,
                                        fontWeight: 'bold',
                                        fontSize: 13,
                                        paddingLeft: 8,
                                        paddingRight: 8
                                    }}
                                    onClick={(e) => {
                                        // e.stopPropagation();
                                        // message.info("Feature under maintenance");
                                    }}
                            ><DeleteOutlined style={{color: '#b71c1c'}} onClick={() => {}}/> Delete
                            </Button>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ];

    useEffect(() => {
        setRecords(state.items);
    }, [state, setRecords]);

    return (
        <div style={{padding: 24, paddingTop: 8}}>
            <Row justify={"center"} align={"middle"}>
                <Col span={12} style={{fontSize: 24, fontWeight: 'bold'}}>
                    Items
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                        <Button type={"primary"} onClick={() => {
                            openItemDrawer(false);
                        }} style={{
                            // background: 'darkslateblue',
                            // color: 'white',
                            borderRadius: 6,
                            fontWeight: 'bold',
                        }}>
                            <PlusOutlined /> New Item
                        </Button>
                </Col>
            </Row>
            <br/>
            <Table
                columns={columns}
                dataSource={records}
            />

        </div>
    );
}

export default Items;
