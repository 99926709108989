import React, {useEffect, useState} from "react";
import {Button, Col, Input, Row, Select, Table, Tabs, Tag} from "antd";
import {PlusOutlined, TransactionOutlined} from "@ant-design/icons";
import {getSims} from "../../services/api";
import {customDispatcher} from "../../../utils";
import {useDispatch} from "react-redux";
import moment from "moment";
import {validSimStatuses} from "../../models/constant";

const defaultPagination = {
    current: 1,
    total: 10,
    pageSize: 10,
};

const SimsPage = () => {
    const dispatch = customDispatcher(useDispatch());
    const [data, setData] = useState({});
    const [pagination, setPagination] = useState(defaultPagination);
    const {sims: records = [], simProviders = []} = data;
    // const [parties, setParties] = useState([]);

    const [loading, setLoading] = useState(true);
    const [filterProvider, setFilterProvider] = useState();
    const [filterStatus, setFilterStatus] = useState();
    const [filterSearch, setFilterSearch] = useState("");

    // const [searchText, setSearchText] = useState("");


    const fetchRecords = async (isPaginationTrigger = false) => {
        // getParties().then(resp => setParties(resp.data.data.entities.filter(entity => entity.type !== "M2M Account")));
        const paginationParams = isPaginationTrigger ? pagination : defaultPagination;
        setLoading(true);
        const params = {};
        if (filterProvider) {
            params.provider = filterProvider;
        }
        if (filterStatus) {
            params.status = filterStatus;
        }
        if (filterSearch) {
            params.search = filterSearch;
        }

        await getSims({
            ...paginationParams,
            ...params,
        }).then(resp => setData(resp.data.data));
        setLoading(false);
        if (!isPaginationTrigger) {
            setPagination(defaultPagination);
        }
    }

    useEffect(() => {
        fetchRecords();
    }, [filterStatus, filterProvider]);

    useEffect(() => {
        fetchRecords(true);
    }, [pagination]);

    const columns = [
        {
            title: "Date Added",
            render: obj => moment(obj.dateAdded).format("DD MMM YYYY"),
        },
        {
            title: "Sim Provider",
            render: obj => obj.simProvider?.name || "---",
        },
        {
            title: "Sim Number",
            dataIndex: "simNumber",
        },
        {
            title: "Mobile Number",
            dataIndex: "mobileNumber",
        },
        {
            title: "Status",
            dataIndex: "status",
        },
    ];

    const openDrawer = () => {
        dispatch({
            popData: {
                visible: true,
                type: "bulkSims",
                width: 500,
                title: `Bulk Add/ Update Sims`,
                // segments: ["Manual", "Bulk"],
                onSave: fetchRecords,
                // data: obj,
            }
        });
    }

    return (
        <div style={{padding: 24, paddingTop: 8}}>
            <Row justify={"center"} align={"middle"}>
                <Col span={12} style={{fontSize: 24, fontWeight: 'bold'}}>
                    Sim Cards
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <>
                        <Button type={"primary"} onClick={() => {
                            openDrawer();
                        }} style={{
                            // background: 'darkslateblue',
                            // color: 'white',
                            borderRadius: 6,
                            fontWeight: 'bold',
                        }}>
                            <PlusOutlined style={{}}/> Bulk Upload
                        </Button>

                    </>

                </Col>
            </Row>


            <Row>
                <Select
                    placeholder={"Operator-wise"}
                    style={{width: 200}}
                    options={
                        simProviders.map(p => ({
                            label: p.name,
                            value: p._id
                        }))
                    }
                    allowClear={true}
                    showSearch={true}
                    onChange={setFilterProvider}
                />
                &nbsp;
                <Select
                    placeholder={"Status-wise"}
                    style={{width: 200}}
                    options={
                        validSimStatuses.map(s => ({
                            label: s,
                            value: s,
                        }))
                    }
                    allowClear={true}
                    showSearch={true}
                    onChange={setFilterStatus}
                />
                &nbsp;
                <Input.Search
                    style={{width: 300}}
                    placeholder={"Search using Sim/ Mobile #"}
                    enterButton={true}
                    onChange={(e) => setFilterSearch(e.target.value)}
                    onSearch={fetchRecords}
                />
            </Row>

            <br/>

            <Table
                loading={loading}
                columns={columns}
                pagination={{...pagination, total: data.count || records.length || 0}}
                onChange={setPagination}
                dataSource={records.filter(r => true || (r.name || "").toLowerCase().includes("searchText".toLowerCase()))}
            />

        </div>
    );
};

export default SimsPage;
