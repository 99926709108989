import {prodAppConfig} from "./prod";
import {stagingAppConfig} from "./staging";
import {isProdEnv} from "../utils";

const configsMap = {
    prod: prodAppConfig,
    staging: stagingAppConfig,
}


const currentEnv = isProdEnv() ? "prod" : "staging";

export const appConfig = configsMap[currentEnv];