import React, {useEffect, useState} from "react";
import {Button, Col, Input, message, Row, Table, Tabs} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {customDispatcher} from "../../../utils";
import {useDispatch} from "react-redux";
import {deleteDeviceModel, getDeviceModels} from "../../../erp/services/api";
import EditButton from "../../../erp/components/buttons/EditButton";
import DeleteButton from "../../../erp/components/buttons/DeleteButton";
import {loginRequest} from "../../../services/api";

const ErpInvItemsPage = () => {
    const dispatch = customDispatcher(useDispatch());
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");

    const fetchRecords = async () => {
        const response = await getDeviceModels();
        setRecords(response.data.data.deviceModels);
    }

    useEffect(async () => {
        fetchRecords();
    }, []);

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "SMS Configuration Commands (for GPS H/W Models)",
            render: record => (record.smsCommands || "").split("\n").map(cmd => <div>{cmd}</div>),
        },
        {
            title: "Actions",
            render: (record) => <>
                <EditButton label={"Edit"} onClick={() => {
                    openDeviceWriteDrawer(record);
                }}/>
                &nbsp;
                <DeleteButton onClick={async () => {
                    await deleteDeviceModel(record._id);
                    message.success(`HW Model deleted successfully`);
                    fetchRecords();
                }}/>
            </>
        }
    ];

    const openDeviceWriteDrawer = (record) => {
        const isNew = !record;
        dispatch({
            popData: {
                visible: true,
                type: "deviceModelWrite",
                // width: 600,
                size: "medium",
                title: isNew ? `Add Item` : `Edit Item`,
                onSave: async () => {
                    await loginRequest("", "", dispatch);
                    await fetchRecords();
                },
                record,
                // segments: ["Manual", "Bulk"]
                // data: obj,
            }
        })
    }

    return (
        <div style={{
            padding: 24, paddingTop: 8
        }}>
            <Row justify={"center"} align={"middle"}>
                <Col span={12} style={{fontSize: 24, fontWeight: 'bold'}}>
                    Items (or Hardware Device Models)
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <>
                        <Button type={"primary"} onClick={() => {
                            openDeviceWriteDrawer();
                        }} style={{
                            // background: 'darkslateblue',
                            // color: 'white',
                            borderRadius: 6,
                            fontWeight: 'bold',
                        }}>
                            <PlusOutlined style={{}}/> New Item
                        </Button>
                    </>

                </Col>
            </Row>

            {/*<Row>*/}
            {/*    <Input.Search onChange={(e) => setSearchText(e.target.value)}*/}
            {/*                  placeholder={"Search with Name"}*/}
            {/*                  style={{width: 'auto', minWidth: 300,*/}
            {/*                      // height: 40*/}
            {/*                  }}*/}
            {/*                  allowClear/>*/}

            {/*</Row>*/}

            {/*<div style={{padding: 16, paddingLeft: 0, paddingRight: 0}}>*/}
            {/*    <h2 style={{float: 'left'}}>{title}</h2>*/}
            {/*    <Button onClick={() => {*/}
            {/*        setSelectedRecord({*/}
            {/*            type*/}
            {/*        });*/}
            {/*        setShowModal(true);*/}
            {/*    }} style={{float: 'right'}} type={"primary"}>*/}
            {/*        Create New Ledger</Button>*/}
            {/*</div>*/}

            <br/>
            <Table
                loading={loading}
                columns={columns}
                dataSource={records.filter(r => true || (r.name || "").toLowerCase().includes("searchText".toLowerCase()))}
            />

        </div>
    );
};

export default ErpInvItemsPage;
