import React from "react";
import {Form, DatePicker, Select, message} from "antd";
import {baseUrl, externalApiInstance} from "../../services/api";
import moment from "moment";
import {useSelector} from "react-redux";
import { ButtonV2 } from "../../components/ButtonV2";
import { getDateRange } from "../../services/cookies";
import { getPresetDateRange } from "../../services/functions";

const {RangePicker} = DatePicker;

const ExportData = () => {

    const state = useSelector(state => state.oldState);
    const [form] = Form.useForm();
    const dateRange = getDateRange()
    const presetDateRange = {...getPresetDateRange()}
    delete presetDateRange['Today']
    return (
        <div>
            <h2>Export Reports</h2>
            <Form
                form={form}
                layout={"vertical"}
                initialValues={{}}
            >
                <Form.Item name={"dateRange"} label={"Date Range"}
                           rules={[{required: true, message: "can't be blank"}]}>
                    <RangePicker 
                        picker="month" 
                        ranges={presetDateRange}
                        defaultValue={dateRange}
                    />
                </Form.Item>

                <Form.Item name={"type"} label={"Export Type"} style={{width: 400}}
                           rules={[{required: true, message: "can't be blank"}]}>
                    <Select
                        placeholder={"Select Export Type"}
                        options={[
                            {label: "GST Report (Excel)", value: "gstExcel"},
                            {label: "All Sales (Excel)", value: "allSales"},
                            {label: "GSTR-1 JSON", value: "gstr1Json"},
                            // {label: "GST Report (JSON for GST Portal)", value: "gstJson"},
                            // {label: "Transactions for TallyERP", value: "tallyTransactions"},
                            // {label: "Masters for TallyERP", value: "tallyMasters"},
                            // {label: "Items for TallyERP", value: "tallyItems"},
                            {label: "Export for TallyERP", value: "tallyErp"},
                        ]}
                    />
                </Form.Item>

                <ButtonV2 type={"primary"} onClick={async () => {
                    const data = await form.validateFields();
                    console.log("## Data ##", data);
                    
                    let blob;
                    try {
                        const params = {
                            company: state?.company?._id,
                            type: data.type,
                            from: data.dateRange[0].startOf('month').valueOf(),
                            to: data.dateRange[1].endOf('month').valueOf()
                        };
                        // const response = await generateReportsFile(params);
                        const response = await externalApiInstance.get(`${baseUrl}/internal/export?` + new URLSearchParams(params), {
                            responseType: 'blob'
                        });
                        // console.log("## Response ##", response.status, response);
                        blob = response.data;
                    } catch(err) {
                        message.error(err.response?.headers?.custommsg || "Something went wrong, Please contact Customer Support");
                        return;
                    }

                    // return;
                    // generateReportsFile({
                    //     company: state?.company?._id,
                    //     type: data.type,
                    //     from: data.dateRange[0].startOf('month').valueOf(),
                    //     to: data.dateRange[1].endOf('month').valueOf()
                    // })
                    //     .then((response) => {
                    //         // console.log("## Response ##", response);
                    //         if (response.status !== 200) {
                    //             throw new Error(response);
                    //         }
                    //         return response.data; //.blob();
                    //     })
                    //     .catch(err => {
                    //         console.log("## ERR IN EXPORT ##", Object.keys(err));
                    //         message.error(err.message);
                    //     })
                    //     .then((blob) => {
                            // Create blob link to download
                            const url = window.URL.createObjectURL(
                                new Blob([blob]),
                            );
                            console.log("url is",url)
                            const link = document.createElement('a');
                            link.href = url;
                            const timeStr = moment().format("DDMMYYYYHHmmss");
                            let fileName;
                            if (data.type === "gstExcel") {
                                fileName = `GST_Reports_${timeStr}.zip`
                            } else if (data.type === "gstr1Json") {
                                fileName = `gstr_1_${timeStr}.json`;
                            } else if (data.type.startsWith("tally")) {
                                fileName = `kbExportForTally_${Date.now()%1000}.zip`;
                            } else {
                                fileName = `Sales_${timeStr}.xlsx`;
                            }
                            link.setAttribute(
                                'download',
                                fileName
                            );
                            // Append to html link element page
                            document.body.appendChild(link);
                            // Start download
                            link.click();
                            // Clean up and remove the link
                            link.parentNode.removeChild(link);
                        // });
                }}>Generate</ButtonV2>
            </Form>
        </div>
    )
};

export default ExportData;
