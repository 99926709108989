import React, {useEffect, useState} from "react";
import _ from "lodash";
import {Button, Form, Input, message, Select, Upload} from "antd";
import {bulkUploadSims, getParties, } from "../../../erp/services/api";
import BulkUpload from "../../../erp/components/upload/BulkUpload";
import {isValidM2MNumber, isValidSimNumber, isValidSimStatus} from "../../../erp/utils";

const sampleFileLink = `https://staging.khatabuddy.com/api/internal/file/sample_sims.xlsx`;

const BulkSimsDrawer = ({selectedSegment, closeDrawer, onSave}) => {

    const [parties, setParties] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        getParties().then(resp => setParties(resp.data.data.entities.filter(entity => entity.type === "M2M Account")));
    }, []);


    return (
        <div>
            <Form
                form={form}
                layout={"vertical"}
                onFinish={async (vals) => {
                    console.log("## FormUpload ##", vals);
                    try {
                        const records = vals.inputRecords.map(r => ({
                            simNumber: r["Sim Number"]?.toString?.(),
                            mobileNumber: r["Mobile Number"]?.toString?.(),
                            status: r["Status"]?.toString?.(),
                        })).filter(r => r.simNumber && r.mobileNumber && r.status);

                        if (!records.length) {
                            throw new Error(`No records found in file under valid headers, please check data format carefully`);
                        }

                        records.forEach((record, index) => {
                            // Validate all 3 fields::
                            if (!isValidSimNumber(record.simNumber)) {
                                throw new Error(`Invalid Sim Number [${record.simNumber}] at row ${index + 2}`);
                            } else if (!isValidM2MNumber(record.mobileNumber)) {
                                throw new Error(`Invalid Mobile Number [${record.mobileNumber}] at row ${index + 2}`);
                            } else if (!isValidSimStatus(record.status)) {
                                throw new Error(`Invalid Sim Status [${record.status}] at row ${index + 2}`);
                            }
                        });

                        if (records.length > 1000) {
                            throw new Error(`Max 1000 records can be processed at once`);
                        }

                        const resp = await bulkUploadSims({
                            simProvider: vals.simProvider,
                            records,
                        });

                        if (resp.data.success) {
                            message.success(`Bulk update of sims has been processed successfully`);
                            form.resetFields();
                            closeDrawer();
                            onSave();
                        } else {
                            message.error(resp.data.message);
                        }
                    } catch (err) {
                        console.log("## Bulk upload ERR ##", err);
                        message.error(`Error: ${err.message}`);
                    }
                }}
            >

                <Form.Item label={"Upload excel file"} name={"inputRecords"}
                           // rules={[{required: true, message: "can't be blank"}]}
                >
                    <BulkUpload headers={true}
                                description={
                                    <div>File should have 3 columns (names should be exactly same):
                                        <br/>
                                        <b>Sim Number</b>: Value must be 19 digit numeric sim number
                                        <br/>
                                        <b>Mobile Number</b>: Value must be 10 or 13 digit numeric mobile number
                                        <br/>
                                        <b>Status</b>: Value must be any one status from these: "Available", "Active", "Deactivated", "Safe Custody", "Temp Disconnected"
                                        <br/>
                                        <br/>
                                        <b>Note</b>: If sim doesn't exist, it will be added as new, otherwise it's status will be updated as per values.
                                        <br/>
                                        <i>(All case-sensitive)</i>
                                        <br/>
                                        Check <a href={sampleFileLink}>Sample File Format</a>
                                    </div>
                                }
                    />
                </Form.Item>


                <Form.Item label={"Sim Provider"} name={"simProvider"} rules={[{required: true, message: "Select Sim Provider / M2M Account"}]}>
                    <Select
                        placeholder={"Select M2M Account"}
                        options={
                            parties.map(model => ({
                                label: model.name,
                                value: model._id
                            }))
                        }
                    />

                </Form.Item>

                <Button type={"primary"} htmlType={"submit"}>
                    Upload & Process
                </Button>
            </Form>
        </div>
    )
};

export default BulkSimsDrawer;
