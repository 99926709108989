

import React, { useEffect, useState } from "react";
import { beautifyAmount, isMobileView } from "../../utils";
import BrandingHeader from "./components/BrandingHeader";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  PageHeader,
  Row,
  Tag,
} from "antd";

import CartItem from "./components/CartItem";
import { RUPEE } from "../../models/constants";

import {
  ClockCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import { fetchOrderBasedOnOrderId } from "../../services/api/ecommerce";

const CartPage = () => {
  const isMobile = isMobileView();
  const params = useParams();
  const navigate = useNavigate();

  const [cartTotal, setCartTotal] = useState(0);

  const order = params.orderId;

  const [isOrder, setIsOrder] = useState(false);

  const [cartProducts, setCartProducts] = useState([]);

  const [orderId, setOrderId] = useState();
  const [status, setOrderStatus] = useState();
  const [storeId, setStoreID] = useState();
  const [storeInformation, setStoreInformation]= useState();

  const [form] = Form.useForm();

  const orderStatus = {
    pending: {
      icon: <ClockCircleOutlined />,
      color: "warning",
    },
    cancelled: {
      icon: <CloseCircleOutlined />,
      color: "error",
    },
    completed: {
      icon: <CheckCircleOutlined />,
      color: "success",
    },
  };

  const fetchOrderOnOrderId = async (orderId) => {
    try {
      const response = await fetchOrderBasedOnOrderId(orderId);
      const order = _.get(response, "data.data.orders.0", false);
      setStoreInformation(order)
      setCartProducts(order.items);
      setOrderId(order.orderId);
      setOrderStatus(order.status);
      setCartTotal(order.amount);
      setStoreID(order.company);
      form.setFieldsValue({
        name: order.customerDetails.name,
        address: order.customerDetails.address,
        phone: order.customerDetails.phone,
      });
      if (!order) {
        throw new Error("No orders");
      }
    } catch (e) {
      console.log(e, "Error in Fetching orders");
    }
  };

  useEffect(() => {
    if (order) {
      setIsOrder(true);
      fetchOrderOnOrderId(order);
    }
  }, []);

  useEffect(() => {
    OrderSummary();
  }, [cartProducts]);

  const OrderSummary = () => (
    <>
      <Row align={"middle"}>
        <Col span={12} style={{ fontWeight: "bold", fontSize: 16 }}>
          Order Summary
        </Col>

        <Col
          span={12}
          style={{ textAlign: "right", color: "#444", fontSize: 14 }}
        >
          {cartProducts.length} items
        </Col>

        <Col span={24} style={{ fontWeight: "bold", fontSize: 16 }}>
          Order Id - KB-{orderId}
        </Col>

        <Col span={24} style={{ fontWeight: "bold", fontSize: 16 }}>
          Order Status -{" "}
          <span>
            <Tag
              color={orderStatus[status]?.color}
              icon={orderStatus[status]?.icon}
            >
              {status ? status.charAt(0).toUpperCase() + status.slice(1) : ""}
            </Tag>
          </span>
        </Col>
      </Row>

      {!isMobile && <Divider />}

      {cartProducts.map((item) => (
        <CartItem item={item} disabled={true} />
      ))}
    </>
  );

  const TotalAmount = () => (
    <div>
      <Row
        style={{
          marginTop: 12,
          background: isMobile ? "#fff" : "#eeeefa",
          borderRadius: 8,
          border: (isMobile ? "0" : "1") + "px solid lightgrey",
          padding: 16,
          fontSize: 16,
          fontWeight: "bold",
        }}
      >
        <Col span={12}>Total Amount</Col>

        <Col span={12} style={{ textAlign: "right" }}>
          {RUPEE} {beautifyAmount(cartTotal)}
        </Col>
      </Row>

      <div style={{ marginLeft: "35%", marginTop: "10px" }}>
        <Button
          type="primary"
          onClick={() => {
            navigate(`/store/${storeId}`);
          }}
        >
          Visit Store Home
        </Button>
      </div>
    </div>
  );

  const AddressForm = () => {
    return (
      <div
        style={{
          background: isMobile ? "#fff" : "#eeeefa",
          borderRadius: 8,
          border: (isMobile ? "0" : "1") + "px solid lightgrey",
          padding: 16,
        }}
      >
        <h3 style={{ fontWeight: "bold" }}>Delivery Address</h3>

        <Form form={form} disabled={true}>
          <Form.Item
            name={"name"}
            style={{ marginBottom: 12 }}
            required={true}
            rules={[
              {
                required: true,

                message: "Please enter your name!",
              },
            ]}
          >
            <Input
              placeholder={"Full Name"}
              style={{ borderRadius: 4, padding: 8 }}
              disabled={true}
            />
          </Form.Item>

          <Form.Item
            name={"phone"}
            style={{ marginBottom: 12 }}
            required={true}
            rules={[
              {
                required: true,

                message: "Please enter valid phone number",

                validator: (record, val) => {
                  console.log(!val.match(/^[0-9]*$/gi));

                  if (val.match(/^[0-9]*$/gi)) {
                    return Promise.resolve();
                  }

                  return Promise.reject();
                },
              },
            ]}
          >
            <Input
              prefix={"+91"}
              placeholder={"Contact Number"}
              style={{ borderRadius: 4, padding: 8 }}
              disabled={true}
            />
          </Form.Item>

          <Form.Item
            name={"address"}
            style={{ marginBottom: 12 }}
            required={true}
            rules={[
              {
                required: true,

                message: "Please enter your address!",
              },
            ]}
          >
            <Input.TextArea
              placeholder={"Address"}
              rows={2}
              style={{ borderRadius: 4, padding: 8 }}
              disabled={true}
            />
          </Form.Item>
        </Form>
      </div>
    );
  };

  return (
    <div>
      {!isMobile && <BrandingHeader/>}

      {isMobile ? (
        <div style={{ paddingBottom: 100 }}>
          <PageHeader
            ghost={false}
            title={`Order Status`}
            style={{
              padding: 6,
              paddingLeft: 12,
              borderBottom: "1px solid lightgrey",
            }}
          />

          <AddressForm />

          <Divider style={{ margin: 0 }} />

          <div style={{ padding: 16 }}>
            <OrderSummary />
          </div>

          <Divider style={{ margin: 0 }} />

          <TotalAmount />
        </div>
      ) : (
        <>
          <Row
            style={{
              width: "100%",
              marginTop: 16,
              paddingLeft: isMobile ? 12 : "11%",
              paddingRight: isMobile ? 12 : "11%",
              paddingBottom: 100,
            }}
          >
            <Col
              span={16}
              style={{
                background: "#eeeefa",
                padding: 16,
                verticalAlign: "middle",
                borderRadius: 8,
                border: "1px solid lightgrey",
              }}
            >
              <OrderSummary />
            </Col>

            <Col span={8} style={{ paddingLeft: 12 }}>
              <AddressForm />

              <TotalAmount />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default CartPage;
