import mixpanel from 'mixpanel-browser';

// Near entry of your product, init Mixpanel
mixpanel.init('5041644857e45fbbbf9b5eda807fc4d2', {debug: true, track_pageview: true, persistence: 'localStorage'});

export const setMpUserIdentity = (userId) => mixpanel.identify(userId);

export const setMpUserAttributes = ({name, email, phone}) => mixpanel.people.set({
    '$name': name,
    '$email': email,
    'phone': phone,
});

export const EMpEventName = {
    SIGNUP: "Sign Up",
    LOGIN: "Login",
    FEATURE_USED: "Feature Used",
}

let env;
const hostname = window.location.hostname;
if (hostname.includes("localhost") || hostname.includes("dev.xp") ) { // localhost without nginx, use api-server from local
    env = "local";
} else if (hostname.includes("app.khatabuddy")) { // localhost without nginx, use api-server from staging
    env = "prod";
} else if (hostname.includes("staging.khatabuddy")) {
    env = "staging";
} else { // prod or (local using nginx)
    env = "unknown";
}

const commonArgs = {};

export const setMpCommonArgs = (args) => {
    Object.assign(commonArgs, args || {});
}

export const trackEvent = (eventName, params) => mixpanel.track(eventName, {
    ...commonArgs,
    env,
    ...params,
});