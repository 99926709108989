export const INV_ACTIONS = {
    SALE: "SALE",
    SALE_RETURN: "SALE_RETURN",
    ISSUE_SAMPLE: "ISSUE_SAMPLE",
    RETURN_SAMPLE: "RETURN_SAMPLE",
    CLIENT_REPAIR_TICKET: "CLIENT_REPAIR_TICKET",
    CLIENT_REPAIR_TICKET_FIX: "CLIENT_REPAIR_TICKET_FIX",
    PURCHASE: "PURCHASE",
    PURCHASE_RETURN: "PURCHASE_RETURN",
    VENDOR_REPAIR_TICKET: "VENDOR_REPAIR_TICKET",
    VENDOR_REPAIR_TICKET_FIX: "VENDOR_REPAIR_TICKET_FIX",
};

export const pagesMap = {
    sale: {
        title: "Sales",
        partySelectionType: "Client",
        tabs: [
            {label: "Sale", key: "new"},
            {label: "Repair", key: "repair"},
            {label: "Samples", key: "sample"},
        ],
        new: {
            add: {
                buttonTitle: "New Sale",
                transactionType: INV_ACTIONS.SALE,
            },
            return: {
                buttonTitle: "Sale Return",
                transactionType: INV_ACTIONS.SALE_RETURN,
            }
        },
        repair: {
            add: {
                buttonTitle: "Client Repair Ticket",
                transactionType: INV_ACTIONS.CLIENT_REPAIR_TICKET,
            },
            return: {
                buttonTitle: "Client Repair Resolution",
                transactionType: INV_ACTIONS.CLIENT_REPAIR_TICKET_FIX,
            }
        },
        sample: {
            add: {
                buttonTitle: "Issue New Samples",
                transactionType: INV_ACTIONS.ISSUE_SAMPLE,
            },
            return: {
                buttonTitle: "Samples Return",
                transactionType: INV_ACTIONS.RETURN_SAMPLE,
            },
        }
    },
    purchase: {
        title: "Purchases",
        partySelectionType: "Vendor",
        tabs: [
            {label: "Purchase", key: "new"},
            {label: "Repair", key: "repair"},
        ],
        new: {
            add: {
                buttonTitle: "New Purchase",
                transactionType: INV_ACTIONS.PURCHASE,
            },
            return: {
                buttonTitle: "Purchase Return",
                transactionType: INV_ACTIONS.PURCHASE_RETURN,
            },
        },
        repair: {
            add: {
                buttonTitle: "Vendor Repair Ticket",
                transactionType: INV_ACTIONS.VENDOR_REPAIR_TICKET,
            },
            return: {
                buttonTitle: "Vendor Repair Resolution",
                transactionType: INV_ACTIONS.VENDOR_REPAIR_TICKET_FIX,
            },
        },
    },
}

export const transactionTypesMap = {
    [INV_ACTIONS.SALE]: {
        label: "Sale",
        factor: 1,
        color: "green",
        recordType: "sale",
        subTab: "new",

    },
    [INV_ACTIONS.SALE_RETURN]: {
        label: "Sale Return",
        factor: -1,
        color: "red",
        recordType: "sale",
        subTab: "new",

    },
    [INV_ACTIONS.CLIENT_REPAIR_TICKET]: {
        label: "Repair Ticket",
        factor: 1,
        color: "red",
        recordType: "sale",
        subTab: "repair",

    },
    [INV_ACTIONS.CLIENT_REPAIR_TICKET_FIX]: {
        label: "Repair Resolution",
        factor: 1,
        color: "green",
        recordType: "sale",
        subTab: "repair",
    },
    [INV_ACTIONS.ISSUE_SAMPLE]: {
        label: "Sample Issue",
        factor: 1,
        color: "red",
        recordType: "sale",
        subTab: "sample",
    },
    [INV_ACTIONS.RETURN_SAMPLE]: {
        label: "Sample Return",
        factor: 1,
        color: "green",
        recordType: "sale",
        subTab: "sample",
    },
    [INV_ACTIONS.PURCHASE]: {
        label: "Purchase",
        factor: 1,
        color: "green",
        recordType: "purchase",
        subTab: "new",
    },
    [INV_ACTIONS.PURCHASE_RETURN]: {
        label: "Purchase Return",
        factor: -1,
        color: "red",
        recordType: "purchase",
        subTab: "new",
    },
    [INV_ACTIONS.VENDOR_REPAIR_TICKET]: {
        label: "Repair Ticket",
        factor: 1,
        color: "red",
        recordType: "purchase",
        subTab: "repair",
    },
    [INV_ACTIONS.VENDOR_REPAIR_TICKET_FIX]: {
        label: "Repair Resolution",
        factor: 1,
        color: "green",
        recordType: "purchase",
        subTab: "repair",
    },
}