import React from "react";

const SubHome = () => {
    return (
        <div>
            Home Component
        </div>
    )
};

export default SubHome;
