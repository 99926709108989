import React from "react";
import _ from "lodash";
import * as XLSX from 'xlsx';
import XlsxPopulate from 'xlsx-populate';
import {Alert, Button, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";


const readExcelV2 = async (binaryData) => {
    let workbook;
    try {
        // try without password:
        workbook = await XlsxPopulate.fromDataAsync(binaryData);
    } catch (err) {
        // try with password:
        workbook = await XlsxPopulate.fromDataAsync(binaryData, {password: "720101091992"});
    }


    const sheets = workbook.sheets();
    // console.log("## workbook ##", sheets);
    const values = sheets[0].usedRange().value();
    return values;
}

const BulkUpload = ({onChange, headers = false, description = "", raw = false, v2Read = false }) => {
    const DEFAULT_VALUE = [];

    const processExcel = (f) => {
        const reader = new FileReader();
        reader.onload = async (evt) => { // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            console.log("## Parsing ##", bstr);
            let data;
            if (v2Read) {
                data = await readExcelV2(bstr);
            } else {
                const wb = XLSX.read(bstr, {type: 'binary', raw, password: "720101091992"});
                /* Get first worksheet */
                console.log("## Read Done ##");
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                data = XLSX.utils.sheet_to_json(ws, headers ? undefined : {header: 1});
            }

            /* Update state */
            console.log("## Data>>>", data);
            onChange(data);
        };
        if (v2Read) {
            reader.readAsArrayBuffer(f);
        } else {
            reader.readAsBinaryString(f);
        }
    }

    return (
        <div>
            <Upload
                accept={".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
                maxCount={1}
                beforeUpload={(file) => {
                    processExcel(file);
                    return false;
                }}
                onRemove={() => {
                    onChange(null);
                }}
            >
                <Button icon={<UploadOutlined/>}>Click to upload</Button>
            </Upload>
            <br/>
            {
                description &&
                <Alert
                    showIcon={true}
                    description={description}
                />
            }
        </div>
    )
};

export default BulkUpload;
