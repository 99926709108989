import React, {useEffect, useState} from "react";
import {Button, message, Popconfirm, Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {deleteSubuser, getSubusers, loginRequest} from "../services/api";
import {DeleteOutlined} from "@ant-design/icons";
import SubuserModal from "./subuserModal";
import {customDispatcher} from "../utils";

const SubUsers = () => {
    const state = useSelector(state => state.oldState);
    const dispatch = customDispatcher(useDispatch());
    const [subusers, setSubusers] = useState([]);
    const [showUserModal, setShowUserModal] = useState(false);
    const [isAddingNewUser, setIsAddingNewUser] = useState(false);

    const cols = [
        {title: "S.No.", render: (obj, curr, index) => (index + 1).toString()},
        {title: "Name", dataIndex: "name"},
        {title: "Email", dataIndex: "email"},
        {title: "Phone", dataIndex: "phone"},
        // {title: "Permissions", dataIndex: "permissions"},
        {
            title: "Actions", render: (obj, curr, index) => {
                return (
                    <div>
                        {/*<Button disabled={obj.permissions.includes("superadmin")} type={"primary"} onClick={() => {*/}
                        {/*    // setSelectedRecord(obj);*/}
                        {/*    // setShowModal(true);*/}
                        {/*}}><EditOutlined/></Button>*/}
                        <Popconfirm
                            title="Are you sure to delete this client ?"
                            onConfirm={async () => {
                                // const newUsers = _.cloneDeep(subusers);
                                // newUsers.splice(index, 1);
                                // setSubusers(newUsers);
                                await deleteSubuser(index);
                                message.success("User removed from company");
                                await loginRequest("", "", dispatch);
                                // deleteRecord(obj._id)
                                //     .then((resp) => {
                                //         if (_.get(resp, 'data.success')) {
                                //             message.success('Client Deleted');
                                //             fetchRecords();
                                //         } else {
                                //             message.error(_.get(resp, 'data.message'));
                                //         }
                                //     })
                                //     .catch(() => message.error('Failed to delete client'))
                            }}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                        >
                            &nbsp;
                            <Button disabled={obj.permissions.includes("superadmin") || obj._id.toString() === state?.user?._id} type={"danger"}><DeleteOutlined/></Button>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ];

    useEffect(() => {
        if (state.company) {
            getSubusers().then(resp => {
                setSubusers(resp.data.data.users);
            });
        }
    }, [state]);

    return (
        <div>
            <h2 style={{float: 'left'}}>Sub-users & Roles</h2>
            <Button style={{float: 'right'}} type={"primary"} onClick={() => {
                // message.info("Feature not yet launched");
                setIsAddingNewUser(true);
                setShowUserModal(true);
            }}>Add New User</Button>
            <Table
                columns={cols}
                dataSource={subusers}
            />
            <SubuserModal
            visible={showUserModal}
            isNew={isAddingNewUser}
            users={subusers}
            onCancel={() => setShowUserModal(false)}
            />
        </div>
    )
};

export default SubUsers;
