const getemailBodyAndSubjectForLedgerStatement = (record,companyName) => {
    const result={}
    result.body = `Hi ${record?.name},\nThank you for your business.\nPlease find the ledger statement here from ${companyName} between ${record?.from} to ${record?.to}.`
    result.subject = `Ledger statement from ${companyName} between ${record?.from} to ${record?.to}.`
    
    return result
}

export {
    getemailBodyAndSubjectForLedgerStatement
}