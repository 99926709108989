import React, {useEffect} from "react";
import {AndroidOutlined, CheckCircleOutlined} from "@ant-design/icons";
import mobileApp from "../res/img/mobileApp.png";
import logo from "../res/img/logoLandscapeV3.png";
import {Tag} from "antd";

const MobileView = () => {

    useEffect(() => {
    }, []);


    return (
        <div style={{width: '100%'}}>
            <div style={{
                height: 'auto',
                background: '#251d70',
                position: 'fixed',
                bottom: 0,
                width: '100%',
                textAlign: 'center',
                padding: 16,
                paddingBottom: 24
            }}>
                <div className={"blink"}
                     style={{color: 'white', fontSize: 22, marginBottom: 16, fontWeight: 'bold'}}>Switch to Digital
                    Accounting
                </div>
                <a href={"https://play.google.com/store/apps/details?id=com.khatabuddy.app"}>
                    <span style={{
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        background: 'darkorange',
                        color: 'white',
                        fontSize: 25,
                        width: 'auto',
                        borderRadius: 100,
                        padding: 10,
                        paddingLeft: 40,
                        paddingRight: 40
                    }}><AndroidOutlined/> Download App</span>
                </a>
            </div>
            <div style={{paddingBottom: 150, paddingTop: 16, textAlign: 'center', width: '100%', height: 'available', overflow: 'scroll', border: '0px solid red'}}>
                <img alt={"Logo"} src={logo} style={{width: 200}}/>
                <img alt={"Mobile App Logo"} src={mobileApp} style={{width: '80%'}}/>
                <h3>
                    <Tag icon={<CheckCircleOutlined/>} color="success"
                         style={{fontSize: 16, padding: 8, marginTop: 8}}>
                        Create Invoice
                    </Tag>
                    <Tag icon={<CheckCircleOutlined/>} color="success"
                         style={{fontSize: 16, padding: 8, marginTop: 8}}>
                        Download PDF
                    </Tag>
                    <Tag icon={<CheckCircleOutlined/>} color="success"
                         style={{fontSize: 16, padding: 8, marginTop: 8}}>
                        Share on Whatsapp without saving Contact
                    </Tag>
                    <Tag icon={<CheckCircleOutlined/>} color="success"
                         style={{fontSize: 16, padding: 8, marginTop: 8}}>
                        Share Public URL Link
                    </Tag>
                    <Tag icon={<CheckCircleOutlined/>} color="success"
                         style={{fontSize: 16, padding: 8, marginTop: 8}}>
                        Send Invoice on Email
                    </Tag>
                </h3>
                {/*ABCD*/}
            </div>
        </div>
    )
};

export default MobileView;
