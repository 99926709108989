import {Button, Popconfirm, Tooltip} from "antd";
import {DeleteOutlined, EyeOutlined} from "@ant-design/icons";
import React from "react";
import {customDispatcher} from "./index";
import {useDispatch} from "react-redux";

export const ACTION_TYPES = {
    DELETE: "delete", // to delete that record
    DETAILS: "details", // to open right drawer with details of record
};

export const RECORD_TYPES = {
    VOUCHER: "voucher",
}


const deleteActionButton = () => {
    return <Popconfirm
        title="Are you sure to delete this record ?"
        onConfirm={async (e) => {
            // e.stopPropagation();
            // deleteVoucher(obj._id)
            //     .then(() => {
            //         message.success('Document Deleted');
            //         fetchRecords();
            //     })
            //     .catch(() => message.error('Failed to delete document'))
        }}
        onCancel={(e) => {
            e.stopPropagation();
        }}
        okText="Yes"
        cancelText="No"
    >
        <Tooltip title={"Delete"}>
            <Button className={"btnCustom4 hoverRedBorder"}
                    style={{
                        background: '#ffebee',
                        color: '#b71c1c',
                        padding: 0,
                        height: 28,
                        fontWeight: 'bold',
                        fontSize: 13,
                        paddingLeft: 8,
                        paddingRight: 8
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
            ><DeleteOutlined style={{color: '#b71c1c'}} onClick={e => e.stopPropagation()}/>
            </Button>
        </Tooltip>
    </Popconfirm>
}

const detailsActionButton = (dispatch, voucher) => {
    return <Button className={"btnCustom3"}
                   style={{
                       padding: 0,
                       height: 28,
                       fontSize: 13,
                       fontWeight: 'bold',
                       paddingLeft: 8,
                       paddingRight: 8,
                       background: '#e8eaf6',
                       color: '#283593'
                   }}
                   onClick={(e) => {
                       e.stopPropagation();

                       const titleMap = {
                           "invoice": "Preview Invoice",
                           "proforma": "Preview Proforma",
                           "receipt": "Preview Receipt",
                       }

                       dispatch({
                           popData: {
                               visible: true,
                               type: "invoice",
                               title: titleMap[voucher.type] || "Preview", // `Send Invoice ${invoiceNum} on Email`,
                               data: voucher,
                           }
                       })
                   }}
    ><EyeOutlined/> Details
    </Button>;
}

export const MyActionButton = ({actionType, recordType, record}) => {
    const dispatch = customDispatcher(useDispatch());
    switch(actionType) {
        case ACTION_TYPES.DELETE:
            return deleteActionButton();
        case ACTION_TYPES.DETAILS:
            return detailsActionButton(dispatch, record);
        default:
            break;
    }

    return <>Invalid Action Type</>;
}