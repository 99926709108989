import {validSimStatuses} from "../models/constant";

const isNumber = function(str) {return /^\d+$/.test(str);}

export const isValidDeviceID = (deviceId, shortIdAllowed = false) => {
    return typeof deviceId === "string" && (deviceId.length === 15 || (shortIdAllowed && deviceId.length === 6)) && isNumber(deviceId);
}

export const isValidSimNumber = (input, shortIdAllowed = false) => {
    return typeof input === "string" && (input.length === 19 || (shortIdAllowed && input.length === 8)) && isNumber(input);
}

export const isValidM2MNumber = (input) => {
    return typeof input === "string" && ([10, 13].includes(input.length)) && isNumber(input);
}

export const isValidSimStatus = input => validSimStatuses.includes(input);

export const verifyRole = (requiredRole, user) => {
    return (user?.permissions || []).includes(requiredRole);
}