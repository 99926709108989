import React, {useEffect, useState} from "react";
import {Layout, Menu} from "antd";
import {
    DollarOutlined,
    FileOutlined,
    IdcardOutlined,
    UsergroupAddOutlined,
    UserOutlined,
    BankOutlined
} from "@ant-design/icons";
import {Link, Outlet, useParams} from "react-router-dom";
import {isErpWeb, isPuc} from "../../utils";
import {verifyRole} from "../../erp/utils";
import {ROLES} from "../../erp/models/constant";
import {useSelector} from "react-redux";

const {Sider, Content} = Layout;

export const TiPortal = () => {
    const state = useSelector(state => state.oldState);
    const params = useParams();
    const [selectedKey, setSelectedKey] = useState();
    useEffect(() => {
        setSelectedKey(params["*"]?.split("/")[1]);
    }, [params]);

    console.log("## chatAcc selected ##", [selectedKey + (selectedKey === "chatbot" ? params["*"]?.split("/")[2] : "")])
    return (
        <div>
            <Layout>
                <Sider width={160} className="site-layout-background">
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={[selectedKey + (selectedKey === "chatbot" ? params["*"]?.split("/")[2] : "")]}
                        style={{height: '100%', borderRight: 0}}
                        selectedKeys={[selectedKey + (selectedKey === "chatbot" ? params["*"]?.split("/")[2] : "")]}
                    >
                        {/*{*/}
                        {/*    isPuc() &&*/}
                        {/*    <Menu.Item key={"config"} icon={<AppstoreOutlined/>}>*/}
                        {/*        <Link to={"/settings/config"}>Config</Link>*/}
                        {/*    </Menu.Item>*/}
                        {/*}*/}

                        <Menu.Item key={"chatbot8010666611"} icon={<UserOutlined/>}>
                            <Link to={"/portal/chatbot/8010666611/messages"}>Chatbot 8010</Link>
                        </Menu.Item>
                        {
                            state?.user?.phone === "8700260932" &&
                            <Menu.Item key={"chatbot8130146196"} icon={<UserOutlined/>}>
                                <Link to={"/portal/chatbot/8130146196/messages"}>Chatbot 8130</Link>
                            </Menu.Item>
                        }


                        <Menu.Item key={"banking"} icon={<UserOutlined/>}>
                            <Link to={"/portal/banking"}>Banking</Link>
                        </Menu.Item>

                        {/*<Menu.Item key={"errors"} icon={<UserOutlined/>}>*/}
                        {/*    <Link to={"/portal/errors"}>Issues</Link>*/}
                        {/*</Menu.Item>*/}
                        <Menu.Item key={"kyc"} icon={<UserOutlined/>}>
                            <Link to={"/portal/kyc"}>KYC Admin</Link>
                        </Menu.Item>
                        {/*<Menu.Item key={"mapping"} icon={<UserOutlined/>}>*/}
                        {/*    <Link to={"/portal/mapping"}>TI-KB Mapping</Link>*/}
                        {/*</Menu.Item>*/}

                    </Menu>
                </Sider>
                <Layout style={{padding: '0 24px 24px'}}>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}
                    >
                        <Outlet/>
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
};
