import {Switch} from "antd";
import React from "react";

const SWITCH_ON_COLOR = "#00c853";
const SWITCH_OFF_COLOR = "#ef5350";

export const SwitchSelect = (props) => {
    const {value, onChange, valuesMap, reverseMap, debug} = props;
    if (debug) {
        console.log("## Val ##", value, reverseMap[value]);
    }

    return <Switch {...props}
                   defaultChecked={reverseMap[value]}
                   checked={reverseMap[value]}
                   onChange={(newVal) => onChange(valuesMap[newVal])}
                   style={{background: reverseMap[value] ? SWITCH_ON_COLOR : SWITCH_OFF_COLOR}}
    />
}
