import React, {useState} from "react";
import {message, Upload} from "antd";
import _ from "lodash";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";

export const UploadImage = ({value, onChange, altTag = "Signature"}) => {
    const [uploading, setUploading] = useState(false);
    return (
        <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={`/api/internal/upload`}
            beforeUpload={(file) => {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                    message.error('You can only upload JPG/PNG file!');
                }
                const isLt2M = file.size / 1024 / 1024 < 10;
                if (!isLt2M) {
                    message.error('Image must smaller than 3 MB!');
                }
                return isJpgOrPng && isLt2M;
            }}
            onChange={(info) => {
                if (info.file.status === 'uploading') {
                    setUploading(true);
                    return;
                }
                if (info.file.status === 'done') {
                    setUploading(false);
                    const imgFilename = _.get(info, 'file.response.data.filename');
                    onChange(imgFilename);
                }
            }}
        >
            {value ? <img src={`/api/internal/file/${value}`} alt={altTag}
                          style={{width: '100%'}}/> :
                <div>
                    {uploading ? <LoadingOutlined/> : <PlusOutlined/>}
                    <div style={{marginTop: 8}}>Upload {altTag}</div>
                </div>
            }
        </Upload>
    )
}
