import React, {useEffect, useState} from "react";
import {Modal} from "antd-mobile";
import {Alert, Col, message, Row} from "antd";
import moment from "moment";

const ModalRenew = ({visible, setShowRenewModal, addItemToCart, productsMap, subscription = {}}) => {
    const plans = productsMap[subscription.productId]?.plans || [];
    const [selectedPlan, setSelectedPlan] = useState(0);
    const plan = plans[Number(selectedPlan) - 1];
    const newExpiry = (selectedPlan && selectedPlan!=="0") ?
        moment(subscription.expiryDate).add(plan.duration, plan.durationUnit).format("DD MMM YYYY") : "---";

    useEffect(() => {
    }, []);

    return (
        <div>
            <Modal
                visible={visible}
                title={`Renewal - ${productsMap[subscription?.productId]?.name}`}
                actions={[
                    {key: 'add', text: 'Add to Cart', primary: true},
                    {key: 'cancel', text: "Cancel", danger: true},
                ]}
                closeOnMaskClick={true}
                onAction={async (action) => {
                    console.log("## Action ##", {action, selectedPlan});
                    if (action?.key !== 'add') {
                        setShowRenewModal(false)
                        return;
                    }
                    if (!selectedPlan || selectedPlan === 0 || selectedPlan === "0") {
                        message.error(`Select Renewal Plan`);
                        return;
                    }
                    addItemToCart(plan);
                    setShowRenewModal(false)
                }}
                onClose={() => setShowRenewModal(false)}
                content={
                    <div>
                        {
                            (productsMap[subscription.productId]?.customFields || []).map(cField => (
                                <Row style={{paddingLeft: 8}}>
                                    <Col span={12}>{cField}</Col>
                                    <Col span={12}>{subscription.customFieldValues?.[cField] || "---"}</Col>
                                </Row>
                            ))
                        }
                        <Row style={{paddingLeft: 8}}>
                            <Col span={12}>Current Expiry:</Col>
                            <Col span={12}>
                                {moment(subscription.expiryDate).format("DD MMM YYYY")}
                            </Col>
                        </Row>
                        <Row style={{paddingLeft: 8}}>
                            <Col span={12}>Select Plan</Col>
                            <Col span={12}>
                                <select onChange={(e) => setSelectedPlan(e.target.value)}>
                                    <option value={0}>--Select--</option>
                                    {
                                        plans.map((p, i) => (
                                                <option value={i+1}>
                                                    {p.name} @{p.price}
                                                </option>
                                            ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row style={{paddingLeft: 8}}>
                            <Col span={12}>New Expiry:</Col>
                            <Col span={12}>
                                {newExpiry}
                            </Col>
                        </Row>
                        <Alert
                            style={{padding: 0, margin: 0, fontWeight: 'bold'}}
                            type={"info"}
                            description={<div style={{padding: 0, margin: 0}}>
                                <Row style={{padding: 0, margin: 0}}>
                                    <Col span={12} style={{padding: 0, margin: 0, paddingLeft: 8}}>
                                        Price
                                    </Col>
                                    <Col span={12} style={{padding: 0, margin: 0}}>
                                        ₹ {plan?.price || "---"}
                                    </Col>
                                </Row>
                            </div>}
                        />
                    </div>
                }
            />
        </div>
    )
};

export default ModalRenew;
