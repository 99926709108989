import React, {useEffect, useState} from "react";
import {Drawer, Table, Tag} from "antd";
import moment from "moment";
import {pucRenewalPlansMap} from "../../utils";
import {getPucSales} from "../../services/api";

const HistoryDrawer = ({showHistory, setShowHistory, historyVehicle}) => {
    const [sales, setSales] = useState([]);

    useEffect(() => {
        if (showHistory) {
            if (historyVehicle) {
                getPucSales(historyVehicle?._id).then(resp => setSales(resp.data.data.sales));
            }
        }
    }, [showHistory, historyVehicle]);

    const cols = [
        {
            title: "Issue Date",
            render: ({renewalDate}) => moment(renewalDate).format("DD MMM YYYY"),
            sorter: (a, b) => moment(a.renewalDate).diff(moment(b.renewalDate))
        },
        {
            title: "Expiry Date",
            render: ({expiryDate}) => moment(expiryDate).format("DD MMM YYYY"),
            sorter: (a, b) => moment(a.expiryDate).diff(moment(b.expiryDate))
        },
        {
            title: "Plan",
            render: ({plan}) => pucRenewalPlansMap[plan]?.label,
            sorter: (a, b) => a.plan.localeCompare(b.plan)
        },
        {
            title: "Payment Status",
            render: ({isPaid}) => <Tag color={isPaid ? "green" : "red"}>{isPaid ? "Paid" : "Pending"}</Tag>,
            sorter: (a, b) => a.isPaid - b.isPaid
        },
    ];

    return (
        <Drawer title={`PUC Renewals - ${historyVehicle?.vehicleNumber || "--"}`} placement="right" onClose={() => {
            setShowHistory(false);
            setSales([]);
        }} visible={showHistory}>
            <Table
                columns={cols}
                dataSource={sales}
            />
        </Drawer>
    )
};

export default HistoryDrawer;
