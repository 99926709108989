import {Button, Image, Input, Popover, Table} from "antd";
import {useCallback, useEffect, useState} from "react";
import {actionOnKycReview, getKycForReview} from "../../../services/api";
import moment from "moment";
import {addressStates} from "../../../models/constants";
import {ButtonV2} from "../../../components/ButtonV2";

const docTypesMap = {
    aadhaar: "Aadhaar",
    dl: "DL",
    voterId: "Voter ID",
};

const getImg = (id) => <Image style={{width: 50}} src={`/api/internal/file/${id}`}/>;

const columns = (remarks, setRemarks, refreshList) => [
    {title: "S.No.", render: (curr, obj, index) => `${index+1}`},
    {title: "Device IDs", dataIndex: "deviceIds"},
    {title: "Request Date", render: (curr, obj) => moment(obj.createdOn).format("DD MMM HH:mm")},
    {title: "Doc Type", render: (curr, obj) => docTypesMap[obj.docType]},
    {title: "Doc ID", dataIndex: "docNum"},
    {title: "Contact Verified", dataIndex: "primaryContact"},
    {title: "Full Name", render: (curr, obj) => obj.userData?.name},
    {title: "Address", render: (curr, obj) => obj.userData?.address + `(${addressStates.IN[obj.userData?.state]?.name})`},
    {title: "Photo", render: (curr, obj) => getImg(obj.userData?.selfPic)},
    {title: "RC", render: (curr, obj) => getImg(obj.userData?.vehicleRc)},
    {title: "ID (Front)", render: (curr, obj) => getImg(obj.userData?.idProofImg1)},
    {title: "ID (Back)", render: (curr, obj) => getImg(obj.userData?.idProofImg2)},
    {title: "Actions", render: (curr, obj) => {
        return <div>
            <ButtonV2 onClick={async () => {
                await actionOnKycReview({recordId: obj._id, action: "approve", remarks});
                setRemarks("");
                await refreshList();
            }} type={"primary"}>Approve</ButtonV2>&nbsp;
            <ButtonV2 onClick={async () => {
                await actionOnKycReview({recordId: obj._id, action: "reject", remarks});
                setRemarks("");
                await refreshList();
            }} type={"danger"}>Reject</ButtonV2>
        </div>
        }}
];

export const PortalKycAdminPage = () => {
    const [records, setRecords] = useState();
    const [remarks, setRemarks] = useState("");

    const refreshList = useCallback(async () => {
        const response = await getKycForReview();
        setRecords(response.data.data.records);
    }, []);

    useEffect(() => {
        refreshList();
    }, []);

    return <div>
        <h2>
            Portal KYC Admin
            &nbsp;&nbsp;
            <div style={{fontSize: 14, textAlign: 'right'}}>Remarks:&nbsp;
                <Input style={{width: 300}} type={"text"} maxLength={200} value={remarks} onChange={(e) => setRemarks(e.target.value)}/>
            </div>
        </h2>
        {
            <Table
                loading={!records}
                dataSource={records}
                columns={columns(remarks, setRemarks, refreshList)}
            />
        }
    </div>
}