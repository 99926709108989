import React, {useEffect, useState} from 'react';
import {Button, message, Form, Input, Select, Row, Col, Alert} from 'antd';
import {getInternalCompany, saveCompany, gstinValidation} from '../../services/api';
import _ from "lodash";
import {states} from "../../models/constants";
import {useSelector} from "react-redux";
import {UploadImage} from "../../components/UploadImage";
import { ButtonV2 } from '../../components/ButtonV2';



const findStateByGstin = (gstin = "") => {
    const inputStateCode = gstin.slice(0, 2);
    const vals = _.values(states["IN"]);
    for (const state of vals) {
        if (state.gstCode === inputStateCode) {
            return state.alphaCode
        }
    }
}


const CompanySettings = () => {
    const state = useSelector(state => state.oldState);
    const [form] = Form.useForm();
    const [gstState, setGstState] = useState();
    const [company, setCompany] = useState({});
    const [loading, setLoading] = useState(false);

    if (gstState) {
        form.setFieldsValue({state: gstState});
    }

    useEffect(() => {
        if (state.company) {
            getInternalCompany(state.company._id).then(res => {
                const _company = _.get(res, 'data.data.companies[0]');
                setCompany(_company);
                setGstState(findStateByGstin(_company.gstin));
            });
        }
    }, [state]);

    useEffect(() => {
        form.setFieldsValue(company);
    }, [company, form]);

    return (
        <div>
            <h2>Company Settings</h2>

            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={company}
            >
                <>
                    <h3>Business Info</h3>
                    <Row>
                        <Col span={8}>
                            <Form.Item name={"name"} label={"Business Name"}
                                       rules={[{required: true, message: "can't be blank"}]}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item name={"address"} label={"Address"}
                                       rules={[{required: true, message: "can't be blank"}]}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item name={"gstin"} label={"GSTIN (Optional)"}>
                                <Input maxLength={15} onChange={(e) => {
                                    form.setFieldsValue({ gstin: e.target.value.toUpperCase()})
                                     setGstState(findStateByGstin(e.target.value))
                                }
                                   
                                }/>
                            </Form.Item>
                        </Col>
                        
                        <Col span={3} style={{paddingRight:"1%"}}>
                            <Form.Item name={"verify"} label={"        "}>
                                <ButtonV2 type="primary" style={{ marginLeft: '5%', width:'95%' }} onClick={
                                    async()=>{
                                        const gstinValue = form.getFieldValue("gstin");
                                        const resp = await gstinValidation(gstinValue);
                                        
                                        if(!resp.data.success){
                                            message.error('Invalid GSTIN');
                                            return
                                        }
                                        message.success('GSTIN verified successfully');
                                        form.setFieldsValue({
                                            address: resp?.data?.data?.address,
                                            name: resp?.data?.data?.name
                                        });
                                        
                                        
                                        
                                    }
                                }
                                >Verify</ButtonV2>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={"state"} label={"State"}
                                       rules={[{required: true, message: "can't be blank"}]}>
                                <Select
                                    disabled={!!gstState}
                                    options={_.map(states["IN"], state => ({label: state.name, value: state.alphaCode}))}
                                    allowClear={true}
                                    filterOption={(input, option) => {
                                        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }}
                                    showSearch
                                />
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item name={"contactNumber"} label={"Business Contact Number"}>
                                <Input/>
                            </Form.Item>
                        </Col>&nbsp;
                        <Col span={12}>
                            <Form.Item name={"imgSign"} label={"Signature"}>
                                <UploadImage/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={"imgLogo"} label={"Business Logo"}>
                                <UploadImage/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Alert message="For Signature and Logo, only JPG/ PNG files of size less than 1MB are allowed." type="info" />
                        </Col>
                        <Col span={24}>&nbsp;</Col>
                    </Row>
                </>

            </Form>

            <Button type={"primary"} onClick={() => {
                form.validateFields().then(async () => {
                    const obj = form.getFieldsValue();
                    _.assign(company, obj);
                    await saveCompany(company);
                    message.success("Business details saved successfully!");
                }).catch(() => {
                });
            }}>Save</Button>
        </div>
    );
}

export default CompanySettings;
