import React, {useEffect, useState} from "react";
import {Button, Input, Row, Col, Form, message, Divider} from "antd";
import { useMediaQuery } from "react-responsive";
import {useDispatch} from "react-redux";
import {customDispatcher, isErpWeb, isPuc, isValidEmail, isValidPhone, sleep} from "../utils";
import {forgotPassword, googleLogin, loginRequest, requestOtpV2, signup, verifyLoginV2} from "../services/api";
import {Link, useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import loginBack from "../res/img/leftBanner.png";
import {GoogleLogin} from 'react-google-login';
import logo from "../res/img/logoLandscapeV3.png";
import imgIndia from "../res/img/madeInIndia.png";
import {CustomerServiceFilled} from '@ant-design/icons';
import {EMpEventName, trackEvent} from "../services/analytics/mixpanel";


const ResendOtpComponent = ({username}) => {
    const [timeLeft, setTimeLeft] = useState(15);

    useEffect(() => {
        if (timeLeft > 0) {
            setTimeout(() => setTimeLeft(timeLeft-1), 1000);
        }
    }, [timeLeft])

    return <div>
        {
            timeLeft > 0 &&
            <div>
                Resend OTP in {timeLeft} seconds
            </div>
        }
        {
            !timeLeft &&
            <div>
                <b><a onClick={() => {
                    requestOtpV2({username});
                    setTimeLeft(-1);
                }}>Resend OTP</a></b>
            </div>
        }
        {
            timeLeft < 0 &&
                <span style={{color: 'green'}}>OTP Resent !</span>
        }

    </div>
}

const LoginComponent = ({}) => {
    const [formUsername] = Form.useForm();
    const [formOtp] = Form.useForm();
    const dispatch = customDispatcher(useDispatch());
    const navigate = useNavigate();
    const [currentData, setCurrentData] = useState({step: 'askUsername'});
    const [isRequestInProgress, setIsRequestInProgress] = useState(false);

    const verifyLoginAttempt = async (params, isGoogle) => {
        const {success, message: msg, data} = await verifyLoginV2(params);
        await sleep(500);
        if (!success) {
            !isGoogle && formOtp.setFields([
                {
                    name: 'inputOtp',
                    errors: [msg],
                },
            ]);
            console.log("## Returning ##");
            return;
        }
        message.success("Login Successful");
        console.log("## loginData ##", data, params, isValidEmail(params.username), isValidPhone(params.username));
        const user = await loginRequest(data.username, data.token, dispatch, "web");
        window.showNewAccountNudge = user.showNewAccountNudge;
        if (user) { // logged in
            let method;
            if (isGoogle) {
                method = "Google";
            } else if (isValidEmail(params.username)) {
                method = "Email OTP";
            } else if (isValidPhone(params.username)) {
                method = "Phone OTP";
            } else {
                method = "Unknown";
            }

            trackEvent(EMpEventName.LOGIN, {
                method,
            });
            navigate("/dashboard");
        }
    }

    const onGoogleLogin = async ({profileObj}) => {
        try {
            // data required:: email, name, phone, createCompany, companyName
            await verifyLoginAttempt({
                username: profileObj.email,
                googleProfileData: {
                    name: profileObj.name,
                }
            }, true);
            // const signupData = {
            //     email: profileObj.email,
            //     name: profileObj.name,
            //     phone: "",
            //     createCompany: true,
            //     companyName: ""
            // };
            // const gData = await googleLogin(signupData);
            // const {email, token} = gData.data.data;
            // const user = await loginRequest(email, token, dispatch);
            // if (user) { // logged in
            //     navigate("/dashboard");
            // }
            // message.success("Login Successful");
        } catch (err) {
            console.log("## Google Login Failed ##", err);
            message.error(`Google login failed. Please retry or login using OTP.`);
        }
    }
    return (
        <div style={{width: '100%', paddingLeft: '5%', paddingRight: '5%'}}>
            <br/>
            {/*<h1 style={{fontWeight: 'bold'}}>Login using Google account or OTP</h1>*/}
            {/*<div style={{color: '#666', paddingBottom: 32, fontSize: 16}}>Sign in to KhataBuddy with Google or email address</div>*/}
            <div style={{textAlign: 'center', color: "#888"}}>
                {
                    !isPuc() && !isErpWeb() &&
                    <>
                    <GoogleLogin
                        clientId="342300141684-boqeg9bphb51s88di8pfjl94nmp3umcc.apps.googleusercontent.com"
                        // render={renderProps => (
                        //     <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
                        // )}
                        buttonText={<b>Sign in with Google</b>}
                        onSuccess={onGoogleLogin}
                        onFailure={() => message.error(`Google login failed. Please retry or login using email/ password.`)}
                        cookiePolicy={'single_host_origin'}
                        style={{background: 'red', width: '100%'}}
                    />
                    <br/><br/>
                    <div style={{textAlign: 'center', verticalAlign: 'middle', lineHeight: '30px'}}>
                    <div style={{width: '30%', border: '0.5px solid #aaa', marginTop: 14, float: 'left'}}></div>
                    <div style={{width: '40%', float: 'left'}}>or Sign in using OTP</div>
                    <div style={{width: '30%', border: '0.5px solid #aaa', marginTop: 14, float: 'left'}}></div>
                    </div>
                    </>
                }

                <br/><br/>
            </div>

            <div style={{textAlign: 'center', width: '60%', marginLeft: '20%', marginTop: 40}}>
            {
                currentData?.step === 'askUsername' &&
                <Form
                    form={formUsername}
                    layout={"vertical"}
                    onFinish={async ({username}) => {
                        try {
                            setIsRequestInProgress(true);
                            const {success, message} = await requestOtpV2({username});
                            await sleep(500);
                            console.log("## Success ##", success, message);
                            if (!success) {
                                formUsername.setFields([
                                    {
                                        name: 'username',
                                        errors: [message],
                                    },
                                ]);
                                return;
                            }
                            setCurrentData({
                                step: 'askOtp',
                                username,
                            });
                        } catch(err) {
                        } finally {
                            setIsRequestInProgress(false);
                        }
                    }}
                >
                    <Form.Item name={"username"} label={"10 digit Mobile Number or Email"}
                               rules={[{
                                   required: true,
                                   message: "Please enter a valid 10-digit phone or email",
                                   // validator: (rule, value, cb) => {
                                   //     if (!isValidPhone(value) && !isValidEmail(value)) {
                                   //         cb("custom error");
                                   //     }
                                   // },
                               }]}>
                        <Input maxLength={100} style={{fontWeight: 'bold', fontSize: 18, borderRadius: 10, height: 40, paddingLeft: 24}} placeholder={"10 digit Mobile Number or Email address"}
                               autoFocus/>
                    </Form.Item>
                    <br/>
                    <Button loading={isRequestInProgress} htmlType={"submit"} type={"primary"} style={{height: 40, borderRadius: 500, width: '60%'}}>Send OTP</Button>
                </Form>

            }

            {
                currentData?.step === 'askOtp' &&
                <Form
                    form={formOtp}
                    layout={"vertical"}
                    onFinish={async ({inputOtp}) => {
                        try {
                            setIsRequestInProgress(true);
                            const {username} = currentData;
                            console.log("## Username ##", username);
                            await verifyLoginAttempt({
                                username,
                                inputOtp,
                            })
                        } catch(err) {
                            console.log("## ERR ##", err);
                        } finally {
                            setIsRequestInProgress(false);
                        }
                    }}
                >
                    <div>OTP sent to <b>{currentData?.username}</b>&nbsp;&nbsp;<a onClick={() => {
                        setCurrentData({
                            step: "askUsername",
                        })
                        formOtp.resetFields();
                    }}><b>Edit</b></a></div>
                    <br/>
                    <Form.Item name={"inputOtp"} label={"Enter 4 digit OTP"}
                               rules={[{required: true, type: isErpWeb() ? undefined : "email" && undefined, message: "Please enter valid OTP"}]}>
                        <Input maxLength={4} style={{fontWeight: 'bold', fontSize: 18, borderRadius: 10, height: 40, paddingLeft: 24}} placeholder={"4 digit OTP"}
                               autoFocus/>
                    </Form.Item>
                    <Button loading={isRequestInProgress} htmlType={"submit"} type={"primary"} style={{height: 40, borderRadius: 500, width: '60%'}}>Confirm OTP</Button>
                    <br/><br/>
                    <ResendOtpComponent username={currentData?.username}/>
                </Form>
            }

            </div>

        </div>
    )
}


const LoginPageV2 = () => {
    const puc = isPuc();
    const isZoomed = useMediaQuery({
        query: '(max-height: 700px)'
      })
    const navigate = useNavigate();

    let isMobile;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true;
    }

    useEffect(() => {
        if (isMobile) {
            console.log("## This is mobile ##");
            navigate("/m");
        } else {
            // message.error("Web Device");
        }
    }, [isMobile, navigate]);


    useEffect(() => {
        console.log("## Login Effect ##");
        const _user = Cookies.get("invUser");
        const _pass = Cookies.get("invPass");
        if (_user && _pass) {
            navigate("/");
        }
    });

    return (
        <div
        >
            <Row style={{height: '100vh', padding: 0, margin: 0}}>
                <Col onClick={() => {
                    // window.location = "https://khatabuddy.com";
                }} span={11} style={{
                    // border: '5px solid red',
                    backgroundImage: `url(${loginBack})`,
                    backgroundSize: '100% 100%',
                    backgroundRepeat: 'no-repeat',
                    height: '100%'
                    // cursor: 'pointer'
                }}>
                </Col>
                <Col span={13} style={{padding: 32, height: '100%'}}>
                    <Row>
                        <div style={{display:'flex', alignItems: 'center', width: '100%'}}>
                        <Col span={12}>
                            <img alt={"Logo"} src={logo} width={175}/>
                        </Col>
                        <Col span={12} style={{textAlign: 'right'}}>
                        <div>
                <Button className={"hoverGreenBackground"} style={{
                                marginLeft: 24,
                                borderRadius: 4,
                                // color: 'white',
                                border: 'none', padding: 0,
                                height: 22, fontWeight: 'bold', fontSize: 14, paddingLeft: 8, paddingRight: 8
                            }}
                                    onClick={() => {
                                        const msg = puc ? "Hello, I need some help on PUC Software." :
                                            "Hello, I need some help on KhataBuddy platform.";
                                        const url = `https://api.whatsapp.com/send/?phone=91${"8700260932"}&text=${encodeURI(msg)}`;
                                        window.open(url, '_blank');
                                    }}
                            ><CustomerServiceFilled/> Help/Support</Button>
                            </div>
                        </Col>
                        </div>
                    </Row>
                    <Divider/>
                    <div style={{padding:0}}>
                        <LoginComponent />
                    </div>
                    <div style={{position: isZoomed ? 'static' : 'absolute', bottom: 0, width: '90%', textAlign: 'center', color: 'grey', fontSize: 10, border: '0px solid blue'}}>
                        <img alt={"img india"} src={imgIndia} width={175} style={{marginTop: '10px'}}/>
                        <div>
                            © 2023 KhataBuddy | All rights reserved.
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export default LoginPageV2;
