import React from "react";
import {Button, Popconfirm, Tooltip} from "antd";
import {DeleteOutlined} from "@ant-design/icons";


const DeleteButton = ({onClick, ...otherProps}) => {
    return <Popconfirm
        title="Are you sure to delete this record ?"
        onConfirm={onClick}
        okText="Yes"
        cancelText="No"
    >
        <Tooltip title={"Delete"}>
            <Button className={"btnCustom4 hoverRedBorder"}
                    style={{
                        background: '#ffebee',
                        color: '#b71c1c',
                        padding: 0,
                        height: 28,
                        fontWeight: 'bold',
                        fontSize: 13,
                        paddingLeft: 8,
                        paddingRight: 8
                    }}
                    {...otherProps}
            ><DeleteOutlined style={{color: '#b71c1c'}} />
            </Button>
        </Tooltip>
    </Popconfirm>
}

export default DeleteButton;
