import React, { useState } from 'react'
import BrandingHeader from '../EcommerceUser/components/BrandingHeader'
import LedgerStatement from '../ledgers/ledgerStatement'


export default function LedgerShare() {
  const [company, setCompany] = useState({});
  return (
    <>
    
    <BrandingHeader company={company} headerChange={true}/>
    <LedgerStatement isPublicView={true} setCompany={setCompany}/>
    
    </>
    
    
  )
}
