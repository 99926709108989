import {useEffect, useState} from "react";
import {getPortalErrorsData} from "../../../services/api/portal";
import {Spin, Table} from "antd";

const columns = [
    {title: "S.No.", render: (curr, obj, index) => `${index+1}`},
    {title: "Issue", dataIndex: "title"},
    {title: "Link", render: (curr) => {
        return <div>
            <a href={curr.link} target={"_blank"}>Link</a>
        </div>
        }},
]

export const ErrorsReportingPage = () => {
    const [issues, setIssues] = useState();


    useEffect(() => {
        (async () => {
            const response = await getPortalErrorsData();
            setIssues(response.data.data.issues);
        })();
    }, []);

    return <div>
        <h2>
            Portal Issues Reporting
        </h2>
        {
            <Table
                loading={!issues}
                dataSource={issues}
                columns={columns}
            />
        }

    </div>
}