import React, {useEffect, useState} from "react";
import {getProducts, saveProduct} from "../../services/api";
import {Button, Form, Input, message, Modal, Table} from "antd";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import { ButtonV2 } from "../../components/ButtonV2";

const Products = () => {
    const state = useSelector(state => state.oldState);
    const [products, setProducts] = useState([]);
    const [formName] = Form.useForm();

    const fetchProducts = async () => {
        await getProducts().then(resp => setProducts(resp.data.data.products));
        console.log("## Fetched Products ##");
    }

    useEffect(() => {
        if (state?.company) {
            fetchProducts();
        }
    }, [state]);


    const columns = [
        {
            title: "S.No.",
            render: (curr, obj, index) => (index + 1).toString()
        },
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Plans",
            render: (curr) => curr?.plans?.length.toString() + " subscription plans"
        },
        {
            title: "Actions",
            render: (curr) => (
                <Link to={`/subscription/products/view/${curr._id}`}>
                    <Button>
                        View
                    </Button>
                </Link>)
        }
    ]


    return (
        <div>
            <h2 style={{float: 'left'}}>Products</h2>
            <ButtonV2 onClick={async () => {
                const modal = Modal.info();
                modal.update({
                    icon: false,
                    maskClosable: true,
                    title: 'Product Name',
                    content: (
                        <div>
                            <Form form={formName}>
                                <Form.Item name={"name"} label={"New Name"} rules={[{required: true, message: "can't be blank"}]}>
                                    <Input />
                                </Form.Item>
                            </Form>
                        </div>
                    ),
                    onOk: async () => {
                        const formVals = await formName.validateFields();
                        const {name} = formVals
                        await saveProduct({
                            product: {
                                name,
                                customFields: [],
                                plans: []
                            }
                        });
                        message.success("Product name updated successfully");
                        await fetchProducts();
                    }
                });
            }} style={{float: 'right'}} type={"primary"}>
                Add New Product</ButtonV2>
            <Table
                columns={columns}
                dataSource={products}
            />
        </div>
    )
};

export default Products;
