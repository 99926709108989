import React, {useEffect, useState} from "react";
import _ from "lodash";
import {Button, Col, DatePicker, Input, message, Pagination, Row, Select, Table, Tabs} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {deleteParty, getLogs, getParties} from "../../services/api";
import EditButton from "../../components/buttons/EditButton";
import DeleteButton from "../../components/buttons/DeleteButton";
import {customDispatcher} from "../../../utils";
import {useDispatch} from "react-redux";
import moment from "moment";

const ErpLogsPage = () => {
    const dispatch = customDispatcher(useDispatch());
    const [data, setData] = useState({});
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });
    const [loading, setLoading] = useState(false);

    // search filters:
    const [filterDate, setFilterDate] = useState(null);
    const [filterUser, setFilterUser] = useState();
    const [filterAction, setFilterAction] = useState([]);
    const [filterText, setFilterText] = useState("");


    const {logs: records = [], ERP_LOG_LABELS = {}, users = []} = data;

    const fetchRecords = async () => {
        setLoading(true);
        console.log("## Filters ##", {
            filterDate,
            filterUser,
            filterAction,
            filterText
        });
        const params = {};
        if (filterDate) {
            const [from, to] = filterDate;
            params.from = from.startOf('day').valueOf();
            params.to = to.endOf('day').valueOf();
        }
        if (filterUser) {
            params.user = filterUser;
        }
        if (filterAction && filterAction.length) {
            params.actions = filterAction.join(",");
        }
        if (filterText) {
            params.search = filterText;
        }

        await getLogs({
            ...pagination,
            ...params,
        }).then(resp => setData(resp.data.data));
        setLoading(false);
    }

    useEffect(() => {
        fetchRecords();
    }, [pagination, filterUser, filterAction, filterDate]);


    const columns = [
        {
            title: "Date Time",
            render: obj => <>
                {moment(obj.eventTime).format("DD MMM")}
                <br/>
                <div style={{fontSize: 12, color: 'grey'}}>
                    {moment(obj.eventTime).format("HH:mm:ss")}
                </div>
            </>
        },
        {
            title: "User",
            dataIndex: "user",
        },
        {
            title: "Action",
            render: obj => ERP_LOG_LABELS[obj.type] || "--",
        },
        {
            title: "Remarks",
            dataIndex: "remarks",
        }
    ];


    return (
        <div style={{padding: 24, paddingTop: 8}}>
            <Row justify={"center"} align={"middle"}>
                <Col span={12} style={{fontSize: 24, fontWeight: 'bold'}}>
                    Platform Logs
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                </Col>
            </Row>

            <Row>
                <DatePicker.RangePicker
                    onChange={setFilterDate}
                />
                &nbsp;
                <Select
                    placeholder={"User-wise"}
                    style={{width: 150}}
                    // mode={"multiple"}
                    options={users.map(u => ({
                        label: u.name,
                        value: u._id
                    }))}
                    showSearch={true}
                    onChange={setFilterUser}
                    allowClear={true}
                    />
                &nbsp;
                <Select
                    style={{width: 250}}
                    placeholder={"Action-wise"}
                    mode={"multiple"}
                    options={_.map(ERP_LOG_LABELS, (label, value) => ({
                        label, value
                    }))}
                    showSearch={true}
                    maxTagCount={1}
                    onChange={setFilterAction}
                    allowClear={true}
                    />
                &nbsp;
                <Input.Search
                    style={{width: 300}}
                    placeholder={"Search Term"}
                    enterButton={true}
                    onChange={(e) => setFilterText(e.target.value)}
                    onSearch={fetchRecords}
                    // allowClear={true}
                    />
            {/*    <Input.Search onChange={(e) => setSearchText(e.target.value)}*/}
            {/*                  placeholder={"Search with Name"}*/}
            {/*                  style={{width: 'auto', minWidth: 300,*/}
            {/*                      // height: 40*/}
            {/*                  }}*/}
            {/*                  allowClear/>*/}

            </Row>

            {/*<div style={{padding: 16, paddingLeft: 0, paddingRight: 0}}>*/}
            {/*    <h2 style={{float: 'left'}}>{title}</h2>*/}
            {/*    <Button onClick={() => {*/}
            {/*        setSelectedRecord({*/}
            {/*            type*/}
            {/*        });*/}
            {/*        setShowModal(true);*/}
            {/*    }} style={{float: 'right'}} type={"primary"}>*/}
            {/*        Create New Ledger</Button>*/}
            {/*</div>*/}

            <br/>
            <Table
                loading={loading}
                columns={columns}
                pagination={{
                    total: data.count || 0
                }}
                onChange={setPagination}
                dataSource={records.filter(r => true || (r.name || "").toLowerCase().includes("searchText".toLowerCase()))}
            />

        </div>
    );
};

export default ErpLogsPage;
