import React from "react";
import {EditOutlined} from "@ant-design/icons";
import {Button} from "antd";

const EditButton = ({label, onClick, ...otherProps}) => {

    return (
        <Button className={"btnCustom3"}
                style={{
                    padding: 0,
                    height: 28,
                    fontSize: 13,
                    fontWeight: 'bold',
                    paddingLeft: 8,
                    paddingRight: 8,
                    background: '#e8eaf6',
                    color: '#283593'
                }}
                onClick={onClick}
                {...otherProps}
        ><EditOutlined/>{label}
        </Button>
    )
};

export default EditButton;
