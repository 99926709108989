import React, {useEffect, useState} from "react";
import {Button, Col, Input, Row, Select, Table, Tabs} from "antd";
import {PlusOutlined, TransactionOutlined} from "@ant-design/icons";
import {getDevices, getParties} from "../../services/api";
import {customDispatcher} from "../../../utils";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {verifyRole} from "../../utils";
import {DEVICE_STATES, ROLES} from "../../models/constant";
import {useSearchParams} from "react-router-dom";
import _ from "lodash";

const defaultPagination = {
    current: 1,
    total: 10,
    pageSize: 10,
};


const ErpInvAdvanced = () => {
    const miscData = useSelector(state => state.miscData);
    const dispatch = customDispatcher(useDispatch());
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultHolder = searchParams.get("defaultHolder");
    const defaultStates = searchParams.get("defaultStates");

    const [data, setData] = useState({});
    const [pagination, setPagination] = useState(defaultPagination);
    const [loading, setLoading] = useState(false);

    const {devices: records = [], entities = [], deviceModels = []} = data || {};

    // Filters::
    // const [filterTypes, setFilterTypes] = useState(["Blank", "Mapped"]);
    // const [filterModel, setFilterModel] = useState();
    const [filterInventory, setFilterInventory] = useState(defaultHolder || undefined);
    const [filterStates, setFilterStates] = useState(defaultStates ? defaultStates.split(",") : []);
    const [filterSearch, setFilterSearch] = useState("");

    // useEffect(() => {
    //     if (defaultHolder) {
    //         setFilterInventory(defaultHolder);
    //     }
    //     if (defaultStates) {
    //         setFilterStates(defaultStates.split(","))
    //     }
    // }, [defaultHolder, defaultStates]);

    const fetchRecords = async (isPaginationTrigger = false) => {
        const paginationParams = isPaginationTrigger ? pagination : defaultPagination;
        setLoading(true);
        const params = {};
        // if (filterTypes) {
        //     params.types = filterTypes.join(",");
        // }
        // if (filterModel) {
        //     params.model = filterModel;
        // }
        if (filterInventory) {
            params.inventoryHolder = filterInventory;
        }
        if (filterStates && filterStates.length) {
            params.filterStates = filterStates.join(",");
        }
        if (filterSearch) {
            params.search = filterSearch;
        }

        await getDevices({
            ...paginationParams,
            ...params,
        }).then(resp => setData(resp.data.data));
        setLoading(false);
        if (!isPaginationTrigger) {
            setPagination(defaultPagination);
        }
    }

    useEffect( () => {
        if (pagination !== defaultPagination) {
            fetchRecords(true);
        }
    }, [pagination]);

    useEffect(() => {
        fetchRecords();
    }, [filterInventory, filterStates]);

    const columns = [
        {
            title: "Date Added",
            render: obj => moment(obj.dateAdded).format("DD MMM YYYY"),
        },
        {
            title: "Inventory Party",
            render: obj => obj.inventoryHolder?.name || "Stock",
        },
        {
            title: "Item",
            render: obj => obj.model?.name || "---",
        },
        {
            title: "Device ID",
            render: obj => <b>{obj.deviceId}</b>,
        },
        // {
        //     title: "Subscription Plan",
        //     dataIndex: "subscription",
        // },
        // {
        //     title: "Sim",
        //     render: obj => obj.sim ? (obj.sim.mobileNumber + " (" + obj.sim.simNumber.slice(-8) + ")") : "---",
        // },
        {
            title: "Actions",
            render: obj =>
                <a onClick={() => {
                    dispatch({
                        popData: {
                            visible: true,
                            type: "erpInvHistory",
                            // width: 500,
                            title: `Timeline [${obj.deviceId}]`,
                            // segments: ["Manual", "Bulk"],
                            // onSave: fetchRecords,
                            data: {
                                deviceId: obj.deviceId,
                            },
                        }
                    });
                }}>
                    Movement History
                </a>
        }
    ];

    const openDeviceWriteDrawer = () => {
        dispatch({
            popData: {
                visible: true,
                type: "deviceWrite",
                width: 500,
                title: `Add New Devices`,
                segments: ["Manual", "Bulk"],
                onSave: fetchRecords,
                // data: obj,
            }
        });
    }

    const openInventoryTransferDrawer = () => {
        dispatch({
            popData: {
                visible: true,
                type: "inventoryTransfer",
                width: 500,
                title: `Inventory Transfer`,
                segments: ["Manual", "Bulk"],
                onSave: fetchRecords,
                // data: obj,
            }
        });
    }

    return (
        <div style={{padding: 24, paddingTop: 8}}>
            <Row justify={"center"} align={"middle"}>
                <Col span={12} style={{fontSize: 24, fontWeight: 'bold'}}>
                    Advanced (Current stock with IMEIs)
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    {/*<>*/}
                    {/*    {*/}
                    {/*        verifyRole(ROLES.INVENTORY, state?.user) &&*/}
                    {/*        <Button type={"primadry"} onClick={() => {*/}
                    {/*            openInventoryTransferDrawer();*/}
                    {/*        }} style={{*/}
                    {/*            background: 'darkslateblue',*/}
                    {/*            color: 'white',*/}
                    {/*            borderRadius: 6,*/}
                    {/*            fontWeight: 'bold',*/}
                    {/*        }}>*/}
                    {/*            <TransactionOutlined />Inventory Transfer*/}
                    {/*        </Button>*/}
                    {/*    }*/}
                    {/*    &nbsp;*/}
                    {/*    {*/}
                    {/*        verifyRole(ROLES.ADD_DEVICES, state?.user) &&*/}
                    {/*        <Button type={"primary"} onClick={() => {*/}
                    {/*            openDeviceWriteDrawer();*/}
                    {/*        }} style={{*/}
                    {/*            // background: 'darkslateblue',*/}
                    {/*            // color: 'white',*/}
                    {/*            borderRadius: 6,*/}
                    {/*            fontWeight: 'bold',*/}
                    {/*        }}>*/}
                    {/*            <PlusOutlined style={{}}/> New Devices*/}
                    {/*        </Button>*/}

                    {/*    }*/}

                    {/*</>*/}

                </Col>
            </Row>
<br/>
            <Row>
                {/*<Select*/}
                {/*    mode={"multiple"}*/}
                {/*    placeholder={"Type"}*/}
                {/*    style={{width: 250}}*/}
                {/*    maxTagCount={2}*/}
                {/*    options={*/}
                {/*        DEVICE_STATES.map(s => ({*/}
                {/*            label: s,*/}
                {/*            value: s,*/}
                {/*        }))*/}
                {/*    }*/}
                {/*    allowClear={true}*/}
                {/*    showSearch={true}*/}
                {/*    value={filterTypes}*/}
                {/*    onChange={setFilterTypes}*/}
                {/*/>*/}
                {/*&nbsp;*/}
                <Select
                    placeholder={"Inventory Party"}
                    style={{width: 200}}
                    options={
                        entities.filter(e => e.type !== "M2M Account").map(p => ({
                            label: `${p.name} (${p.inventoryBalance || 0})`,
                            value: p._id,
                        }))
                    }
                    allowClear={true}
                    showSearch={true}
                    value={filterInventory}
                    onChange={setFilterInventory}
                />
                {/*&nbsp;*/}
                <Select
                    placeholder={"Select States to filter"}
                    mode={"multiple"}
                    style={{width: 500}}
                    defaultValue={[]}
                    options={
                        _.map(miscData?.invStateLabels || {}, (label, value) => ({
                            label,
                            value,
                        }))
                    }
                    allowClear={true}
                    showSearch={true}
                    value={filterStates}
                    onChange={setFilterStates}
                />
                {/*<Select*/}
                {/*    placeholder={"H/W Model"}*/}
                {/*    style={{width: 200}}*/}
                {/*    options={*/}
                {/*        deviceModels.map(model => ({*/}
                {/*            label: model.name,*/}
                {/*            value: model._id*/}
                {/*        }))*/}
                {/*    }*/}
                {/*    allowClear={true}*/}
                {/*    showSearch={true}*/}
                {/*    onChange={setFilterModel}*/}
                {/*/>*/}
                &nbsp;
                <Input.Search
                    style={{width: 300}}
                    placeholder={"Search with Device ID"}
                    enterButton={true}
                    onChange={(e) => setFilterSearch(e.target.value)}
                    onSearch={fetchRecords}
                />


            </Row>

            <br/>

            <Table
                loading={loading}
                columns={columns}
                pagination={{...pagination, total: data.count || records.length || 0}}
                onChange={setPagination}
                dataSource={records.filter(r => true || (r.name || "").toLowerCase().includes("searchText".toLowerCase()))}
            />

        </div>
    );
};

export default ErpInvAdvanced;
