import React, {useEffect, useState} from "react";
import {getCustomers, saveCustomer, saveProduct} from "../../services/api";
import {Button, Col, Form, Input, InputNumber, message, Modal, Popconfirm, Row, Select, Table} from "antd";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import _ from "lodash";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import { ButtonV2 } from "../../components/ButtonV2";

const CustomerPage = () => {
    const state = useSelector(state => state.oldState);
    const params = useParams();
    const {customerId} = params;
    const [customer, setCustomer] = useState({});
    const [showSubModal, setShowSubModal] = useState(false);
    const [editId, setEditId] = useState("");
    const [formSub] = Form.useForm();


    useEffect(() => {
        const fetchCustomers = async () => {
            await getCustomers().then(resp => setCustomer(resp.data.data.customers.filter(({_id}) => _id === customerId)[0]));
        }

        if (state?.company) {
            fetchCustomers();
        }
    }, [state, customerId]);


    useEffect(() => {
        const plan = customer?.subscriptions?.[editId];
        formSub.resetFields();
        if (plan) {
            formSub.setFieldsValue(plan);
        }
    }, [editId, customer?.subscriptions, formSub]);

    const columns = [
        {
            title: "S.No.",
            render: (curr, obj, index) => (index + 1).toString()
        },
        {
            title: "Plan Name",
            dataIndex: "name",
        },
        {
            title: "Duration",
            dataIndex: "duration",
        },
        {
            title: "Price",
            dataIndex: "price"
        },
        {
            title: "Actions",
            render: (curr, obj, index) => (
                <>
                    <Button type={"primary"}
                            onClick={() => {
                                setEditId(index);
                                setShowSubModal(true);
                            }}
                    ><EditOutlined/></Button>
                    <Popconfirm title={"Are you sure to delete this plan ?"} onConfirm={async () => {
                        const clonedCustomer = _.cloneDeep(customer);
                        clonedCustomer.subscriptions.splice(index, 1);
                        setCustomer(clonedCustomer);
                        // return;
                        await saveProduct({customer: clonedCustomer});
                        message.success("Plan deleted successfully");
                    }}>
                        <Button type={"danger"}><DeleteOutlined/></Button>
                    </Popconfirm>
                </>
            )
        }
    ]


    return (
        <div>
            <h2 style={{float: 'left'}}>Custome Info: {customer?.name}</h2>
            <ButtonV2 onClick={async () => {
                setEditId("");
                setShowSubModal(true);
            }} style={{float: 'right'}} type={"primary"}>
                Link New Subscription</ButtonV2>
            <Table
                columns={columns}
                dataSource={customer?.subscriptions}
            />

            <Modal
                visible={showSubModal}
                title={"Link Subscription"}
                okText={"Add"}
                cancelText="Cancel"
                onCancel={() => {
                    // formPlan.resetFields();
                    setShowSubModal(false);
                }}
                onOk={async () => {
                    const values = await formSub.validateFields();
                    console.log("## Plan Vals ##", {editId, values});
                    const clonedCustomer = _.cloneDeep(customer);
                    if (editId !== "") { // edit
                        clonedCustomer.subscriptions[editId] = values;
                    } else { // new
                        if (clonedCustomer.subscriptions) {
                            clonedCustomer.subscriptions.push(values);
                        } else {
                            clonedCustomer.subscriptions = [values];
                        }

                    }
                    setCustomer(clonedCustomer);
                    setShowSubModal(false);
                    // return;
                    await saveCustomer({customer: clonedCustomer});
                    message.success("Customer saved successfully");
                    //         const email = values.email;
                    //         if (_.filter(users || [], user => user.email === email).length) {
                    //             message.error("User already added !");
                    //             return;
                    //         }
                    //         const resp = await addSubuser({
                    //             email
                    //         });
                    //         if (_.get(resp, 'data.success')) {
                    //             message.success("Invite sent successfully");
                    //             form.resetFields();
                    //             onCancel();
                    //         } else {
                    //             message.error(resp.data.message);
                    //         }
                    //     })
                }}
            >
                <Form
                    form={formSub}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{}}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{required: true, message: "can't be blank"}]}
                    >
                        <Input/>
                    </Form.Item>
                    Plan Validity:
                    <Row>
                        <Col span={12}>
                            <Form.Item name={"duration"} label={""}
                                       rules={[{required: true, message: "can't be blank"}]}>
                                <InputNumber style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={"durationUnit"} label={""}
                                       rules={[{required: true, message: "can't be blank"}]}>
                                <Select
                                    options={[
                                        {label: "Days", value: "d"},
                                        {label: "Months", value: "m"},
                                        {label: "Years", value: "y"}
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name={"price"} label={"Price"} rules={[{required: true, message: "can't be blank"}]}>
                        <InputNumber style={{width: '100%'}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
};

export default CustomerPage;
