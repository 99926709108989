import React, {useEffect, useState} from "react";
import {Button, Col, DatePicker, Input, message, Row, Select, Table, Tabs} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {deleteInstallation, getDevices} from "../../services/api";
import {customDispatcher} from "../../../utils";
import {useDispatch} from "react-redux";
import moment from "moment";
import EditButton from "../../components/buttons/EditButton";
import DeleteButton from "../../components/buttons/DeleteButton";
import {SUBSCRIPTIONS} from "../../models/constant";


const defaultPagination = {
    current: 1,
    total: 10,
    pageSize: 10,
};


const InstallationsPage = () => {
    const dispatch = customDispatcher(useDispatch());
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState(defaultPagination);

    const {devices: records = [], entities = []} = data || {};

    // Filters:
    const [filterDate, setFilterDate] = useState(null);
    const [filterTech, setFilterTech] = useState();
    const [filterSearch, setFilterSearch] = useState("");

    const fetchRecords = async (isPaginationTrigger = false) => {
        const paginationParams = isPaginationTrigger ? pagination : defaultPagination;
        setLoading(true);
        const params = {
            queryType: "installations",
        };

        if (filterDate) {
            const [from, to] = filterDate;
            params.from = from.startOf('day').valueOf();
            params.to = to.endOf('day').valueOf();
        }
        if (filterTech) {
            params.installedBy = filterTech;
        }
        if (filterSearch) {
            params.search = filterSearch;
        }

        const response = await getDevices({
            ...paginationParams,
            ...params,
        });
        setData(response.data.data);
        setLoading(false);
        if (!isPaginationTrigger) {
            setPagination(defaultPagination);
        }
    }

    useEffect(() => {
        fetchRecords(true);
    }, [pagination]);

    useEffect(() => {
        fetchRecords();
    }, [filterDate, filterTech]);

    const columns = [
        {
            title: "Date",
            render: obj => <>
                {moment(obj.installationDate).format("DD MMM YYYY")}
                <br/>
                <div style={{fontSize: 12, color: 'grey'}}>
                    By: {obj.installedBy?.name}
                </div>
            </>
        },
        {
            title: "Device ID",
            dataIndex: "deviceId",
        },
        {
            title: "Subscription Plan",
            render: obj => SUBSCRIPTIONS[obj.subscriptionPlan] || "---",
        },
        {
            title: "Sim",
            render: obj => <>
                {obj.sim?.mobileNumber}
                <br/>
                <div style={{fontSize: 12, color: 'grey'}}>
                    # {obj.sim?.simNumber?.slice?.(-8)}
                </div>
            </>
        },
        {
            title: "Customer",
            render: (record) => <>
                <b>{record.customerName}</b>
                <br/>
                <div style={{fontSize: 12, color: 'grey'}}>
                    {record.customerPhone}
                </div>
            </>,
        },
        {
            title: "Vehicle",
            render: (record) => <>
                <b>{record?.vehicleNumber}</b>
                <br/>
                <div style={{fontSize: 12, color: 'grey'}}>
                    {record?.vehicleModel}
                </div>
            </>,
        },
        {
            title: "Actions",
            render: (record) => <>
                <EditButton onClick={() => {
                    openDrawer(record);
                }} />
                &nbsp;
                <DeleteButton onClick={async () => {
                    await deleteInstallation(record._id);
                    message.success("Installation deleted successfully");
                    fetchRecords();
                }} />
            </>
        }
    ];


    const openDrawer = (record) => {
        const isNew = !record;
        console.log("## Record ##", record);
        dispatch({
            popData: {
                visible: true,
                type: "installation",
                // width: 600,
                // size: "medium",
                title: isNew ? `Add New Installation` : `Edit Installation`,
                onSave: fetchRecords,
                record: record || {},
                // segments: ["Manual", "Bulk"]
                // data: obj,
            }
        })
    }


    return (
        <div style={{padding: 24, paddingTop: 8}}>
            <Row justify={"center"} align={"middle"}>
                <Col span={12} style={{fontSize: 24, fontWeight: 'bold'}}>
                    Installations
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <>
                        <Button type={"primary"} onClick={() => {
                            openDrawer();
                        }} style={{
                            // background: 'darkslateblue',
                            // color: 'white',
                            borderRadius: 6,
                            fontWeight: 'bold',
                        }}>
                            <PlusOutlined style={{}}/> New Installation
                        </Button>
                    </>

                </Col>
            </Row>

            <Row>
                <DatePicker.RangePicker
                    onChange={setFilterDate}
                />
                &nbsp;
                <Select
                    placeholder={"Techincian-wise"}
                    style={{width: 150}}
                    // mode={"multiple"}
                    options={entities.filter(e => e.type==="Technician").map(u => ({
                        label: u.name,
                        value: u._id
                    }))}
                    showSearch={true}
                    onChange={setFilterTech}
                    allowClear={true}
                />
                &nbsp;
                <Input.Search
                    style={{width: 350}}
                    placeholder={"Search with Device ID / Customer / Vehicle"}
                    enterButton={true}
                    onChange={(e) => setFilterSearch(e.target.value)}
                    onSearch={fetchRecords}
                    // allowClear={true}
                />

            </Row>

            <br/>
            <Table
                loading={loading}
                columns={columns}
                pagination={{...pagination, total: data.count || records.length || 0}}
                onChange={setPagination}
                dataSource={records.filter(r => true || (r.name || "").toLowerCase().includes("searchText".toLowerCase()))}
            />

        </div>
    );
};

export default InstallationsPage;
