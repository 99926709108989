import React, {useEffect, useState} from "react";
import {
    getCustomers,
    getProducts,
    getSubscriptions,
    saveSubscription
} from "../../services/api";
import {
    Badge,
    Button,
    DatePicker,
    Form,
    Input,
    message,
    Modal,
    Select,
    Table
} from "antd";
import {useSelector} from "react-redux";
import _ from "lodash";
import moment from "moment";
import { ButtonV2 } from "../../components/ButtonV2";

const Subscriptions = () => {
    const state = useSelector(state => state.oldState);
    const [customers, setCustomers] = useState([]);
    const [products, setProducts] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);

    const customer = {};
    const [showSubModal, setShowSubModal] = useState(false);
    const [editId, setEditId] = useState("");
    const [formSub] = Form.useForm();
    const [formSelectedProduct, setFormSelectedProduct] = useState();

    const customersMap = {};
    const productsMap = {};

    customers.forEach(c => {
        customersMap[c._id] = c
    });

    products.forEach(p => {
        productsMap[p._id] = p;
    });

    console.log("## Maps ##", {customersMap, productsMap, subscriptions});

    const fetchSubscriptions = async () => {
        getSubscriptions().then(resp => setSubscriptions(resp.data.data.subscriptions));
        console.log("## Fetched Subscriptions ##");
    }

    const fetchCustomers = async () => {
        await getCustomers().then(resp => setCustomers(resp.data.data.customers));
        console.log("## Fetched Customers ##");
    }

    const fetchProducts = async () => {
        await getProducts().then(resp => setProducts(resp.data.data.products));
        console.log("## Fetched Products ##");
    }

    useEffect(() => {
        if (state?.company) {
            fetchCustomers();
            fetchProducts();
            fetchSubscriptions();
        }
    }, [state]);


    useEffect(() => {
        const plan = customer?.subscriptions?.[editId];
        formSub.resetFields();
        if (plan) {
            formSub.setFieldsValue(plan);
        }
    }, [editId, formSub, customer?.subscriptions]);

    const columns = [
        {
            title: "S.No.",
            render: (curr, obj, index) => (index + 1).toString()
        },
        {
            title: "User",
            render: (curr) => customersMap[curr.customerId]?.name || "---"
        },
        {
            title: "Product",
            render: (curr) => productsMap[curr.productId]?.name || "---"
        },
        {
            title: "Custom Fields",
            render: (curr) => (<div>
                {_.map(curr?.customFieldValues, (val, key) => (
                    <div>{key}: {val}</div>
                ))}
            </div>)
        },
        {
            title: "Activation Date",
            render: (curr) => curr.activationDate ? moment(curr.activationDate).format("DD MMM YYYY") : "---"
        },
        {
            title: "Expiry Date",
            render: (curr) => curr.expiryDate ? moment(curr.expiryDate).format("DD MMM YYYY") : "---"
        },
        {
            title: "Status",
            render: (curr) => {
                const isActive = moment(curr.expiryDate).diff(moment()) > 0;
                return <>
                    {isActive ? <Badge status="success" /> : <Badge status="error" />}
                    <span style={{color: isActive ? "green" : "red"}}>
                        {isActive ? "Active" : "Expired"}
                    </span>
                </>
            }

        },
        {
            title: "Actions",
            render: (curr, obj, index) => (
                <>
                    More Details
                {/*    <Button type={"primary"}*/}
                {/*            onClick={() => {*/}
                {/*                setEditId(index);*/}
                {/*                setShowSubModal(true);*/}
                {/*            }}*/}
                {/*    ><EditOutlined/></Button>*/}
                {/*    <Popconfirm title={"Are you sure to delete this plan ?"} onConfirm={async () => {*/}
                {/*        const clonedCustomer = _.cloneDeep(customer);*/}
                {/*        clonedCustomer.subscriptions.splice(index, 1);*/}
                {/*        setCustomer(clonedCustomer);*/}
                {/*        // return;*/}
                {/*        await saveProduct({customer: clonedCustomer});*/}
                {/*        message.success("Plan deleted successfully");*/}
                {/*    }}>*/}
                {/*        <Button type={"danger"}><DeleteOutlined/></Button>*/}
                {/*    </Popconfirm>*/}
                </>
            )
        }
    ]


    return (
        <div>
            <h2 style={{float: 'left'}}>Subscriptions</h2>
            <ButtonV2 onClick={async () => {
                formSub.resetFields();
                setEditId("");
                setShowSubModal(true);
            }} style={{float: 'right'}} type={"primary"}>
                Link New Subscription</ButtonV2>
            <Table
                columns={columns}
                dataSource={subscriptions}
            />

            <Modal
                visible={showSubModal}
                title={"Link Subscription"}
                okText={"Add"}
                cancelText="Cancel"
                onCancel={() => {
                    // formPlan.resetFields();
                    setShowSubModal(false);
                }}
                onOk={async () => {
                    const values = await formSub.validateFields();
                    const subscription = {
                        customerId: values.customerId,
                        productId: values.productId,
                        activationDate: values.activationDate,
                        expiryDate: values.activationDate,
                    };
                    delete values.customerId;
                    delete values.productId;
                    delete values.activationDate;
                    subscription.customFieldValues = values;
                    console.log("## Plan Vals ##", {subscription});
                    await saveSubscription({subscription});
                    message.success("Customer saved successfully");
                    fetchSubscriptions();
                    setShowSubModal(false);
                }}
            >
                <Form
                    form={formSub}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{}}
                >
                    <Form.Item name={"activationDate"} label={"Activation Date"} rules={[{required: true, message: "Please select"}]}>
                        <DatePicker format={"DD MMM YYYY"} allowClear={false}/>
                    </Form.Item>
                    <Form.Item
                        name="customerId"
                        label="Customer"
                        rules={[{required: true, message: "Please select user"}]}
                    >
                        <Select
                            showSearch={true}
                            options={
                                customers.map(c => ({label: c.name, value: c._id}))
                            }
                        />
                    </Form.Item>
                    <Form.Item name={"productId"} label={"Product"} rules={[{required: true, message: "Please select Product"}]}>
                        <Select
                            showSearch={true}
                            onChange={(val) => setFormSelectedProduct(productsMap[val])}
                            options={
                                products.map(c => ({label: c.name, value: c._id}))
                            }
                        />
                    </Form.Item>
                    {
                        formSelectedProduct?.customFields?.map?.(cField => (
                            <Form.Item name={cField} label={cField}>
                                <Input/>
                            </Form.Item>
                        ))
                    }
                </Form>
            </Modal>
        </div>
    )
};

export default Subscriptions;
