import React, {useEffect, useState} from "react";
import _ from "lodash";
import {Button, Col, Input, Row, Select, Table, Tabs} from "antd";
import {PlusOutlined, TransactionOutlined} from "@ant-design/icons";
import {getDevices, getErpInvSummary, getParties} from "../../services/api";
import {customDispatcher} from "../../../utils";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {verifyRole} from "../../utils";
import {DEVICE_STATES, ROLES} from "../../models/constant";
import {Link} from "react-router-dom";

const defaultPagination = {
    current: 1,
    total: 10,
    pageSize: 10,
};

const defaultFilterStates = ["STOCK_OK"];

const ErpInvSummaryPage = () => {
    const miscData = useSelector(state => state.miscData);
    const summaryFilterStates = useSelector(state => state.summaryFilterStates || defaultFilterStates);
    console.log("## summaryFilterStates ##", summaryFilterStates);
    const dispatch = customDispatcher(useDispatch());
    const [data, setData] = useState({});
    const [pagination, setPagination] = useState(defaultPagination);
    const [loading, setLoading] = useState(false);

    const {summary: records = [], entities = [], deviceModels = []} = data || {};

    // Filters::
    // const [filterStates, setFilterStates] = useState(["STOCK_OK"]);
    const filterStates = summaryFilterStates;
    const fetchRecords = async (isPaginationTrigger = false) => {
        const paginationParams = isPaginationTrigger ? pagination : defaultPagination;
        setLoading(true);
        const params = {};


        if (filterStates) {
            params.states = filterStates.join(",");
        }


        await getErpInvSummary({
            ...paginationParams,
            ...params,
        }).then(resp => setData(resp.data.data));
        setLoading(false);
        if (!isPaginationTrigger) {
            setPagination(defaultPagination);
        }
    }

    // useEffect( () => {
    //     fetchRecords(true);
    // }, [pagination]);

    useEffect(() => {
        fetchRecords();
    }, [filterStates]);

    const columns = [
        {
            title: "Inventory Party",
            render: obj => obj.inventoryParty?.name || "Stock",
        },
        {
            title: "Qty",
            render: obj => obj.count || "---",
        },
        {
            title: "Actions",
            render: obj =>
                <Link
                to={`/erp_inv/advanced?` + new URLSearchParams({
                    defaultHolder: obj._id,
                    defaultStates: filterStates.join(",")
                })}
                >
                    IMEI-wise details
                </Link>
        }
    ];

    const openDeviceWriteDrawer = () => {
        dispatch({
            popData: {
                visible: true,
                type: "deviceWrite",
                width: 500,
                title: `Add New Devices`,
                segments: ["Manual", "Bulk"],
                onSave: fetchRecords,
                // data: obj,
            }
        });
    }

    const openInventoryTransferDrawer = () => {
        dispatch({
            popData: {
                visible: true,
                type: "inventoryTransfer",
                width: 500,
                title: `Inventory Transfer`,
                segments: ["Manual", "Bulk"],
                onSave: fetchRecords,
                // data: obj,
            }
        });
    }

    return (
        <div style={{padding: 24, paddingTop: 8}}>
            <Row justify={"center"} align={"middle"}>
                <Col span={12} style={{fontSize: 24, fontWeight: 'bold'}}>
                    Inventory Summary
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    {/*<>*/}
                    {/*    {*/}
                    {/*        verifyRole(ROLES.INVENTORY, state?.user) &&*/}
                    {/*        <Button type={"primadry"} onClick={() => {*/}
                    {/*            openInventoryTransferDrawer();*/}
                    {/*        }} style={{*/}
                    {/*            background: 'darkslateblue',*/}
                    {/*            color: 'white',*/}
                    {/*            borderRadius: 6,*/}
                    {/*            fontWeight: 'bold',*/}
                    {/*        }}>*/}
                    {/*            <TransactionOutlined />Inventory Transfer*/}
                    {/*        </Button>*/}
                    {/*    }*/}
                    {/*    &nbsp;*/}
                    {/*    {*/}
                    {/*        verifyRole(ROLES.ADD_DEVICES, state?.user) &&*/}
                    {/*        <Button type={"primary"} onClick={() => {*/}
                    {/*            openDeviceWriteDrawer();*/}
                    {/*        }} style={{*/}
                    {/*            // background: 'darkslateblue',*/}
                    {/*            // color: 'white',*/}
                    {/*            borderRadius: 6,*/}
                    {/*            fontWeight: 'bold',*/}
                    {/*        }}>*/}
                    {/*            <PlusOutlined style={{}}/> New Devices*/}
                    {/*        </Button>*/}

                    {/*    }*/}

                    {/*</>*/}

                </Col>
            </Row>
            <br/>
            <Row>
                {/*<Select*/}
                {/*    mode={"multiple"}*/}
                {/*    placeholder={"Type"}*/}
                {/*    style={{width: 250}}*/}
                {/*    maxTagCount={2}*/}
                {/*    options={*/}
                {/*        DEVICE_STATES.map(s => ({*/}
                {/*            label: s,*/}
                {/*            value: s,*/}
                {/*        }))*/}
                {/*    }*/}
                {/*    allowClear={true}*/}
                {/*    showSearch={true}*/}
                {/*    value={filterTypes}*/}
                {/*    onChange={setFilterTypes}*/}
                {/*/>*/}
                {/*&nbsp;*/}
                <Select
                    placeholder={"Select States to show summaries"}
                    mode={"multiple"}
                    style={{width: 500}}
                    defaultValue={["STOCK_OK"]}
                    options={
                        _.map(miscData?.invStateLabels || {}, (label, value) => ({
                            label,
                            value,
                        }))
                        // entities.filter(e => e.type !== "M2M Account").map(p => ({
                        //     label: `${p.name} (${p.inventoryBalance || 0})`,
                        //     value: p._id,
                        // }))
                    }
                    allowClear={true}
                    showSearch={true}
                    value={filterStates}
                    onChange={(newFilterStates) => {
                        dispatch({
                            summaryFilterStates: newFilterStates
                        });
                    }}
                />
                {/*&nbsp;*/}
                {/*<Select*/}
                {/*    placeholder={"H/W Model"}*/}
                {/*    style={{width: 200}}*/}
                {/*    options={*/}
                {/*        deviceModels.map(model => ({*/}
                {/*            label: model.name,*/}
                {/*            value: model._id*/}
                {/*        }))*/}
                {/*    }*/}
                {/*    allowClear={true}*/}
                {/*    showSearch={true}*/}
                {/*    onChange={setFilterModel}*/}
                {/*/>*/}
                {/*&nbsp;*/}
                {/*<Input.Search*/}
                {/*    style={{width: 300}}*/}
                {/*    placeholder={"Search with Device ID"}*/}
                {/*    enterButton={true}*/}
                {/*    onChange={(e) => setFilterSearch(e.target.value)}*/}
                {/*    onSearch={fetchRecords}*/}
                {/*/>*/}


            </Row>

            <br/>

            <Table
                loading={loading}
                columns={columns}
                pagination={{...pagination, total: data.count || records.length || 0}}
                onChange={setPagination}
                dataSource={records.filter(r => true || (r.name || "").toLowerCase().includes("searchText".toLowerCase()))}
            />

        </div>
    );
};

export default ErpInvSummaryPage;
