import React, {useEffect} from "react";
import {Button, Form, Input, message, Select} from "antd";
import {saveDeviceModel, saveParty} from "../../../erp/services/api";
import {partyTypeOptions} from "../../../erp/models/constant";

const PartyWriteDrawer = ({closeDrawer, onSave, record = {type: "Client"}}) => {

    const isNewRecord = !record._id;
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(record);
    }, [record]);

    return (
        <div>
            <Form
                form={form}
                layout={"vertical"}
                onFinish={async (vals) => {
                    console.log("## FormVals ##", vals);
                    if (!isNewRecord) {
                        vals._id = record._id;
                    }
                    await saveParty({entity: vals});
                    message.success(`Party saved successfully`);
                    closeDrawer();
                    onSave();
                }}
            >
                <Form.Item label={"Type"} name={"type"}>
                    <Select
                        options={partyTypeOptions.map(t => ({
                            label: t,
                            value: t,
                        }))}
                    />
                </Form.Item>
                <Form.Item label={"Name"} name={"name"} rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Button type={"primary"} htmlType={"submit"}>
                    Save Party
                </Button>
            </Form>
        </div>
    )
};

export default PartyWriteDrawer;
