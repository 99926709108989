import React, {useCallback, useEffect, useState} from "react";
import {EyeOutlined, PlusOutlined} from "@ant-design/icons";
import {Image, message, Modal, Upload} from "antd";
import _ from "lodash";
import {baseUrl} from "../services/api";

const onFileRemove = () => {
    const {confirm} = Modal
    return new Promise((resolve, reject) => {
        confirm({
            title: 'Are you sure to Delete this attachment ?',
            onOk: () => resolve(true),
            onCancel: () => reject(true),
        })
    })
}

const allowedFileTypes = [
    "application/pdf",
    "image/jpeg",
    "image/png",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const getDisplayNameFromFilename = (filename) => {
    const extensionDotPosition = filename.lastIndexOf(".");
    return `file-${filename.slice(extensionDotPosition - 2)}`;
}

const AttachmentTypes = {
    IMAGE: "image",
    PDF: "pdf",
    OTHERS: "others",
};

const getAttachmentType = (filename) => {
    const filenameParts = filename.split(".");
    const extension = filenameParts[filenameParts.length - 1];

    if (extension === "pdf") { // preview in iframe in new tab
        return AttachmentTypes.PDF;
    } else if (["png", "jpg", "jpeg"].includes(extension)) { // image
        return AttachmentTypes.IMAGE;
    } else { // all other types (like excel): trigger download
        return AttachmentTypes.OTHERS;
    }
}

const uploadButton = (
    <div>
        <PlusOutlined/>
        <div
            style={{
                marginTop: 8,
            }}
        >
            Upload
        </div>
    </div>
);


const VoucherAttachments = ({value , onChange}) => {
    const [imagePreviewData, setImagePreviewData] = useState({
        visible: false,
        src: "",
    });
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        const newFileList = (value || []).map(attachmentFileName => ({
            uid: attachmentFileName,
            name: getDisplayNameFromFilename(attachmentFileName),
            filename: attachmentFileName,
            status: "done",
            url: `${baseUrl}/internal/file/${attachmentFileName}`
        }));
        setFileList(newFileList);
    }, [value]);


    const handlePreview = useCallback((file) => {
        // handle 3 types:: image, pdf, others (excel, etc.)
        const {filename} = file;
        const attachmentType = getAttachmentType(filename);

        if (attachmentType === AttachmentTypes.PDF) { // preview in iframe in new tab
            window.open(`/api/internal/previewFile/${filename}`, '_blank');
        } else if (attachmentType === AttachmentTypes.IMAGE) { // image
            setImagePreviewData({
                visible: true,
                src: file.url,
            });
        } else { // all other types (like excel): trigger download
            window.open(`/api/internal/file/${filename}`, '_blank');
        }
    }, []);

    const handleChange = useCallback((args) => {
        const newFileList = (args.fileList || []).filter(file => {
            return !["error", "removed"].includes(file.status);
        });

        if (["done", "removed"].includes(args.file?.status)) { // either successfully uploaded or removed: update Value
            if (args.file.status === 'done') {
                const filename = _.get(args, 'file.response.data.filename');
                args.file.filename = filename;
                args.file.name = getDisplayNameFromFilename(filename);
                args.file.url = `${baseUrl}/internal/file/${filename}`;
            }

            const newAttachments = newFileList.map(file => file.filename);

            if ((value || []).length !== newAttachments.length && onChange) {
                onChange(newAttachments);
            }
        }

        setFileList(newFileList);
    }, [value, onChange]);


    return (
        <>
            <Upload
                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                action={`/api/internal/upload`}
                onRemove={onFileRemove}
                showUploadList={{
                    showPreviewIcon: true,
                    showDownloadIcon: true,
                    showRemoveIcon: true,
                    previewIcon: (file) => {
                        const attachmentType = getAttachmentType(file.filename || file.name);
                        if (attachmentType === AttachmentTypes.OTHERS) {
                            return null;
                        }
                        return <EyeOutlined/>;
                    }
                }}
                beforeUpload={(file) => {
                    if (!allowedFileTypes.includes(file.type)) {
                        message.error('You can only upload JPG/PNG or PDF or Excel file!');
                        file.status = "error";
                        return false;
                    }
                    const isLt2M = file.size / 1024 / 1024 < 10;
                    if (!isLt2M) {
                        message.error('File size must be smaller than 2 MB!');
                        file.status = "error";
                        return false;
                    }
                    return true;
                }}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
            >
                {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            <Image
                width={200}
                style={{display: 'none'}}
                preview={{
                    visible: imagePreviewData.visible,
                    src: imagePreviewData.src,
                    onVisibleChange: value => {
                        setImagePreviewData({
                            visible: false,
                            src: ""
                        });
                    },
                }}
            />
        </>
    );
}

export default VoucherAttachments;