import {assignTableColsWidth, customDispatcher, formulateItems} from "../../utils";
import _ from "lodash";
import {Button, Divider, Form, Input, InputNumber, Select, Table, Tooltip} from "antd";
import {CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {RUPEE} from "../../models/constants";
import {SwitchSelect} from "../../components/switch";

const EDIT_MODE_BACKGROUND = '#f2f7f3';
const EDIT_MODE_BORDER = '1px solid lightgrey';



const ItemFormLine = ({line = {editMode: true, newMode: true, priceType: "inc"}, onSave, onCancel, isIgst, isAbroad}) => {
    if (!line.discountType) {
        line.discountType = "percent";
    }
    const state = useSelector(state => state.oldState);

    const dispatch = customDispatcher(useDispatch());
    const [discountType, setDiscountType] = useState('percent');
    const [selectedItem, setSelectedItem] = useState({price: 0, qty: 1, taxRate: 0, ...line});

    const {tableItems} = formulateItems({
        items: [selectedItem],
        dbItemsMap: _.keyBy(state.items || [], "_id"), isIgst, extraDiscount: {}, isAbroad
    });
    const calculations = tableItems[0];

    const [form] = Form.useForm();

    const formCols = [
        {
            // item name, description
            weight: 5,
            content:
                <>
                    <Form.Item
                        name="_id"
                        // label="Item"
                        rules={[
                            {
                                required: true,
                                message: "select item"
                            },
                        ]}
                        style={{marginBottom: 8}}
                    >
                        <Select
                            placeholder={"Select Item"}
                            options={_.map(state.items || [], ({name, _id}) => ({
                                label: name,
                                value: _id
                            }))}
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(selectedItemId) => {
                                const _selectedItem = _.filter(state.items || [], item => item._id === selectedItemId)[0] || {};
                                const updateVals = {};
                                const clonedItem = _.cloneDeep(selectedItem);

                                if (_selectedItem.defaultRate) {
                                    updateVals.price = _selectedItem.defaultRate;
                                    clonedItem.price = _selectedItem.defaultRate;
                                }
                                if (_selectedItem.defaultDescription) {
                                    updateVals.description = _selectedItem.defaultDescription || "";
                                }
                                
                                let currentQtyInput = form.getFieldValue("qty");
                                if (!currentQtyInput) {
                                    // currentQtyInput = 1
                                    updateVals.qty = 1
                                     // toDo - can set default qty to 1 but not required as of now (for product)
                                }
                                form.setFieldsValue(updateVals);
                                clonedItem.hsn = _selectedItem.hsn;
                                clonedItem.taxRate = _selectedItem.taxRate;
                                clonedItem._id = _selectedItem._id;
                                setSelectedItem(clonedItem);
                            }}
                            dropdownRender={menu => (
                                <div>
                                    {menu}
                                    <Divider style={{margin: '4px 0'}}/>
                                    <Link to={"#"} style={{width: '100%', paddingLeft: 16}} onClick={() => {
                                        dispatch({
                                            popData: {
                                                visible: true,
                                                width: 550,
                                                type: "item",
                                                title: "Add New Item",
                                                bodyStyle: {
                                                    padding: 16,
                                                },
                                                data: {
                                                    selectedRecord: {
                                                        type: "Product",
                                                        unit: "NOS",
                                                    },
                                                    onSave: (newItemAdded) => {
                                                        form.setFieldsValue({
                                                            _id: newItemAdded._id,
                                                            description: newItemAdded.defaultDescription,
                                                            price: newItemAdded.defaultRate,
                                                            qty: form.getFieldValue("qty") || 1
                                                        });
                                                        setSelectedItem({price: newItemAdded?.defaultRate|| 0, taxRate: newItemAdded?.taxRate || 0, qty:1,...line})
                                                    }
                                                }
                                            }
                                        });
                                    }}><PlusOutlined/> Add New Item
                                    </Link>
                                </div>
                            )}
                            showSearch
                        />
                    </Form.Item>
                    <Form.Item name={"description"}
                               style={{marginBottom: 8}}
                        // label={"Description"}
                    >
                        <Input.TextArea placeholder={"Description"}/>
                    </Form.Item>
                </>
        },
        {
            // hsn
            weight: 2,
            align: 'right',
            content: <Input value={selectedItem.hsn || "---"} disabled={true}/>
        },
        {
            // qty
            weight: 2,
            align: 'right',
            content: <Form.Item
                name={"qty"}
                // label={"Qty"}
                rules={[{required: true, message: "can't be blank"}]}>
                <InputNumber placeholder={"Qty"} style={{width: '100%'}} min={1}/>
            </Form.Item>
        },
        {
            // rate
            weight: 3,
            content: <>
                <Form.Item name={"price"}
                           style={{marginBottom: 8}}
                    // label={"Price per unit"}
                           rules={[{required: true, message: "can't be blank"}]}>
                    <InputNumber prefix={RUPEE} placeholder={"Price per unit"} style={{width: '100%'}} min={0}/>
                </Form.Item>
                <center>
                    <Form.Item name={"priceType"}>
                        <SwitchSelect
                            defaultChecked={true}
                            checkedChildren={"With Tax"}
                            unCheckedChildren={"Without Tax"}
                            valuesMap={{
                                true: "inc",
                                false: "exc"
                            }}
                            reverseMap={{
                                inc: true,
                                exc: false
                            }}
                        />
                    </Form.Item>
                </center>
            </>
        },
        {
            // discount
            weight: 3,
            content: <>
                <Form.Item name={"discountValue"}
                           style={{marginBottom: 8}}
                    // label={"Price per unit"}
                    //        rules={[{required: true, message: "can't be blank"}]}
                >
                    <Input suffix={discountType==='percent' && '%'} prefix={discountType!=='percent' && RUPEE} placeholder={"Discount"} style={{width: '100%'}} min={0}/>
                </Form.Item>
                <center>
                    <Tooltip placement={"right"} title={<>
                        % (Percentage): Use this if you want to apply discount based on percentage.
                        <br/>
                        {RUPEE} (Fixed): Use this if you want to apply fixed amount of discount per item.
                    </>}>
                        <Form.Item name={"discountType"}
                        >
                            <SwitchSelect
                                defaultChecked={true}
                                checkedChildren={"%"}
                                unCheckedChildren={RUPEE}
                                valuesMap={{
                                    true: "percent",
                                    false: "fixed"
                                }}
                                reverseMap={{
                                    percent: true,
                                    fixed: false
                                }}
                                // onChange={(newVal) => console.log("## NewVal ##", newVal)}
                            />
                        </Form.Item>
                    </Tooltip>
                </center>

            </>
        },
        {
            // taxable
            weight: 3,
            align: 'right',
            content: <>{calculations.taxable || "---"}</>
        },
        ...(
            isIgst ?
                [
                    {
                        // igst
                        weight: 3,
                        align: 'right',
                        content: <>
                            {calculations.igst || "---"}
                        </>
                    },
                ] :
                [
                    {
                        // sgst
                        weight: 3,
                        align: 'right',
                        content: <>
                            {calculations.sgst || "---"}
                        </>
                    },
                    {
                        // cgst
                        weight: 3,
                        align: 'right',
                        content: <>
                            {calculations.cgst || "---"}
                        </>
                    },
                ]
        ),
        {
            // total
            weight: 3,
            align: 'right',
            
            content: <>{calculations.total || "---"}</>
        },
        {
            // actions
            weight: 2.5,
            align: 'left',
            content: <>
            
                <Button className={line.newMode ? "itemLineAddBtn" : "itemLineSaveBtn"} onClick={() => {
                    form.validateFields().then((record) => {
                        if (!record.description) {
                            record.description = "";
                        }
                        console.log("## Record ##", record);
                        onSave(record);
                    }).catch(() => {
                    });
                }}
                    // type={"primary"}
                    // style={{background: "green", borderRadius: 8}}
                >{line.newMode ? <>
                    <PlusOutlined/> Add Line</> : <CheckOutlined/>}</Button>&nbsp;
                {
                    !line.newMode &&
                    <Button className={"itemLineCancelBtn"} onClick={onCancel}
                    ><CloseOutlined/></Button>
                }

            </>
        },
        // {
        //
        // }
        // {
        //     weight: 16,

        // }
    ];

    assignTableColsWidth(formCols);

    return (
        <div style={{border: '0px solid green',
            fontWeight: 'normal'
            // paddingTop: 8, paddingLeft: 8
        }}>
            <Form
                form={form}
                layout="horizontal"
                name="form_in_modal"
                initialValues={line}
                onValuesChange={(vals, allValues) => {
                    console.log("## Vals ##", allValues);
                    if (vals.discountType) {
                        setDiscountType(vals.discountType);
                    }

                    const clonedItem = _.cloneDeep(selectedItem);
                    allValues = _.cloneDeep(allValues);
                    if (!allValues.qty) {
                        allValues.qty = 1;
                    }
                    if (!allValues.taxRate && !clonedItem.taxRate) {
                        allValues.taxRate = 0;
                    }
                    if (!allValues.price) {
                        allValues.price = 0;
                    }
                    _.assign(clonedItem, allValues);
                    console.log("## Updated ##", clonedItem);
                    setSelectedItem(clonedItem);
                }}
                style={{width: '100%'}}
            >
                {
                    formCols.map(col => <div style={{
                        width: col.width,
                        float: 'left',
                        padding: 8,
                        textAlign: col.align || 'left'
                    }}>
                        {col.content}
                    </div>)
                }
            </Form>
        </div>
    )
}


const ItemsComponent = ({value = [], onChange, isIgst, isAbroad}) => {
    const stateItems = useSelector(state => state.oldState?.items) || [];
    const dbItemsMap = _.keyBy(stateItems, "_id");
    const {
        tableItems,
    } = formulateItems({items: value, dbItemsMap, isIgst, extraDiscount: {}, isAbroad});

    const renderContent = (value, row, index) => {
        const obj = {
            children: value,
            props: {},
        };
        if (row.editMode) {
            obj.props.colSpan = 0;
        }
        return obj;
    };

    const itemsColumns = [
        {title: "S.No.", weight: 1, render: (obj, curr, index) => {
                return {
                    children: <>{index + 1}</>,
                    props: {
                        style: obj.editMode ? {background: EDIT_MODE_BACKGROUND, border: EDIT_MODE_BORDER,
                            borderRight: 'none'} : {}
                    }
                }}
        },
        {
            title: "Item / Description",
            weight: 5,
            render: (obj, row, index) => {
                if (!obj.editMode) {
                    return <div>
                        {obj.name}
                        {obj.description && <span style={{fontWeight: 'normal'}}><br/>{obj.description}</span>}
                    </div>;
                }
                return {
                    children: (
                        <div>
                            <ItemFormLine
                                isIgst={isIgst}
                                isAbroad={isAbroad}
                                line={value[index]}
                                onSave={(record) => {
                                    console.log("## onSave Record ##", record);
                                    const newValue = _.cloneDeep(value);
                                    const isNewLine = !!obj.newMode;

                                    record.taxRate = dbItemsMap[record._id].taxRate;
                                    record.editMode = false;
                                    record.newMode = false;
                                    if (isNewLine) {
                                        console.log("## this is new ##");
                                        newValue.push(record);
                                    } else {
                                        console.log("## this is old ##");
                                        const currentItem = newValue[index];
                                        _.assign(currentItem, record);
                                    }

                                    onChange(_.cloneDeep(newValue));
                                }}
                                onCancel={() => {
                                    const newValue = _.cloneDeep(value);
                                    const currentItem = newValue[index];
                                    currentItem.editMode = false;
                                    onChange(newValue);
                                }}
                            />
                        </div>
                    ),
                    props: {
                        colSpan: 10,
                        style: {
                            padding: 0,
                            background: EDIT_MODE_BACKGROUND,
                            border: EDIT_MODE_BORDER,
                            borderLeft: 'none'
                        }
                    }
                }
            }
        },
        {title: "HSN/ SAC", dataIndex: 'hsn', render: renderContent, align: 'right', weight: 2},
        {title: "Qty", dataIndex: 'qty', render: renderContent, align: 'right', weight: 2},
        {title: "Rate", dataIndex: "price", render: renderContent, align: 'right', weight: 3},
        {title: "Discount", dataIndex: "discount", render: renderContent, align: 'right', weight: 3},
        {title: "Taxable Value (₹)", dataIndex: "taxable", render: renderContent, align: 'right', weight: 3},
        ...(
            isIgst ? [
                {title: "IGST (₹)", dataIndex: "igst", render: renderContent, align: 'right', weight: 3},
            ] : [
                {title: "CGST (₹)", dataIndex: "cgst", render: renderContent, align: 'right', weight: 3},
                {title: "SGST (₹)", dataIndex: "sgst", render: renderContent, align: 'right', weight: 3},
            ]
        ),
        {title: "Total (₹)", dataIndex: "total", render: renderContent, align: 'right', weight: 3},
        {
            title: "", weight: 2.5,
            render: (obj, curr, index) => {
                if (obj.editMode) {
                    return {
                        children: "",
                        props: {
                            colSpan: 0
                        }
                    }
                }
                return (
                    <div>
                        <Tooltip
                            title={"Edit Item Line"}>
                            <Button className={"btnCustom4 hoverOrangeBorder"}
                                    style={{
                                        background: '#ffecb3',
                                        color: '#ff6f00',
                                        padding: 0,
                                        height: 28,
                                        fontWeight: 'bold',
                                        fontSize: 13,
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                        marginTop: 2
                                    }}
                                    onClick={(e) => {
                                        const newValue = _.cloneDeep(value);
                                        const currentItem = newValue[index];
                                        currentItem.editMode = true;
                                        onChange(newValue);
                                    }}
                            >
                                <EditOutlined style={{color: '#ff6f00'}}/>
                            </Button>
                        </Tooltip>
                        &nbsp;
                        <Tooltip title={"Delete"}>
                            <Button className={"btnCustom4 hoverRedBorder"}
                                    style={{
                                        background: '#ffebee',
                                        color: '#b71c1c',
                                        padding: 0,
                                        height: 28,
                                        fontWeight: 'bold',
                                        fontSize: 13,
                                        paddingLeft: 8,
                                        paddingRight: 8
                                    }}
                                    onClick={(e) => {
                                        console.log("## DelValue ##", value);
                                        const newValue = _.cloneDeep(value);
                                        newValue.splice(index, 1)
                                        onChange(newValue);
                                        console.log("## Deleted ##");
                                    }}
                            ><DeleteOutlined style={{color: '#b71c1c'}} />
                            </Button>
                        </Tooltip>
                    </div>
                )
            }
        }
    ];

    assignTableColsWidth(itemsColumns);

    return (
        <div style={{
            // background: '#e3f2fd',
            background: '#fff',
            padding: 8, borderRadius: 8,
            border: '1px solid lightgrey'
        }}>
            <h3>Select Products</h3>
            <Table
                className={"itemsTable"}
                pagination={false}
                style={{
                    fontWeight: 'bold',
                    border: '1px solid lightgrey'
                }}
                columns={itemsColumns}
                dataSource={[...tableItems, {editMode: true, newMode: true}]}
            />

        </div>
    )
}

export default ItemsComponent;